import { DInput, SComponent, SDate, createRef, _, Checkbox, Ref, If, route, Comp, Ariscat, Rq, RInput, HSelect, Table, RIcons, Repo, Btn } from "../../dump";
import { Panel } from "../../components/panel/panel";
import './styl.scss'
import { Link } from "preact-router";

export class Branch extends SComponent {
    async cmount(s,p) {
        this.pob = {};
        this.ID = +p.ID;

        if(!Repo.stg.cs__subDealersEnabled) return;

        this.pob = p.ID > 0 ? (await Rq.info("subdealer", p.ID)) : {};
        this.employes = (await Rq.sd("zamestnanec", 1)).rows;
        this.pob.nsSbdOnSubDActionParNtfEmail = (this.pob.nsSbdOnSubDActionParNtfEmail||"").replace("%40", "@");

        this.onLoad(this.pob.nsSbdNumber || "");

        delete this.pob.nsSbdPassword;
        this.timer = null;
        this.timer2 = 500;
        this.emailInp = createRef();
    }

    showError(error){
        Ref.notify(error, 6, "error");
        this.refresh();
        return false;
    }

    checkFil(name) {
        return String(name || "").trim().length > 1;
    }

    save = async () => {
		const pob = { ...this.pob };

        if(!this.checkFil(pob.nsSbdNumber)) return this.showError(_.brchNumNotFil);
        if(!this.checkFil(pob.nsSbdFullName)) return this.showError(_.usernameNotFilled);

        const badEmails = [pob.nsSbdLogin, pob.nsSbdEmail, pob.nsSbdOnSubDActionParNtfEmail]
            .filter(em=> em && !Ariscat.testEmail(em));

		if(badEmails.length) return this.showError(_.invalidEmail + ": " + badEmails.join(", "));

        if(pob.nsSbdPassword) {
            if(!Ariscat.testPsw(pob.nsSbdPassword, true)) return
    
            if(pob.nsSbdPassword != pob.nsSbdPassword_conf) {
                return this.showError(_.passwordsNotSame);
            }

            pob.nsSbdPassword = Ariscat.cypherPass(pob.nsSbdPassword);
        }

        delete pob.nsSbdPassword_conf;
        this.saved = "saving";
        this.refresh();

        //nsSbdNumber, nsSbdLogin, nsSbdPassword, přičemž nsSbdLogin 

        var res = await Rq.up("subdealer", 1, pob);
        
        if(res.ferror) return this.showError(res.error);

        this.refresh();

        if(this.ID == 0 && res.rows[0]) {
            route("/branches/" + res.rows[0].ID);
        }
	}

    saveVal=async (row, val, key)=> {
        this.saved = "saving";
        this.refresh();

        var res = await Rq.up(
            "subd_prod", 1, 
            {ID: row.ID, [key]: val}, 
            {iSubdealerID: this.ID, bAktivni: 1}
        );

        this.refresh();
    }

    delete = () => {

    }

    rnd(s) {     
        if(!Repo.stg.cs__subDealersEnabled) {
            return Ref.notify(_.modNotAlw, 10, "error");
        }
        
        return (
            <Comp className="dlrBranchs">
                <div className="compBar buttons">
                    <Btn add id="saveInf" onClick={this.save}><RIcons.Fa.FaSave /> {_.save}</Btn>
                    <Link href="/branches"><Btn close id="backAgenda"><RIcons.Fa.FaTimes /> {_.close}</Btn></Link>
                </div>	
                <div className="grid-2">
                    <Panel text={_.basicData}>
                        <div className="grid-2">
                            <RInput timer={this.timer} tname="branchNum" state={this.pob} item="nsSbdNumber" maxLength="20" disabled={false} onChange={() => this.onLoad(this.pob.nsSbdNumber || "")} />
                            <RInput timer={this.timer} tname="branchName" state={this.pob} item="nsSbdCompany" maxLength="200" disabled={false} />
                            <RInput timer={this.timer} tname="username" state={this.pob} item="nsSbdLogin" maxLength="100" disabled={false} onChange={()=>this.refresh()} />
                            <RInput timer={this.timer} tname="fullName" state={this.pob} item="nsSbdFullName" maxLength="200" disabled={false} />    
                            <RInput type="password" tname="password" timer={this.timer} state={this.pob} item="nsSbdPassword" maxLength="100" disabled={false} />
                            <RInput type="password" tname="passwordConfirmation" timer={this.timer} state={this.pob} item="nsSbdPassword_conf" maxLength="100" disabled={false} />
                        </div>   

                        <RInput timer={this.timer} tname="street" state={this.pob} item="nsSbdStreet" maxLength="200" disabled={false} />
                        <RInput timer={this.timer} tname="city" state={this.pob} item="nsSbdCity" maxLength="200" disabled={false} />
                        <RInput timer={this.timer} tname="psc" state={this.pob} item="nsSbdZIP" maxLength="20" disabled={false} />
                        <RInput timer={this.timer} tname="country" state={this.pob} item="nsSbdCountry" maxLength="200" disabled={false} />
                        <RInput timer={this.timer} tname="ico" state={this.pob} item="nsSbdICO" maxLength="30" disabled={false} />
                        <RInput timer={this.timer} tname="dic" state={this.pob} item="nsSbdDIC" maxLength="30" disabled={false} />
                        <RInput timer={this.timer} tname="web" state={this.pob} item="nsSbdWebURL" maxLength="2000" disabled={false} />

                        <RInput 
                            timer={this.timer} 
                            state={this.pob} 
                            item="nsSbdEmail" 
                            placeholder={this.pob.nsSbdLogin} 
                            maxLength="30" 
                            disabled={false} 
                            ref={this.emailInp}
                            tname="email" 
                        />

                        <RInput timer={this.timer} tname="telephone" state={this.pob} item="nsSbdPhone" maxLength="200" disabled={false} />
                        <RInput timer={this.timer} tname="bank" state={this.pob} item="nsSbdBankDetails" maxLength="200" disabled={false} />
                        <RInput timer={this.timer} tname="iban" state={this.pob} item="nsSbdIBAN" maxLength="82" disabled={false} /> 

                        <HSelect
                            state={this.pob}
                            label="nsZamNazev"
                            item="iSbdTrgParUserID_Staff"
                            values={this.employes}
                            tname="dealerForBrch"
                        />

                        <RInput type="tarea" timer={this.timer} tname="userNote" state={this.pob} item="nsSbdPrivateNote" disabled={false} />

                    </Panel> 
                    <div>
                        <Panel text={_.rabatCharter}>
                            <Table 
                                recordset="subd_prod" 
                                className="brchTab"
                                sw_iSubdealerID={this.ID} 
                                sw_bAktivni="1" 
                                cols="0" 
                                customCols={true} 
                                showSearch={false}
                                showFilters={false}
                                autoReload={false}
                                ref={this.prodTable}
                            >
                                <tab-col 
                                    name="bSbpSubDAccessible" 
                                    type="format" 
                                    format={r => 
                                        <Checkbox state={r} item="bSbpSubDAccessible" onCheck={val=> this.saveVal(r, val, "bSbpSubDAccessible")} />
                                    } 
                                />
                                <tab-col name="iSbpZarukaM" type="format" format={r => 
                                        <RInput type="number" min="0" timer={this.timer2} state={r} item="iSbpZarukaM" onChange={val=> this.saveVal(r, val, "iSbpZarukaM")} />
                                    }
                                />

                                <tab-col name="fSbpMarzePrc" type="format" format={r => {
                                    r.fSbpMarzePrc = +r.fSbpMarzePrc;

                                    return (
                                        <RInput type="number" 
                                            timer={this.timer2} 
                                            state={r} item="fSbpMarzePrc" 
                                            onChange={val=> this.saveVal(r, val, "fSbpMarzePrc")} 
                                        />
                                        )
                                    }}
                                />
                            </Table>

                            <RInput timer={this.timer} state={this.pob} tname="emForNotifInOr" item="nsSbdOnSubDActionParNtfEmail" maxLength="30" disabled={false} />
                        </Panel>
                        <Panel text={_.rigtBrch}>
                                <Checkbox state={this.pob} item="bSbdActive" tname="active" />
                                <Checkbox state={this.pob} item="bSbdCanCreateOffers" tname="brchOfers" />
                                <Checkbox state={this.pob} item="bSbdCanUseSKDisc" name="brchCanUseStorCrd" />                  
                                <Checkbox state={this.pob} item="bSbdCanSeeObjState" tname="brchSeeOrProd" />
                                <Checkbox state={this.pob} item="bSbdAcceptStrvMsg" tname="forwMsgManf"  />
                                <Checkbox state={this.pob} item="bSbdCanSeePurchasePrices" tname="seePurchPric" />
                        </Panel>
                    </div>
                </div>
            </Comp>
        )
    }
}