import { SComponent, Table, HSelect, createRef, Ariscat, Repo, Rq, SDate, _, xmlParser, Ref, Http, route, Btn, If } from "../dump";

export class Popob extends SComponent {
    async cmount(s, p) {
        this.loading = true;

        this.refresh();
        
        if (this.props.url == "/orders") {
            this.recordset = "objednavka";
            this.do = "order";
            this.pobUrl = "/orders//order/";
            this.pf = "Obj";
        }
        else {
            this.recordset = "poptavka";
            this.do = "demand";
            this.pobUrl = "/demands//demand/";
            this.pf = "Pop";
        }

        this.showDealer = false;
        this.obchodnici = {};
        this.record = {};
        this.hasCSO = Ariscat.hasPrivilege("customerOffer");

        //console.log("ID", this.props.ID);

        if (!Repo.stg.bAnyVrbcJoined) {
            Ref.notify(_.noVrbcConn, 20, 'error');
        }

        this.loading = false;
        this.refresh();
    }

    async copy(rec, withoutDemand = false) {
        try {
            const data = withoutDemand ? {ID: rec.ID, [`i${this.pf}ParentZak`]: 0} : {};
            var res = await Rq.duplicateRecord(this.recordset, rec.ID, data);

            res === true ?
                Ref.notify(_.recordWasCopied, 5) :
                Ref.notify(_.error + ": " + res.error, 5, 'error');

            rec.reload();
        }
        catch(e) {
            Ref.notify(_.error + ": " + e.message, 5, 'error');
            console.error("res", e.message);
        }
    }

    async calculate(rec) {
        try {
            const info = await Rq.info(this.recordset, rec.ID);

            if (info[`ie${this.pf}}Stav`] == 1) {
                const send = await Ref.confirm.open("Info", _.reallySendReq);
                if (!send) return false;
            }

            var error = await Http.sendDemandor(rec.ID, 'KalkulaceVyrobcem');

            if(error) {
                Ref.notify(error, 5, 'error');
            }
            else {
                var msg = this.pf == "Obj" ? _.orderWasSendedToManufacturer : _.inquryWasSuccessfullSentToManufacturer;
                Ref.notify(msg, 4, 'success');
                this.refresh();
            }

            rec.reload();
		}
        catch (er) {
            Ref.notify(er, 6, 'error');
        }
    }

    rekServis = (rec, col) => {
        const val = rec[col.attrname] || "";

        if (!val) return "-";

        try {
            const json = JSON.parse(val || {});

            const format = m => {
                var inf = (m.draft || m.news) ? "inf1" : 'inf2';
                var title = m.catg + (m.num ? " " + m.num : "") + `, ${_.created} ` + SDate(m.crt);
                var img = "";

                if (m.draft)
                    img = `<span class="info fas fa-pen"></span>`;
                else if (m.news)
                    img = `<span class="info"><span class="txt">1</span></span>`;

                return `    
                    <span class="tabReklamace ${inf}">
                        ${img}
                        <span value="74" title="${title}">${m.catg}${m.num ? " " + m.num : ""}</span>
                    </span>
                `;
            }


            return <HSelect
                values={json}
                onChange={this.doReklamace}
                uid="id"
                empty={`${_.selComplaint} (${json.length})`}
            >
                <h-fop disabled={true}>{`<b>${_.selComplaint}</b>`}</h-fop>
                <h-op format={format} />
            </HSelect>
        }
        catch {
            return "";
        }
    }

    xmlList = (rec, col) => {
        const val = rec[col.attrname] || "";
        const invoices = xmlParser.parse(val);

        if (invoices.C === undefined) return "";

        const inv = Array.isArray(invoices.C) ? invoices.C : [invoices.C];

        return inv.map(m => <a href={"/invoices/search/" + m.iDocID}>{m.nsCisloDoc || m.nsDataName}</a>);
    }

    novaReklamace = async rec => {
        let objFakt = "Obj";
        let rcd = "objednavka";
        let data = await Rq.info(rcd, rec.ID);
        let cislo = data[`ns${objFakt}Cislo`];

        var req = { nsRsPopisZavady: "" }
        req[`iRs${objFakt}ID`] = rec.ID;
        req[`nsRsCislo` + objFakt] = cislo;

        const { rows } = await Rq.up("reksrv", 1, req, { nsDomainID: Ariscat.getDomainID() });

        if (rows.length) {
            location.href = ("/reclamation/" + rows[0].ID);
        }
    }

    doReklamace = (key, val, data) => {
        location.href = "/reclamation/" + data.id;
    }

    changeDealer = async (show, rec) => {
        if (rec && show) {
            this.rec = rec;
            this.record = await Rq.info(this.recordset, rec.ID);
        }

        if (!this.dealers) {
            const { rows } = await Rq.sd("zamestnanec", 1);
            this.dealers = rows;
        }

        Ref.modal.open(_.changeDealer,
            <div className="data">
                {_.selectDealer}
                <HSelect
                    state={this.record}
                    item={`i${this.pf}ObchodnikID`}
                    label="nsZamNazev"
                    values={this.dealers}
                    onChange={val => this.record[`i${this.pf}ObchodnikID`] = val}
                >
                </HSelect>
            </div>,
            <div className="modal-buttons">
                <Btn ok onClick={e => this.saveDealer()}>{_.confirm}</Btn>
            </div>

        )

        this.refresh();
    }

    saveDealer = async () => {
        Ref.modal.close();
        const res = await Rq.up(this.recordset, 1, { ID: this.record.ID, [`i${this.pf}ObchodnikID`]: this.record[`i${this.pf}ObchodnikID`] });

        //if(res.status) {
        Ref.notify(_.dealerWasChanged, 4, 'success');
        //}
        this.rec.reload();
    }

    async showOffer(rec) {
        if (rec[`i${this.pf}ParentZak`]) {
            route(`/custOffer/${this.recordset}/${rec.ID}`);
        }
        else {
            Ref.notify(_.creationDeal, 2);
            //const idZak = await Rq.newRecord("zakazka", {bZakAktivni: true});
            
            /*DODELAT
            const ag = new Agendy();
            const idZak = await ag.createNewRecord("zakazka", "");
            var res = await ag.saveHeadOffer(this.recordset, rec.ID, {[`i${this.pf}ParentZak`]: idZak })
            Ref.notify(_.dealCreated, 2);
            location.href = `/deals/customerOffer/deal/${idZak}/${this.do}/${rec.ID}`;
            */
        }
    }

    pdf = rec => {
        const pdf = rec.nsPopXMLPDFInfo || rec.nsObjXMLPDFInfo;
        if (!pdf) return;

        const xml = xmlParser.parse(pdf);

        if (xml && xml.Version && xml.Version.HEAD_pdf && xml.Version.HEAD_pdf.pdf) {
            const guid = xml.Version.HEAD_pdf.pdf.guidPDF;
            const name = xml.Version.HEAD_pdf.pdf.nsDataName;
            const code = rec.nsPopVrbcKod || rec.nsObjVrbcKod;

            const a = document.createElement('a');
            a.target = '_blank';
            a.href = `/genFile.php?shortcut=PO_PDF&vrbcKod=${code}&guidFile=${guid}&compress=0&ext=.pdf&filename=${name}`;
            a.click();
        }
        else {
            Ref.notify(_.erPDF, 4, "error");
            console.log("PDF", pdf);
        }
    }

    isReadOnly = rec => rec[`b${this.pf}ReadOnly`] === true;
    notReadOnly = rec => rec[`b${this.pf}ReadOnly`] !== true;

    checkPDF = rec => {
        const info = rec.nsObjXMLPDFInfo || rec.nsPopXMLPDFInfo;

        if (info && info.includes("nsDataName")) {
            return true;
        }

        return false
    }

    showPDFName = rec => {
        const info = rec.nsObjXMLPDFInfo || rec.nsPopXMLPDFInfo;

        if (!info || (info && !info.includes("nsDataName"))) return false;

        //console.log("info", info, xmlParser, rec);
        const xml = xmlParser.parse(info);

        if (xml && xml.Version && xml.Version.HEAD_pdf && xml.Version.HEAD_pdf.pdf) {
            return xml.Version.HEAD_pdf.pdf.nsDataName;
        }

        return _.offer + " (PDF)"
    }

    sendPopOb = async rec => {
		const wrnSoft = Repo.stg.cs__wrnOverdueInvoicesDaysSoft;
		const wrnHard = Repo.stg.cs__wrnOverdueInvoicesDaysHard;

        const {rows} = await Rq.sd("fakt_prij", 1);
        const now = SDate();
        const f1 = rows.filter(f => SDate.diff(f.dFpSplatnost, now).day > 7).length;
        const f2 = rows.filter(f => SDate.diff(f.dFpSplatnost, now).day > 14).length;

		if((wrnSoft || wrnHard) && (f1 || f2)) {
			var msg = (<div class="modal-body">
				<span id="dialogIcon"><RIcons.Fa.FaExclamationTriangle /></span>
				<p id="dialogText">
					Evidujeme u Vás {f1} faktur po splatnosti.<br/>
					Z toho je minimálně {f2} faktur po splatnosti více než 800 dnů.<br/>
					{_.ordersNotWilMad}<br/>
					<div class="line"></div>
					<center>{_.wantViewInvoices}</center>
				</p>
			</div>)

			var res = await Ref.confirm.open(_.warning, msg);
			
			if(res) {
				route("/invoices");
				return;
			}
		}

		this.inquireOrSend(rec, "Obj");
    }

    async inquireOrSend(rec, type = "Pop") {
        var sendRes = false;
    
        if(rec.nsStnAgenda == "poptavka") {
            sendRes = await Ref.confirm.open(_.warning, _.sendDemandToManufacturer, _.sendImmediately, _.changeBeforeSend);
        }
        else {
            sendRes = await Ref.confirm.open(_.warning, _.sendOrderToManufacturerConfirm, _.sendImmediately, _.changeBeforeSend);
        }
    
        if(!sendRes) return;
    
        var action = type == "Obj" ? "Objednat" : "KalkulaceVyrobcem";
        var error = await Http.sendDemandor(rec.ID, action);
    
        if(error) {
            Ref.notify(error, 5, 'error');
        }
        else {
            var msg = type == "Obj" ? _.orderWasSendedToManufacturer : _.inquryWasSuccessfullSentToManufacturer;
            Ref.notify(msg, 4, 'success');
            rec.reload();
        }
    }

    createNew = async () => {
        const id = await Rq.newRecord(this.recordset, {[`b${this.pf}Aktivni`]: true});
        route(this.do + "s/" + id);
    }

    rnd(s) {
        return (
            <div className="comp popob">
                <If is={!this.loading}>
                    <Table 
                        recordset={this.recordset} 
                        sw_bPendingOnly="1" 
                        cols="0" 
                        detail={rec => this.recordset == "objednavka" ? route("/orders/" + rec.ID) : route("/demands/" + rec.ID)}
                    >
                        <bar-button
                            type="add"
                            icon="Plus"
                            text=""
                            visible={() => Repo.stg.bAnyVrbcJoined}
                            click={async e => {
                                Ref.notify(this.recordset == "poptavka" ? _.dmdCreat : _.ordCreat, 2);
                                var newID = await Rq.newRecord(this.recordset, {[`b${this.pf}Aktivni`]: true });
                                console.log("ID", newID);
                                Ref.notify(_.itemCreated, 2);
                                console.log("route", `/${this.do}/${newID}`);
                                route(`/${this.do}s/${newID}`);
                            }}
                        />
                        <tab-button
                            type="warning"
                            icon="Edit"
                            visible={this.notReadOnly}
                            disabled={() => !Repo.stg.bAnyVrbcJoined}
                            link={rec => {
                                if (rec.iPopParentZak)
                                    return "/deals//deal/" + rec.iPopParentZak + "/demand/" + rec.ID;
                                else if (rec.iObjParentZak)
                                    return "/deals//deal/" + rec.iObjParentZak + "/order/" + rec.ID;
                                else
                                    return this.pobUrl + rec.ID;
                            }}
                        />
                        <tab-button
                            type="info"
                            icon="Eye"
                            visible={this.isReadOnly}
                            disabled={() => !Repo.stg.bAnyVrbcJoined}
                            link={rec => {
                                if (rec.iPopParentZak)
                                    return "/deals//deal/" + rec.iPopParentZak + "/demand/" + rec.ID;
                                else if (rec.iObjParentZak)
                                    return "/deals//deal/" + rec.iObjParentZak + "/order/" + rec.ID;
                                else
                                    return this.pobUrl + rec.ID;
                            }}
                        />
                        <tab-button
                            type="primary"
                            icon="Cog"
                        >
                            <b-item icon="Share"
                                text={_.copy}
                                visible={r => !r[`b${this.pf}AnyMissingDV`] && r[`i${this.pf}ParentZak`]}
                                click={rec => this.copy(rec, false)}
                            />
                            <b-item
                                icon="Share"
                                text={_.copyBezObchodu}
                                visible={r => !r[`b${this.pf}AnyMissingDV`]}
                                click={rec => this.copy(rec, true)}
                            />
                            <b-item icon="Share"
                                text={_.order}
                                visible={r => !Repo.stg.cs__popobjCantOrderFromGridPopup && !r[`b${this.pf}AnyMissingDV`] && (r[`ie${this.pf}Stav`] == 2 || r[`ie${this.pf}Stav`] == 3)}
                                click={rec => this.sendPopOb(rec)}
                            />
                            <b-item
                                icon="Calculator"
                                visible={r => !Repo.stg.cs__popobjCantOrderFromGridPopup && this.notReadOnly(r)}
                                text={_.calculate_at_manufacturer}
                                click={rec => this.calculate(rec)}
                            />
                            <b-item
                                icon="Trash"
                                text={_.delete}
                                visible={r => this.notReadOnly(r) || r[`ie${this.pf}Stav`] == 11}
                                click={rec => rec.del()}
                            />
                            <b-item
                                icon="Users"
                                text={_.changeDealer}
                                click={rec => this.changeDealer(true, rec)}
                            />
                            <b-item
                                icon="Users"
                                text={_.customersOffer}
                                visible={r => this.hasCSO && !r[`b${this.pf}AnyMissingDV`] && ![1, 11, 5, 22].includes(r[`ie${this.pf}Stav`])}
                                click={rec => this.showOffer(rec)}
                            />
                            <b-item
                                icon="Users"
                                //reklamovat se da jen objednavka (bObjReadOnly)
                                visible={r => r.bObjReadOnly && (r.ieObjStav != 12) && r.nsObjVrbcKod == Repo.prod.nsVrbcVrbcKod && Repo.stg.cs__rekSrvEnabled}
                                text={_.claim}
                                click={rec => this.novaReklamace(rec)}
                            />
                            <b-item
                                icon="Download"
                                visible={r => this.checkPDF(r)}
                                textFN={r => this.showPDFName(r)}
                                click={r => this.pdf(r)}
                            />
                        </tab-button>
                        <tab-col name="nsObjRekSrvJS" type="format" format={this.rekServis} />
                        <tab-col name="nsObjXMLFPLst" type="format" format={this.xmlList} />
                    </Table>
                </If>
            </div>
        )
    }
}