import './styl.scss'
import * as Chartjs from 'chart.js'
import { SComponent, _, Table, route, SDate, Ariscat, Rq, DInput, HSelect, createRef, Checkbox } from '../../dump'
import { IOTGraphMini } from './IOTGraphMini';
import { RangeMultiple } from './RangeMul';

window.ch = Chartjs;

export class IOTGraph extends SComponent {
    async cmount(s,p) {
        this.chartEl = createRef();
        this.chartMiniEl = createRef();
        this.rangeEl = createRef();

        this.dotCount = 10;
        this.showLines = false;
        this.dateEnd = SDate();
        this.data = { rows: []};
        this.dataCopy = [];
        this.dateEvent = true;
        this.indexStart = 0;
        this.loading = false;
        this.pointRadius = 2;
        this.logaritmic = false;
        
        this.state = {
            dotCount: 10,
            showLines: true,
            lineTension: 0.0,
            dateStart: SDate("1.1.2021"),
            dateEnd: SDate(),
            graphType: "Bodový",
            rangeMin: 0,
            rangeMax: 100,
            rangeVals: [0,100]
        }
        //console.log("dc1", this.dataCopy);

        this.loading = true;
        this.data = await Rq.sd("iot_device_data", 1, 0, { sDeviceIdLst: p.ID, dDTFr: "2020-01-01", dDTTo: SDate.addDate(1).ymd });
        //data se musi seradit, protoze nekdy prijdou neserazeny z backendu a v grafu by se pak nezobrazovaly dobre
        this.data.rows.sort((a,b) => a.dDevdCasZpravy - b.dDevdCasZpravy);
        this.dataCopy = [...this.data.rows];
        
        //console.log("dc2", this.dataCopy);

        if(!this.data.rows.length) {
            Ariscat.alert.hlaska("Pro tento modul nejsou k dispozici data!", 5, "error");
        }

        this.visCols = this.data.cols.filter(f => 
            //f.visible == "TRUE" && 
            f.attrname != "ID" && 
            (f.fieldtype == "i4" || f.fieldtype == "b" || f.fieldtype == "fixedFMT")
        );

        var idx = 0;
        var avgCur = {...this.visCols.find((f,i) => f.attrname == "iDevdAverageCurrent" ? (idx = i) && true : false)}

        if(avgCur && avgCur.attrname) {
            avgCur.attrname = "iDevdAverageCurrent2";
            avgCur.caption += " A";
            this.visCols.splice(idx+1, 0, avgCur);
        }

        setTimeout(async () => {
            this.state.dateEnd = this.data.rows[this.data.rows.length-1].dDevdCasZpravy || SDate();

            this.draw();
            this.loading = false;
            this.refresh();
            this.chartMiniEl.current.draw();
        }, 10);

        this.onLoad(this.props.ID);
        Ref.Header.tagLinks = ["/iot"];
    }

    componentWillReceiveProps(p) {
        this.props = p;
        this.cmount(this.state,p);
    }

    switchDatasets =()=> {
        this.disableAll = !this.disableAll;
        this.refresh();
        this.chart.data.datasets.forEach(d => d.hidden = !this.disableAll);
        this.draw();
    }

    switchLog =(val)=> {
        this.draw();
    }

    switchFilter =(val)=> {
        console.log("sw", val);
        //this.filter0 = val;
        this.draw();
    }

    rnd = (s, p) => {
        return (
            <div className="comp iotGraph">
                <div className="panel">
                    <header>{`Přehled grafu IoT modulu č. ` + p.ID}</header>

                    <div className="filter buttons">
                        <DInput 
                            tname="Dfrom"
                            onChange={this.setDate} 
                            state={this.state} 
                            item="dateStart" 
                        />
                        <DInput
                            tname="Dto"
                            onChange={this.setDate} 
                            state={this.state} 
                            item="dateEnd" 
                        />
                        <HSelect
                            state={this.state} 
                            style={{width: "12rem"}}
                            name="Typ grafu"
                            values={[
                                {ID: 0, val: "Bodový"},
                                {ID: 1, val: "Spojnicový"},
                                {ID: 2, val: "Spojnicový + body"},
                                {ID: 3, val: "Bodový detailní"},

                            ]}
                            item="graphType"
                            label="val" 
                            onChange={this.changeTypeGraph}
                        ></HSelect>
                        
                        <Checkbox state={this} item="disableAll" onCheck={this.switchDatasets} name="Zakázat vše" />
                        <Checkbox state={this} item="logaritmic" onCheck={this.draw} name="Logaritmické" />
                        <Checkbox state={this} item="filter0" name="Filtr 0" onCheck={this.draw} />

                        <div className="range">
                            {/*<div>Interpolace: <span id="intRange">{this.state.interpolation}</span>
                                <Input 
                                    type="range" 
                                    min="0" 
                                    max="7" 
                                    onChange={this.draw} 
                                    state={this.state} 
                                    item=lineTens"ion" 
                                />
                            </div>*/}
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <header>Graf</header>

                    <div className={this.cln({loading:1, dnone: !this.loading})}>{_.loading}</div>

                    <canvas ref={this.chartEl} id="chartA" style={{display: "block", width: "500px", height: "150px"}} />
                    <div style={{position: "relative", top: "15px"}} className={this.cln({dnone: this.loading})}>
                        <RangeMultiple 
                            min={s.rangeMin} 
                            max={s.rangeMax} 
                            values={s.rangeVals} 
                            onChange={v=>this.rmChange(v)} 
                            ref={this.rangeEl}
                        />

                        <IOTGraphMini data={this.data} dataCopy={this.dataCopy} cols={this.visCols} ref={this.chartMiniEl} />
                    </div>
                </div>
            </div>
        )
    }

    changeTypeGraph=(val, key, item)=> {
        //this.showLines = (val == "Spojnicový");

        switch(item.ID) {
            case 0: 
                this.showLines = false;
                this.pointRadius = 3;
                break;
            case 1: 
                this.showLines = true;
                this.pointRadius = 0;
                break;
            case 2: 
                this.showLines = true;
                this.pointRadius = 3;
                break;
            case 3: 
                this.showLines = false;
                this.pointRadius = 1;
                break;
        }

        //console.log(item.ID);

        this.draw();
    }

    rmChange=(vals)=> {
        //console.log(vals);
        this.refresh();
        this.chartZoom(...vals.sort((a,b) => a-b));
    }    

    chartZoom(min, max) {
        let dateFirst = this.data.rows[0].dDevdCasZpravy;
        let dateLast = this.data.rows[this.data.rows.length-1].dDevdCasZpravy;
        let dif = dateLast - dateFirst;

        let difA = dif * (min/100);
        let difB = dif * (max/100);
        let dateStart = SDate(dateFirst.time + difA);
        let dateEnd = SDate(dateFirst.time + difB);
        //console.log("min", dateFirst, dateLast, dateStart, dateEnd, difA, difB);

        //this.dataCopy = [...this.data.rows].splice(min, max-min);
        this.dataCopy = this.data.rows.filter(f => f.dDevdCasZpravy >= dateStart && f.dDevdCasZpravy <= dateEnd);

        if(!this.dataCopy.length) return;

        this.dateMin = this.dataCopy[0].dDevdCasZpravy;
        this.dateMax = this.dataCopy[this.dataCopy.length-1].dDevdCasZpravy;
        this.dateEvent = false;

        this.state.dateStart = dateStart;
        this.state.dateEnd = dateEnd;
        

        this.draw();
        this.dateEvent = true;
        this.refresh();
    }

    setDate = () => {
        if(!this.dateEvent) return;

        let startDate = this.state.dateStart;
        let endDate = this.state.dateEnd;
        
        if(endDate <= startDate) {
            startDate = endDate.copy;
            endDate = endDate.addDate(1);
        }

        //osetreni, aby to hledalo i aktualni datum
        endDate.hour = 23;
        endDate.min = 59;

        const fRows = this.data.rows.filter(f => f.dDevdCasZpravy >= startDate && f.dDevdCasZpravy <= endDate);
        
        this.indexStart = this.data.rows.indexOf(fRows[0]);
        this.indexEnd = this.data.rows.indexOf(fRows[fRows.length-1]);

        if(!this.indexStart == "-1" || !this.indexEnd == "-1") {
            //Ariscat.alert.hlaska(_.bigFile + ' 70 kB.', 4, 'error');
            console.error("zadaný datum nebyl v datech nalezen", this.indexStart, this.indexEnd);
            return;
        };

        const starti =  this.data.rows[this.indexStart];//.dDevdCasZpravy;
        const endi = this.data.rows[this.indexEnd];//.dDevdCasZpravy;

        if(!starti || !endi) {
            return Ariscat.alert.hlaska("Zadaný datum není platný!", 2, "error");
        }

        const start = starti.dDevdCasZpravy;
        const end = endi.dDevdCasZpravy;

        if(!start.compareDate(startDate) || !end.compareDate(endDate)) {
            Ariscat.alert.hlaska("Tento datum nebyl v datech nalezen. Bude nastavena nejbližší hodnota.", 2, 'error');
        }

        //setTimeout(() => $(e.target).datepicker('setDate', e.target.id == "dateStart" ? start : end), 5);

        this.dataCopy = [...this.data.rows].splice(this.indexStart, this.indexEnd || this.data.rows.length);

        this.state.rangeMax = this.data.rows.length;
        //console.log("vals", this.indexStart, this.indexEnd, this.data.rows.length);
        this.state.rangeVals = [this.indexStart, this.indexEnd || this.data.rows.length];

        //Ariscat.alert.dialog("Chyba", "Pro tento den nejsou k dispozici data.<br>Bude nastaven nejbližší den, pro který existuje záznam.", 1);
        this.rangeEl.current.init(this.state.rangeVals, this.state.rangeMax);
        this.draw();
    }

    init(data) {
        this.data = data;
        data.rowsCopy = [...data.rows];
        this.dateMin = this.data.rows.length ? this.data.rows[0].dDevdCasZpravy : SDate();
        this.dateMax = (this.data.rows[this.data.rows.length-1]||{}).dDevdCasZpravy || SDate();

        this.dateEvent = false;

        this.draw();
        this.dateEvent = true;
    }

    draw = () => {  
        //$('#dateStart').datepicker("update", this.dateMin);
        //$('#dateEnd').datepicker("update", this.dateMax);

        let date = this.state.dateEnd.copy;
        let labels = [];

        this.ds = {}

        for(let row of this.dataCopy) {
            labels.push(date);
            //const row = this.data.rows.find(f => f.dDevdCasZpravy.dmy == date.dmy) || {};

            const d = SDate(row.dDevdCasZpravy);
            labels.push(d);

            for(let vs of this.visCols) {
                var key = vs.attrname;
                this.ds[key] = this.ds[key] || [];

                if(this.filter0 && row[key] == 0) continue;

                if(key == "iDevdAverageCurrent" && !row.bDevdAvgCurrentAnomaly && row.iDevdAverageCurrent !== undefined)
                    this.ds.iDevdAverageCurrent.push({x: d, y: +row.iDevdAverageCurrent });    
                else if(key == "iDevdAverageCurrent2" && row.bDevdAvgCurrentAnomaly && row.iDevdAverageCurrent !== undefined)
                    this.ds.iDevdAverageCurrent2.push({x: d, y: +row.iDevdAverageCurrent });    
                else if(row[key] !== undefined && key !== "iDevdAverageCurrent" && key !== "iDevdAverageCurrent2") {
                    this.ds[key].push({x: d, y: +row[key] });
                }
            }
        }

        const config = this.chart ? this.chart.config : this.chartConfig();

        for(let ds of config.data.datasets) {
            ds.data = this.ds[ds.key];
        }

        config.data.labels = labels;//.map(m => m.dmy);
        config.options.showLines = this.showLines;

        //Chart.defaults.global.elements.point.radius = this.pointRadius;
        //config.options.pointRadius = this.pointRadius;

        if(config.options.elements) {
            config.options.elements.line.tension = this.state.lineTension;
        }

        if(this.chart) {
            this.chart && this.chart.data.datasets.forEach(d => d.pointRadius = this.pointRadius);
            this.chart.options.scales.yAxes[0].type = this.logaritmic ? "logarithmic" : "linear";
            //this.chart.options.scales.x.min = (this.dateMin || this.state.dateStart).valueOf();
            //this.chart.options.scales.x.max = (this.dateMax || this.state.dateEnd).valueOf();
            this.chart.update();
            //console.log(this.chart.canvas.clientWidth);
            //this.rangeMul.width = this.chart.canvas.clientWidth;
        }
        else {
            this.chart = new Chartjs.Chart(this.chartEl.current, config);
        }
    }

    getDataset(atrName, color) {
        const col = this.data?.cols?.find(f => f.attrname == atrName) || {};

        return {
            label: col.caption,
            fill: false,
            backgroundColor: color,
            borderColor: color,
        }
    }

    createDatasets() {
        var colors = [
            "red", "blue", "green", "#FFFF00", "#00FFFF", "#FF00FF", "orange", "purple", "black", "brown", 
            "gold", "grey", "#7CFC00", "#EE82EE", "#FF6347", "#B22222", "#D2691E", "#7FFF00", "#7FFF00", "#FF1493", "#000088", "#008800", "#880000",
        ];

        return this.visCols.map((col,i) => {
            return {
                label: col.caption,
                fill: false,
                backgroundColor: colors[i],
                borderColor: colors[i],
                key: col.attrname,
                pointRadius: this.pointRadius,
                //hidden: col.visible != "TRUE"
            }
        })
    }

    chartConfig() {
        return {
            type: 'line',
            data: {
                datasets: this.createDatasets()
            },
            options: {
                responsive: true,
                animation: false,
                spanGaps: true,
                //parsing: false,
                //normalized: true,
                title: {
                    display: false,
                    text: 'Zařízení 1'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        display: true,
                        time: {
                            //parser: "MM/DD/YYYY HH:mm",
                            //round: 'day',
                            //distribution: 'linear',
                            //unit: "day",
                            tooltipFormat: 'DD.MM.YY HH:mm',
                            displayFormats: {
                               day: 'DD.MM.YY',
                               hour: 'DD. HH:mm',
                               minute: 'HH:mm',
                               second: 'HH:mm:ss',
                            }
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Datum'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        }
                    }]
                },
            }
        }
    }
}