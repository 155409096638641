import { SComponent, RInput, DInput, HSelect, createRef, DpComponent, If, Comp } from "../dump";
import './styl.scss'

  
export class Test extends SComponent {
  rnd() {
      return (
        <Comp>test</Comp>
      );
  }
}