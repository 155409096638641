import { SComponent, RInput, _, Repo, HSelect, If, Comp, Http, Ariscat, RIcons } from "../../dump";

export class InfoComp extends SComponent {
  async cmount() {
    this.data = Repo.dealer;
    this.seznamMen = [];

    document.addEventListener("dataLoaded", () => {
      this.data = Repo.dealer;
      this.refresh();
    });

    const {rows} = await Rq.sd("mena", 1);
    this.seznamMen = rows;
  }

  saveBaseInfoAll = async (val, key) => {
    if (key == "fDealerDefDPHPrc") {
      val = val.replace(/\s/g, "");

      if (!val.match(/^[+-]?\d+([\.\,]\d+)?$/)) {
        this.set.saved = "error";
        return (this.set.saved = _.wrongDPHFormat + ": " + val);
      }
    }

    const dealerData = Object.entries(this.data)
      .filter(
        ([k, v]) =>
          //k != "nsDealerLogo" &&
          k != "nsDealerGoogleCalID" &&
          k != "nsDealerKod" &&
          k != "bDealerUsesGoogleCal" &&
          k != "Vyrobce"
      )
      .map(([k, v]) => `${k}="${Ariscat.htmlEntities(v)}"`)
      .join(" ");

    await this.saveDS(`<Dealer ${dealerData}></Dealer>`);
  };

  async saveDS(dealer) {
    var error = await Http.dealerSettings(dealer);
    //console.log("r", error);

    if(error) {
      Ref.notify(error, 10, "error");
    }
  }
  
	selectLogo = async e => {
		let file = await Ariscat.selectFile("base", ".png");

		if(!file.type.includes("image")) {
			return Ref.notify(_.fileNotPic, 6, "error");
		}
		else if(file.size > 300 * 1024) {
			return Ref.notify(_.fileCannotLarger + " 300kB!", 6, "error");
		}
		else {
			this.file = file;
      this.data.nsDealerLogo = file.data;
      this.refresh();
      this.saveBaseInfoAll(file.data, "nsDealerLogo");
		}
	}

  panelLogo = () => (
    //<div className={this.cln({ "grid-3": 1, logoDisabled: !this.logoEnabled })} >
    <If is={Repo.stg.cs__settingLogo}>
      <div className="panel imgUp">
          <header>{_.logoStg}</header>
          <div
              className={this.cln({ imagePreview: 1, disabled: this.notAdmin })}
              onClick={this.selectLogo}
              style={{
                  backgroundImage: this.data.nsDealerLogo ? `url(data:image/png;base64,${this.data.nsDealerLogo})` : null,
              }}
          />
          <If is={!this.notAdmin}>
              <label>{_.logoInfo}. {_.clickSelect}.</label>
          </If>
      </div>
    </If>
  );

  panelInfoNext = () => (
    <div className="grid-2 panel">
        <header>{_.nextInf}</header>
        <div className="grid-2 settingsGrid">
        <label>{_.regLang}</label>
            <RInput
                disabled={true}
                state={this.data}
                timer="800"
                item="nsDealerRegLang"
                onChange={this.saveBaseInfoAll}
            />
        <If is={Repo.stg.cs__popobjOfrShowEditTextABC}>
            <label>{_.nsDealerOfferFooterA}</label>
            <RInput
            type="tarea"
            state={this.data}
            timer="800"
            item="nsDealerOfferFooterA"
            onChange={this.saveBaseInfoAll}
            />

            <label>{_.nsDealerOfferFooterB}</label>
            <RInput
            type="tarea"
            state={this.data}
            timer="800"
            item="nsDealerOfferFooterB"
            onChange={this.saveBaseInfoAll}
            />

            <label>{_.nsDealerOfferFooterC}</label>
            <RInput
            type="tarea"
            state={this.data}
            timer="800"
            item="nsDealerOfferFooterC"
            onChange={this.saveBaseInfoAll}
            />
        </If>

        <label>{_.nsDealerDodatInfo}</label>
        <RInput
            type="tarea"
            state={this.data}
            timer="800"
            item="nsDealerDodatInfo"
            onChange={this.saveBaseInfoAll}
        />

        <label>{_.calendarName}</label>
        <RInput
            state={this.cal}
            item="summary"
            timer="800"
            onChange={this.saveCalendar}
            disabled={this.notAdmin}
            maxLength="50"
        />
      </div>
    </div>
  );

  panelInfo = () => (
    <div className="panel grid-2 settingsGrid">
    <header>{_.infoAboutComp}</header>
      <div className="grid-2">
        <label>{_.companyName}</label>
        <RInput
          state={this.data}
          item="nsDealerNazev"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="100"
        />

        <label>{_.street}</label>
        <RInput
          state={this.data}
          item="nsDealerUlice"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="100"
        />

        <label>{_.city}</label>
        <RInput
          state={this.data}
          item="nsDealerMesto"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="100"
        />

        <label>{_.psc}</label>
        <RInput
          state={this.data}
          item="nsDealerPSC"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="10"
        />

        <label>{_.country}</label>
        <RInput
          state={this.data}
          item="nsDealerZeme"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="100"
        />

        <label>{_.ico}</label>
        <RInput
          state={this.data}
          item="nsDealerICO"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="15"
        />

        <label>{_.dic}</label>
        <RInput
          state={this.data}
          item="nsDealerDIC"
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          maxLength="15"
        />

        <If is={Repo.stg.cs__settingWWW}>
          <label>{_.www}</label>
          <RInput
            state={this.data}
            item="nsDealerWWW"
            timer="800"
            onChange={this.saveBaseInfoAll}
            disabled={this.notAdmin}
            maxLength="1000"
          />

          <label>{_.email}</label>
          <RInput
            state={this.data}
            item="nsDealerOrgOfrEmail"
            timer="800"
            onChange={this.saveBaseInfoAll}
            disabled={this.notAdmin}
            maxLength="1000"
          />

          <label>{_.telephone}</label>
          <RInput
            state={this.data}
            item="nsDealerOrgOfrTel"
            timer="800"
            onChange={this.saveBaseInfoAll}
            disabled={this.notAdmin}
            maxLength="1000"
          />

          <label>{_.bank}</label>
          <RInput
            state={this.data}
            item="nsDealerBank"
            timer="800"
            onChange={this.saveBaseInfoAll}
            disabled={this.notAdmin}
            maxLength="100"
          />

          <label>{_.iban}</label>
          <RInput
            state={this.data}
            item="nsDealerIBAN"
            timer="800"
            onChange={this.saveBaseInfoAll}
            disabled={this.notAdmin}
            maxLength="41"
          />

          <label>{_.text}</label>
          <RInput
            state={this.data}
            item="nsDealerText"
            timer="800"
            onChange={this.saveBaseInfoAll}
            disabled={this.notAdmin}
            maxLength="4000"
          />
        </If>

        <label>{_.defaultDPH} %</label>
        <RInput
          state={this.data}
          item="fDealerDefDPHPrc"
          format={f => (+f)}
          timer="800"
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
          type="number"
          maxLength="14"
        />

        <label>{_.defaultCurrency}</label>
        <HSelect
          state={this.data}
          item="iDealerDefMenaID"
          label="nsMenaKod"
          values={this.seznamMen}
          onChange={this.saveBaseInfoAll}
          disabled={this.notAdmin}
        />

        <span></span>
        <span className="help-block">
          <RIcons.Fa.FaInfoCircle /> {_.defaultCurrencyHelp}
        </span>
      </div>
    </div>
  )



  rnd = (s, p) => (
    <Comp className="settings panels grid-2">
        <this.panelInfo />
        <div>
            <this.panelLogo />
            <this.panelInfoNext />
        </div>
    </Comp>
  );
}
