//Třída na formátování datumu
const SDate_ = class extends Date {
    constructor(...args) {
        const argsOrg = [...args];

        //vezme cesky format datumu a prevede ho na SDate
        if(args.length && typeof args[0] == "string" && args[0].includes(".")) {
            let split = args[0].split(".");

            if(split.length == 3) {
                //datum s hod a min
                if(split[2].includes(" ")) {
                    //rozlozi na (rok hod:min)
                    const yhm = split[2].match(/(\d+) (\d+)\:(\d+)/);

                    if(yhm && yhm.length == 4) {
                        //prevede zkaceny rok na dlouhy (21 => 2021)
                        if(yhm[1] < 100) yhm[1] = 2000 + +yhm[1];

                                //rok    //mesic-1    //den     //hod   /min
                        args = [ yhm[1], split[1] -1, split[0], yhm[2], yhm[3] ];
                    }
                }
                else {
                    //prevede zkaceny rok na dlouhy (21 => 2021)
                    if(split[2] < 100) split[2] = 2000 + +split[2];

                            //rok     //mesic-1    //den  
                    args = [ split[2], split[1]-1, split[0] ];
                }
            }
        }

        const a = args[0];
        
        //empty value test
        (a === "" || a === undefined || a === null || a === false || a === 0) ? 
            super() : super(...args);
        
        this.args = argsOrg;
        this.i = this;
    }
  
    format(fm) {
        const d = this.i;

        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let seconds = d.getSeconds();

        const rep = (time, txt, reg) =>
            fm.replace(txt, time.toString().replace(/^(\d)$/, reg));

        if (!fm) fm = "dd.MM.yyyy";

        if (fm.includes("M")) {
            fm = rep(month, "MM", "0$1");
            fm = rep(month, "M", "$1");
        }
        if (fm.includes("yy")) {
            fm = fm.replace("yyyy", year.toString());
            fm = fm.replace("yy", year.toString().substr(2, 2));
        }
        if (fm.includes("YY")) {
            fm = fm.replace("YYYY", year.toString());
            fm = fm.replace("YY", year.toString().substr(2, 2));
        }
        if (fm.includes("d")) {
            fm = rep(day, "dd", "0$1");
            fm = rep(day, "d", "$1");
        }
        if (fm.includes("D")) {
            fm = rep(day, "DD", "0$1");
            fm = rep(day, "DD", "$1");
        }
        if (fm.includes("t")) {
            hours > 11 ? (fm = fm.replace("t", "pm")) : (fm = fm.replace("t", "am"));
        }
        if (fm.includes("H")) {
            fm = rep(hours, "HH", "0$1");
            fm = rep(hours, "H", "$1");
        }
        if (fm.includes("h")) {
            if (hours > 12) hours -= 12;
            if (hours === 0) hours = 12;

            fm = rep(hours, "hh", "0$1");
            fm = rep(hours, "h", "$1");
        }
        if (fm.includes("m")) {
            fm = rep(minutes, "mm", "0$1");
            fm = rep(minutes, "m", "$1");
        }
        if (fm.includes("s")) {
            fm = rep(seconds, "ss", "0$1");
            fm = rep(seconds, "s", "$1");
        }
        return fm;
    }

    addDate(days = 0, months = 0, years = 0) {
        var d = this.i;
        
        d.setDate(d.getDate() + days);
        d.setMonth(d.getMonth() + months);
        d.setFullYear(d.getFullYear() + years);
        return d;
    }
  
    addTime(secs = 0, mins = 0, hours = 0) {
        var d = this.i;
        d.setSeconds(d.getSeconds() + secs);
        d.setMinutes(d.getMinutes() + mins);
        d.setHours(d.getHours() + hours);
        return d;
    }

    toString() {
        return this.i.format("d.M.yy H:mm")
    }

    is(hour, min, sec) {
        const d = this.i;

        hour = hour === undefined ? d.hour : hour;
        min = min === undefined ? d.min : min;
        sec = sec === undefined ? d.sec : sec;
        
        return hour == d.hour && min == d.min && sec == d.sec;
    }

    isSameDay(d1, d2) {
        return d1.day == d2.day && d1.month == d2.month && d1.year == d2.year;
    }

    compareDate(date) {
        date = new SDate_(date);
        const d = this.i; 
        return date.day === d.day && date.month === d.month && date.year === d.year;
    }

    isDate(day, month, year) {
        const d = this.i;
        day = day === undefined ? d.day : day;
        month = month === undefined ? d.month : month;
        year = year === undefined ? d.year : year;
        
        return day === d.day && month === d.month && year === d.year;
    }

    diff(dateA, dateB) {
        const a = new SDate_(dateA);
        const b = new SDate_(dateB);
        const c = b-a;

        return {
            get ms() { return c },
            get sec() { return c/1000 },
            get min() { return c/1000/60 },
            get hour() { return c/1000/3600 },
            get day() { return c/1000/3600/24 },
        }
    }

    get fDate() { return this.i.format("d.M.yyyy") }
    get dmy()   { return this.i.format("d.M.yyyy") }
    get fTime() { return this.i.format("H:mm:ss") }
    get hms()   { return this.i.format("H:mm:ss") }
    get ymd()   { return this.i.format("yyyy-MM-dd") }
    get hhm()   { return this.i.format("HH:mm") }
    get hm()    { return this.i.format("H:mm") }

    get copy()  {
        return new SDate_(this.i);
    }

    get aFormat() {
        const d = this.i;
        return (d.hour == 0 && d.min == 0) ? d.dmy : d.toString();
    }


    get hour()         { return this.i.getHours() }
    set hour(val)      { return this.i.setHours(val) }

    get min()          { return this.i.getMinutes() }
    set min(val)       { return this.i.setMinutes(val) }

    get sec()          { return this.i.getSeconds() }
    set sec(val)       { return this.i.setSeconds(val) }

    get day()          { return this.i.getDate() }
    set day(val)       { return this.i.setDate(val) }

    get month()        { return this.i.getMonth() + 1 }
    set month(val)     { return this.i.setMonth(val - 1) }

    get year()        { return this.i.getFullYear() }
    set year(val)     { return this.i.setFullYear(val) }

    get time()        { return this.i.getTime() }
    set time(val)     { return this.i.setTime(val) }

    get wDay()        { 
        let day = this.i.getDay();
        return day === 0 ? 7 : day;
    }

    set wDay(day) { 
        this.day = day;
        return this;
    }
};

//volani SDate bez new
function SDate(d) {
    return new SDate_(d);
}
  
SDate.__proto__ = SDate_.prototype;
//i = nahrazuje instanci pro volani statickych funkci - napr. SDate.hour
Object.defineProperty(SDate, "i", { get: ()=> new SDate() });

export { SDate };
