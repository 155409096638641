import { Ariscat, Http, Ref, Repo, Rq, Table, route } from "../../dump"

async function newRecord(recordset, parID) {
    let type = recordset == "poptavka" ? "demands" : "orders";
    let pf = recordset == "poptavka" ? "Pop" : "Obj";

    Ref.notify(recordset == "poptavka" ? _.dmdCreat : _.ordCreat, 2);
    var newID = await Rq.newRecord(recordset, {[`b${pf}Aktivni`]: true, [`i${pf}ParentZak`]: parID });
    await Rq.up(recordset, 1, { ID: newID, [`b${pf}Aktivni`]: true, [`i${pf}ParentZak`]: parID });

    Ref.notify(_.itemCreated, 2);
    Rq.deleteCache("stromnab");
    
    if(newID > 0) {
        route(`/${type}/${newID}`);
    }
    else {
        Ref.notify(_.erCreatRec, 4, 'danger');
    }
}

async function cusOffer(rec) {
    route(`/custOffer/${rec.nsStnAgenda}/${rec.ID}`);
}

async function copy(rec, withoutDemand = false) {
    try {
        const pf = rec.nsStnAgenda == "objednavka" ? "Obj" : "Pop";
        const data = withoutDemand ? {ID: rec.ID, [`i${pf}ParentZak`]: 0} : {};
        var res = await Rq.duplicateRecord(rec.nsStnAgenda, rec.ID, data);

        res === true ?
            Ref.notify(_.recordWasCopied, 5) :
            Ref.notify(_.error + ": " + res.error, 5, 'error');

        rec.reload();
    }
    catch(e) {
        Ref.notify(_.error + ": " + e.message, 5, 'error');
        console.error("res", e.message);
    }
}

async function inquireOrSend(rec, type = "Pop") {
    var sendRes = false;

    if(rec.nsStnAgenda == "poptavka") {
        sendRes = await Ref.confirm.open(_.warning, _.sendDemandToManufacturer, _.sendImmediately, _.changeBeforeSend);
    }
    else {
        sendRes = await Ref.confirm.open(_.warning, _.sendOrderToManufacturerConfirm, _.sendImmediately, _.changeBeforeSend);
    }

    if(!sendRes) return;

    var action = type == "Obj" ? "Objednat" : "KalkulaceVyrobcem";
    var error = await Http.sendDemandor(rec.ID, action);

    if(error) {
        Ref.notify(error, 5, 'error');
    }
    else {
        var msg = type == "Obj" ? _.orderWasSendedToManufacturer : _.inquryWasSuccessfullSentToManufacturer;
        Ref.notify(msg, 4, 'success');
        rec.reload();
    }
}

async function sendPopOb(rec) {
    const msg1 = rec.nsStnAgenda == 'objednavka' ? _.send_order_to_manufacturer_confirm : _.sendPopManf;
    const msg2 = rec.nsStnAgenda == 'objednavka' ? _.order_was_sended_to_manufacturer : _.inquryWasSuccessfullSentToManufacturer;

    if (await Ariscat.alert.dialog(_.info, msg1)) {
        try {
            await Ariscat.app.updateSendOrder(true, rec.ID, 'Objednat');
            Ref.notify(msg2, 4, "success");
        }
        catch (err) {
            Ref.notify(err, 6, 'error');
        }
    }

    rec.reload();
}

async function deleteRecord(rec) {
    var res = await Ref.confirm.open("Info", _.deleteRecordConfirm);
    
    if(res) {
        await Rq.del(rec.nsStnAgenda, rec.ID);
        rec.reload();
    }
}

export const Strom = ({ID}) => {
    return (
    <Table
        recordset="stromnab" 
        sw_iParentZakID={ID}
        cols="1" 
        customCols={true} 
        changeCols={false}
        showFilters={false}
        showAll={true}
        expand={true}
        className="tabStrom"
        panel={_.relatedDocuments}
        detail={r => r.nsStnAgenda == "objednavka" ? route("/orders/" + r.ID) : route("/demands/" + r.ID)}
    >
        <bar-button
            type="add"
            icon="Plus"
            text={_.inquirie}
            click={async e=> newRecord("poptavka", ID)}
        />
        <bar-button
            type="add"
            icon="Plus"
            text={_.requisition}
            click={async e=> newRecord("objednavka", ID)}
        />
         <tab-button
            type="primary"
            icon="Cog"
            click={rec => rec.reload()}
        >
            <b-item 
                icon="Trash" 
                text={_.delete} 
                click={rec => deleteRecord(rec)} 
                visible={r => r.bStnReadOnly !== true || r.ieStnStav == 11}
            />
            <b-item icon="Copy" text={_.copy} click={rec => copy(rec)} />
            <b-item icon="Copy" text={_.customersOffer} click={rec => cusOffer(rec)} />
            <b-item icon="Seedling" text={_.inquire} click={rec => inquireOrSend(rec, "Pop")}
                visible={r => r.nsStnAgenda == "poptavka" && !r.bStnReadOnly && r.ieStnStav == 2}
            />
            <b-item icon="Share"
                text={_.order}
                visible={r => !Repo.stg.cs__popobjCantOrderFromGridPopup && !r.bStnAnyMissingDV && (r.ieStnStav == 2 || r.ieStnStav == 3)}
                click={rec => sendPopOb(rec)}
            />
        </tab-button>
        <tab-col 
            name="type" 
            caption={_.type} 
            type="format" 
            format={r => 
                r.nsStnAgenda == "objednavka" ? 
                    <div className="objType">OBJ</div> : 
                    <div className="popType">POP</div>
            } 
        />
        <tab-col name="nsStnIntCislo" />
        <tab-col name="nsStnOznaceni" />
        <tab-col name="nsStnVyrobky" />
        <tab-col name="fStnCenaBezDPH" />
        <tab-col name="fStnCenaSDPH" />
        <tab-col name="nsMenaKod" />
        <tab-col name="nsStnStav" />
    </Table>
    )
}