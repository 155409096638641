import './styl.scss'
import { RInput, SComponent, HSelect, _, Rq, Repo, Checkbox, Ariscat, Ref, route, If, Btn, InputLn, Http } from "../../dump";
import InputColor from 'react-input-color';

export class User extends SComponent {
	async cmount() {
		this.ID = this.props.ID;
		this.data = {};

		this.getLists();

		if(this.ID > 0) {
			this.data = await Rq.info('zamestnanec', this.ID);
	
			if(this.data._error) {
				return Ref.notify(_.employeeDoesNotExist, 20, "error");
			}
		}
		else {
			this.new = true;
		}

		this.onLoad(this.data.nsZamNazev);
		Ref.Header.tagLinks = ["/users"];
	}

	async getLists() {
		const { rows } = await Rq.sd('dealer_pob', 1);
		this.branches = rows;

		this.refresh();
	}

	save = async () => {
		const sCopy = { ...this.data };

		let del = ["dZamPorizeno", "dZamZmeneno", "nsZamZmenil", "nsZamPublicKod", 
			"_error", "oldPsw", "newPsw", "confPsw", "nsZamPictureURL", "constructor"];

		if(!this.ID) {
			if(!this.checkPsw()) return;

			del.push("ID");
			sCopy.nsZamHeslo = Ariscat.cypherPass(sCopy.newPsw);
		}
		else {
			del.push("nsZamHeslo");
		}

		del.forEach(f => delete sCopy[f]);

		var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if(String(this.data.nsZamNazev || "").trim().length == 0) {
			return Ref.notify(_.usernameNotFilled, 6, "error");
		}
		if(!emailRegex.test(this.data.nsZamLogin.trim())) {
			return Ref.notify(_.invalidEmail, 6, "error");
		}

		if(this.file) {
			const res = await Http.sendImage(this.file.data, this.file.name);
		
			if(res?.success) {
				this.data.nsZamPictureURL = res.msg;
				sCopy.nsZamPictureURL = res.msg;

				//pokud menim sebe, zmenim i obrazek v liste
				if(this.ID == Repo.user.ID) {
					Repo.user.nsZamPictureURL = res.msg;
					Ref.Header.refresh();
				}
			}
			else {
				Ref.notify(_.imgNotUpload, 6, "error");
				delete sCopy.nsZamPictureURL;
			}
		}

		var res = await Rq.up("zamestnanec", 1, sCopy);

		if(res?.rows[0]) {
			this.ID = res.rows[0].ID;
			this.file = null;
			this.setState(s => s = res.rows[0]);
			this.data.oldPsw = "";
			this.data.newPsw = "";
			this.data.confPsw = "";

			if(this.new) {
				route("/users/" + this.ID);
			}
		}

		res?.error.join("").includes("UQ_cloud_logins_nsUserLogName") ?
			Ref.notify(_.duplicateEmail, 12, 'error') :
			Ref.notify(_.saved, 2);

		const s = this.data;

		this.refresh();
		if(s.oldPsw || s.newPsw || s.confPsw) {
			await this.savePsw();
		}
	}

	savePsw = async e => {
		if(!this.checkPsw()) return;

		const s = this.data;
		const res = await Http.setCredentials(s.oldPsw || "", s.newPsw || "", this.ID);

		!res ?
			Ref.notify(_.passwordChanged, 3, "error") : 
			Ref.notify(_.error + ": " + res, 6, "error");

		//heslo bylo zmeneno
		if(!res) {
			this.data.oldPsw = "";
			this.data.newPsw = "";
			this.data.confPsw = "";
		}

		this.refresh();
	}

	checkPsw() {
		const pswRg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?:.+){6,}$/;
		const s = this.data;

		if(String(s.newPsw || "").trim().length == 0) {
			Ref.notify(_.passwordNotFilled, 6, "error");
			return false;
		}

		if(!pswRg.test(s.newPsw)) {
			Ref.notify(_.passwordRequirements, 6, "error");
			return false;
		}

		if(s.newPsw != s.confPsw) {
			Ref.notify(_.passwordsNotSame, 6, "error");
			return false;
		}

		return true;
	}


	changeImg = async e => {
		let file = await Ariscat.selectFile();

		if(!file.type.includes("image")) {
			return Ref.notify(_.fileNotPic, 6, "error");
		}
		else if(file.size > 200 * 1024) {
			return Ref.notify(_.fileCannotLarger + " 200kB!", 6, "error");
		}
		else {
			this.file = file;
			this.data.nsZamPictureURL = file.b64;
		}

		this.refresh();
	}

	settingsPanel = () => (
		<div className="panel">
			<header>{_.userStg}</header>

			<div className="userCheckboxes grid-2">
				<Checkbox state={this.data} item="bZamJeAdmin" name={_.adminSeeAll} />
				<Checkbox state={this.data} item="bZamVidiZakazkyJinychObchodniku" name="Vidí zákazky jiných obchodníku" />
				<Checkbox state={this.data} item="bZamVidiGlobStat" name={_.seeGlobStat} />
				<Checkbox state={this.data} item="bZamAktivni" name={_.active} />
				
				<If is={Ariscat.hasPrivilege("assemblyPlanning")}>
					<Checkbox state={this.data} item="bZamJeMonter" name={_.fitter} />
				</If>

				<span className="flex colorInput">
					<InputColor
						//initialValue={this.data.nsZamHexColor || "#FFFFFF"}
						initialValue={this.data.nsZamHexColor || "#FFFFFF"}
						onChange={color => (this.data.nsZamHexColor = color.hex, this.refresh())}
					/><label class="riLabel">{_.calendarColor}</label>
				</span>
			</div>
			<If is={Ariscat.hasPrivilege("assemblyPlanning")}>
				<div className="grid-2">
					{/*<ColorInput value={this.data.nsZamHexColor} onChange={color => (this.data.nsZamHexColor = color, this.refresh())} placeholder="Vyberte barvu" label="Barva kalendáře" />*/}
					<HSelect tname="selectBranch" state={this.data} item="iZamPobockaID" label="nsPobNazev" values={this.branches} />
					<HSelect 
						tname="defEventType" 
						state={this.data} 
						item="ieZamTypVychoziUdalosti"
						label="evt"
						values={[
							{ ID: 1, evt: _.montage },
							{ ID: 2, evt: _.contactMeetings },
							{ ID: 3, evt: _.service },
							{ ID: 4, evt: _.reclamation }
						]}
					/>
				</div>
			</If>
		</div>
	)

	gooPanel = () => (
		<If is={this.ID != 0 && this.userID}>
			<div className="panel">
				<header>{_.calendar}</header>
				<a href={this.authUrl} className="btn btn-primary btn-block">{this.useGC ? _.googleCalUnsubscribe : _.googleCalSubscribe}</a>
			</div>
		</If>
	)

	rnd(s) {
		const url = this.data.nsZamPictureURL || "/src/img/no-photo.jpg";

		return (
			<div className="comp userDetail">
				<div className="compBar buttons">
					<Btn save className="mt-2" onClick={this.save} id="saveInf">{_.save_informations}</Btn>
				</div>
				<div className="panels grid-2">
					<div className="panel">
						<header>{_.basicData}</header>

						<div className="grid-12">
							<div className="fileupload fileupload-new" id="photo-upload">
								<div className="user-image">
									<div className="fileupload-new thumbnail">
										<div id="user-photo" className={"c" + this.i} onClick={this.changeImg} style={{backgroundImage: `url('${url}')`}} />
									</div>
									<div className="fileupload-preview fileupload-exists thumbnail"></div>
								</div>
							</div>
							<div>
								<RInput tname="username" state={this.data} item="nsZamLogin" />
								<RInput tname="fullName" state={this.data} item="nsZamNazev" />
							</div>
						</div>
						<div className="grid-2">
							<RInput tname="telephone" state={this.data} item="nsZamTelefon" />
							<RInput tname="shortcut3" state={this.data} item="nsZamUzivZkratka" maxLength="3" />
						</div>
						<RInput tname="userNote" type="tarea" state={this.data} item="nsZamPozn" format={val => val?.replaceAll("&#x0A;", "\n")} />

						<If is={!this.new}>
							<RInput tname="oldPassword" type="password" state={this.data} item="oldPsw" />
						</If>
						<div className="grid-2">
							<RInput tname="newPassword" type="password" state={this.data} item="newPsw" />
							<RInput tname="newPasswordConfirmation" type="password" state={this.data} item="confPsw" />
						</div>

					</div>
					<div>
						<this.gooPanel />
						<this.settingsPanel />
					</div>	
				</div>
			</div>
		)
	}
}