import './styl.scss'
import { SComponent, _, SDate, Rq, Ref, RIcons, PreactHTMLConverter, Btn, Table, createRef, Repo } from "../../dump";
import { Tab } from '../../components/tab/tab';
import { RiMessage2Fill } from "react-icons/ri";

const MsgTable = ({rows, detail, rf}) => (
    <Table 
        className="readed" 
        rows={rows} 
        customCols={true} 
        showMenu={false} 
        detail={detail} 
        needUpdate={true}
        expand={true}
        ref={rf}
    >
        <tab-col name="nsMsgSubject" caption={_.subject}/>
        <tab-col name="bMsgConfirmation" caption={_.reqConf} type="boolean" />
        <tab-col name="nsMsgType" caption={_.type} />
        <tab-col name="dMsgPorizeno" caption={_.date} type="date" />
    </Table>
)

export class Messages extends SComponent {
    async cmount() {
        this.msgColumns = [
            'ID',
            'bMsgConfirmation',
            'bMsndRead',
            'dMsgPorizeno',
            'dMsgZmeneno',
            'dMsndPorizeno',
            'dMsndReadDate',
            'iMsndIDmsg',
            'dMsgValidTill',
            //['blbMsgIcon', 'Binary'],
            //['nsMsgIconExt', 510],
            ['nsMsgSubject', 100],
            ['nsMsgType', 100],
            ['blbMsgIcon', 'Binary', {visible: false}],
			['nsMsgText', 'WideText', {visible: false}],
        ];

        this.messages = [];
        this.unreaded = [];
        this.readed = [];
        this.showUnReaded = true;
        this.showReaded = false;
        this.recordset = 'strv_msg_send';
        this.tabRef = createRef();
        this.tableRef1 = createRef();
        this.tableRef2 = createRef();
        
        Ref.Messages = this;

        this.converter = PreactHTMLConverter();
        await this.initMessages();
        this.watchMessages();
    }

    async initMessages() {
        const {rows} = await Rq.sd(this.recordset, this.msgColumns, 0);
        this.messages = rows.sort((a, b) => b.dMsgPorizeno - a.dMsgPorizeno).filter(f => f.dMsgValidTill ? SDate.diff(f.dMsgValidTill).day < 1 : true) || [];
        this.readed = this.messages.filter(f => f.dMsndReadDate) || [];
        this.unreaded = this.messages.filter(f => !f.dMsndReadDate) || [];
        this.unreadedConf = this.messages.filter(f => !f.dMsndReadDate && f.bMsgConfirmation === true) || [];

        if(this.unreadedConf.length && Repo.stg.cs__messages) {
            this.open();
        }
    }

    watchMessages() {
        //pokud nemam tento modul zapnut, nesledovat nove zpravy
        if(!Repo.stg.cs__messages) return;

        if(this.interval) {
            clearInterval(this.interval);
        }

        //1x za 2 minuty zkontrolovat zpravy
        this.interval = setInterval(() => {
            Rq.deleteCache(this.recordset);
            this.initMessages().then(() => this.refresh());
        }, 120000);
    }

    updateTables() {
        if(this.tableRef1.current?.props) {
            this.tableRef1.current.props.rows = this.unreaded;
            this.tableRef1.current.reload();
        }
        if(this.tableRef2.current?.props) {
            this.tableRef2.current.props.rows = this.readed;
            this.tableRef2.current.reload();
        }
    }

    closeOneMessage = () => {
        this.set.oneMessage = "hidden";
    }

    async confirmMessage(msg, read = true) {
		const data = read ?
			{ ID: msg.ID, dMsndReadDate: SDate.ymd, bMsndRead: true } :
			{ ID: msg.ID, bMsndRead: false }
        
		var res = await Rq.up(this.recordset, 1, data);
        Ref.modal2.close();
		await this.initMessages();
        //this.updateTables();
        this.open();
        this.refresh();
	}

    openMsg = async msg => {
        const msgInfo = await Rq.info(this.recordset, msg.ID);
        this.openedMsg = msgInfo;
        const msgText = Ariscat.decodeHTMLEntities(msgInfo.nsMsgText || "");
        
        //PREDELAT na converter
        //const html = this.converter.convert(msgText);

        Ref.modal2.open(_.messages,
            <div className="modal-data messageDetail">
                <div className="info">
                    <div>{msg.nsMsgSubject}</div>
                    <div>{msg.dMsgPorizeno?.toString()}</div>
                </div>
                <div className="txt" dangerouslySetInnerHTML={{ __html: msgText }}></div>
            </div>,
            <div className="modal-buttons">
                <Btn back className="msgRead" onClick={() => this.confirmMessage(msg, true)}>{_.markasRead}</Btn>
                <Btn action className="msgUnRead" onClick={() => this.confirmMessage(msg, false)}>{_.markUnread}</Btn>
            </div>
        );
    }

    open = () => {
        Ref.modal.open(_.messages,
            <div className="modal-data messageList">
                <Tab ref={this.tabRef}>
                    <tab-content title={`${_.unreadMsgs} (${this.unreaded.length})`}>
                        <MsgTable detail={this.openMsg} rows={this.unreaded} rf={this.tableRef1} />
                    </tab-content>
                    <tab-content title={`${_.readMsgs} (${this.readed.length})`}>
                        <MsgTable detail={this.openMsg} rows={this.readed} rf={this.tableRef2} />
                    </tab-content>
                </Tab>
            </div>
        )

        setTimeout(() => this.tabRef.current.tabChange(this.unreaded.length ? 0 : 1), 1);

        Ref.modal.onClose =m=> {
            if(this.unreadedConf.length) {
                this.tabRef.current.tabChange(0);
                return false;
            }

            return true;
        }
    }

    rnd(s,p) {
        if(!Repo.stg.cs__messages) return [];

        return <div className="messages" onClick={e=> this.open()}>
            <div className={this.cln({um: 1, dnone: !this.unreaded.length})}>{this.unreaded.length}</div>
            <RiMessage2Fill />
        </div>
    }
}