import './styl.scss';
import { SComponent, h, _, Ref, Http, RIcons, HtmlParser, xmlParser, route, createRef, Repo } from "../../dump";

export class Search extends SComponent {
    cmount() {
        this.inpRef = createRef();
        this.searchVal = "";
        this.searchActive = false;
    }

    search=e=> {
        this.searchVal = e.target.value;
        this.refresh();

        for(let tab of (Repo.tabList || [])) {
            tab.set.searchText = e.target.value;
        }
    }

    showSearch=()=> {
        this.searchActive = !this.searchActive;
        this.inpRef.current.focus();
        this.refresh();

        if(!this.searchActive) {
            this.searchVal = "";
            this.refresh();
            
            for(let tab of (Repo.tabList || [])) {
                tab.set.searchText = "";
            }
        }
    }

    rnd =(s,p)=> (
        <div className={this.cln({pageSearch:1, searchActive: this.searchActive})}>
            <div className="searchWrapper">
                <input ref={this.inpRef} type="search" id="searchInput" value={this.searchVal} onChange={this.search} />
                {this.searchVal.length ? 
                    <RIcons.Fa.FaTimes onClick={this.showSearch} /> : 
                    <RIcons.Fa.FaSearch onClick={this.showSearch} 
                />}
            </div>
        </div>
    )
}