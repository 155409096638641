import { If } from "../base/if";

export const ObchodniZastupce = (p) => {
    let asistentka = ['nsNazevAsistent', 'nsMobilAsistent', 'nsEmailAsistent'].map(m => Repo.stg[m]).filter(f => f).join(", ")
    let zastupcu = ['nsNazevObchZastupce', 'nsMobilObchZastupce', 'nsEmailObchZastupce'].map(m => Repo.stg[m]).filter(f => f).join(", ")

    if(!zastupcu.length && !asistentka.length) {
        return null;
    }

    // console.log("ggg", zastupcu);

    return <div id="obZastupce" className={p.className}>
        <b>{_.ifHasAnyQe}</b>
        <If is={zastupcu.length}>
            <span>
                <b>{_.obchZas} </b>
                {zastupcu}
            </span>
        </If>
        <If is={asistentka.length}>
            <span>
                <b>{_.asSalRep}</b>
                {asistentka}
            </span>
        </If>
    </div>
}