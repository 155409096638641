import { Rq, _, createRef, Ref, SComponent, RInput, RIcons, HSelect, Repo, Ariscat, Btn } from "../../dump";
import { If } from "../base/if";
import { ButtonMenu } from "../buttonMenu/buttonMenu";
import { Checkbox } from "../checkbox/checkbox";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styl.scss';
import { Link } from "preact-router";

export class CustomerOffer extends SComponent {
    cmount() {
        this.ID = this.props.ID;
        this.recordset = this.props.recordset;
        this.pRecordset = this.recordset;

        this.tables = {};
        this.secs = {};
        this.curs = [];
        this.hideAll = true;
        this.lastInput = {}
        this.discounts = {};
        this.state = { saved: "saved" }
        this.ofr = {};
        this.pf = this.recordset == "poptavka" ? "Pop" : "Obj";
        this.orPop = this.recordset == "poptavka" ? "demand" : "order";

        this.selRef = createRef();
        this.langRef = createRef();

        this.getData();

        
        this.printButtons = []
        Rq.sd('ofr_cis_prn', 1, 0, {}).then(data => {
          data.rows.map(d => this.printButtons.push([d.nsOcpTSDescr, d.nsOcpTSCode]))
        }
        )
    }

    keyInput = (e, row, key) => {
        if (e.key === "Delete") {
            e.preventDefault();
            e.target.value = 0;

            if (key === "prdjKurz") this.changeCourse(e);
            if (key === "prdjSazbaDPH") this.changeDPH(e);
            else this.changeMoney(e, row, key);

            return false;
        }
    }

    changeText = async(e, row, key) => {
        if (this.timerHandle) clearTimeout(this.timerHandle);

        let val = e.target.value;

        if (key == "nsOcrNazevTrans") {
            val = "def=" + val;
        }

        var data = { ID: row.ID, iOcrPopObjID: this.ID, [key]: val };
        row[key] = val;
        this.refresh();

        this.timerHandle = setTimeout(() => this.sendRekap(data), 800);
    }

    changeMoney = async(e, row, key) => {
        if (this.timerHandle) clearTimeout(this.timerHandle);

        const cursor = e.target.selectionStart;
        const val = e.target.value;
        const value = this.float(val);
        const course = this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`];

        if (!isNaN(value)) {
            this.calculateRow(row, key, value);

            this.sumRows(row);
            this.sumRekap();

            setTimeout(() => (e.target.selectionStart = cursor, e.target.selectionEnd = cursor), 1);
            this.refresh();
        }

        const rowSend = {...row };
        rowSend[key] = value;
        var data = { ID: row.ID, iOcrPopObjID: this.ID, ...row };

        //tohle se musi posilat vzdycky - backend by neaktualizoval udaj
        data.fOcrProdejPredSlevou_JC = row.fOcrProdejPredSlevou_JC;

        this.timerHandle = setTimeout(() =>
            !this.error && this.sendRekap(data), 800);
    }

    calculateRow(row, key, value) {
        if (key) {
            row[key] = this.round(value);
        }

        /* vypocet na backendu
        fOcrNaklVProdMene_JC = R2(fOcrNaklVNakMene_JC * f[Pop|Obj]PrdjProdejKuNakupMenaKurz)
        fOcrNaklVProdMene_CC = R2(fOcrMnozstvi * fOcrNaklVProdMene_JC)
        fOcrProdejPredSlevou_JC = R2(fOcrNaklVProdMene_JC * ((100 + fOcrNavyseniCeny_Prc)/100))
        fOcrProdejPredSlevou_CC = R2(fOcrProdejPredSlevou_JC * fOcrMnozstvi)
        fOcrProdejPoSleve_JC = R2(fOcrProdejPredSlevou_JC * (100 - fOcrSleva_Prc)/100 - ISNULL(fOcrSleva_Abs/NULLIF(fOcrMnozstvi,0),0))
        fOcrProdejPoSleve_CC = R2(fOcrProdejPoSleve_JC * fOcrMnozstvi)
        fOcrSlevaCelkem = R2(fOcrProdejPredSlevou_CC - fOcrProdejPoSleve_CC)
        fOcrMarze = R2(fOcrProdejPoSleve_CC - fOcrNaklVProdMene_CC)
        */

        row.fOcrNaklVProdMene_JC = this.round(row.fOcrNaklVProdMene_JC);
        row.fOcrMnozstvi = this.round(row.fOcrMnozstvi);

        //celková cena (náklady) = jedn. * počet
        row.fOcrNaklVProdMene_CC = this.round(row.fOcrNaklVProdMene_JC * row.fOcrMnozstvi);

        //NAVÝŠENÍ - 
        //prodej - jedn. a cel. cena = jedn. a cel. cena v nákladech + navýšení v %
        //pokud se zmeni prodej pred slevou, spocita se navyseni, jinak se prodej vynasobi tim navysenim
        key === "fOcrProdejPredSlevou_JC" ?
            row.fOcrNavyseniCeny_Prc = this.round(this.notInf(row.fOcrProdejPredSlevou_JC / row.fOcrNaklVProdMene_JC) - 1) * 100 :
            row.fOcrProdejPredSlevou_JC = this.round(row.fOcrNaklVProdMene_JC * (1 + row.fOcrNavyseniCeny_Prc / 100));

        row.fOcrProdejPredSlevou_CC = this.round(row.fOcrProdejPredSlevou_JC * row.fOcrMnozstvi);
        //row.fOcrProdejPredSlevou_CC = this.round(row.fOcrNaklVProdMene_CC * (1 + row.fOcrNavyseniCeny_Prc/100));


        //výpočet slev a marže
        row.fOcrProdejPoSleve_JC = this.round(row.fOcrProdejPredSlevou_JC * (100 - row.fOcrSleva_Prc) / 100 - this.notInf(row.fOcrSleva_Abs / row.fOcrMnozstvi));

        //console.log("po sleve", row.fOcrProdejPoSleve_JC * row.fOcrMnozstvi, row.fOcrProdejPoSleve_JC, row.fOcrMnozstvi);
        row.fOcrProdejPoSleve_CC = this.round(row.fOcrProdejPoSleve_JC * row.fOcrMnozstvi);

        row.fOcrSlevaCelkem = this.round(row.fOcrProdejPredSlevou_CC - row.fOcrProdejPoSleve_CC);
        row.fOcrMarze = this.round(row.fOcrProdejPoSleve_CC - row.fOcrNaklVProdMene_CC);

        //vypocet slevy
        const basePrice = this.round(row.fOcrCenikVNakMene_CC > 0 ? row.fOcrCenikVNakMene_CC : row.fOcrNaklVProdMene_CC);
        const discountPercent = 0.85;
        const minCC = this.round(basePrice * discountPercent);
        const prodPoSl = this.round(row.fOcrProdejPoSleve_CC);

        //console.log("cena", basePrice);
        //console.log("MINCC > ProdejPoSleve", minCC, prodPoSl, minCC > prodPoSl);

        //lamelland maximalni sleva (15%) a navyseni (100%)

        //bOcrForbidden = např. sleva od výrobce - nebude se počítat do chyby
        //bOcrHiddenToCust = zakázaný řádek - nebude se počítat do chyby

        const isOther = row.nsOcrAgenda == "user";

        //lamelland maximalni sleva (15%) a navyseni (100%)
        //bOcrForbidden = např. sleva od výrobce - nebude se počítat do chyby
        //bOcrHiddenToCust = zakázaný řádek - nebude se počítat do chyby
        if (Repo.stg.cs__DealsMaxSale && !row.bOcrForbidden && !row.bOcrHiddenToCust) {
            if (row.fOcrNavyseniCeny_Prc > 100 && (!isOther || (isOther && !Repo.stg.cs__DealsMaxSaleInOthersSection)) && row.fOcrNaklVProdMene_CC >= 0) {
                row.data.error = true;
                this.error = true;
                this.set.saved = _.errorMaxsale;
                Ref.notify(_.errorMaxsale, 10, 'error');
            } else if (minCC > prodPoSl && (!isOther || (isOther && !Repo.stg.cs__DealsMaxSaleInOthersSection)) && row.fOcrNaklVProdMene_CC >= 0) {
                row.data.error = true;
                this.error = true;
                this.set.saved = _.errorDiscount;
                Ref.notify(_.errorDiscount, 6, 'error');
            } else {
                row.data.error = false;
                this.error = false;
                this.set.saved = "saved";
                //Ref.notify(_.saved, 2, 'error');
            }
        }

        Ariscat.setChanged(false);
    }

    notInf(num) {
        return Number.isFinite(num) ? num : 0;
    }

    sumRows(row) {
        //SECTE VSECHNY HODNOTY ve sloupcich
        const sec = row.data.tab.find(f => f.nsOcrAgenda == "section_sum")
        const rows = row.data.tab.filter(f => !f.data.deleted);

        for (let col of["fOcrNaklVProdMene_CC", "fOcrProdejPredSlevou_CC", "fOcrProdejPoSleve_JC",
                "fOcrProdejPoSleve_CC", "fOcrSlevaCelkem", "fOcrMarze"]) {
            const sum = rows.map(m => parseFloat(m[col]) || 0).reduce((a, b) => a + b, 0);
            sec[col] = sum - parseFloat(sec[col]);
        }
    }

    sumTable(tab) {
        //SECTE VSECHNY HODNOTY ve sloupcich
        const sec = tab.find(f => f.nsOcrAgenda == "section_sum")
        const rows = tab.filter(f => !f.data.deleted);

        for (let col of["fOcrNaklVProdMene_CC", "fOcrProdejPredSlevou_CC", "fOcrProdejPoSleve_JC",
                "fOcrProdejPoSleve_CC", "fOcrSlevaCelkem", "fOcrMarze"]) {
            const sum = rows.map(m => parseFloat(m[col]) || 0).reduce((a, b) => a + b, 0);
            sec[col] = sum - parseFloat(sec[col]);
        }
    }

    sumRekap() {
        const sums = Object.values(this.tables).map(m => m.find(f => f.nsOcrAgenda == "section_sum"));

        let sum = 0;

        if (sums.length == 1) {
            sum = parseFloat(sums[0].fOcrProdejPoSleve_CC || 0);
        } else if (sums.length > 1) {
            sums.forEach(a => sum += parseFloat(a.fOcrProdejPoSleve_CC || 0))
        }

        const dph = parseFloat(this.ofr[`f${this.pf}PrdjSazbaDPH`]);
        this.ofr[`f${this.pf}PrdjTotal_bezDPH`] = sum;
        this.ofr[`f${this.pf}PrdjTotal_DPH`] = sum / (100 / dph);
        this.ofr[`f${this.pf}PrdjTotal_sDPH`] = sum * (100 + dph) / 100;
    }

    createRowClass(r) {
        return [
            r.bOcrHiddenToCust ? "text-muted" : "",
            r.bOcrForbidden ? "tr-forbidden" : "",
            r.nsOcrAgenda === "section_sum" ? "summaryRow" : "",
            r.data.error ? "tr-error" : "",
        ].join(" ");
    }

    chbCheck = (val, row) => {
        this.refresh();

        if (val) { //obnovi puvodni hodnoty - po nacteni se rovna 0 (pokud je radek zakazan)
            row.fOcrNavyseniCeny_Prc = row.data.backup.fOcrNavyseniCeny_Prc;
            row.fOcrSleva_Prc = row.data.backup.fOcrSleva_Prc;
            row.fOcrSleva_Abs = row.data.backup.fOcrSleva_Abs;

            this.calculateRow(row);
        } else {
            row.data.error = false;
            this.error = false;
            this.set.saved = "saved";
            this.nulRow(row);
        }

        this.sumRows(row);
        this.sumRekap();
        this.sendRekap({ ID: row.ID, iOcrPopObjID: this.ID, bOcrHiddenToCust: !val })

        setTimeout(() => this.refresh(), 10);
    }

    nulRow(row, backup = true) {
        if (backup) {
            //zalohuje puvodni hodnoty
            row.data.backup.fOcrNavyseniCeny_Prc = row.fOcrNavyseniCeny_Prc;
            row.data.backup.fOcrSleva_Prc = row.fOcrSleva_Prc;
            row.data.backup.fOcrSleva_Abs = row.fOcrSleva_Abs;
        }

        //vynuluje vsechno krome nakladu a marze (vpravo)
        row.fOcrNavyseniCeny_Prc = 0;
        row.fOcrSleva_Prc = 0;
        row.fOcrSleva_Abs = 0;

        //vynuluje hodnoty
        row.fOcrProdejPredSlevou_JC = 0;
        row.fOcrProdejPredSlevou_CC = 0;
        row.fOcrProdejPoSleve_JC = 0;
        row.fOcrProdejPoSleve_CC = 0;
        row.fOcrSlevaCelkem = 0;

        //vypocita marzi
        row.fOcrMarze = this.round(row.fOcrProdejPoSleve_CC - row.fOcrNaklVProdMene_CC);
    }

    async getData() {
            this.data = await Rq.sd("obj_cen_rekap", 1, 0, { iZakID: this.ID });
            this.dealsMaxSale = Repo.stg.cs__DealsMaxSale;

            for (let row of this.data.rows.sort((a, b) => a.iOcrSectionNo - b.iOcrSectionNo)) {
                row.data = {
                    error: false,
                    backup: {
                        fOcrNavyseniCeny_Prc: this.round(row.fOcrNavyseniCeny_Prc_BACKUP || 0),
                        fOcrSleva_Prc: this.round(row.fOcrSleva_Prc_BACKUP || 0),
                        fOcrSleva_Abs: this.round(row.fOcrSleva_Abs_BACKUP || 0),
                    }
                }

                if (row.nsOcrAgenda != "section")
                    this.tables[row.iOcrSectionNo] ?
                    this.tables[row.iOcrSectionNo].push(row) :
                    this.tables[row.iOcrSectionNo] = [row]

                if (row.nsOcrAgenda == "section") {
                    row.data.visible = true;
                    this.secs[row.iOcrSectionNo] = row;
                }

                row.data.tab = this.tables[row.iOcrSectionNo];
            }

            try {
                //prdjMena: row['i'+this.pf+'PrdjMenaID_Prodej'],

                this.ofr = await Rq.info(this.recordset, this.ID);
                console.log("hOffer", this.ofr);
                this.loadCourse = this.round(this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`], 12);
                const title = this.recordset == "poptavka" ? _.inquirie : _.requisition;
                this.onLoad("", this.ofr[`ns${this.pf}ParentZak`], title + " " + this.ofr[`ns${this.pf}IntCislo`]);
                Ref.Header.tagLinks = ["/deals", `/deals/${this.ofr[`i${this.pf}ParentZak`]}`, `/${this.orPop}s/${this.ID}`];
      }
      catch(er) {
        Ref.notify(_.errorLoadingOffer + ": " + er + " " + this.ID, 4, 'error');
      }
  
      const {rows} = await Rq.sd("mena", 1, 0, {nsKurzKuMenaKod: this.ofr.nsMenaKod });
      this.curs = rows;
  
      //update currency
      const cur = this.curs.find(f => f.ID == this.ofr[`i${this.pf}PrdjMenaID_Prodej`]) || {};
      this.ofr.currencyCodePr = cur.nsMenaKod;
      this.ofr.prdjKurzTxt = String(this.loadCourse).replace(".", ",");

      await this.refresh();
      //this.selRef.current.update();
      //this.langRef.current.update();
    }
    
    showAll =e=> {
      this.hideAll = !this.hideAll;
      this.update = Object.values(this.secs).map(m => m.data.visible = this.hideAll);
    }
  
    switchTable =sec=> {
      this.update = sec.data.visible = !sec.data.visible;
    }
  
    translate = (text, langID) => {
      const obj = {};
  
      if(text.startsWith("def=")) {
        return text.substr(4);
      }
  
      const split = text.split("\n");
  
      for(let sp of split) {
        const [num, txt] = sp.split("=");
        obj[num] = txt;
      }

      if(obj[langID] === undefined) return "";

      return (obj[langID])?.replace("\n", "") || text;
    }
  
    round(num, dec = 2) {
      return Math.round(num*Math.pow(10, dec)) / Math.pow(10, dec);
    }
  
    async moveTab(num, index) {
      const sec = this.secs[index];
      const items = Object.values(this.secs).sort((a,b) => a.iOcrSectionNo - b.iOcrSectionNo);
      const ranks = items.map(m => m.iOcrSectionNo);
      const itemIndex = ranks.indexOf(sec.iOcrSectionNo);
      const nextSec = items[itemIndex + num];
  
      if(!nextSec) {
        console.log("tato polozka neexistuje");
        //this.showError();
        return;
      }
  
      //this.showSaving();
  
      //ulozit poradi polozek
      const origNum = sec.iOcrSectionNo;
      const nextNum = nextSec.iOcrSectionNo;
      
      //prohodit hodnoty
      sec.iOcrSectionNo = nextNum;
      nextSec.iOcrSectionNo = origNum;
  
      const s1 = { ID: sec.ID, iOcrSectionNo: nextNum, iOcrPopObjID: this.ID };
      const s2 = { ID: nextSec.ID, iOcrSectionNo: origNum, iOcrPopObjID: this.ID };
  
      this.tables = {};
      this.secs = {};
  
      await this.sendRekap([s1, s2]);
      this.getData();
    }

    hideAgenda(row) {
      return row.nsOcrAgenda == "dopravne" ||
        row.nsOcrAgenda == "balne" ||
        row.nsOcrAgenda == "zamereni" ||
        row.nsOcrAgenda == "montaz" ||
        row.nsOcrAgenda == "postovne";
    }
  
    //skryti radku co maji cenu 0
    filterRow = row => {
      return !(row.fOcrNaklVNakMene_JC == 0 && this.hideAgenda(row));
    }
  
    print = async (type) => {
      if (type === null || type === undefined) type = 'nabidka'
      //kontrola navyseni ve vsech tabulkach - nesmi byt 0
      const navyseni = Object.values(this.tables)
        .map(tab => tab.filter(f => f.fOcrNavyseniCeny_Prc == 0 && !f.bOcrHiddenToCust).length)
        .some(v => v > 0);
  
      if(!this.ofr.nsOrgNazev && Repo.stg.cs__popobjWarnMissCustOnPrnNab) {
        const res = await Ref.confirm.open(_.info, _.cusNotFil + " " + _.reallyWantPrint);
        if(!res) return;
      }
  
      if(navyseni && Repo.stg.cs__popobjWarnMissPriceIncOnPrnNab) {
        const res = await Ref.confirm.open(_.info, _.increasePerSomeLn + " " + _.reallyWantPrint);
        if(!res) return;
      }
  
      if(this.error) {
        Ref.notify(_.printingNotPossible, 4 , "error");
        Ariscat.setChanged(false);
      }
      else {
        window.open(
          Repo.serverURL + `/api/pdf/${type}/${this.recordset}/${this.ID}/${this.ofr[`i${this.pf}PrdjLangID`]}`, 
          "_blank", 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=595', '_blank'
        );
      }
    }

    editFooter = async () => {
      this.ofrText = (this.ofr[`ns${this.pf}PrdjPrnFooterFR3`] ||"").replaceAll("\n", "<br>");

      Ref.modal.open(_.editTextPrnRep,
        <div className={this.cln({editorFooter: 1})}>
            <ReactQuill theme="snow" value={this.ofrText} onChange={html => this.ofr[`ns${this.pf}PrdjPrnFooterFR3`] = html} />
        </div>,
        <div className="modal-buttons">
            <Btn save onClick={this.savePrnFooter}>
              <RIcons.Fa.FaCheck /> {_.save}
            </Btn>
            <Btn close onClick={() => Ref.modal.close()}>{_.close}</Btn>
        </div>
      )
    }

    modalPrnFooter(s,p) {
      return (
        <div className={this.cln({modal:1, in: this.state.prnFooter})}>
          <div className="modal-dialog modal-lg prnFooter">
            <div className="modal-content">
              <div className="modal-header">
                <Btn close onClick={()=>this.set.prnFooter=false}>×</Btn>
                <h4 className="modal-title">{_.printSetup}</h4>
              </div>
              <div className="modal-body">
                <div id="prnfEditor"></div>
              </div>
              <div className="modal-footer">
                <Btn save onClick={this.savePrnFooter}>{_.save}</Btn>
                <Btn back onClick={()=>this.set.prnFooter=false}>{_.back}</Btn>
              </div>
            </div>
          </div>
        </div>
      )
  }
  
    money = (m, places = 2) => {
      if(m === undefined || m === null) return "-";
      if(isNaN(m)) m = 0;
      return parseFloat(m).toLocaleString("cs-CZ", {style: "currency", currency: "BBB", minimumFractionDigits: places}).replace("BBB", "").trim()
    }
  
    async moveRow(num, row, tab) {
      //await this.loadRows();
      
      //filtruje posledni radek - soucet a setridi
      const items = tab.filter(f => f.nsOcrAgenda != "section_sum").sort((a,b) => a.iOcrSequenceNo - b.iOcrSequenceNo);  
      const ranks = items.map(m => m.iOcrSequenceNo);
      const itemIndex = ranks.indexOf(row.iOcrSequenceNo);
      const nextRow = items[itemIndex + num]
  
      if(!nextRow) {
        console.warn("tato polozka neexistuje");
        //this.showError();
        return;
      }
  
      //ulozit poradi polozek
      const origNum = row.iOcrSequenceNo;
      const nextNum = nextRow.iOcrSequenceNo;
  
      //prohodit hodnoty
      row.iOcrSequenceNo = nextNum;
      nextRow.iOcrSequenceNo = origNum;
  
      tab.sort((a,b) => a.iOcrSequenceNo - b.iOcrSequenceNo);
      this.refresh();
  
      //odeslat
      const r1 = { ID: row.ID, iOcrSequenceNo: nextNum, iOcrPopObjID: this.ID };
      const r2 = { ID: nextRow.ID, iOcrSequenceNo: origNum, iOcrPopObjID: this.ID };
      this.sendRekap([r1, r2]);
    }
  
    addRow = async index => {
      const tab = this.tables[index];
      //soucet
      const sum = tab[tab.length-1];
      const nRow = this.newRow(tab, index);
      const {rows} = await this.sendRekap(nRow);
  
      if(rows.length) {
        const row = rows[0];
        row.data = { error: false, tab };
        tab.pop(); //odstrani posledni radek (soucet)
        tab.push(row); //prida novy radek
        tab.push(sum); //prida znovu soucet;
        this.refresh();
      }
    }
  
    deleteRow = async (tab, row) => {
      const dialog = await Ref.confirm.open("Info", _.deleteRecordConfirm, 10);
      if(!dialog) return;
  
      this.set.saved = "saving";
      this.update = row.data.deleted = true;
      const {ferror} = await Rq.del("obj_cen_rekap", row.ID);
      this.set.saved = ferror ? "error" : "saved";
      Ariscat.setChanged(false);
  
      this.sumRows(row);
      this.sumRekap();
      this.refresh();
    }
  
    changeDiscount = (e, index) => {
      this.discounts[index] = e.target.value;
    }
  
    setDiscount =(val,idx) => {
      const rVal = this.round(val || 0);
      const rows = this.tables[idx].filter(f => !f.bOcrHiddenToCust && f.nsOcrAgenda !== "section_sum");
      let sendData = [];
  
      //pokud neobsahuje tabulka polozky, slevu neni potreba nastavit
      if(!rows.length) return;
  
      for(let row of rows) {
        this.calculateRow(row, "fOcrSleva_Prc", rVal);
  
        sendData.push({ID: row.ID, iOcrPopObjID: this.ID, fOcrSleva_Prc: rVal, fOcrProdejPredSlevou_JC: row.fOcrProdejPredSlevou_JC});
      }
  
      if(rows.length) this.sumRows(rows[0]);

      console.log(sendData);
  
      this.sumRekap();
      this.sendRekap(sendData);
      this.refresh();
    }
  
    changeDPH =(val, key, item)=> {
      //const cursor = e.target.selectionStart;
      const fVal = this.float(val);
      const wDPH = this.float(this.ofr[`f${this.pf}PrdjTotal_bezDPH`]);
      const rdph = this.round(fVal);
  
      const dph = {
        prdjSazbaDPH: rdph,
        prdjTotal_DPH: wDPH * rdph / 100,
        prdjTotal_sDPH: wDPH + wDPH * rdph / 100,
      }
  
      //setTimeout(() => (e.target.selectionStart = cursor, e.target.selectionEnd = cursor), 1);
      this.update = this.ofr = { ...this.ofr, ...dph};
  
      this.sumRekap();
      this.sendHd("f§PrdjSazbaDPH", fVal);
      this.refresh();
    }
  
    changeRekap = (val,key,item) => {
      this.ofr[key] = val;

      const course = parseFloat(item.fKzKurzKuBase);
      this.ofr.currencyCodePr = item.nsMenaKod;

      if(key == "iObjPrdjMenaID_Prodej") {
        this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`] = course;
        this.ofr.prdjKurzTxt = String(course).replace(".", ",");
      }

      this.reCalc(course);
      this.sumRekap();  

      this.sendHd(key, val);

      if(key == "iObjPrdjMenaID_Prodej") {
        this.sendHd("f§PrdjProdejKuNakupMenaKurz", course);
      }

      this.refresh();
    }
  
    changeLang =e=> {
      this.update = this.ofr[`i${this.pf}PrdjLangID`] = e.target.value;
      this.sendHd("i§PrdjLangID", e.target.value);
    }
  
    changeCourse =val=> {
      this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`] = this.round(this.ofr.prdjKurzTxt.replace(",", "."), 12);

      if(
        this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`] < 0 || 
        this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`] > 100000
      ) 
        this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`] = 1;

      this.ofr.prdjKurzTxt = String(this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`]).replace(".", ",");
      
      this.reCalc(this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`]);
      this.sumRekap();
      this.sendHd("f§PrdjProdejKuNakupMenaKurz", this.ofr[`f${this.pf}PrdjProdejKuNakupMenaKurz`]);
      this.refresh();
    }
  
    reCalc = (course) => {
      const realCourse = course / this.loadCourse;
  
      for(let tab of Object.values(this.tables)) {
        //kurz se neprepocita u uzivatelskych polozek
        tab.filter(f => f.nsOcrAgenda != "section_sum" && f.nsOcrAgenda != "user").forEach(row => {
            if(!row.data.origPrice) {
              row.data.origPrice = row.fOcrNaklVProdMene_JC;
            }
  
            if(row.bOcrHiddenToCust) {
              row.fOcrNaklVProdMene_JC = row.data.origPrice*realCourse;
              row.fOcrNaklVProdMene_CC = this.round(row.fOcrNaklVProdMene_JC * row.fOcrMnozstvi);
              this.nulRow(row, false);
            }
            else {
              this.calculateRow(row, "fOcrNaklVProdMene_JC", row.data.origPrice*realCourse);
            }
  
        });
  
        this.sumTable(tab);
      }
    }
  
    async sendHd(key, value) {
      if(this.error) return;
  
      key = key.replace("§", this.pf)
      this.set.saved = "saving";
      const data = { ID: this.ID, [key]: value};
  
      const {ferror} = await Rq.up(this.recordset, 1, data);
      this.set.saved = ferror ? "error" : "saved";
    }
  
    async sendRekap(data) {
      if(this.error) return Ariscat.setChanged(false);
  
      this.set.saved = "saving";
      console.log("rekap", data);
      const res = await Rq.up("obj_cen_rekap", 1, data, { iZakID: this.ID });
      this.set.saved = res.ferror ? "error" : "saved";
      return res;
    }
  
    float(number) {
      //hodnota muze obsahovat carku nebo mezery
      return parseFloat(String(number).replace(",", ".").replace(/\s/g, ''));
    }

    savePrnFooter= async () => {
      const prnFooter = this.ofr[`ns${this.pf}PrdjPrnFooterFR3`]
      .replaceAll("<p>","")
      .replaceAll("</p>","<br>")
      .replaceAll("<strong>", "<b>")
      .replaceAll("</strong>", "</b>")
      .replaceAll("<em>", "<i>")
      .replaceAll("</em>", "</i>")
      .replaceAll("<span", "<font")
      .replaceAll("</span>", "</font>")
      .replace(/style="color: rgb\((\d+), (\d+), (\d+)\);"/g, (fnd, c1,c2,c3) => {
        var col = "#" + this.toHex(c1) + this.toHex(c2) + this.toHex(c3);
        return `color="${col}"`
      })
      
      Ref.modal.close();
      this.ofr[`ns${this.pf}PrdjPrnFooterFR3`] = prnFooter;
      await Rq.up(this.recordset, 1, {ID: this.ID, [`ns${this.pf}PrdjPrnFooterFR3`]: this.ofr[`ns${this.pf}PrdjPrnFooterFR3`]});
      //console.log("ft", prnFooter);
    }

    editHeel=()=> {
      this.set.prnFooter = true;
      $.trumbowyg.svgPath = '/images/icons.svg';
      var tm = $('#prnfEditor').trumbowyg({ lang: _.lang, removeformatPasted: true, btns: [['strong', "em", "underline", 'foreColor']], });
      tm.trumbowyg('html', this.ofr.prnFooter);
    }




  TD = ({ inp, r, v, text }) => (
    inp ? 
      <td className={v + " input"}>
        <input type="text" width="60" 
          onChange={e=> this.changeText(e,r,v)} 
          className={v + " td-input"} 
          value={text} />
      </td> :
      <td className={v}>{text || "-"}</td>
  )

  Money = ({ inp, r, v, noSum }) => {
    let val = r[v];

    return inp ? 
      <td className={v + " input text-right"}>
        <input type="text" width="60" 
          onChange={e=> this.changeMoney(e,r,v)} 
          onFocus={e=> e.target.select()} 
          onKeyDown={e=> this.keyInput(e,r,v)} 
          className={v + " td-input text-right"} 
          disabled={r.bOcrHiddenToCust}
          value={this.money(val)} />
      </td> :
      <td className={v + " text-right"}>{noSum ? "-" : (this.money(val) || "-")}</td>
  }

  newRow = (tab, index) => {
    return {
      bOcrHiddenToCust: false,
      data: { new: true, tab },
      fOcrMarze: 0,
      fOcrMnozstvi: 0,
      fOcrNaklVNakMene_JC: 0,
      fOcrNaklVProdMene_CC: 0,
      fOcrNaklVProdMene_JC: 0,
      fOcrNavyseniCeny_Prc: 0,
      fOcrNavyseniCeny_Prc_BACKUP: 0,
      fOcrProdejPoSleve_CC: 0,
      fOcrProdejPoSleve_JC: 0,
      fOcrProdejPredSlevou_CC: 0,
      fOcrProdejPredSlevou_JC: 0,
      fOcrSlevaCelkem: 0,
      fOcrSleva_Abs: 0,
      fOcrSleva_Abs_BACKUP: 0,
      fOcrSleva_Prc: 0,
      fOcrSleva_Prc_BACKUP: 0,
      iOcrLevel: 2,
      iOcrPopObjID: this.ID,
      iOcrSectionNo: index,
      nsOcrAgenda: "user",
      nsOcrNazevTrans: "",
    }
  }

  Row = (row, tab) => {
    const { TD, Money } = this;
    if (!row || row.data.deleted) return [];

    const sum = row.nsOcrAgenda === "section_sum";
    const user = row.nsOcrAgenda === "user";

    if(row.fOcrNavyseniCeny_Prc == -100) row.fOcrNavyseniCeny_Prc = 0;

    return (
      <tr 
        className={this.cln({
          textMuted: row.bOcrHiddenToCust,
          trForbidden: row.bOcrForbidden,
          sectionSum: row.nsOcrAgenda,
          trError: row.data.error,
          user: user
        })} 
        key={row.iOcrSequenceNo}
      >
        <td className="CHB2">
          <If is={!sum && !user}>
            <Checkbox 
              disabled={user} 
              state={row} 
              item="bOcrHiddenToCust"
              not={true}
              onChange={v=> this.chbCheck(v, row)} 
            />
          </If>  
        </td>
        <td className="moveTD">
          <If is={!sum}>
            <Btn move onClick={e=> this.moveRow(-1, row, tab)} className="action moveBtnUp" title={_.moveUp} sec={row.nsOcrLevelDesc}><RIcons.Fa.FaCaretUp /></Btn>
            <Btn move onClick={e=> this.moveRow(1, row, tab)} className="action moveBtnDown" title={_.moveDown} sec={row.nsOcrLevelDesc}><RIcons.Fa.FaCaretDown /></Btn>
          </If>
        </td>

        <td className="levelDesc2">
          <If is={user}>
            <Btn del onClick={e=> this.deleteRow(tab, row)} className="deleteRow"><RIcons.Fa.FaTrash /></Btn>
          </If>
        </td>
        <TD inp={user} r={row} v="nsOcrNazevTrans" text={this.translate(row.nsOcrNazevTrans, this.ofr[`i${this.pf}PrdjLangID`])} />

        <Money inp={user} r={row} v="fOcrMnozstvi" />

        <Money inp={user} r={row} v="fOcrNaklVProdMene_JC" course={true} />
        <Money r={row} v="fOcrNaklVProdMene_CC" course={true} />

        {Repo.stg.cs__popobjOfrShowColsPriceLstBuyPr &&
<>
        <Money r={row} v="fOcrCenikVNakMene_JC" course={true} />
        <Money r={row} v="fOcrCenikVNakMene_JC" />
</>        
        }

        <Money inp={!sum} r={row} v="fOcrNavyseniCeny_Prc" />

        <Money inp={user} r={row} v="fOcrProdejPredSlevou_JC" />
        <Money r={row} v="fOcrProdejPredSlevou_CC" course={true} />

        <Money inp={!sum} r={row} v="fOcrSleva_Prc" />
        <Money inp={!sum} r={row} v="fOcrSleva_Abs" />

        <Money noSum={sum} r={row} v="fOcrProdejPoSleve_JC" course={true} />
        <Money r={row} v="fOcrProdejPoSleve_CC" course={true} />
        <Money r={row} v="fOcrSlevaCelkem" course={true} />
        <Money r={row} v="fOcrMarze" course={true} />
      </tr>
    );
  }

  Deal =()=> {
    const cur = this.ofr.currencyCodePr;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <span>{_.deal}</span>
        </div>
        <div className="panel-body" />
      </div>
    )
  }

  BaseSettings =()=> {
    const cur = this.ofr.currencyCodePr;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <span>{_.basic_settings}</span>
          <Btn show id="switch-all" title={_.dispEntirTab} onClick={this.showAll}>{_.show} {_.all}</Btn>
        </div>
        <div className="panel-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <td className="text-right" width="150">{_.exchangeRate} [{this.ofr.currencyCode}/{cur}]</td>
                <td className="text-right">{_.currencyCosts}</td>
                <td className="text-right">{_.currencySales}</td>
                <td className="text-right">{_.offerLang}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-right input">
                  {/*
                  <input type="text" className="text-right" 
                    onChange={this.changeCourse} 
                    onFocus={e=> e.target.select()} 
                    onKeyDown={e=> this.changeCourse(e)} 
                    id="course" 
                    value={this.hOffer.prdjKurzTxt || ""}
                  />
                  */}
                  <RInput id="course" state={this.ofr} item="prdjKurzTxt" />
                </td>
                <td className="text-right"><span className="naklMena">{this.ofr.currencyCode}</span></td>
                <td className="">
                  <HSelect
                      state={this.ofr} 
                      values={this.curs} 
                      item={`i${this.pf}PrdjMenaID_Prodej`}
                      label="nsMenaKod" 
                      onChange={this.changeRekap}
                      ref={this.selRef}
                  ></HSelect>
                </td>
                <td className="">
                  <HSelect
                    state={this.ofr} 
                    values={[
                      {ID: 1, lng: _.cs},
                      {ID: 2, lng: _.en},
                      {ID: 3, lng: _.de},
                      {ID: 4, lng: _.sk},
                      {ID: 5, lng: _.fr},
                      {ID: 6, lng: _.pl},
                      {ID: 7, lng: _.hu},
                      {ID: 8, lng: _.ro},
                    ]}
                    item={`i${this.pf}PrdjLangID`}
                    label="lng" 
                    onChange={this.changeRekap}
                    ref={this.langRef}
                  ></HSelect>
                </td>
              </tr> 
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  Recap =()=> (
    <div className="panel panel-default">
      <div className="panel-heading">{_.overallRecap}</div>
      <div className="panel-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <td className="text-right" width="200">{_.vatSettings}</td>
              <td className="text-right" width="200">{_.priceWithoutVat}</td>
              <td className="text-right" width="100">{_.vat}</td>
              <td className="text-right" width="200">{_.totalPrice} [{this.ofr.currencyCodePr}]</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-right input tax-input">
                <span className="mask-hint fas fa-info-circle" title={_.defDPHChanged} />
                
                <input type="text" 
                  className="text-right" 
                  id="tax-setting" 
                  onChange={this.changeDPH} 
                  onFocus={e=> e.target.select()} 
                  onKeyDown={e=> this.keyInput(e,this.ofr,"prdjSazbaDPH")} 
                  value={this.money(this.ofr.prdjSazbaDPH)}                   
                />
              </td>
              <td className="text-right withoutDPH">{this.money(this.ofr.prdjTotal_bezDPH)}</td>
              <td className="text-right DPH">{this.money(this.ofr.prdjTotal_DPH)}</td>
              <td className="text-right sDPH">{this.money(this.ofr.prdjTotal_sDPH)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )

  Table =num=> {
    const sec = this.secs[num];
    if(!sec) return [];

    const cur = this.ofr.currencyCodePr;
    const tab = this.tables[num];
    //sekce ostatni nema iOcrPozID
    const hideUsRow = (!sec.iOcrPozID || !Repo.stg.cs__userRowOnlyInOtherSection) ? "" : " dnone";

    return (
      <div className="panel cusPanel" key={num}>
        <header className="flex">
          <div className="leftHeader">
            <span className="table-name">
              <Checkbox 
                onChange={v=>this.switchTable(sec)} 
                state={sec.data} 
                item="visible" 
                name={sec.nsOcrLevelDesc + ". " + this.translate(sec.nsOcrNazevTrans, this.ofr[`i${this.pf}PrdjLangID`])} 
              />
            </span>
          </div>
          <div className="rightHeader min-width-300">
            <RInput
              placeholder={_.discount + " %"}
              className="sleva min-width-300" 
              state={this.discounts}
              item={num}
              timer="500"
              onChange={this.setDiscount}
            />
          </div>
          <div>
            <Btn move onClick={e=> this.moveTab(-1, num)} className="action moveUp" title={_.moveUp} sec={sec.nsOcrLevelDesc}><RIcons.Fa.FaCaretUp /></Btn>
            <Btn move onClick={e=> this.moveTab(1, num)} className="action moveDown" title={_.moveDown} sec={sec.nsOcrLevelDesc}><RIcons.Fa.FaCaretDown /></Btn>
            <Btn add onClick={e=> this.addRow(num)} className={"new addCustRow" + hideUsRow} data-num={sec.nsOcrLevelDesc}><RIcons.Fa.FaPlus /> {_.addCustRow}</Btn>
          </div>
        </header>
        <table className="cusTab">
          <thead>
            <tr className="hdA">
              <td></td>
              <td></td>
              <td></td>
              <td className="polozky"></td>
              <td></td>
              <td colSpan={Repo.stg.cs__popobjOfrShowColsPriceLstBuyPr ? '4' : '2'} className="text-center naklady hd">{_.costs} [<span className="prdjMena">{cur}</span>]</td>
              <td colSpan="3" className="text-center prodej hd">{_.sale} [<span className="prdjMena">{cur}</span>]</td>
              <td colSpan="2" className="text-center sleva hd">{_.discount}</td>
              <td colSpan="2" className="text-center poSleve hd">{_.saleAftDisc} [<span className="prdjMena">{cur}</span>]</td>
              <td className="sleva"></td>
              <td></td>
            </tr>
            <tr className="hdB">
              <td className="center"><span className="fas fa-print" title={_.checkedLinesInPrintReport}>{_.forPrint}</span></td>
              <td width="85"></td>
              <td className="center"></td>
              <td className="center polozky">{_.items}</td>
              <td className="center">{_.quantity}</td>
              <td className="center naklady c1" width="80">{_.unitPrice}</td>
              <td className="center naklady c2">{_.totalPrice}</td>
              {Repo.stg.cs__popobjOfrShowColsPriceLstBuyPr &&
              <>
                <td className="center naklady c3">{_.listPrice}</td>
                <td className="center naklady c4">{_.listPriceTotal}</td>
              </>
              }
              <td className="center sale c1">{_.increasePrc}</td>
              <td className="center sale c2" width="80">{_.unitPrice}</td>
              <td className="center sale c3">{_.totalPrice}</td>
              <td className="center sleva c1" width="50">%</td>
              <td className="center sleva c2" width="60">[<span className="prdjMena">{cur}</span>] {_.tot}</td>
              <td className="center pric c1">{_.unitPrice}</td>
              <td className="center pric c2">{_.totalPrice}</td>
              <td className="center sleva" width="20">{_.totalDisc} [<span className="prdjMena">{cur}</span>]</td>
              <td className="text-center">{_.margin} [<span className="prdjMena">{cur}</span>]</td>
            </tr>
          </thead>
          <tbody className={this.cln({dnone: !sec.data.visible})}>
            {tab && tab.filter(this.filterRow).map(m => this.Row(m, tab))}
          </tbody>
        </table>
      </div>
    )
}

changePaymDisc = async () => {
  this.ofr[`b${this.pf}PrdjPrnShowAdvPaymDisc`] = !this.ofr[`b${this.pf}PrdjPrnShowAdvPaymDisc`];
  await this.sendHd("b§PrdjPrnShowAdvPaymDisc", this.ofr[`b${this.pf}PrdjPrnShowAdvPaymDisc`]);
  this.refresh();
}

//this.showEditFooter

rnd(s) {
  return (
    <div className="comp customerOffer">
      <div className="compBar buttons">
        <Link className="btn edit backDeal" href={`/deals/${this.ofr[`i${this.pf}ParentZak`]}`}><RIcons.Fa.FaArrowLeft /> {_.backToDeal}</Link>
        <Link className="btn edit backOrder" href={`/${this.orPop}s/${this.ID}`}>
          <RIcons.Fa.FaArrowLeft /> {this.recordset == 'poptavka' ? _.backToInq : _.backToOrder}
        </Link>

        <Btn show onClick={this.showAll}>{this.hideAll ? _.hideAl : _.showAl}</Btn>

        {this.printButtons && this.printButtons.length > 1
          ? 
          <ButtonMenu icon={<RIcons.Fa.FaPrint />} name={_.print}>
            {this.printButtons.map((e, index) => (
              e && e.length > 1 
              ? <me-nu key={index} onClick={() => this.print(e[1])}>{e[0]}</me-nu>
              : null
            ))}
          </ButtonMenu>
          :
          this.printButtons && this.printButtons.length === 1 
          ? <Btn className="menu"  onClick={() => this.print(this.printButtons[0][1])}><RIcons.Fa.FaPrint /> {_.print}</Btn>
          : null
        }

        <ButtonMenu name={_.othOpt}>
          <me-nu onClick={this.editFooter} className={this.cln({dnone: !Repo.stg.cs__popobjOfrShowEditFooterFR3})}><RIcons.Fa.FaEdit /> {_.editFooter}</me-nu>
          <me-nu className={this.cln({dnone: !Repo.stg.cs__popobjOfrShowCBAdvPaymDisc})} onClick={this.changePaymDisc}>
            {this.ofr[`b${this.pf}PrdjPrnShowAdvPaymDisc`] ? <RIcons.Fa.FaToggleOn /> : <RIcons.Fa.FaToggleOff />}
            {_.dispDiscNotic}
          </me-nu>
        </ButtonMenu>

      </div>	
      <div className="panels grid" style={{gridTemplateColumns: "auto 11rem"}}>
        <div>
            {Object.keys(this.tables).map(key => this.Table(key))}
        </div>
        <div>
          <div className="panel">
              <header>{_.recapitulation}</header>

                <div id="rekapData">
                  <div className="info">
                    <div className="infoTitle">{_.offerLang}</div>
                    <HSelect
                      state={this.ofr} 
                      values={[
                        {ID: 1, lng: _.cs},
                        {ID: 2, lng: _.en},
                        {ID: 3, lng: _.de},
                        {ID: 4, lng: _.sk},
                        {ID: 5, lng: _.fr},
                        {ID: 6, lng: _.pl},
                        {ID: 7, lng: _.hu},
                        {ID: 8, lng: _.ro},
                      ]}
                      item={`i${this.pf}PrdjLangID`}
                      label="lng" 
                      onChange={this.changeRekap}
                      ref={this.langRef}
                    ></HSelect>
                  </div>

                  <div className="info">
                    <div className="infoTitle">{_.vatSettings}</div>
                    <RInput
                      className="taxSetting" 
                      timer="800"
                      onChange={this.changeDPH} 
                      onFocus={e=> e.target.select()} 
                      onKeyDown={e=> this.keyInput(e,this.ofr,"prdjSazbaDPH")} 
                      state={this.ofr}
                      item={`f${this.pf}PrdjSazbaDPH`}
                    />
                  </div>

                  <div className="info">
                    <div className="infoTitle">{_.currencySales}</div>
                    <HSelect
                        state={this.ofr} 
                        values={this.curs} 
                        item={`i${this.pf}PrdjMenaID_Prodej`}
                        label="nsMenaKod" 
                        onChange={this.changeRekap}
                        ref={this.selRef}
                    ></HSelect>
                  </div>

                  <div className="info">
                    <div className="infoTitle">{_.exchangeRate} [{this.ofr.nsMenaKod || "?"}/{this.ofr.currencyCodePr}]</div>
                    <RInput 
                      onChange={this.changeCourse} 
                      onFocus={e=> e.target.select()} 
                      timer="800"
                      id="course" 
                      state={this.ofr}
                      item="prdjKurzTxt"
                    />
                  </div>

                  <div className="info">
                    <div className="infoTitle">{_.currencyCosts}</div>
                    <div className="naklMena">{this.ofr.nsMenaKod || "?"}</div>
                  </div>

                  <div className="info">
                    <div className="infoTitle">{_.priceWithoutVat}</div>
                    <div className="withoutDPH">{this.money(this.ofr[`f${this.pf}PrdjTotal_bezDPH`])}</div>
                  </div>
                  <div className="info">
                    <div className="infoTitle">{_.vat}</div>
                    <div className="DPH">{this.money(this.ofr[`f${this.pf}PrdjTotal_DPH`])}</div>
                  </div>
                  <div className="info">
                    <div className="infoTitle">{_.sum}</div>
                    <div className="sDPH">{this.money(this.ofr[`f${this.pf}PrdjTotal_sDPH`])}</div>
                  </div>
              </div>
          </div>
        </div>	
      </div>
    </div>
  )
}

}