import { SComponent, _, Ref, RInput, Checkbox, RIcons, Table, HSelect, Ariscat, Rq, DInput, SDate, Repo, Http, Btn, createRef } from '../../dump'
import { AtchList } from '../attachments/atchList';
import { If } from '../base/if';
import './style.scss'

export class EventDetail extends SComponent {
    attchm = [];

    async cmount() {
        this.eventTypes = [];
        this.createEventTypes();

        this.cusList = (await Rq.sd("uni_custom_list", 0, 0, { nsAgenda: "typ_jednani" }))?.rows;
        this.deals = (await Rq.sd("zakazka", 1))?.rows;
        this.fitters = (await Rq.sd("zamestnanec", 1))?.rows?.filter(f => f.bZamJeMonter);
    }

    reOpen = () => {
        this.openModal();
    }

    createEventTypes() {
        console.log("cr", Repo.stg.cs__DealsMont);

        this.eventTypes = [
            { ID: 0, type: _.eventTypeAssembly },
            { ID: 1, type: _.contactMeetings },
            { ID: 2, type: _.service },
            { ID: 3, type: _.reclamation }, 
        ];

        if(!Repo.stg.cs__DealsMont) {
            this.eventTypes[0].visible = false;
            this.eventTypes[2].visible = false;
            this.eventTypes[3].visible = false;
        }
        if(!Repo.stg.cs__DealsKontJednani) {
            this.eventTypes[1].visible = false;
        }

        console.log(this.eventTypes);
    }

    async saveEvent(rec, goShop = false) {
        const {
            ID,
            //číslo zakázky
            iKalZakID,
            //název jednání
            nsKalNazevJednani,
            //místo realizace
            nsKalMistoRealizace,
            //s kým se jednalo - chybi
            nsKalUcastnici,
            //typ jednání - chybi
            ieKalTyp,
            //popis udalosti - chybi
            nsKalPozn,
            //stavieKalStav - chybi
            ieKalStav,
            //zaplánovat do kalenáře
            bKalZaplanovat,
            // druh zaznamu
            iKalDruhZaznamu,
            // ID montéra
            iKalMonterID,

            //datum od do
            nsKalDatumOd,
            nsKalDatumDo,
        } = rec;

        this.saved = true;

        const data = {
            ID,
            //číslo zakázky
            iKalZakID,
            //název jednání
            nsKalNazevJednani,
            //místo realizace
            nsKalMistoRealizace,
            //s kým se jednalo - chybi
            nsKalUcastnici,
            //typ jednání - chybi
            ieKalTyp,
            //popis udalosti - chybi
            nsKalPozn,
            //stavieKalStav - chybi
            ieKalStav,
            //zaplánovat do kalenáře
            bKalZaplanovat,
            // druh zaznamu
            iKalDruhZaznamu,
            // ID montéra
            iKalMonterID,

            //datum od do
            nsKalDatumOd,
            nsKalDatumDo,
        }

        if(rec.nsKalDatumOdCas != "") {
            let [hour,min] = rec.nsKalDatumOdCas?.split(":");
            data.nsKalDatumOd.hour = hour;
            data.nsKalDatumOd.min = min;
        }
        if(rec.nsKalDatumDoCas != "") {
            let [hour,min] = rec.nsKalDatumDoCas?.split(":");
            data.nsKalDatumDo.hour = hour;
            data.nsKalDatumDo.min = min;
        }

        if(rec.allDay) {
            data.nsKalDatumOd.hour = 0;
            data.nsKalDatumOd.min = 0;
            data.nsKalDatumDo.hour = 23;
            data.nsKalDatumDo.min = 59;
        }
        
        if(rec.iKalDruhZaznamu != 1) {
            delete data.ieKalTyp;
            data.iKalMonterID = rec.fitter?.ID;
        }

        data.iKalZakID = rec.deal?.ID;

        if(rec.nsKalDatumDo <= rec.nsKalDatumOd) {
            Ref.notify(_.endEventMsg, 8, "error");
            return;
        }

        //console.log("sav", data);
        //return
        this.reOpen();

        if(
            !rec.deal?.ID || 
            (!rec.allDay && !rec.nsKalDatumOdCas) ||
            (!rec.allDay && !rec.nsKalDatumDoCas) ||
            (rec.iKalDruhZaznamu == 1 && data.ieKalTyp === undefined) || 
            (rec.iKalDruhZaznamu != 1 && data.iKalMonterID === undefined)
        ) {
            Ref.notify( _.redMustFilled, 4, "error");
            return;
        }

        const res = await Rq.up("kalendar_m", 2, data, { iZakID: data.iKalZakID, iDruhZaznamu: rec.iKalDruhZaznamu });

        if(!res.ferror) {
            Ref.modal.close(data);
        }

        const newID = res.rows[0]?.ID || rec.ID;
        let errors = 0;

        //odesle prilozene soubory
        if(newID && rec.newAttchm) {
            for(let file of rec.newAttchm) {
                var [a, ok, msg] = await Http.uploadAttchm("popobj", data.iKalZakID, newID || 0, file.name, file.nsAttPozn || "", file.data);
                if(!ok) errors++;
            }
        }

        if(errors > 0) Ref.notify(_.erNuUnsFil + " " + errors, 5, "error");
    }

    async selectDeal(thID, zakID, enabledOverwrite) {
        const ID = thID || zakID;
        const sDeal = this.selected.deal = this.deals.find(f => f.ID == ID) || {};

        this.docsEvents.iPOID = sDeal.ID;
        $("#customerEvent").val(sDeal.nsOrgNazev);

        //select je zakazan, pokud thID nebo zakID obsahuje nejakou hodnotu
        $("#dealEvent").attr('disabled', (!!thID) || (!!zakID));

        if(enabledOverwrite) {
            $("#dealEvent").attr('disabled', false);
        }
        
        if(sDeal.ID) {
            $("#dealEvent").val(ID).trigger('change');
            $("#placeEvent").val((this.openedEvent||{}).nsKalMistoRealizace || sDeal.nsZakDodaciAdr || sDeal.nsOrgAdresa);
            $("#customerEvent").val(sDeal.nsOrgNazev);
            
            //if($("#nameEvent").val() == "") $("#nameEvent").val(sDeal.nsZakOznaceni);
        }
        else {
            $("#dealEvent").val(null).trigger('change');
            $("#placeEvent").val("");
            $("#customerEvent").val("");
            //if($("#nameEvent").val() == "") $("#nameEvent").val("");
        }

        //zobrazeni tlacitek u zaplanovani udalosti
        const showBtn1 = this.ID && location.pathname.includes("realization");

        //zobrazeni tlacitek mimo obchod
        const showBtn2 = zakID && this.viewAllRec;

        if(showBtn1 || showBtn2) {
            $("#goShop").show()[0].href = "/deal/" + this.selected.deal.ID;
            $("#saveEventGo").show();
        }
        else {
            $("#saveEventGo").hide()
            $("#goShop").hide();
        }
    }

	addAtch = async (file, newList) => {
        this.rec.newAttchm = newList;
        this.reOpen();
    }

    delSelect = async row => {
        await Rq.del("uni_custom_list", row.ID);
        this.cusList = (await Rq.sd("uni_custom_list", 0, 0, { nsAgenda: "typ_jednani" })).rows;
        this.refresh();
    }

    delEvent = async rec=> {
        const d = await Ref.confirm.open(_.delete, _.reallyDeleteEvent);

        if(!d) return;

        for(let at of this.attchm.filter(f => f.eventID == rec.ID)) {
            //await Ariscat.app.updateDeleteAttchm(true, at.id);
        }
        
        console.log("recid", rec.ID, rec);
        await Rq.del('kalendar_m', rec.ID);

        Ref.modal.close({del: true}); 
    }

    onSearchDeals = (a,b,c) => {
        console.log("ccccc",a,b,c);
    }

    changeType =()=> {
        this.rec.nsKalMistoRealizace = this.rec.deal?.selItem?.nsZakDodaciAdr || this.rec.deal?.nsZakDodaciAdr || "";
        this.rec.iKalMonterID = this.rec.fitter?.ID;
        this.reOpen();
    }

    openModal = async () => {
        const rec = this.rec;
        rec.iKalDruhZaznamu = rec.iKalDruhZaznamu || 0;
        rec.deal = rec.deal || {};
        rec.fitter = rec.fitter || this.fitters.find(f => f.ID == rec.iKalMonterID) || {};
        rec.attchm = rec.attchm || [];
        rec.ieKalStav = rec.ieKalStav || 0;
        rec.ieKalTyp = rec.ieKalTyp || this.cusList?.[0]?.ID;

        return await Ref.modal.open(rec.ID ? _.editEvent : _.newEvent,
            <div className="modal-data eventDetail">
                <div className="grid-4 inputs">
                    <RInput tname="title" state={rec} item="nsKalNazevJednani" />
                    <HSelect
                        className={this.cln({error: this.saved && !rec.deal?.ID})}
                        tname="deal"
                        state={rec.deal}
                        item="ID"
                        label="nsZakCislo"
                        values={this.deals}
                        onChange={this.changeType}
                        disabled={rec.ID || rec.noDealChange}
                    >
                        <h-op format={r => `<b>${r.nsZakCislo}</b><div>${r.nsZakOznaceni}</div>`} />
                        <h-sc>{_.search}</h-sc>
                    </HSelect>
                    <RInput 
                        tname="customer" 
                        state={rec.deal} 
                        item="nsOrgNazev" 
                        disabled={true} 
                        placeholder={rec.deal?.nsZakCislo ? this.rec.deal.selItem?.nsOrgNazev || this.rec.deal?.nsOrgNazev || _.notFilled : _.notFilled} 
                    />
                    <HSelect
                        tname="eventType"
                        state={rec}
                        item="iKalDruhZaznamu"
                        values={this.eventTypes}
                        label="type"
                        onChange={this.changeType}
                        disabled={rec.ID}
                    ></HSelect>

                    <DInput tname="eventStart" state={rec} item="nsKalDatumOd" />
                    <RInput name="&nbsp;" state={rec} type="time" item="nsKalDatumOdCas" disabled={rec.allDay} className={this.cln({error: this.saved && !rec.allDay && !rec.nsKalDatumOdCas})} />
                    <DInput tname="eventEnd" state={rec} item="nsKalDatumDo" />
                    <RInput name="&nbsp;" state={rec} type="time" item="nsKalDatumDoCas" disabled={rec.allDay} className={this.cln({error: this.saved && !rec.allDay && !rec.nsKalDatumDoCas})} />
                    <RInput tname="eventLoc" state={rec} item="nsKalMistoRealizace" disabled={rec.iKalDruhZaznamu != 1} />


                <If is={rec.iKalDruhZaznamu == 1 /*kontaktni*/}>
                    <HSelect
                        className={this.cln({error: this.saved && rec.iKalDruhZaznamu == 1 && rec.ieKalTyp === undefined})}
                        tname="state"
                        state={rec}
                        item="ieKalTyp"
                        values={this.cusList}
                        label="nsLstHodnota"
                        onChange={this.reOpen}
                    >                        
                        <h-op format={r => <div class="flex">{r.nsLstHodnota}
                            <Btn del onClick={()=>this.delSelect(r)} className={this.cln({btn: 1, delSelect: 1, dnone: r.nsLstPoridil == "system"})}><RIcons.Fa.FaTrash /></Btn>
                        </div>} />
                    </HSelect>
                    <RInput tname="whoDealt" state={rec} item="nsKalUcastnici" />
                </If>
                <If is={rec.iKalDruhZaznamu != 1}>
                    <HSelect
                        state={rec}
                        tname="typeMeeting"
                        item="ieKalStav"
                        label="type"
                        values={[
                            { ID: 0, type: _.scheduled },
                            { ID: 1, type: _.done },
                            { ID: 2, type: _.canceled },
                            { ID: 3, type: _.postponed },
                        ]}
                        onChange={this.reOpen}
                    >
                    </HSelect>
                    <HSelect
                        className={this.cln({error: this.saved && rec.iKalDruhZaznamu != 1 && rec.iKalZakID === undefined})}
                        tname="fitter" 
                        state={rec.fitter} 
                        item="ID"
                        values={this.fitters}
                        label="nsZamNazev"
                    />
                </If>
                <If is={rec.iKalDruhZaznamu == 1}>
                    <div>
                        <Checkbox tname="scheduleCalendar" state={rec} item="bKalZaplanovat" />
                        <Checkbox tname="allDay" state={rec} item="allDay" onChange={this.reOpen} />
                    </div>
                </If>
                <If is={rec.iKalDruhZaznamu != 1}>
                    <div>
                        <Checkbox tname="scheduleCalendar" state={rec} item="bKalZaplanovat" />
                        <Checkbox tname="allDay" state={rec} item="allDay" onChange={this.reOpen} />
                    </div>
                </If>
                </div>

                <div className="grid-2">
                    <RInput 
                        type="tarea" 
                        tname="eventDescription" 
                        state={rec} 
                        item="nsKalPozn" 
                        format={val => val?.replaceAll("&#x0D;&#x0A;", "\n")}
                    />
                    <AtchList
                        className="eventAtch"
                        name={_.attachments}
                        list={rec.attchm.filter(f => f.iAttJednaniID == rec.ID)}
                        onChange={this.addAtch}
                        alwex={Repo.stg.alwex}
                        canDelete={true}
                    />
                </div>
            </div>,
            <div className="modal-buttons">
                <If is={rec.ID}>
                    <Btn del className="delEvent" onClick={() => this.delEvent(rec)}><RIcons.Fa.FaTrash /> {_.delete}</Btn>
                </If>
                <If is={(rec.iZakID || this.dealID) && !location.href.includes("/deals")}>
                    <a className="btn btn-primary goShop" href={"/deals/" + (rec.iZakID || this.dealID)}> {_.toShop}</a>
                </If>
                <If is={this.dealID && !location.href.includes("/deals")}>
                    <Btn back className="saveEventGo" onClick={() => this.saveEvent(rec, true)}><RIcons.Fa.FaCheck /> {_.saveReturnShop}</Btn>
                </If>
                <Btn save className="saveEvent" onClick={() => this.saveEvent(rec)}><RIcons.Fa.FaCheck /> {_.save}</Btn>
            </div>
        );
    }

    openDetail = async (rec = this.rec, events = this.events, dealID = 0) => {
        this.createEventTypes();

        this.rec = rec;
        this.events = events;
        this.dealID = dealID;
        this.saved = false;

        if(dealID) {
            this.rec.noDealChange = true;
            this.rec.deal = {ID: dealID}
            this.rec.fitter = {ID: rec.iKalMonterID}
        }

        if(!rec.nsKalDatumOd) rec.nsKalDatumOd = SDate();
        if(!rec.nsKalDatumDo) rec.nsKalDatumDo = SDate.addTime(0,0,2);

        rec.nsKalDatumOd = typeof rec.nsKalDatumOd === "string" ? SDate(rec.nsKalDatumOd) : rec.nsKalDatumOd;
        rec.nsKalDatumDo = typeof rec.nsKalDatumDo === "string" ? SDate(rec.nsKalDatumDo) : rec.nsKalDatumDo;

        if(rec.iKalZakID) {
            const {rows} = await Rq.sd('attchm', 1, 0, { iPOID: rec.iKalZakID, frReload: true });
            rec.attchm = rows;
            rec.newAttchm = [];
        }

        rec.nsKalDatumOdCas = SDate(rec.nsKalDatumOd)?.hhm;
        rec.nsKalDatumDoCas = SDate(rec.nsKalDatumDo)?.hhm;
        rec.allDay = rec.nsKalDatumOd.is(0,0) && rec.nsKalDatumDo.is(23,59);

        return await this.openModal();
    }

    rnd =()=> <></>
}
