import { _, SComponent, RIcons, Ref, RInput, Rq, Table, createRef, Comp, Btn } from '../../dump'

export class Branches extends SComponent {
    async cmount() {
        this.table = createRef();
    }

    async saveBranch(branch) {
        this.set.saved = "saving";
        const {ID, nsPobNazev} = branch
        const { ferror } = await Rq.up("dealer_pob", 1, { ID, nsPobNazev });
        Ref.modal.close();

        this.table?.current?.reload();
    }

    edit = async branch => {
        Ref.modal.open(branch.ID ? _.branchEditing : _.addNewBranch,
            <div id="branchLightboxForm">
                <RInput state={branch} item="nsPobNazev" tname="branchName" />
                <Btn ok onClick={e=> this.saveBranch(branch)}>{_.confirm}</Btn>
            </div>
        )
    }

    rnd() {
        return (
            <Comp className="branches">
                <Table 
                    recordset="dealer_pob" 
                    cols="1" 
                    customCols={true} 
                    changeCols={false} 
                    detail={this.edit} 
                    ref={this.table}
                    panel={_.branches}
                >
                    <bar-button
                        type="add"
                        icon="Plus"
                        text=""
                        click={() => this.edit({ nsPobNazev: "" })}
                    />
                    <tab-button
                            type="primary"
                            icon="cog"
                        >
                        <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                    </tab-button>
                    <tab-col name="nsPobNazev" />
                </Table>
            </Comp>

        )
    }
}