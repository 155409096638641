import './styl.scss';
import { SComponent, h, _, Btn } from "../../dump";

export class Confirm extends SComponent {
    state = {
        open: false,
        btnYes: _.yes, 
        btnNo: _.no,
    }

    close = state => {
        this.set.open = false;
        this.dialogRet && this.dialogRet(state);
        return state;
    }

    open = (info, msg, btnYes = _.yes, btnNo = _.no) => {
        if(btnYes == 10) {
            btnYes = _.yes;
            btnNo = _.no;
        }

        this.setState({info, msg, open: true, btnYes, btnNo});

        return new Promise(rs => this.dialogRet = rs)
    }

    rnd =(s,p)=> {
        return (
            <div id="confirmBg" className={this.cln({open: s.open})}>
                <div id="confirm">
                    {/* <header><span>{s.info || "Potvrzení"}</span></header> */}
                    <div className="msg">{s.msg|| ""}</div>
                    <footer>
                        <Btn confirm class="yes" onClick={e=> this.close(true)}>{s.btnYes}</Btn>
                        <Btn cancel class="no" onClick={e=> this.close(false)}>{s.btnNo}</Btn>   
                    </footer>
                </div>
            </div>
        )
    }
}