import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'dayjs/locale/cs';
import dayjs from 'dayjs'
import { EventDetail } from "../../components/eventDetail/eventDetail";
import { DInput, SComponent, SDate, createRef, _, Checkbox, Ref, If, route, Comp, Ariscat } from "../../dump";
import './styl.scss'

dayjs.locale("cs");
const localizer = dayjsLocalizer(dayjs)
const DragAndDropCalendar = withDragAndDrop(Calendar)

export class CalendarEvents extends SComponent {
    plusDate = 0;
    viewWeek = true;
    hideClass = "hd";
    branchs = [];
    fitters = [];
    deals = [];
    events = [];
    weekSec = 7*24*60*60;
    view = "month";
    withoutBranch = true;
    withoutFitter = true;
    eventRef = createRef();

    months = [
        "", _.january, _.february, _.march, _.april,
        _.may, _.june, _.july, _.august, _.september,
        _.october, _.november, _.december
    ];
    
    async cmount(s,p) {
        this.days = ["mon", "tues", "wednes", "thurs", "fri", "satur", "sun"];
        this.eventDetail = createRef();
        this.date = SDate()
        this.ID = +p.dealID
        console.clear();

        await this.loadData();
        await this.loadCalendar();    
    }

    setEventTitle(ev) {
        let midnight = ev.nsKalDatumOd.is(0,0) && ev.nsKalDatumDo.is(23,59);
        let sameDay = SDate.isSameDay(ev.nsKalDatumOd, ev.nsKalDatumDo);

        if(midnight && sameDay) {
            ev.allDay = 1;
            ev.nsKalDatumFm = ev.nsKalDatumOd.format("d.M.");
        }
        else if(sameDay) {
            ev.allDay = 0;
            ev.nsKalDatumFm = ev.nsKalDatumOd.format("d.M. H:mm - ") + ev.nsKalDatumDo.format("H:mm");
        }
        else {
            ev.allDay = 0;
            ev.nsKalDatumFm = ev.nsKalDatumOd.toString() + " - " + ev.nsKalDatumDo.toString();
        }
        if(midnight) {//pokud je pres 2 dny, oznaci se jako celodeni
            ev.allDay = 2;
        }

        ev.title = ev.nsKalNazevJednani || ev.nsKalMistoRealizace || "Bez popisku";
        ev.desc = "";
    }

    async loadCalendar() {
        let dateFrom = this.date.copy.addDate(-40).ymd;
		let dateTo = this.date.copy.addDate(40).ymd;

        //console.log("calendar", this.dateFrom, this.dateTo);
		let {rows} = await Rq.sd("kalendar_m", 1, 0, { dDTFr: dateFrom, dDTTo: dateTo, frReload: true });
        this.events = rows;

        for(let ev of rows) {
            ev.nsKalDatumOd = SDate(ev.nsKalDatumOd);
            ev.nsKalDatumDo = SDate(ev.nsKalDatumDo);
            ev.branch = this.branchs.find(br => ev.iPobID == br.ID) || {};
            ev.fitter = this.fitters.find(f => ev.iKalMonterID == f.ID) || {};
            ev.deal = this.deals.find(d => ev.iKalZakID == d.ID) || {};
            ev.allDay = 0;
            this.setEventTitle(ev);
        }

        this.refresh();
    }

    async loadData() {
        this.branchs = (await Rq.sd('dealer_pob', 1)).rows?.map(m => (m.check = true, m));
        this.branchs = [{ID: null, nsPobNazev: _.noBranch, check: true}, ...this.branchs];
		this.fitters = (await Rq.sd('zamestnanec', 1)).rows?.map(m => (m.check = true, m));
        this.deals = (await Rq.sd("zakazka", 1)).rows;
        this.fitters = this.fitters.filter(f => f.bZamJeMonter);
    }
    
    getDays(date = SDate()) {
        return this.viewWeek ? this.getWeek(date) : this.getCalMonth(date);
    }

    createTooltip(ev) {
        const notes = [
			_.deal + " " + _.number_short, 
			_.deal,
			_.title, 
			_.adress,
			_.from, _.to, 
			_.type,
			_.abbrFitter,
			_.fitter,
			_.customer,
			_.branchDeal,
			_.fitterBranch
		];

        var branch = {};
		
		const vals = [ 
			ev.deal.nsZakCislo,
			ev.deal.nsZakOznaceni,
			ev.nsKalNazevJednani,
			ev.nsKalMistoRealizace,
			ev.nsKalDatumOd, 
            ev.nsKalDatumDo,
			ev.type, 
			ev.fitter.nsZamUzivZkratka,  
			ev.fitter.nsZamNazev, 
			ev.deal.nsOrgNazev,
			branch.nsPobNazev, 
			ev.branch.nsPobNazev,
			ev.deal.nsZakOznaceni, 
		];

		let texts = [];

		for(let i=0;i<notes.length;i++) {
			const value = vals[i] === undefined ? "-" : String(vals[i]);
			texts.push(<div className="tooltipRow"><span className="col1">{notes[i] + ": "}</span><span className="col2">{value}</span></div>);
		}

        return texts;
    }

    async eventClick(event) {
        if(event.noClick) return;

        const res = await this.eventDetail.current.openDetail(event, this.events);
        console.log("r1", res);

        if(res?.goShop) {
            route("/deals/" + res.iZakID);
            return;
        }

        if(!Ariscat.isNul(res) || res?.del === true) {//smazani udalosti || uprava
            this.loadCalendar();
        }
    }

    eventCSS = (event, start, end, isSelected) => {
        let color = this.fitters.find(f => f.ID == event.iZamID)?.nsZamHexColor || "";

        let allowClick = true;

        if(this.ID && event.iKalZakID != this.ID) {
            color = "rgb(221, 221, 221)";
            allowClick = false;
            event.noClick = true;
        }

        return { style: {
            backgroundColor: color,
            cursor: allowClick ? "" : "no-drop",
            display: 
                //(event.branch?.check && event.fitter?.check || 
                event.fitter?.check || 
                (!event.fitter?.ID && this.withoutFitter) ||
                (!event.branch?.ID && this.branchs[0]?.check)
            ? "" : "none"
            //boxShadow: "0 0 2px 1px " + color,
            //borderRadius: "0px",
            //border: "2px solid rgba(0,0,0,0.2)"
        }};
    }

    changeDate = async date => {
        this.state.cDate = date;
        await this.loadCalendar();
        this.month(0);
    }

    moveEvent =data=> {
        this.resizeEvent(data);
    }

    resizeEvent =async data=> {
        const ev = data.event;
        if(ev.noClick) return;
        ev.nsKalDatumOd = SDate(data.start);
        ev.nsKalDatumDo = SDate(data.end);

        const {ID, nsKalDatumOd, nsKalDatumDo} = ev;
        const res = await Rq.up("kalendar_m", 1, {ID, nsKalDatumOd, nsKalDatumDo});
        //console.log("resize", data);
        this.setEventTitle(ev);
        this.refresh();
    }

    newEvent = async({start, end}) => {
        if(!(Repo.stg.cs__DealsMont || Repo.stg.cs__DealsKontJednani)) {
            return Ref.notify(_.mountContAlw, 6, "error");
        }

        let event = {
            nsKalDatumOd: SDate(start),
            nsKalDatumDo: SDate(end),
            allDay: true,
            bKalZaplanovat: true
        }

        const res = await this.eventDetail.current.openDetail(event, this.events, this.ID);

        if(!Ariscat.isNul(res) || res?.del === true) {//smazani udalosti || uprava
            this.loadCalendar();
        }
    }

    eventRender =ev=> {
        return (
            <div onMouseOver={e=> Ref.tooltip.open(this.createTooltip(ev.event), e)}>
                <b>{ev.event.nsKalDatumFm}</b> {ev.title}
            </div>
        )
    }

    checkPob = (val, key, {state}) => {
        this.fitters.filter(f => f.iZamPobockaID == state.ID).forEach(f => f.check = val);
        this.refresh();
    }

    changeDate =date=> {
        this.date = SDate(date);
        this.loadCalendar()
    }

    rnd(s) {
        return <Comp className="calendar panels12">
            <div>
                <EventDetail ref={this.eventDetail} />
                <Checkbox state={this} item="withoutFitter" tname="withoutFitter" onCheck={() => this.refresh()} />
                
                <div className="branchs">
                    {this.branchs.map(brch =><>
                        <div className="branch">
                            <div className="branchTitle">
                                <Checkbox state={brch} item="check" name={brch.nsPobNazev} onCheck={this.checkPob} />
                            </div>
                            <div className="fitters">{this.fitters.filter(f => f.iZamPobockaID == brch.ID)
                                .map(fit => 
                                    <Checkbox 
                                        onCheck={e => this.refresh()} 
                                        state={fit} item="check" 
                                        style={{color: fit.nsZamHexColor, /*textShadow: "0 0 2px rgba(0,0,0,0.4)"*/}} 
                                        name={fit.nsZamNazev}
                                    />
                                )}
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
            <div className="calendarTable">
                <DragAndDropCalendar
                    events={this.events}
                    localizer={localizer}
                    startAccessor="nsKalDatumOd"
                    endAccessor="nsKalDatumDo"
                    defaultDate={this.date}
                    style={{ height: 500 }}
                    onSelectEvent={this.eventClick.bind(this)}
                    onEventDrop={this.moveEvent}
                    onEventResize={this.resizeEvent}
                    eventPropGetter={this.eventCSS}
                    onSelectSlot={this.newEvent}
                    onNavigate={this.changeDate}
                    resizable={true}
                    selectable={true}
                    components={{
                        month: {event: this.eventRender},
                        week: {event: this.eventRender},
                        day: {event: this.eventRender},
                        // toolbar: CustomToolbar,
                    }}
                    messages={{
                        agenda: 'agenda',
                        allDay: _.allDay,
                        month: _.month,
                        day: _.day,
                        today: _.today,
                        previous: _.prev,
                        next: _.next,
                        date: _.date,
                        noEventsInRange: _.inPeriodNoEvents,
                        time: _.time,
                        more: _.more,
                        tomorrow: _.tomorrow,
                        week: _.week,
                        work_week: _.workWeek,
                        yesterday: _.yesterday,
                        event: _.event,
                        time: _.time
                    }}
                />
            </div>
        </Comp>
    }
}

// class CustomToolbar extends SComponent {
//     navigate = (action) => {
//       this.props.onNavigate(action);
//     };
  
//     navigateToNextYear = () => {
//       const { date, onNavigate } = this.props;
//       onNavigate('NEXT', moment(date).add(1, 'year').toDate());
//     };
  
//     navigateToPreviousYear = () => {
//       const { date, onNavigate } = this.props;
//       onNavigate('PREV', moment(date).subtract(1, 'year').toDate());
//     };
  
//     rnd() {
//       return (
//         <div className="rbc-toolbar">
//           <span className="rbc-btn-group">
//             <button onClick={() => this.navigateToPreviousYear()}>Previous Year</button>
//             <button onClick={() => this.navigate('TODAY')}>Today</button>
//             <button onClick={() => this.navigateToNextYear()}>Next Year</button>
//           </span>
//         </div>
//       );
//     }
//   }