import { SComponent, _, Datapacket, Rq, RIcons, RInput, Repo, Ref, SDate, createRef, If, Btn } from "../../dump";

export class ColSettings extends SComponent {
	cmount(s, p) {
		this.checkedAll = true;
		this.all = p.all || false;
		this.recordset = this.props.recordset;
		this.data = this.props.data;
		this.switches = this.props.switches;

		this.search = {
			all: "",
			avaible: "",
			selected: "",
		}

		this.loadData();
	}
	
	async loadNewData() {
		const {cols} = await Rq.sd(this.recordset, 0, 0, {frReload: true});
		this.cols = cols;
		await this.getData();

		this.setState({
			all: [...this.cols],
			available: [...this.cols],
			viewed: [...this.cols],
		})
	}

	async loadData() {
		if(!this.data && this.recordset) {
			const {cols} = await Rq.sd(this.recordset, 0, 0, {frReload: true});
			this.cols = cols;
			await this.getData();
		}
		else if(this.data && this.recordset) {
			const {cols} = this.data;
			this.cols = cols;
			await this.getData();
		}
	}

	async getData() {
		//console.log("cc", this.cols);

		this.cols.forEach(f => {
			if(typeof f !== "string") {
				//console.log("ff", f);
				f.checked = f.orderby <= 6;
				f.visible = f.visible === "TRUE" || f.visible === true;
				f.orderby = +f.orderby;
			}
		});

		//render jeste neprobehl, takze se musi pouzit state = {}
		this.state = {
			search: "",
			open: false,
			all: [...this.cols],
			available: [...this.cols],//cols.filter(f => f.checked && !f.visible),
			viewed: [...this.cols],//cols.filter(f => f.checked && f.visible),
		};
	}

	click = row => {
		row.visible = !row.visible;
		row.selected = true;
		this.open(false);
	}

	clickCheckbox = (e, row) => {
		console.log("ck", row.checked);
		row.checked = !row.checked;

		this.open(false);
	}

	clickR = () => {
		this.lastClick.visible = !this.lastClick.visible;
		this.lastClick.selected = true;
		this.open(false);
	}
	clickL = () => {
		this.lastClick.visible = !this.lastClick.visible;
		this.lastClick.selected = true;
		this.open(false);
	}

	setDefault = async () => {
		const res = await Ref.confirm.open(_.info, _.wantChangeCols);
		if(!res) return;

		var switches = {};

		if(this.iPoziceID) switches.iPoziceID = iPoziceID;

		await Rq.reset(this.recordset, switches);

		await this.loadNewData();
		Rq.deleteCache(this.recordset);
		Ref.modal.close();
		this.props.save && this.props.save();
		this.refresh();
	}

	save = async e=> {
		const DP = new Datapacket();
		DP.recordset = this.recordset;
		const obArray = [];
		this.state.viewed.forEach(f => DP.addField(f.attrname, f.visible, f.orderby))

		for(let field of this.state.all) {
			const dField = DP.findField(field.attrname);
			dField.orderby = dField.orderby || 0;

			if(field.checked) {
				if(field.orderby >= 6 && field.orderby <= 14) dField.orderby -= 10;
			}
			else
				if(field.orderby >= -4 && field.orderby <= 4) dField.orderby += 10;

			//zkontroluje, ze orderby neobsahuje duplicitni hodnoty (krome 0 a 10)
			if(dField.orderby != 0 && dField.orderby != 10 && !obArray.includes(dField.orderby)) {
				obArray.push(dField.orderby);
			}
			else if(obArray.includes(dField.orderby)) {
				dField.orderby = 0;
			}
		}

		/*
		for(let field of this.state.all.filter(f=> f.checked)) {
			const {orderby, visible} = field;
			fields.push([field.attrname, { visible, orderby }]);
		}

		console.log(fields)
		Rq.up(this.recordset, fields);
		*/

		//Rq.up(this.recordset, cols)

		// console.log('Colsettings switches: ' + JSON.stringify(this.switches).toString())
		if (this.switches != null){
			DP.createSwitches(this.switches)
		}

		DP.addRow({'ID' : 0})

		await DP.send();
		Rq.deleteCache(this.recordset)
		Ref.modal.close();
		this.props.save && this.props.save();
	}

	cancelFilters = () => {
		Ref.modal.close();
		//this.props.save && this.props.save();
	}

	filterOnChange = (val, filpole) => {
		this.state[filpole].map(row => row[filpole] = this.compareText(val, row.caption, row.attrname))
		this.open();
	}

	rowClick = rc => {
		rc.selected = !rc.selected	
		if (this.lastClick) {
			this.lastClick.selected = false;
		}
		this.lastClick = rc;
		this.open(false);
	}

	move = delta => {
		const allCols = this.state.viewed;
		const viewed = allCols.filter(v => v.viewed != false && v.visible && v.checked);
		const index = allCols.indexOf(this.lastClick);
		let newIndex = viewed.indexOf(this.lastClick) + delta;

		newIndex = viewed[newIndex] ? allCols.indexOf(viewed[newIndex]) : -1;

		if (newIndex < 0  || newIndex == allCols.length) return; //Already at the top or bottom.
	
		const ai = allCols[index];
		const ani = allCols[newIndex];

		//zamena sloupcu
		allCols[index] = ani;
		allCols[newIndex] = ai;

		this.open(false);
	}

	moveAll = delta => {
		const allCols = this.state.viewed;
		const viewed = allCols.filter(v => v.viewed != false && v.visible && v.checked);
		const index = allCols.indexOf(this.lastClick);
		let newIndex = viewed.indexOf(this.lastClick) + delta;

		newIndex = viewed[newIndex] ? allCols.indexOf(viewed[newIndex]) : -1;

		if (newIndex < 0  || newIndex == allCols.length) return; //Already at the top or bottom.
	
		const ai = allCols[index];
		const ani = allCols[newIndex];

		//zamena sloupcu
		allCols[index] = ani;
		allCols[newIndex] = ai;

		this.open(false);
	}
		
	compareText(search, ...texts) {
		const cropIt = t => t.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

		//slouci texty do 1ho
		const text = cropIt(texts.join(" "));
		search = cropIt(search);

		return text.includes(search);
	}

	clickAll =e=> {
		//this.set.poleA.forEach(f => f.checked = false)
		this.checkedAll = !this.checkedAll;
		this.state.all.forEach(f => f.checked = this.checkedAll);
		this.open(false);
	}

	toSelected =e=> {
		
	}

    async open(load = true) {
		if(load) {
			await this.loadData();
		}

        Ref.modal.open(_.filters,
			<div className="modal-data">
				<div className={this.cln({"filters-columns": 1, hid: 1, all: this.all, col2: !this.all})}>
					<div className="allCols">
						<h3>{_.allColumns}</h3>	
						<RInput 
							id="alColSearch"
							placeholder={_.search}
							onChange={val => this.filterOnChange(val, "all")}
							state={this.search}
							item="all"
						/>
						<div className="filters-hidden sel">
							{this.state.all.filter(v => v.all != false).map(m => {
								//<input type="checkbox" className={m.selected ? "selected" : ""} onClick={e => this.rowClick(m)} onDoubleClick={e => this.click(m)} key={m.attrname} /> {m.caption}
								return (
									<div key={"sall" + m.attrname}>
										<input 
											key={"all" + m.attrname} 
											checked={m.checked === true} 
											onChange={e => this.clickCheckbox(e, m)} 
											type="checkbox" 
											className={this.cln({"filters-checkbox":1, selected: m.selected})} 
										/>
										{" " + (m.table ? `(${m.table}) ${m.caption}` : m.caption)}
									</div>
								)
							})}					
						</div>
					</div>
					<div className="filters-buttons all">
						<Btn move onClick={e => this.moveAll(-1)} className="moveAllLeft"><RIcons.Fa.FaAngleDoubleLeft /></Btn>
						<Btn move onClick={e => this.moveAll(1)} className="moveAllRight"><RIcons.Fa.FaAngleDoubleRight /></Btn>
					</div>
					<div className="availableCols no-padding">	
						<h3>{_.avaible_cols}</h3>
						<RInput 
							id="avaibleSearch"
							placeholder={_.search}
							onChange={val => this.filterOnChange(val, "available")}
							state={this.search}
							item="avaible"
						/>	
						<div>
							<div className="filters-avaible sel">
							{this.state.available.filter(v => v.available != false && !v.visible && v.checked).map((m) => 
								<div 
									className={m.selected ? "selected" : ""} 
									onClick={e => this.rowClick(m)} 
									onDoubleClick={e => this.click(m)} key={"ava" + m.attrname}
								>
									{m.table ? `(${m.table}) ${m.caption}` : m.caption}
								</div>
							)}
							</div>
						</div>
					</div>

					<div className="filters-buttons available">
						<Btn move onClick={this.clickL} id="toAvaible"><RIcons.Fa.FaAngleLeft /><RIcons.Fa.FaAngleRight /></Btn>
					</div>

					<div className="viewed">
						<h3>{_.cols_shown}</h3>
						<div>
							<RInput 
								id="selectedSearch"
								placeholder={_.search}
								onChange={val => this.filterOnChange(val, "viewed")}
								state={this.search}
								item="selected"
							/>
						</div>							
						<div>
							<div className="form-control filters-selected sel">
							{this.state.viewed.filter(v => v.viewed != false && v.visible && v.checked).map((m) =>
								<div 
									className={m.selected ? "selected" : ""} 
									onClick={e => this.rowClick(m)} 
									onDoubleClick={e => this.click(m)} 
									key={m.attrname}
								>
									{m.table ? `(${m.table}) ${m.caption}` : m.caption}
								</div>
							)}
							</div>
						</div>
					</div>	

					<div className="filters-buttons viewed">
						<Btn move onClick={e => this.move(-1)} className="moveUp"><RIcons.Fa.FaAngleDoubleUp /></Btn>
						<Btn move onClick={e => this.move(1)} className="moveDown"><RIcons.Fa.FaAngleDoubleDown /></Btn>
					</div>

				</div>	
			</div>,
			<div className="modal-buttons">
				<Btn save onClick={this.save}>{_.save}</Btn>
				<Btn warn onClick={this.setDefault}>{_.default}</Btn>
				<Btn cancel  onClick={this.cancelFilters}>{_.cancel}</Btn>
				<If is={this.all && this.checkedAll}>
					<Btn toggle onClick={this.clickAll} id="check-all"> {_.unMarkAll}</Btn>
				</If>
				<If is={this.all && !this.checkedAll}>
					<Btn toggle onClick={this.clickAll} id="check-all"> {_.markAll}</Btn>
				</If>
			</div>
		)
	}

	rnd() {}
}