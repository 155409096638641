import { AtchView } from "./atchView";
import './styl.scss';
import { Ref, SComponent, _, Http, Ariscat, RIcons, If, Btn, Table } from "../../dump";
import { createRef } from "preact";

export class AtchTable extends SComponent {
    cmount(s, p) {
      this.state = {
        atchList: p.list || [],
        open: false,
      }
  
      this.atchView = createRef();
      this.atchTable = createRef();
      this.file = {};
    }
  
    printSize(size) {
      var sizes = "kMGT" //PEZY";
  
      for(let i=sizes.length-1;i>=0;i--) {
          if(size >= Math.pow(1024, i+1)) return (size/Math.pow(1024, i+1)).toFixed(2) + " " + sizes[i] + "B";
      }
  
      return size + " B";
    }
  
    componentWillReceiveProps(p) {
      this.set.atchList = p.list || [];
    }
    
    
    async update(id) {
      const json = await window.fetchj("/prilohy/"+id);
      this.setState({ atchList: json.data||[] })
    }
  
    addAtch = async e => {
      e.stopPropagation();
      if(this.props.readOnly || this.props.disabled) return;
      
      let file = await Ariscat.selectFile();
  
      if(file.size > (1024*1024*5)) {
        return Ref.notify(_.bigFile + " 5 MB!", 4, 'danger');
      }
  
      if(this.props.alwex) {
        const ext = file.name.split(".").pop();
  
        if(!this.props.alwex.includes(ext)) {
          return Ref.notify(_.forbType + ` (${ext})!`, 4, 'danger');
        }
      }

      file.new = true;

      this.state.atchList.push(file);
  
      if(this.props.onChange) {
        this.props.onChange && this.props.onChange(file, this.state.atchList.filter(f => f.new));
      }
      this.refresh();
    }
  
    removeAtch = async (ID) => {
      const list = "atchList";
      const atch = this.state[list].filter(f => f.ID !== ID);
      const res = await Ref.confirm.open(_.info, _.deleteRecordConfirm);
      
      if(res) {
        const res2 = await Http.deleteAttchm(ID);
        console.log("r", res2);
        const [a, ok, msg] = res2;
        !ok ? Ref.notify(msg, 4, 'error') : this.set.atchList = atch;
      }
      
      this.props.onClear && this.props.onClear(atch);
    }
  
    removeNewAtch = (e, atchName) => {
      e.stopPropagation();
      const newAtch = this.state.newAtch.filter(f => f.name !== atchName);
      this.props.onChange && this.props.onChange([], newAtch);
      this.set.newAtch = newAtch;
    }
  
    viewAtch = async atch => {
      this.viewAtchURL(`${Repo.serverURL}/api/attchm/${atch.ID}/0/${atch.nsAttNazevSPrip}`, atch.nsAttNazevSPrip);
    }
  
    async viewAtchURL(url, name) {
      //var f = await window.fetch(url);
      //var blob = await f.blob();
      //let blobURL = URL.createObjectURL(blob);
      let type = this.getType(name || "");
  
      this.file = {
        name: name,
        data: null,
        type: type,
        url: url
      }
  
      type == "blob" ? 
        window.location.href = url :
        this.atchView.current.open(this.file);
    }
  
    getType(href) {
      const ext = href.split(".").pop().toLowerCase();
      if(!ext) return "blob";
  
      const types = {
          image: ["jpg", "jpeg", "bmp", "webp", "gif", "png", "ico", "svg"],
          video: ["mpg", "avi", "mp4", "mkv", "mpeg", "vob"],
          text: ["txt", "json", "html", "js", "ini"],
          pdf: ["pdf"],
      }
  
      return Object.keys(types).find(key => types[key].includes(ext)) || "blob";
    }
  
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
  
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);
  
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
  
          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
      }
        
      return new Blob(byteArrays, {type: contentType});
    }
  
    rnd = (s,p) => {
      if(this.props.visible === false) {
        return null;
      }


      return (
        <>
            <Table
                  className={this.cln({atchTable:1, empty: s.atchList.length, readOnly: p.readOnly, disabled: p.disabled})} 
                  rows={s.atchList}
                  customCols={true}
                  changeCols={false}
                  expand={true}
                  changeFilters={false}
                  panel={this.props.panel}
                  showAll={true}
                  detail={row => this.viewAtch(row)}
              >
              <tab-col name="nsAttNazevSPrip" caption={_.title} />
              <tab-col name="nsAttPozn" caption={_.userNote} />
              <tab-col name="iAttVelikost" type="size" caption={_.size} />
              <tab-col name="dAttPorizeno" type="date" caption={_.date} />   
              <bar-button
                  type="add"
                  icon="Plus"
                  text=""
                  className={this.cln({empty: s.atchList.length, readOnly: p.readOnly, disabled: p.disabled})}
                  click={this.addAtch}
              />
              
              {p.canDelete === true && !p.readOnly ?
                  <tab-button
                          type="primary"
                          icon="cog"
                      >
                      <b-item icon="Trash" text={_.delete} click={rec => this.removeAtch(rec.ID)} />
                  </tab-button>
              : []}
          </Table>
          <AtchView ref={this.atchView} close={() => Ref.modal3.close()} />
        </>
      )
    }
  }