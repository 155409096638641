import './delivAdr.scss'
import { RInput, _, SDate, Rq, RIcons, Ref, Btn } from "../../dump";
import { Tab } from '../../components/tab/tab';

export class DelivAdr {
    listAdr = [];
    tab = 1;

    async init() {
        this.search = "";
        this.listAdr = [];
        this.recordset = "dealer_addr";
        this.listAdr = (await Rq.sd(this.recordset, 1)).rows;
        console.log("deliv", this)
    }

    formatAdr(row, onlyText = false) {
        var rows = [
            row.nsAddrFirma,
            row.nsAddrJmeno,
            row.nsAddrTelefon,
            [row.nsAddrUlice, row.nsAddrCislo].filter(f => f).join(" "),
            [row.nsAddrPSC, row.nsAddrMesto].filter(f => f).join(" "),
            row.nsAddrZeme,
        ]
            .filter(f => f)

        return onlyText ? rows.join("\n") : rows.map(m => <div>{m === undefined ? "" : m}</div>)
    }

    edit = async (row = {}) => {
        this.selected = row;

        const res = await Ref.modal.open(_.editAdr,
            <>
                <div className="adrForm grid-2">
                    <RInput tname="companyName" state={row} item="nsAddrFirma" />
                    <RInput tname="contactPerson" state={row} item="nsAddrJmeno" />
                    <RInput tname="telephone" state={row} item="nsAddrTelefon" />
                    <RInput tname="street" state={row} item="nsAddrUlice" />
                    <RInput tname="streetNumber" state={row} item="nsAddrCislo" />
                    <RInput tname="city" state={row} item="nsAddrMesto" />
                    <RInput tname="postalCode" state={row} item="nsAddrPSC" />
                    <RInput tname="country" state={row} item="nsAddrZeme" />
                </div>
                <div className="btnSave">
                    <Btn save onClick={e => this.save(row)}>{_.save}</Btn>
                </div>
            </>
        );
    }

    delete = async row => {
        var res = await Ref.confirm.open(_.delete, _.deleteRecordConfirm);
        if (!res) return;

        await Rq.del(this.recordset, row.ID);
        await this.init();
        this.open();
    }

    fav = row => {
        row.bAddrOblibene = !row.bAddrOblibene;
        this.save(row, true);
    }

    select = row => {
        row.text = this.formatAdr(row, true);
        Ref.modal.close(row);
    }

    save = async (row, hide = true) => {
        hide && Ref.modal.hide();
        await Rq.up(this.recordset, 1, row);
        await this.init();
        this.open();
    }

    new = () => {
        let row = {
            nsAddrFirma: "",
            nsAddrJmeno: "",
            nsAddrTelefon: "",
            nsAddrUlice: "",
            nsAddrCislo: "",
            nsAddrMesto: "",
            nsAddrPSC: "",
            nsAddrZeme: "",
        }

        this.edit(row);
    }


    async open() {
        console.log("deliv", this);

        return await Ref.modal.open(_.deliveryAddresses,
            <div className="delivAdr modal-data">

                <div className="search-container">
                    <RInput
                        onChange={val => console.log(val)}
                        placeholder={_.search}
                        value={this.search}
                    />
                </div>

                <Tab tab={this.tab}>
                    <tab-content title={_.favourite}>
                        <div className="adrList grid-3">{this.listAdr.filter(f => f.bAddrOblibene).map(row =>
                            <div class="adrBox">
                                <div className="adrText">{this.formatAdr(row)}</div>
                                <div class="divButtons">
                                    <Btn del class="btn btn-danger" onClick={e => this.fav(row)}><RIcons.Fa.FaHeart /></Btn>
                                    <Btn add onClick={e => this.select(row)}><RIcons.Fa.FaCheck /></Btn>
                                </div>
                            </div>)
                        }</div>
                    </tab-content>
                    <tab-content title={_.addressesCustom}>
                        <div className="add-container">
                            <Btn add onClick={e => this.new()}>
                                <RIcons.Fa.FaPlus />
                                {_.newAddress}
                            </Btn>
                        </div>
                        <div className="adrList grid-3">{this.listAdr.map(row =>
                            <div class={"adrBox" + (row.bAddrOblibene ? " fav" : "")}>
                                <div className="adrText">{this.formatAdr(row)}</div>
                                <div class="divButtons">
                                    <Btn del style={{ backgroundColor: '#f0ad4e' }} onClick={e => this.edit(row)}><RIcons.Fa.FaEdit /></Btn>
                                    <Btn del style={{ backgroundColor: '#d8524f' }} onClick={e => this.delete(row)}><RIcons.Fa.FaTrash /></Btn>
                                    <Btn del style={{ backgroundColor: '#5ac0df' }} onClick={e => this.fav(row)}><RIcons.Fa.FaHeart /></Btn>
                                    <Btn add onClick={e => this.select(row)}><RIcons.Fa.FaCheck /></Btn>
                                </div>
                            </div>)
                        }</div>
                    </tab-content>
                    <tab-content title={_.saved}>

                    </tab-content>
                </Tab>
            </div>
        );
    }
}