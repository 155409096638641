import { Repo, route } from "../../dump"
import { Component } from 'preact';

export default class Dashboard extends Component {
    componentWillMount() {
      // console.log("ccc", Repo.login);
      
      // if(!Repo.login) return;
      
      let url = "/demands";
      
      if(Repo.stg?.bIsProducEmp) url = "/messages";
      else if(Repo.stg?.cs__homePage) url = "/home";
      
      // route(url)
      setTimeout(() => route(url, true), 1);
    }
  
    render =()=> <div></div>
}
