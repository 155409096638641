import './styl.scss';
import { RIcons, SComponent, _, createRef, Ref, If, route, Repo, Http } from "../../dump";

import { Notifications } from './notifications';
import { Loading } from './loading';
import { loadAriscat } from '../../main';
import { Link } from 'preact-router';
import { Search } from './search';
import { Messages } from '../../pages/messages/messages';
import { ObchodniZastupce } from './obchZs';
import { ButtonMenu } from '../buttonMenu/buttonMenu';

const logout =async ()=> {
    Repo.login = false;
    
    await Http.logout();
    route("/");
    //Repo.stg?.bIsProducEmp ? route("/dealers") : route("/deals")
    await loadAriscat(true);      
}

const userDetail =async ()=> {
    if(!Repo.stg?.bIsProducEmp) {
        route("/users/" + Repo.user.ID);
    }
}

const User =()=> {
    const url = Repo.user.nsZamPictureURL || "/src/img/no-photo.jpg";

    return (
        <div className="current-user">
            <div className="user">
            <ButtonMenu style={{'z-index':'100'}} icon={
            <>
                <div 
                    className="logo" 
                    style={{backgroundImage: `url('${url}')`}} 
                    alt={Repo.prod?.nsVrbcVrbcNazev}
                ></div>
                <h3>{Repo.stg.nsUserFullName}</h3>

            </>}>
                <me-nu onClick={userDetail}><RIcons.Ai.AiOutlineUser /> {_.profile}</me-nu>
                <me-nu onClick={logout}><RIcons.Bs.BsBoxArrowRight /> {_.logout}</me-nu>
            </ButtonMenu>
            </div>           
        </div>
    )
}

//zabrani aby se komponenta vytvorila vickrat
const loading = <Loading />;
const notifications = <Notifications />;
const messages = <Messages />;

export class Header extends SComponent {
    cmount() {
        Ref.Header = this;
        //this.messages = createRef();

        this.state = {
            tags: [""],
            tagLinks: [""],
        }
    }

    get tags() { return this.state.tags; }

    set tagLinks(val) { this.set.tagLinks = val; }

    updateTags(tags, cc) {
        this.set.tags = tags;
    }

    rnd = (s,p) => {
        const tags = typeof this.state.tags === "string" ? [this.state.tags] : (this.state.tags || []).filter(f => f);

        return (
            <div className="header">
                <div className="headerLogo">
                    <Link href="/home" className="dealerLogo" style={{backgroundImage: Repo.dealer?.nsDealerLogo ? `url(data:image/png;base64,${Repo.dealer.nsDealerLogo})` : null}} />
                </div>
                <div className="headerInner">
                    <div class="search">
                        {tags.map((tag,i) => 
                            s.tagLinks?.[i] ?
                            <Link class="tagWrap link" href={s.tagLinks[i]}><div class="tag"><span>{tag}</span></div></Link> :
                            <span class="tagWrap"><div class="tag"><span>{tag}</span></div></span>
                        )}
                    </div>
                    <div className="actions">
                        <ObchodniZastupce className={this.cln({dnone: tags.length > 1 || !Repo.stg?.cs__zobrazitObchZastAsistent})} />
                        <Search />
                        {loading}
                        {notifications}
                        {messages}
                        <User />
                    </div>
                </div>
            </div>
        )
    }
}