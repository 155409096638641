import './styl.scss'
import { Tab } from '../../components/tab/tab'
import { SComponent, _, Comp } from '../../dump'
import { SysDetail } from './sysDetail';
import { SysMonth } from './sysMonth';

export class SysInfo extends SComponent {
    rnd = (s, p) => {
        return (
            <Tab renderType="1">
                <tab-content title="Souhrn"><SysMonth /></tab-content>
                <tab-content title="Detaily"><SysDetail /></tab-content>
            </Tab>
        )
    }  
}