import * as Chartjs from 'chart.js'
import { SComponent, _, SDate, Rq, createRef, Ariscat, RIcons } from "../../dump";

export class Stats extends SComponent {
    constructor(p) {
        super(p);
        this.state = {
            year: new Date().getFullYear(),
        };
        this.chartEl = createRef();
    }

    async cmount() {
        this.obrat = {};
        this.pohledavky = {};
        this.opened = {};
        this.months = [];
        const {rows} = await Rq.sd("stat_private_overview", '', '', {dDTFr: `${this.state.year ? this.state.year : new Date().getFullYear()}-01-01`})
        this.obrat = rows.find(f => f.nsStSekce == "obrat-tento-rok") || {};
        this.pohledavky = rows.find(f => f.nsStSekce == "pohl-po-splat");
        this.opened = rows.find(f => f.nsStSekce == "otev-objed");
        this.months = rows.filter(f => f.nsStSekce == "obrat-po-mes").sort((a,b) => a.iStMesic - b.iStMesic);
        this.initChart();
    }

    getData = async () => {
        const {rows} = await Rq.sd("stat_private_overview", '', '', {dDTFr: `${this.state.year}-01-01`})
        this.obrat = rows.find(f => f.nsStSekce == "obrat-tento-rok") || {};
        this.pohledavky = rows.find(f => f.nsStSekce == "pohl-po-splat");
        this.opened = rows.find(f => f.nsStSekce == "otev-objed");
        this.months = rows.filter(f => f.nsStSekce == "obrat-po-mes").sort((a,b) => a.iStMesic - b.iStMesic);
        this.initChart();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.year !== this.state.year) {
          await this.getData();
        }
      }

    initChart = async () => {
        if (this.chart) {
            this.chart.destroy()
        }

        this.chart = new Chartjs.Chart(this.chartEl.current, {
            type: 'bar',
            data: {
                labels: [_.january, _.february, _.march, _.april, _.may, _.june, _.july, _.august, _.september, _.october, _.november, _.december],
                datasets: [{
                    label: _.monthlyTurnover,
                    backgroundColor: 'rgb(33,36,145)',
                    borderColor: 'rgb(33,36,145)',
                    data: this.months.map(m => m.fStCena)
                }]
            },
            options: { responsive: true }
        });
    }

    rnd(s,p) {
        return (
            <div className="stats">
                <canvas ref={this.chartEl} width="750" height="350" className="chart"></canvas>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', margin: '1rem'}}>
                    <button onClick={() => this.set.year = this.state.year - 1}>
                        <RIcons.Fa.FaArrowAltCircleLeft  />
                        {_.previous_year}
                    </button>
                    <span style={{fontWeight: 700, fontSize: '1.25rem'}}>{this.state.year}</span>
                    <button onClick={() => {if (this.state.year < new Date().getFullYear()) this.set.year = this.state.year + 1}} disabled={this.state.year >= new Date().getFullYear()}>
                        {_.next_year}
                        <RIcons.Fa.FaArrowAltCircleRight  />
                    </button>
                </div>

                <div className="smallStats">
                    <div className="statsBox">
                        <span className="txt">
                            {_.annual_turnover} {this.state.year} <br /><strong>{Ariscat.money(this.obrat.fStCena)} {this.obrat.nsStMena}</strong>
                        </span>
                    </div>
                    <div className="statsBox">
                        <span className="txt">
                            {this.pohledavky.nsStPopis} <br /><strong>{Ariscat.money(this.pohledavky.fStCena)}</strong>
                        </span>
                    </div>
                    <div className="statsBox">
                        <span className="txt">
                            {this.opened.nsStPopis} <br /><strong>{Ariscat.money(this.opened.fStCena)} {this.opened.nsStMena}</strong>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}