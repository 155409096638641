import "./hselect.scss";
import { SComponent, _, createRef } from "../../dump";

export class HSelect extends SComponent {
  cmount() {
    this.id = "ID";
    this.selRef = createRef();
    this.selected = null;
    this.allowUpdate = true;
    this.searchText = "";

    const p = this.props;
    this.values = p.values || [];

    const ch = Array.isArray(p.children) ? p.children : [p.children || {}];

    this.searchInp = ch.find(f => f.type == "h-sc");
    this.optFirst = ch.find(f => f.type == "h-fop");
    this.optEmpty = ch.find(f => f.type == "h-empty");
    this.updateInp = ch.find(f => f.type == "h-up");
    this.optEl = ch.find(f => f.type == "h-op");

    //nastavi klic pro hodnoty (vychozi je ID)
    if (p.uid) this.id = p.uid;

    if (this.values && this.values.length) {
      const v0 = p.values[0];

      //zkusi jestli ID je obsazeno v 1. hodnote
      if (v0[this.id] === undefined) {
        //jako ID se pouzije popisek
        if (v0[p.label]) 
          this.id = p.label;
      }
    }

    if (this.optEl) {
      this.optVal = this.optEl.props.val;
      this.optHtml = this.optEl.props.children;
      this.optFormat = this.optEl.props.format;
      this.optElDisabled = this.optEl.props.disabled;
    }
    else {
      this.optHtml = "$" + p.label;
    }

  }

  handleKeyDown = e => {
    // If has focus
    if (this.selRef.current && this.selRef.current.contains(document.activeElement)) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') e.preventDefault()

      const index = this.values.findIndex(v => v[this.id] === this.selected)
      let nIndex = index

      if (e.key === 'ArrowUp' || e.key === 'ArrowRight') {
        nIndex = index > 0 ? index - 1 : this.values.length - 1
      }

      if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
        nIndex = index < this.values.length - 1 ? index + 1 : 0
      }

      if (nIndex !== index && this.values[nIndex] && !this.values[nIndex].hidden) {
        this.selectValue(this.values[nIndex][this.id], e)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    this.update();

    //console.log("mount", this.selRef.current);

    //ZMĚNA HODNOTY PŘÍSTUPNÁ PŘES DOM
    Object.defineProperty(this.selRef.current, 'value', {
      //get: () => this.props.state[this.props.item],
      get: () => this.state.label,
      set: val=> this.selectValue(val)
    });
 
    Object.defineProperty(this.selRef.current, 'open', {
      //get: () => this.props.state[this.props.item],
      get: () => this.state.open || false,
      set: val=> this.openSelect(val)
    });
 
    Object.defineProperty(this.selRef.current, 'update', {
      get: () => this.update()
    });
 
    Object.defineProperty(this.selRef.current, 'it', {
      get: () => this
    });

    this.componentsClose();
  }

  //zajisti, aby se hselect prekreslil, pokud se zmeni state v hlavni komponente
  componentWillReceiveProps(np) {
    this.props = np;
    this.update();
  }

  openSelect = (val) => {
    this.searchText = "";

    if (this.props.values) {
      this.props.values?.forEach(f => f.hidden = false);
    }

    setTimeout(() => {
      this.set.open = val;
    }, 2);
  }

  componentDidUpdate(np) {
    if (!this.allowUpdate) return;

    const p = this.props;

    if (p.state && p.item) {
      let old = this.selected;
      let nw = np.state ? np.state[p.item] : null;

      if ((old !== null && old !== "") && old !== nw) this.update();

    }

    if (p.values !== np.values) {
      this.update();
    }
  }

  update() {
    this.allowUpdate = false;
    const p = this.props;

    //hleda ve state zadanou promennou,
    let value = ""

    if (p.state && p.item) {
      value = p.state[p.item];
    }
    else {
      value = p.value;
    }

    if (value === undefined) (value = "")

    this.selected = value;
    this.set.selected = value;

    this.values = p.values || [];

    //console.log("UPDATE", value, this.values);

    if (this.values.length && p.state) {
      //vybere polozku ze state
      const itemValue = value;
      //najde tu polozku ve values
      const sel = this.values.find(f => f[this.id] == itemValue);

      //priradi polozku do popisku selectu

      //nastavi hodnotu pokud je v seznamu
      if (sel) {
        this.set.label = sel[p.label] ?
          sel[p.label] : //nastavi popisek na 1. option pokud neni definovana hodnota
          this.optFirst && this.removeTags(this.optFirst.props.children);

        this.set.selected = sel[this.id];
      }
      //pokud se hodnota == 1. optionu, pouzije se hodnota z neho
      else if (this.optFirst && this.optFirst.props.val == itemValue) {
        this.set.label = this.removeTags(this.optFirst.props.children);
      }
      //pokud je hodnota nedefinovana
      else if (this.optFirst && this.optFirst.props.val) {
        this.set.label = this.removeTags(this.optFirst.props.children);
      }
      else {//pokud v seznamu neni, nastavi se hodnota z props - item
        this.set.label = itemValue;
      }

    }
    else {
      this.set.selected = 0;

      //vypraznit hodnotu
      this.set.label = value;
    }

    if (p.length && p[0].type == "find") {
      //console.log("FIND", p[0].props.children);
    }

    this.allowUpdate = true;

    if(!this.updated) {
      this.updated = true;
      //console.log("vals", this.values);
      const values = Array.isArray(this.values) ? this.values : [];

      this.selItem = values.find(m => m[p.label] == value);
      //console.log("si", this.selItem);
      p.onLoad && this.selItem && p.onLoad(this.selItem[this.id], p.item, this.selItem);
    }
  }

  removeTags(html) {
    return html.replace(/(<([^>]+)>)/gi, "");
  }

  selectValue(ID, e) {
    const p = this.props;
    const record = this.values.find(m => m[this.id] == ID);
    var label;

    if (record) {
      label = record[p.label] || (typeof record == "object" ? "" : String(record)) || "";
    }
    else {//nastavi vyber na 1. option a odstrani tagy
      label = this.optFirst.props.children.replace(/(<([^>]+)>)/gi, "");
    }

    this.set.selected = ID;
    this.set.label = label;

    //console.log("select", ID, record);

    if (p.state && p.item) {
      //vychozi zmena stavu - napr. kvuli vlastni udalosti onChange
      if(p.stateChange !== false) {
        p.state[p.item] = ID;
      }

      p.state.selItem = record;
      this.selItem = record;
    }

    p.onChange && p.onChange(ID, p.item, record, e);
    this.set.open = false;
  }

  selectOption() {

  }

  search = e => {
    this.searchText = e.target.value;

    for (let v of this.props.values) {
      v.hidden = !this.compareText(this.searchText, v._html);
    }

    this.refresh();
  }

  //hledani textu - bez hacku a carek
  compareText(search, ...texts) {
    const cropIt = t => t.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const text = cropIt(texts.join(" "));
    search = cropIt(search);
    return text.includes(search);
  }

  createValues(s, p) {
    this.optFirstH = [];
    this.optEmptyH = [];
    this.searchInpH = [];

    //VYTVOŘÍ INPUT PRO HLEDÁNÍ
    if (this.searchInp) {
      this.searchInpH = (
        <div className="search" key="searchin">
          <input 
            type="search" 
            className="hselSearch" 
            value={this.searchText} 
            placeholder={this.searchInp.props.children || "Search"} 
            onChange={this.search} 
            no-close
          />
        </div>
      )
    }

    //VYTVOŘÍ 1. OPTION (pokud je vložen)
    if (this.optFirst) {
      const label = this.optFirst.props.children || ";"
      const fValue = this.optFirst.props.val;
      const disabled = this.optFirst.props.disabled === true;
      const cSelected = s.selected == null ? " selected" : "";
      const cDisabled = disabled ? " disabled" : "";

      this.optFirstH = (
        <div
          className={"option first" + cSelected + cDisabled}
          onClick={e => {
            //nastavi popisek z 1. optionu a odstrani html tagy
            !disabled && this.selectValue(fValue, label.replace(/(<([^>]+)>)/gi, ""), e);
          }}
          val={fValue}
          key="firstin"
          dangerouslySetInnerHTML={{ __html: label }}>
        </div>
      )
    }

    //pokud v selectu nejsou data, tak se zobrazí info
    if (this.optEmpty && !this.values.length) {
      const label = this.optEmpty.props.children || ";"
      const fValue = this.optEmpty.props.val;
      const disabled = this.optEmpty.props.disabled === true;

      this.optEmptyH = (
        <div
          className="option empty disabled"
          val={fValue}
          key="emptyin"
          dangerouslySetInnerHTML={{ __html: label }}>
        </div>
      )
    }

    //VYTVOŘÍ HTML OPTIONY
    this.htmlOpt = [];

    if (this.values && this.values.filter) {
      this.htmlOpt = this.values.filter(f => !f.hidden).map((opt, i) => {
        const label = opt[p.label] || String(opt) || "";
        let html = this.optFormat ? this.optFormat(opt) : opt[p.label] || "?";

        opt._html = html;
        

        if (this.optVal) {
          this.optVal = this.optVal.replace(/\$(\w+)/g, (find, vr) => String(opt[vr]));
        }

        const mID = opt[this.id];

        //if(s.selected == mID) {
        this.selected = this.state.selected;
        //}

        const htmlStr = typeof html === "string" ? {dangerouslySetInnerHTML: { __html: html }} : {}

        return (
          <div
            className={this.cln({
              option: 1,
              selected: s.selected == mID,
              disabled: this.optElDisabled ? this.optElDisabled(opt) : false,
              dnone: opt.visible === false,
            })}
            key={this.optVal || mID || i}
            onClick={e => {
              //console.log("CLICK", mID, label);
              this.selectValue(mID, label, e);
            }}
            val={this.optVal || mID || i}
            {...htmlStr}
          >
            {html}
          </div>
        )
      });

      return [this.searchInpH, this.optFirstH, this.optEmptyH, ...this.htmlOpt];
    }
  }

  allowInpUpdate = () => (
    this.updateInp && this.updateInp.props.onUpdate && (this.props.editable !== false)
  )

  //nacteni hodnot pri psani do hlavniho inputu
  inputChange = async e => {
    if (!this.allowInpUpdate()) return;

    window.changedCount = window.changedCount || 0;
    window.changedCount++;
    var inpChangedCount = window.changedCount;

    if (this.timerHandle) clearTimeout(this.timerHandle);
    const val = e.target.value;
    this.set.label = val;

    if (this.updateInp.props.onSearch) {
      //this.values = await this.updateInp.props.onSearch(val, e) || [];
      //console.log("vvv", this.values);
      //this.refresh();
    }

    this.timerHandle = setTimeout(async () => {
      //pokud se mezitim neco zmenilo jinde, zahodit
      if (inpChangedCount == window.changedCount) {
        try {
          let onUpRes = await this.updateInp.props.onUpdate(val, e);
          //pokud onUpdate vrati seznam hodnot, aktualizuji se do vyberu
          if (onUpRes) this.values = onUpRes;

          this.refresh();
        }
        catch {
          this.refresh();
        }
      }
    }, this.updateInp.props.timer || 0);
  }

  rndBefore = (s, p) => {
    this.allowUpdate = false;
  }

  rndAfter = () => {
    this.allowUpdate = true;
  }

  arrowClose = () => {
    if (this.state.open)
      setTimeout(() => this.set.open = false, 5);
  }

  openClose = () => {
    if (!this.state.open) {
      this.openSelect(true)
      //REF DODELAT
      //setTimeout(() => this.selRef.current.querySelector(".search input") && this.selRef.current.querySelector(".search input").focus(), 100);
    }
    else if (!this.allowInpUpdate()) {
      this.set.open = false;
    }
  }

  //u h-select musi byt class=
  rnd = (s, p) => {
    let name = [];

    if (p.name) name = <label className="riLabel">{p.name}</label>
    else if (p.tname) name = <label className="riLabel">{_[p.tname]}</label>

    return (
      <h-select 
        class={this.cln({ 
          [p.item]: 1, 
          [p.className]: 1, 
          openComponent: 1,
          hide: !p.visible, 
          open: s.open,
          //prazdna hodnota se da vnutit i pres objekt vlozeny do selectu
          empty: (s.label == "" || this?.selItem?._empty),
          disabled: p.disabled
        })} 
        ref={this.selRef}
      >
        {name}
        <div className={"hSelect openComponent"} style={p.style}>
          <div className="trigger" onClick={this.openClose}>
            <input 
              readOnly={!this.allowInpUpdate()} 
              value={s.label || p.empty || ""} 
              onChange={this.inputChange} 
              disabled={p.disabled} 
              onClick={this.inputClick} 
            />
            <div className="arrow" onClick={this.arrowClose}></div>
          </div>
          <div className="options">
            {this.createValues(s, p)}
          </div>
        </div>
      </h-select>
    )
  }
}