import { AtchList } from "../attachments/atchList";
import { SComponent, RInput, Rq, _, PreactHTMLConverter, createRef, Ariscat, Repo, RIcons, Btn, Http } from "../../dump";
import './styl.scss';

function notice() {}

window.Ar = Ariscat;

export class Comments extends SComponent {
    cmount(s,p) {
      this.state = { 
        fields: [],
        rows: [],
        files: {},
        text: "",
        showSmileys: false,
        searchText: "",
        cssHeight: "0",
        comment: "",
  
        page: 1,
        start: 0,
        min: 0,
        max: 10,
        maxRec: 10,
      }
  
      this.data = [];
      this.prilohy = createRef();
      this.searchData = [];
      this.converter = PreactHTMLConverter();
  
      this.getData(p);
    }
    
    async getData(p) {
      const res = await Rq.sd(p.recordset, 1, 0, { nsParentType: "reksrv", nsParentGuid: p.switches.nsParentGuid, frReload: true });
  
      if(res.ferror) notice(res.error, 8, "error");
  
      this.data = res.rows;
      await this.refresh();
    }
  
    async reload() {
      await this.getData(this.props);
    }
  
    viewComments =()=> {
      this.searchData = this.data
        .sort((a,b) => b.iChPoradi - a.iChPoradi)
        .filter(f=> f.nsChText.includes(this.state.searchText));
  
  
        return this.searchData/*.filter((f, i) => (i >= this.state.start) && (i < (this.state.start + this.state.max)))*/
        .map((m, i) => 
          <div key={m.nsChText + i} className={this.cln({komentar: 1, poOdeslani: m.bChPoOdeslani, odVyrobce: m.bChOdVyrobce})} onClick={this.commentClick} title={(m.bChPoOdeslani ? _.uploadAfterSend : "")}>
            <div className="flex">
              <span className="jmeno">{m.nsChPoridil}</span>
              <span className="datum">{m.dChPorizeno.format("d.M.yy H:mm")}</span>
            </div>
            <div className="zprava">{this.converter.convert(
              Ariscat.encodeHTMLEntities(m.nsChText).replaceAll("&#38;#x0D;&#38;#x0A;", "<br>")
              .replace(/&#60;att:(.*):(.*)&#62;/, (x,id,fname) => (
                `<a class='priloha' href='${Repo.serverURL}/api/attchm/${id}/0/${fname}' name='${fname}'><span class="fas fa-paperclip"></span> ${fname}</a>`
              ))
            )}
            </div>
            
          </div>
      )
    }
  
    commentClick =e=> {  
      if(e.target.href) {
        this.prilohy.current.viewAtchURL(e.target.href, e.target.name);
      }
  
      e.preventDefault();
      e.stopPropagation();
  
    }
  
    write =e=> {
      this.props.chr.komentar = true;
  
      this.setState({
        text: e.target.value,
        allowSend: true
      });
    }
  
    send = async () => {
      const sw = this.props.switches;
      const atchList = this.prilohy.current.state.newAtch;
  
      for(let atch of atchList) {
        await this.sendAtch(atch);
      }
  
      const data = {
        nsChText: this.state.comment.replace(/<att/, "att"),
        nsChParType: sw.nsChParType,
        iChParID: sw.iChParID
      }
      
      if(atchList.length == 0) {
        const up = await Rq.up(this.props.recordset, 1, data);
      }
  
      this.set.comment = "";
      this.prilohy.current.clear();
      this.getData(this.props);
      this.props.onSave && this.props.onSave(data, atchList);
    }
  
    sendAtch = async file => {
      const sw = this.props.switches;
      return await Http.uploadAttchm(sw.nsChParType, sw.iChParID, 0, file.name, this.state.comment, file.data);
    }
  
    getPages = () => {
      let r = [];
      let s = this.state;
      let page = s.page || 1;
  
      let lastPage = Math.ceil(this.data.length / s.max);
      let pageMin = page-2 > 0 ? page-2 : 1;
      let pageMax = page+2 < lastPage ? page+2 : lastPage;
  
      const li = (i, active) => 
        <li key={i} className={"paginate_button" + (active ? " active" : "")} onClick={() => this.setPage(i)}><a>{i}</a></li>
  
      if(page > 3 && lastPage > 3) {
        r.push(li(1))
        r.push(li("..."))
      }
  
      for(let i=pageMin;i<=pageMax;i++) {
        r.push(li(i, page == i))
      }
  
      if(page+2 < lastPage) {
        r.push(li("..."))
        r.push(li(lastPage))
      }
  
      return r;
    }
  
    setFilter = max => {
      this.setState({
        max: max,
        min: 0,
        page: 1,
      })
    }
  
    showSmileys =e=> {
      e.stopPropagation();
      this.setState({ showSmileys: true});
    }
  
    setPage = (page, plus = 0) => {
      page += plus;
      const s = this.state;
      console.log("SP", page);
  
      if(page < 1 || page > Math.ceil(this.searchData.length / s.max) || page == "...") return;
  
      s.start = page * s.max - s.max;
      this.set.page = page;
    }
  
    searchClear =async e=> {
      this.state.searchText = "";
      this.refresh();
    }
  
    search = text => {
      this.set.searchText = text;
    }
  
    rnd = (s,p) => {
      let recordsFrom = s.min + 1;
      let recordsTo = (s.min*1) + (s.max*1);
  
      if(recordsTo < recordsFrom) recordsFrom = recordsTo;
  
      return (
        <>
          <div className="panel"> 
            <header className="flex">
              {_.comments}
              <Btn send disabled={p.isDisabled} title={_.sendComment} onClick={this.send}><RIcons.Fa.FaFacebookMessenger />{_.send}</Btn>
            </header>
            <div className={this.cln({komentare:1, loading: s.loading})}>
              {this.props.canAddChat ? <>
                <div className="seznam">
                  <div className="dnone">
                    <label>
                      <RInput placeholder={_.search} state={this.state} item="searchText" onChange={this.search} />
                      <Btn info className="searchclrbtn" onClick={this.searchClear}><RIcons.Fa.FaTimes /></Btn>
                    </label>
                  </div>
                  <div className="comments">{this.viewComments()}</div>
                </div>
                <div className="komentarOddil">
                <div className="modalPoznamka">
                  <RInput disabled={p.isDisabled} type="textarea" placeholder={_.writeComment + " ..."} state={this.state} item="comment"/>
                </div>
              </div>
      
              </> : []
              }   
            </div>
          </div>
          <AtchList ref={this.prilohy} alwex={this.props.alwex} panel="Přílohy ke komentáři" />
        </>
      );
    }
  }