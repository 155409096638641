import { SComponent, Table, Rq, _, route, Repo, Comp, Ref } from "../dump";

export class BranchesTab extends SComponent { rnd=s=>(
    <Comp className="dlrBranches" icon="clip-users-2">
        <Table recordset="subdealer" cols="0" detail={rec => route("/branches/" + rec.ID)}>
            <bar-button 
                type="add"
                icon="Plus"
                text=""
                click={async e=> route("/branches/0")}
            />
            <tab-button
                type="primary"
                icon="cog"
            >
                <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
            </tab-button>
        </Table>

        {/*
        <div className="grid-3 dnone">
            <div>
                <h4>Za pozize</h4>
                <div className="grid-3">
                    <div className="form-group">
                        <label>Cena pozic před slevami</label>
                        <div class="input-group ">
                            <RInput state={s} item="nsZamTelefon" />
                            <span class="input-group-addon">Kč</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Slevy na pozicích</label>
                        <div class="input-group ">
                            <RInput state={s} item="nsZamTelefon" />
                            <span class="input-group-addon">Kč</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Cena pozic po slevě</label>
                        <div class="input-group ">
                            <RInput state={s} item="nsZamTelefon" />
                            <span class="input-group-addon">Kč</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h4>Dodatečná sleva</h4>
                <div className="grid-3">
                    <div className="form-group">
                        <label>Procentuální</label>
                        <div class="input-group ">
                            <RInput state={s} item="nsZamTelefon" />
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Absolutní</label>
                        <div class="input-group ">
                            <RInput state={s} item="nsZamTelefon" />
                            <span class="input-group-addon">Kč</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Dodatečná sleva celkem</label>
                        <div class="input-group ">
                            <Input state={s} item="nsZamTelefon" />
                            <span class="input-group-addon">Kč</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h4>Celková rekapitulace</h4>
                    <div className="grid-3">
                        <div className="form-group">
                        <label>Cena zakázky před slevami</label>
                            <div class="input-group ">
                                <RInput state={s} item="nsZamTelefon" />
                                <span class="input-group-addon">Kč</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Celková sleva</label>
                            <div class="input-group ">
                                <RInput state={s} item="nsZamTelefon" />
                                <span class="input-group-addon">Kč</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Cena zakázky po slevě</label>
                            <div class="input-group ">
                                <RInput state={s} item="nsZamTelefon" />
                                <span class="input-group-addon">Kč</span>
                            </div>
                        </div>
                    </div>
            </div>

            <div>
                <Btn class="btn btn-primary btn-sm">Uložit</Btn>
                <Btn class="btn btn-info btn-sm">Zrušit</Btn>
            </div>
            
        </div>
        */}
    </Comp>
)}