import './styl.scss'
import {
	RInput, SComponent, HSelect, DInput, _, SDate, Rq, createRef,
	RIcons, Table, Repo, Ariscat, Datapacket, Ref, If, h, Http, Btn, InputLn
} from "../../dump";

import { Wares } from '../../components/wares/wares';
import { Link, route } from 'preact-router';
import { Mask } from '../../components/mask/mask';
import { AtchList } from '../../components/attachments/atchList';
import { DelivAdr } from './delivAdr';
import { ButtonMenu } from '../../components/buttonMenu/buttonMenu';
import { Imports } from './imports';
import { AtchTable } from '../../components/attachments/atchTable';

export class Demandord extends SComponent {
	zakazka = {};
	pobocky = {};
	stavyZakazky = {};
	calculateRequired = true;
	data = { reCalculateRequired: () => false };
	rekap = [];
	attchm = [];
	xlsErrors = "";
	polTable = createRef();
	pozTable = createRef();
	atchList = createRef();

	tabChange = () => { }

	save = () => { }

	async cmount(s, p) {
		console.log('Demandord cmount');
		this.ID = this.props.ID;
		this.guid = this.props.guid;
		this.loaded = false;
		this.delivAdr = new DelivAdr();
		this.delivAdr.init();
		this.imports = new Imports(this.ID);

		this.setState({
			saved: "saved",
			modalHidden: "hidden",
			open: false,
			gmap: false,
			activeTab: 0,
			demandTableDisplay: true,
			opened: false
		});

		this.isCalculating = false;
		this.recordset = this.props.path.startsWith("/orders") ? "objednavka" : "poptavka";
		this.pf = this.recordset == "objednavka" || this.recordset == "expedice" ? "Obj" : "Pop";
		this.vrJoin = true;//this.si.bAnyVrbcJoined == "1";

		if (!this.vrJoin) {
			//return Ref.notify(_.noVrbcConn, 20, 'error');
		}

		await this.getData();
	}

	async loadDO() {
		this.data = await Rq.info(this.recordset, this.ID);

		this.rekap = Datapacket.parseXML(this.data[`ns${this.pf}XMLCenRekap`])?.cenrekap?.item;
		this.data.reCalculateRequired = () => !this.data[`b${this.pf}ReadOnly`] && ([2, 9, 22].includes(this.data[`ie${this.pf}Stav`]) && this.calculateRequired);
	}

	async getData() {
		this.adresy = [];
		this.adresy2 = [];
		await this.loadDO();
		this.dvLinx = (await Rq.sd("dvgroupLinks", 1)).rows;
		this.prodPoz = (await Rq.sd("produkt_poz", 1, 0, { iZakID: this.ID })).rows;
		this.loadAtch();

		console.log(this.pf);

		this.langs = [
			{ ID: 1, lang: _.cs },
			{ ID: 2, lang: _.en },
			{ ID: 3, lang: _.de },
			{ ID: 4, lang: _.sk },
			{ ID: 5, lang: _.fr },
			{ ID: 6, lang: _.pl },
		]

		this.loaded = true;
		this.onLoad(this.data[`ns${this.pf}IntCislo`]);
		Ref.Header.tagLinks = [this.recordset == "objednavka" ? "/orders" : "/demands"];

		if (this.data[`i${this.pf}ParentZak`]) {
			Ref.Header.updateTags([
				_.orders,
				_.deal + " " + this.data[`i${this.pf}ParentZak`],
				(this.recordset == "objednavka" ? _.requisition : _.inquirie) + " " + this.data[`ns${this.pf}IntCislo`]
			]);

			Ref.Header.tagLinks = ["/deals", "/deals/" + this.data[`i${this.pf}ParentZak`]];
		}

		if (this.data.reCalculateRequired()) {
			this.calculate();
		}

		this.refresh();
	}

	async loadAtch() {
		console.log("la");
		this.attchm = await Rq.sd('attchm', 1, 0, { iPOID: this.ID });
		this.refresh();
	}

	async loadRekap() {
		this.rekap = Datapacket.parseXML(this.data[`ns${this.pf}XMLCenRekap`])?.cenrekap?.item;
	}

	async openMask(row, selectElement) {
		console.log("row", row);

		let pol = row.ID ? await Rq.info("polozka", row.ID, { iZakID: this.ID, iPoziceID: row.iPozID }) : {};
		let sw = this.selectedWare;

		if (!sw.nsPrdKod && this.pozTable.current.rows.length) {
			sw = this.selectedWare = this.pozTable.current.rows[0];
		}

		if (!row.ID) {
			row.bPrdIsPM = sw.bPrdIsPM;
			row.nsPrdKod = sw.nsPrdKod;
			row.nsPrdVrbcKod = sw.nsPrdVrbcKod;
		}

		console.log("openmsk", pol);

		const caption = pol.iPolCislo ? _.editingItemNo.replace("[item_no]", pol.iPolCislo) + " (" + pol.nsPrdNazev + ")" :
			"Nová položka (" + sw.nsPrdNazev + ")";

		return await Ref.modal.open(caption,
			<Mask
				pm={row.bPrdIsPM}
				ware={row.nsPrdKod}
				producer={row.nsPrdVrbcKod}
				data={pol}
				mask={sw.mask}
				onSave={this.saveMaskDetail}
				selectElement={selectElement}
				readOnly={this.data[`b${this.pf}ReadOnly`]}
				type="DETAIL"
			/>
		);
	}

	saveMaskDetail = async msk => {
		console.log("saveMask", msk);

		if (msk.ok) {
			if (this.selectedWare.isNew) {
				var res = await Rq.up("pozice", 2, { iPozZakID: this.data.ID, iPozPrdID: this.selectedWare.iPozPrdID, nsPozArisValues: "" });
				this.pozTable.current.reload();

				if (res.rows.length) this.selectedWare = res.rows[0];
				this.selectWare(this.selectedWare)
			}

			if (msk.record.ID && !msk.duplicate) {
				var res = await Rq.up("polozka", 2, { ID: msk.record.ID, iPolPozID: msk.record.iPozID, nsPolArisValues: msk.text }, { raw: true });
			}
			else {
				//zalozeni nove polozky
				var res = await Rq.up("polozka", 2, { iPolPozID: this.selectedWare.ID, nsPolArisValues: msk.text }, { raw: true });


				if (msk.duplicate && !res.ferror) {
					Ref.notify(_.itmBenDupl, 2);
				}
			}

			Rq.showError(res);

			if (!msk.duplicate) {
				Ref.modal.close();
			}

			//this.polTable.current.props.sw_iPoziceID = this.selectedWare.iPozID;
			await this.polTable?.current?.reload();
			this.calculateRequired = true;
			this.refresh();
		}
		else {
			Ref.notify(`${_.numErFnd} ${numErrors}`, 4, "error");
		}

		// const data = await Rq.info("polozka", msk.record.ID)
		// await Rq.up("polozka", 2, { iPolPozID: msk.record.ID, nsPolArisValues: data.nsPolArisValues});

		//{[`${this.pf}iID`]: this.data.ID}
	}

	saveMaskMaster = async msk => {
		if (!msk.ok) return;

		if (this.selectedWare.isNew) {
			var res = await Rq.up("pozice", 2, { iPozZakID: this.data.ID, iPozPrdID: this.selectedWare.iPozPrdID, nsPozArisValues: msk.text });

			if (res.rows.length) {
				this.selectedWare.isNew = false;
				const poz = await Rq.info("pozice", res.rows[0].ID);
				console.log("sel ware", poz);
				this.selectWare(poz);
			}
		}

		if (msk.record.ID) {
			await Rq.up("pozice", 2, { ID: msk.record.ID, nsPozArisValues: msk.text });
		}

		Ref.modal.close(msk);
		await this.pozTable.current.reload();
		await this.refresh();
	}

	exportCSV = async () => {
		var rows = this.polTable.current?.rows || [];
		var prodInfo = await Rq.info("produkt", rows[0]?.iPrdID);
		var jsbt = prodInfo.nsPrdInpJSBT;
		var prodData = JSON.parse(jsbt.replaceAll('`', '"').replaceAll('__backtick__', '`'))
		var csvVals = [];
		this.prodData = prodData;

		var types = {
			C: _.selection,
			E: _.text,
			S: _.text,
			M: _.longText,
			K: _.yesNo,
			I: _.img,
			L: _.label
		}

		var colsCap = [_.yourMark, _.reqDate, _.comment, _.product, ...prodData.map(m => m.capt)];
		var infoCap = ["Text", "Datum", "Text", "Text", ...prodData.map(m => types[m.type] || m.type)];
		var csvCols = ["Vase_znacka", "Pozadovany_Datum", "Komentar", "Vyrobek", ...prodData.map(m => m.name)];
		var csvRows = [];
		var csvHelp = [
			[_.hlpFilCsv],
			[_.rowBeginVsZnacka],
			[_.folowLinFldTyp],
			[_.typYesNoMsk.replace("$1", _.yesNo)],
			[_.typSelMeanSelect.replace("$1", _.selection)],
			[_.wrtDownValsAc],
			[_.somValsMand],
			[_.folwLnFldName],
			[_.frst3ColsNotTrMsk],
			[``]
		];

		for (let row of rows) {
			var rowObj = {};

			if (!row.nsPolArisValues) {
				row.nsPolArisValues = (await Rq.info("polozka", row.ID, { iZakID: this.ID, iPoziceID: row.iPozID }))?.nsPolArisValues;
			}

			var arisVals = row.nsPolArisValues.split("\n");

			for (let av of arisVals) {
				var [key, val] = av.split("=");
				val = val === undefined ? "" : val;
				key && (rowObj[key] = val);
			}

			//console.log("rob", rowObj)
			csvVals.push(rowObj)
		}

		csvRows.push(colsCap);
		csvRows.push(infoCap);
		csvRows.push(csvCols);

		csvHelp.push(...csvRows);
		var enuMax = Math.max(...this.prodData.map(m => m.enum ? m.enum.length : 0));

		for (let av of csvVals) {
			var crow = csvCols.map(key => {
				return (av[key] === undefined ? "" : av[key])
			});

			//crow[0] = "123";
			csvRows.push(crow);
		}

		//prida zakladni udaje
		csvRows[3][0] = this.data[`ns${this.pf}Oznaceni`];
		csvRows[3][1] = this.data[`d${this.pf}TerminPozad`];
		csvRows[3][2] = this.data[`ns${this.pf}KomentProdej`];
		csvRows[3][3] = prodInfo.nsPrdZkratka;

		console.log(`{"${prodInfo.nsPrdZkratka}": ` + JSON.stringify(csvVals, null, 2) + "}");

		for (let i = 0; i < enuMax; i++) {
			var pz = i == 0 ? prodInfo.nsPrdZkratka : "";
			var crow = [`;;;${pz}`, ...prodData.map((m => m.enum ? m.enum[i] === undefined ? "" : m.enum[i] : ""))];
			csvHelp.push(crow);
		}

		var content = "\uFEFF" + csvRows.map(m => m.join(";")).join("\r\n");
		var file = new Blob([content], { type: 'text/csv' });
		Ariscat.downloadFile("tab.csv", file);

		//console.log("csvH", csvHelp);

		content = "\uFEFF" + csvHelp.map(m => m.join(";")).join("\r\n");
		file = new Blob([content], { type: 'text/csv' });
		Ariscat.downloadFile(`tab-${_.help2}.csv`, file);

		return content;
		//return csvRows
	}

	addItemMask = () => {
		this.openMask({});
	}

	checkCSV = async (showDialog = false, okMsg = true) => {
		var rows = this.polTable.current?.rows || [];

		for (let row of rows) {
			row.bPolImportError = true;
		}

		this.polTable.current?.refresh();

		for (let row of this.polTable.current?.rows || []) {
			if (!row.nsPolArisValues) {
				row.nsPolArisValues = (await Rq.info("polozka", row.ID, { iZakID: this.ID, iPoziceID: row.iPozID }))?.nsPolArisValues;
			}

			//nacte data do masky, bez vykresleni a ulozi
			const maskRes = await new Promise((rs) => {
				const msk = new Mask({
					pm: row.bPrdIsPM,
					ware: row.nsPrdKod,
					producer: row.nsPrdVrbcKod,
					data: row,
					type: "DETAIL",
					cmount: false
				});

				msk.cmount().then(() => rs(msk.save("DETAIL")));
			})

			if (maskRes) {
				row.bPolImportError = !maskRes.ok;
			}

			await Rq.up("polozka", 2, { ID: row.ID, bPolImportError: row.bPolImportError });
		}

		const errors = this.polTable.current?.rows.filter(f => f.bPolImportError);
		const numErrors = errors.length;

		if (numErrors && showDialog) {
			var open = await Ref.confirm.open(`${_.numErFnd} ${numErrors}`, _.redLinesBadFil, _.incValLoadImp, _.wntOpMsk);
			if (open) this.openBadItem();
		}
		else if (numErrors && !showDialog) Ref.notify(`${_.numErFnd} ${numErrors}`, 4, "error");
		else if (okMsg) Ref.notify(_.valsMaskOk, 4, "success");
		this.refresh();
	}

	async selectWare(ware) {
		if (!ware.nsPozArisValues && ware.ID)
			this.selectedWare = await Rq.info("pozice", ware.ID);
		else
			this.selectedWare = ware;

		this.refresh();
		this.pozTable.current.renderTable();
	}

	async copyWare(rec) {
		const data = await Rq.info("polozka", rec.ID)
		await Rq.up("polozka", 2, { iPolPozID: data.iPolPozID, nsPolArisValues: data.nsPolArisValues }, { raw: true });
		rec.reload();
	}

	customerTab = () => (
		<div className="demandMainData">
			<div className='grid-3'>
				<RInput tname="nameDeal" onChange={this.save} timer="500" state={this.zakazka} item="nsZakOznaceni" />
				<DInput tname="required_term" onChange={this.save} timer="500" state={this.zakazka} item="dZakTerminPozad" />
				<DInput tname="date" onChange={this.save} timer="500" state={this.zakazka} item="dZakDatum" />
			</div>

			<div className='grid-3'>
				<HSelect
					tname="customer"
					state={this.zakazka}
					item="iZakOrgID"
					onChange={this.save}
					label="nsOrgNazev"
					values={this.org}
					ref={this.orgRef}
				>
					<h-sc>{_.search}</h-sc>
					<h-fop val="0">{`------`}</h-fop>
				</HSelect>
			</div>

			<RInput tname="comment" onChange={this.save} type="textarea" timer="500" state={this.zakazka} item="nsZakKomentProdej" />
		</div>
	)

	createRekap() {
		const data = this.rekap.filter(f => f.fCenaSDPH != "0.0000" || f.nsClass == "celkem");

		return (
			<Table
				rows={data}
				showFilters={false}
				showMenu={false}
				showAll={true}
				expand={true}
				customCols={true}
				panel={_.recapitulation}
			>
				<tab-col name="nsPopis" caption="" />
				<tab-col name="fSazbaDPH" type="money" caption={_.vatRate} />
				<tab-col name="fCenaSDPH" type="money" caption={_.priceWithoutVat} />
				<tab-col name="fDPH" type="money" caption={_.vat} />
				<tab-col name="fCenaSDPH" type="money" caption={_.priceWithVat} />
			</Table>
		)
	}

	addAtch = async file => {
		//this.set.saved = "saving";
		console.log("add");

		try {
			var res = await Http.uploadAttchm("popobj", this.ID, 0, file.name, "", file.data);
			console.log("aaa", res);

			const [a, ok, msg] = res;


			if (!ok) {
				this.set.saved = "error";
				return Ref.notify(msg, 5, "error");
			}

			await this.loadAtch();
			this.atchList.current.set.newAtch = [];
			this.atchList.current.set.atchList = this.attchm.rows;

			//this.set.saved = "saved";
		}
		catch (e) {
			this.set.saved = "error";
			console.error("error", e)
		}

		//this.refreshPage();
		//this.ref.cm.current.reload();
	}

	saveDO = async (val, key) => {
		await Rq.up(this.recordset, 1, { ID: this.ID, [key]: val });
	}

	panel2 = () => (
		<div></div>
	)

	//prevede csv na json
	csvOnJson(csv) {
		//odstrani \r a utf-8 hlavicku
		csv = csv.replace("\uFEFF", "").replace(/\r/g, "");
		var data = csv.split("\n").filter(f => f);
		var sep = ";"

		//format libre office
		if (!csv.includes(";")) sep = ",";

		//format excel (oddelovac taby)
		if (csv.includes("\t")) sep = "\t";

		var tab = data.map(m => m.split(sep))
		var vaseZnacka = false;

		let keyRow = 0;
		for (let row of tab) {
			if (row[0] == "Vase_znacka") {
				vaseZnacka = true;
				break;
			}
			keyRow++
		}

		if (!vaseZnacka) {
			return { cols: [], rows: [], error: "Vase_znacka" };
		}

		var dkeys = tab[keyRow];

		//odfiltruji se radky nad Vase_znacka
		tab.splice(0, keyRow + 1);

		var json = { cols: dkeys };

		json.rows = tab.map(m => {
			var obj = {};
			m.forEach((n, y) => obj[dkeys[y]] = n);
			return obj
		});

		return json;
	}

	async createRows() {
		this.pd = {};

		/*
		if(this.polozkaID) {
			this.pd = await this.getPolozkaData();
		}
		*/

		var rows = [];

		for (let jrow of this.json.rows) {
			var newItem = { ...this.pd };

			for (let [key, val] of Object.entries(jrow)) {
				newItem[key] = Ariscat.htmlEntities(val);
			}

			var row = {
				bPolImportError: true,
				iPolPozID: this.selectedWare.ID,
				nsPolArisValues: Object.entries(newItem)
					.filter(([k, v]) => k != "Vase_znacka" && k != "Pozadovany_Datum" && k != "Komentar" && k != "Vyrobek")
					.map(([k, v]) => k + "=" + v + "\n")
					.join("")
			}

			rows.push(row);
		}

		console.log("ROWs", rows);

		return rows;
	}

	async getPolozkaData() {
		var pd = await Rq.info("polozka", this.data.iTrgPolID, 0, "", { iPoziceID: this.data.iTrgPozID });
		var vals = pd.nsPolArisValues.split('\r\n');
		var retVals = {};

		for (let val of vals) {
			if (val) {
				var kv = val.split('=');
				retVals[kv[0]] = kv[1];
			}
		}

		return retVals;
	}

	TabProdPoz = () => {
		const prodPoz = this.prodPoz.map(m => <tr onClick={e => Ref.modal.close(m)}>
			<td>{m.nsPrdZkratka}</td>
			<td>{m.nsPrdNazev}</td>
			<td><img src={`data:image/jpeg;base64,${m.blbPrdThumb}`} /></td>
		</tr>)

		return <table>{prodPoz}</table>
	}

	async addNewProduct(prod) {
		const {
			ID, nsPrdVrbcKod, iPrdVrbcID, nsPrdKod, nsPrdNazev, nsPrdZkratka,
			bPrdIsPM, fPrdPrcKeKoncCeniku_Prd, fPrdPrcKeKoncCeniku_Prisl,
			fPrdPrcKeKoncCeniku_Pripl, iPrdDealMenaID, nsPrdDealMenaKod, nsPrdSkupVyr
		} = prod;

		//prevede info o vyrobku na format pozice
		this.selectWare({
			nsPrdVrbcKod, iPrdVrbcID, nsPrdKod, nsPrdNazev, nsPrdZkratka,
			bPrdIsPM, fPrdPrcKeKoncCeniku_Prd, fPrdPrcKeKoncCeniku_Prisl,
			fPrdPrcKeKoncCeniku_Pripl, iPrdDealMenaID, nsPrdDealMenaKod, nsPrdSkupVyr, isNew: true, iPozPrdID: ID, nsPolArisValues: ""
		});

		const mm = await this.selectMaskMaster();
		const md = await this.openMask({});
		//console.log("mm", mm);
	}

	addProduct = async () => {
		//nezobrazovat okno s dlazdicama vyrobku
		if (!Repo.stg.cs__demandDlazdice) {
			return this.selectWareGroup();
		}
		else if (Repo.stg.cs__restrictProductsFromGroup && this.pozTable.current.rows.length) {
			let prod = this.pozTable.current.rows[0];
			return this.selectWareGroup(prod);
		}
		else if (!Repo.stg.cs__showWareWindow) {
			return this.showWareDialog(this.prodPoz);
		}

		const prod = await Ref.modal.open(_.products, <Wares onChange={Ref.modal.close} inModal={true} />);

		if (prod) this.selectWareGroup(prod);
	}

	async selectWareGroup(prod) {
		var prods = (!prod || prod.ID == 1) ? this.prodPoz : this.prodPoz.filter(prp => prp.nsPrdSkupVyr == (prod.nsGrpNazev || prod.nsPrdSkupVyr));

		if (!prod || prod?.bGrpOblibene) {
			const fav = this.dvLinx.map(m => m.iGrlVyrobekID);
			prods = this.prodPoz.filter(f => fav.includes(f.ID));
		}

		if (prods.length == 1) this.addNewProduct(prods[0]);
		else if (prods.length > 1) this.showWareDialog(prods);
		else if (!prods.length) Ref.notify(_.grpn, 4, 'error');
	}

	async showWareDialog(prods) {
		const prodPoz = prods.map(m => <div className="dl-item" onClick={e => Ref.modal.close(m)}>
			<div className="item ware">
				<span className="ikona" style={{ background: `url(data:image/jpeg;base64,${m.blbPrdThumb})` }} />
				<span className="popis">{m.nsPrdNazev}</span>
			</div>
		</div>)

		let prod = await Ref.modal.open(_.productSelection,
			<div className='modal-data'><div className="dl-list">{prodPoz}</div></div>
		);
		if (prod) this.addNewProduct(prod);
	}

	async openStock(item) {

	}


	calculate = async () => {
		if (this.isCalculating) return;

		Ref.notify(_.rcmpInPr, 4);
		this.isCalculating = true;

		try {
			var error = await Http.calculateOrder(this.ID);

			if (error) {
				this.calculationFailed(error);
			}
			else {
				Ref.notify(_.calculateDone, 4, 'success');
				this.calculateRequired = false;
				this.isCalculating = false;
				this.refresh();
			}

			await this.loadDO();
			this.createRekap();
			this.refresh();
		}
		catch (e) {
			Ref.notify(e.message, 10, "error");
			this.isCalculating = false;
			this.calculateRequired = true;
			this.refresh();
		}
		/*
		// pokud se sem dostaneme vlivem vypršení časového limitu při čekání na PHP, pak jde zkrátka o příliš dlouhý přepočet a nebudeme tento hlásit jako chybu. Ostatní hlásit budeme.
		if (status !== 504) {//Gateway timeout
			_this.calculationFailed(_this.ID, err, true);
		} else {
			console.log('Do not worry about the 504 Gateway timeout - this happens if long computation occurs. Just ignore it.');
		}
		*/

		//_this.calculationFailed(_this.ID, err, /*immediate*/true);
	}


	calculationFailed(error) {
		var calcError = _.calculateError.replace('%e', error);

		if (calcError.includes("FAB9E895-5180-412D-9A81-CF6F37CFC951") && calcError.includes("A551EB9D-5647-4BB3-A276-9CFA02762976")) {
			var match = calcError.match(/FAB9E895-5180-412D-9A81-CF6F37CFC951(.*)A551EB9D-5647-4BB3-A276-9CFA02762976/)
			let table;

			if (match.length) {
				this.errorJSON = JSON.parse(match[1]);
				const tabData = this.errorJSON.map(m =>
					<tr class="hl" data={m.iSeqNo} onClick={e => this.errMsgTableLink(m)}>
						<td>{m.iCisloPol}</td>
						<td>{m.nsTrgCaption}</td>
						<td>{m.nsTrgName}</td>
					</tr>)

				const header = <tr class="hd"><td>{_.itemNumber}</td><td>{_.inputField}</td><td>{_.object}</td></tr>
				table = <table class="errMsgTable">{header}{tabData}</table>
			}

			const match2 = error.split("\n");
			let errorMsg = "";

			if (match2.length) errorMsg = match2[0];

			console.log("match", error);

			Ref.notify(<div>{errorMsg}{table}</div>, 120, 'error');
		}
		else {
			Ref.notify(calcError, 20, 'error');
		}

		this.isCalculating = false;
		this.calculateRequired = true;
		this.refresh();

		/*
		if (this.isCalculating) {
			this.hideCalculating();
			this.refetchHeader();
			this.tabs.updateTabsLoaded(false);
			
			if (this.tabs.activeTabID !== null) {
				this.tabs._tabChange({target: this.tabs.$tabs.find('li#tab-'+ this.tabs.activeTabID).find('a').get(0)});
			}
		}
		*/
	}

	async errMsgTableLink(rec) {
		this.selectedWare = this.pozTable.current.rows.find(f => f.ID == rec.iTrgPozID);
		const maskRow = await Rq.info("polozka", rec.iTrgPolID, { iZakID: this.ID, iPoziceID: this.selectedWare.ID });
		this.openMask(maskRow, rec);
	}

	claim = async () => {
		let num = this.data[`ns${this.pf}Cislo`];

		var req = { nsRsPopisZavady: "" };
		req[`iRs${this.pf}ID`] = this.idObj;
		req[`nsRsCislo` + this.pf] = num;

		const { rows } = await Rq.up("reksrv", 2, req, { nsDomainID: Repo.dealer.Vyrobce.nsVrbcDomainID });

		if (rows.length) {
			route("/cm-issued/" + rows[0].ID);
		}
	}

	recapCosts = () => {
		window.open(
			Repo.serverURL + `/api/pdf/naklady/${this.recordset}/${this.ID}/0`,
			"_blank", 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=595', '_blank'
		);
	}

	sendOrder = async () => {
		if (this.xlsErrors && Repo.stg.cs__popobjCantOrderIfXErrMsg) {
			Ref.notify(_.warning + ": " + this.xlsErrors, 10, "danger");
			return false;
		}

		if (this.data.yourMark == "" && this.orderSignReq) {
			this.showError(_.notSavedSign);
			return false;
		}

		const wrnSoft = Repo.stg.cs__wrnOverdueInvoicesDaysSoft;
		const wrnHard = Repo.stg.cs__wrnOverdueInvoicesDaysHard;

		const { rows } = await Rq.sd("fakt_prij", 1);
		const now = SDate();
		const f1 = rows.filter(f => SDate.diff(f.dFpSplatnost, now).day > 7).length;
		const f2 = rows.filter(f => SDate.diff(f.dFpSplatnost, now).day > 14).length;

		if ((wrnSoft || wrnHard) && (f1 || f2)) {
			var msg = (
				<>
					<div class="modal-body">
						<span id="dialogIcon"><RIcons.Fa.FaExclamationTriangle /></span>
						<p id="dialogText">
							Evidujeme u Vás {f1} faktur po splatnosti.<br />
							Z toho je minimálně {f2} faktur po splatnosti více než 800 dnů.<br />
							{_.ordersNotWilMad}<br />
							<div class="line"></div>
						</p>
					</div>
					<center>{_.wantViewInvoices}</center>
				</>
			)

			var res = await Ref.confirm.open(_.warning, msg);

			if (res) {
				route("/invoices");
				return;
			}
		}

		if (Repo.stg.cs__orderSignRequired && !this.data[`ns${this.pf}Oznaceni`]) {
			this.data[`b${this.pf}ReadOnly`] = false;
			this.refresh();
			return Ref.notify(_.notSavedSign, 4, 'danger');
		}

		await this.inquireOrSend(0, "Obj");

		location.reload()
	}

	customersOffer = async () => {
		if (!this.data[`i${this.pf}ParentZak`]) {
			Ref.notify(_.creationDeal, 2)
			const idZak = await Rq.newRecord("zakazka", { bZakAktivni: true });
			var res = await Rq.up(this.recordset, 2, { ID: this.data.ID, [`i${this.pf}ParentZak`]: idZak });
			Ref.notify(_.dealCreated, 2);
			route(`/custOffer/${this.recordset}/${this.data.ID}`);
		}
		else {
			route(`/custOffer/${this.recordset}/${this.data.ID}`);
		}
	}

	backToRecapitulation = () => {

	}

	close = () => {
		route(this.pf == "Obj" ? "/orders" : "/demands");
	}

	showError() {

	}

	async selectDlAdr() {
		var adr = await this.delivAdr.open();
		if (adr !== null) {
			await this.saveDlAdr(adr.ID, adr.text);
			return true;
		}

		return false;
	}

	async saveDlAdr(ID, text = "") {
		this.data[`ns${this.pf}DodaciAdr`] = text;
		this.refresh();
		await Rq.up(this.recordset, 1, { ID: this.ID, [`ns${this.pf}DodaciAdr`]: text, [`i${this.pf}DealerAddrID`]: ID });
		this.refresh();
	}

	copyItem = async rec => {
		console.log("kopie", rec);
		const data = await Rq.up("polozka", 2, { iPolPozID: rec.ID, nsPolArisValues: data.nsPolArisValues });
		rec.reload();
	}

	importCSV = async () => {
		if (!Repo.stg.cs__popobjAllowCSVImport) {
			return Ref.notify(_.impCsvNoAlw, 4, 'danger');
		}

		let file = await Ariscat.selectFile(false, ".csv");

		if (file.size > (1024 * 1024)) {
			return Ref.notify(_.bigFile + " 1 MB!", 4, 'danger');
		}

		var txt = await file.read(file, "utfw");
		this.txt = txt;
		this.json = this.csvOnJson(txt);

		console.log("json", this.json);
		if (
			!this.json.cols.includes("Vase_znacka") ||
			!this.json.cols.includes("Pozadovany_Datum") ||
			!this.json.cols.includes("Komentar") ||
			this.json.error
		) {
			return Ref.notify(_.csvContainCol + " Vase_znacka, Pozadovany_Datum, Komentar!", 8, 'danger');
		}


		if (!this.json.rows.length) return Ref.notify(_.csvEmpty, 4, 'danger');

		//Ariscat.loading.showLoading('#form', _.dataLoading);
		Ref.notify(_.importingData, 3, 'info');

		//upravi udaje k pop/obj
		console.log("aktualizace");
		const fr = this.json.rows[0];

		//pokud se zaklada novy vyrobek, aktualizuje i tyto pole
		await Rq.up(this.recordset, 2, {
			ID: this.data.ID,
			[`d${this.pf}TerminPozad`]: SDate(fr.Pozadovany_Datum),
			[`ns${this.pf}Oznaceni`]: fr.Vase_znacka,
			[`ns${this.pf}KomentProdej`]: fr.Komentar
		});

		this.data[`d${this.pf}TerminPozad`] = fr.Pozadovany_Datum;
		this.data[`ns${this.pf}Oznaceni`] = fr.Vase_znacka;
		this.data[`ns${this.pf}KomentProdej`] = fr.Komentar;

		//ziska seznam vyrobku a zalozi novy vyrobek
		const { rows } = await Rq.sd("produkt", 1, { bAktivni: 1 });
		var vyrobek = rows.find(f => f.nsPrdZkratka == fr.Vyrobek);
		//var dataTab = Ariscat.agenda.tabs.getActiveTab();

		/*
		if(add && (!vyrobek || vyrobek.ID != dataTab.productID)) {
			Ariscat.loading.hideLoading('#form');
			Ariscat.alert.hlaska(_.prodInCsvNotCor, 10, 'error');
			return false;
		}
		*/

		if (!vyrobek) {
			//Ariscat.loading.hideLoading('#form');
			Ref.notify(_.productNotFound + ` (${fr.Vyrobek})!`, 6, 'error');
			return false;
		}

		this.vyrobekID = vyrobek.ID;
		this.vyrobek = vyrobek;

		if (!(await this.checkCSVImport(false, vyrobek))) {
			//Ariscat.loading.hideLoading('#form');
			return;
		}

		//vyrobekID - ziska se z this.checkCSVImport()
		var res = await Rq.up("pozice", 2, { iPozZakID: this.data.ID, iPozPrdID: this.vyrobekID, nsPozArisValues: "" });
		this.polozkaID = 0;

		if (res.rows.length) {
			this.selectedWare = res.rows[0];
		}

		var crows = await this.createRows();

		const fields = [
			'bPolImportError',
			'iPolPozID',
			['nsPolArisValues', { width: 8000, fieldtype: "string" }],
		]

		var res = await Rq.up("polozka", fields, crows, { raw: true });

		if (res.ferror) {
			console.error(res);
			return Ref.notify(_.uploadError + ` CSV!`, 6, 'danger');
		}

		//this.loadDO();
		Ref.notify(_.impComLoad, 3, 'info');
		this.pozTable.current.reload().then(() => this.refresh());
		this.polTable.current.reload().then(() => {
			this.refresh();
			this.checkCSV();
		});

		//ukaze tabulku s vyrobkem
		//await this.updateVals();
		/*
			$('a[href="#summary"]').tab("show");
			Ariscat.agenda.pozice.renderTabs = true;
			Ariscat.agenda.tabs.deleteTabs();
			await Ariscat.agenda.pozice.poziceTable();

		var fndTab = Ariscat.agenda.tabs.tabs.find(f => f && f.positionID == pozice);
		await Ariscat.agenda.tabs.selectTab(fndTab.tabID);
		Ariscat.loading.hideLoading('#form');
		Ariscat.alert.hlaska(_.checkingVals, 2, "info");
		await Ariscat.wait(1000);
		Ariscat.agenda.polozky.checkItems();
		//console.log("JSON", this.json, res);
	

		return false;
		*/
	}

	importCSVWare = async (rec) => {
		if (!Repo.stg.cs__popobjAllowCSVImport) {
			return Ref.notify(_.impCsvNoAlw, 4, 'danger');
		}

		let file = await Ariscat.selectFile(false, ".csv");

		if (file.size > (1024 * 1024)) {
			return Ref.notify(_.bigFile + " 1 MB!", 4, 'danger');
		}

		var txt = await file.read(file, "utfw");
		this.txt = txt;
		this.json = this.csvOnJson(txt);

		console.log("json", this.json);
		if (
			!this.json.cols.includes("Vase_znacka") ||
			!this.json.cols.includes("Pozadovany_Datum") ||
			!this.json.cols.includes("Komentar") ||
			this.json.error
		) {
			return Ref.notify(_.csvContainCol + " Vase_znacka, Pozadovany_Datum, Komentar!", 8, 'danger');
		}

		if (!this.json.rows.length) return Ref.notify(_.csvEmpty, 4, 'danger');

		//Ariscat.loading.showLoading('#form', _.dataLoading);
		Ref.notify(_.importingData, 3, 'info');

		//upravi udaje k pop/obj
		console.log("aktualizace", this.json);

		var crows = await this.createRows();

		const fields = [
			'bPolImportError',
			'iPolPozID',
			['nsPolArisValues', { width: 8000, fieldtype: "string" }],
		]

		var res = await Rq.up("polozka", fields, crows, { raw: true });

		if (res.ferror) {
			console.error(res);
			return Ref.notify(_.uploadError + ` CSV!`, 6, 'danger');
		}

		await this.polTable.current.reload();
		this.checkCSV();
	}

	repairSelect(enms, row, key, selVal, origVal) {
		return <HSelect
			state={row}
			item={key}
			label="val"
			uid="val"
			values={[
				{ val: origVal },
				...enms.map((m, i) => { return { val: m } })
			]}
		/>
	}

	async checkCSVImport(add = false, vyrobek) {
		if (!vyrobek) {
			//Ariscat.loading.hideLoading('#form');
			Ref.notify(_.productNotFound + ` (${fr.Vyrobek})!`, 6, 'error');
			return false;
		}

		this.vyrobekID = vyrobek.ID;

		let csvErrors = [];
		var jsbt = vyrobek.nsPrdInpJSBT;
		var prodData = JSON.parse(jsbt.replaceAll('`', '"').replaceAll('__backtick__', '`'))

		var origCols = ["Vase_znacka", "Pozadovany_Datum", "Komentar", "Vyrobek", ...prodData.map(m => m.name)];
		var csvCols = this.json.cols;
		this.prodData = prodData;

		if (origCols.join(";") != csvCols.join(";")) {
			csvErrors.push(_.colNmsNotMtch);
		}

		if (origCols.length != csvCols.length) {
			csvErrors.push(<>{_.numColsCSVDif} {_.mask}/CSV = {origCols.length}/{csvCols.length}</>)
		}

		var badFils = this.json.rows.filter(
			(f, i) => i > 0 && (
				f.Vyrobek != "" ||
				f.Komentar != "" ||
				f.Pozadovany_Datum != "" ||
				f.Vase_znacka != ""
			)
		);

		if (badFils.length) {
			csvErrors.push(<>
				{_.cols1234FilIn.replace("$1", "Vase_znacka")
					.replace("$2", "Pozadovany_datum")
					.replace("$3", "Komentar")
					.replace("$4", "Vyrobek")
				}
				<b>{_.valsFromNextIgn}</b><br />
				${_.csvOnlySup1Prod}
			</>)
		}

		if ([...new Set(this.json.rows.map(m => m.Vyrobek).filter(f => !!f))].length != 1) {
			csvErrors.push(_.onl1ProdTypFil);
			await Ariscat.alert.dialog(_.badCSV, csvErrors.join("<br><br>"), 0);
			return false;
		}

		var badCols = [];

		for (let b of csvCols) {
			if (!origCols.includes(b)) {
				badCols.push(b)
			}
		}

		if (badCols.length) {
			csvErrors.push(<>
				<b>{_.csvContBadCols}</b> {badCols.join(", ")}.
				<br />{_.ifTypeErNotLoad}
				<br />{_.plsCheckCsv}
			</>)
		}

		//pokusi nastavit hodnotu bud 1 nebo 0, podle textove hodnoty
		var checkboxes = prodData.filter(f => f.type == "K").map(m => m.name);
		var selects = prodData.filter(f => f.type == "C" && f.enum).map(m => m.name);
		//jako "Ano" se berou vsechny hodnoty, krome tohoto seznamu:
		var no = [_.no, "ne", "nie", "no", "0", "-", "00", "--", "---",];
		var yes = [_.yes, "ano", "yes", "ok", "1", "jo", "11", "a", "y", "j"];

		for (var row of this.json.rows) {
			for (var [key, val] of Object.entries(row)) {
				//pokusi se opravit velikost pismen, aby sedela s hodnotou ze seznamu
				if (selects.includes(key)) {
					var enm = prodData.find(f => f.name == key).enum;

					if (enm && !enm.includes(val)) {
						const okVal = enm.find(f => f.toLowerCase() == val.toLowerCase())
						if (okVal !== undefined) row[key] = okVal;
					}
				}
			}
		}

		//zkontroluje preklepy v hodnotach ze seznamu, pokud jsou vyplneny
		var enums = {}
		var checkboxes = {};
		prodData.filter(f => (f.enum || {}).length).forEach(f => enums[f.name] = f);
		prodData.filter(f => f.type == "K").forEach(f => checkboxes[f.name] = f);
		var badSelects = [];

		var ii = 4;
		for (let rw of this.json.rows) {
			for (let [key, val] of Object.entries(rw)) {
				if (val != "" && enums[key] && enums[key].enum && !enums[key].enum.includes(val)) {
					var repairVal = Ariscat.repairTypeError(val, enums[key].enum);
					rw[key] = repairVal;

					badSelects.push(<tr>
						<td>{ii}</td>
						<td>{enums[key].capt}</td>
						<td>{val.replace(/^\s+|\s+$/g, "␣")}</td>
						<td>{this.repairSelect(enums[key].enum, rw, key, repairVal, val)}</td>
					</tr>
					)
				}

				if (checkboxes[key] && no.includes(val)) {
					rw[key] = 0;
				}
				else if (checkboxes[key] && yes.includes(val)) {
					rw[key] = 1;
				}
				//pokud hodnota ano/ne je neznama, da na vyber, kterou hodnotu vyplnit
				else if (checkboxes[key] && !no.includes(val) && !yes.includes(val)) {
					//csvErrors.push(`spatna hodnota!!! ` + key);
					var repairVal = Ariscat.repairTypeError(val, [...yes, ...no]);
					repairVal = no.includes(repairVal) ? 0 : 1;
					rw[key] = repairVal;

					badSelects.push(<tr>
						<td>${ii}</td>
						<td>${checkboxes[key].capt}</td>
						<td>${val}</td>
						<td>${this.repairSelect([1, 0], ii, key, repairVal, val)}</td>
					</tr>)
				}
			}
			ii++;
		}

		if (badSelects.length) {
			csvErrors.push(<>
				<b>{_.csvContBadVals}</b> {_.canEditValsTab}<br />
				<table id="csvWarningTab">
					<tr>
						<th>{_.row}</th>
						<th>{_.column}</th>
						<th>{_.wrongVal}</th>
						<th>{_.corVal}</th>
					</tr>
					{badSelects}
				</table>
			</>
			)
		}

		if (csvErrors.length) {
			this.origc = prodData;

			var contn = await Ref.confirm.open(_.badCSV, csvErrors, _.continue, _.cancUpld);
			if (!contn) return false;
		}

		return true;
	}

	tabPozLoad = async tab => {
		if (tab.rows.length) {
			this.xlsErrors = tab.rows
				.map(m => m?.nsPozArisValues?.match(/XLS_ErrMsg=(.*?)\n/)?.[1])
				.filter(f => f)
				.join(", ");


			const lastErrPoz = tab.rows.filter(f => f.nsPozLastErrPoz).map(m => m.nsPozLastErrPoz).join(", ");
			const lastWrnPoz = tab.rows.filter(f => f.nsPozLastWrnPoz).map(m => m.nsPozLastWrnPoz).join(", ");
			let msg = [];

			if (this.xlsErrors || lastWrnPoz) {
				msg.push(_.warning + ": " + [this.xlsErrors, lastWrnPoz].join(","));
			}
			if (lastErrPoz) {
				msg.push(_.error + ": " + lastErrPoz);
			}

			if (msg.length) {
				console.log("MSG", msg)
				Ref.notify(msg.join("\n"), 20, "error");
			}
		}

		if (tab.rows.length && !this.selectedWare) {
			this.selectWare(tab.rows[0]);
		}

		//prida vyrobek po nacteni, pokud neni zadny pridan a je povoleno v nastaveni
		if (!tab.rows.length && !this.data[`b${this.pf}ReadOnly`] && !this.doLoaded && Repo.stg.cs__showWareWindow && !this.guid) {
			this.doLoaded = true;
			this.addProduct();
		}

		//impord
		if (this.guid && !tab.rows.length) {
			Ref.notify(_.impJsonDt, 4, 'info');
			var res = await this.imports.importJSONGuid(this.guid, this.ID);

			if (res === true) {
				await this.getData();
			}
		}
	}

	inquireOrSend = async (i = 0, type = "Pop") => {
		console.log("snd", i, type);
		if (i > 3) return;

		// Pokud je potřeba přepočet - neprovádět žádnou akci 
		if (this.data.reCalculateRequired()/* || this.pf != type*/) {
			console.log("rt");
			return;
		}

		var sendRes = false;

		if (type == "Pop" && i == 0) {
			sendRes = await Ref.confirm.open(_.warning, _.sendDemandToManufacturer, _.sendImmediately, _.changeBeforeSend);
		}
		else if (type == "Obj" && i == 0) {
			sendRes = await Ref.confirm.open(_.warning, _.sendOrderToManufacturerConfirm, _.sendImmediately, _.changeBeforeSend);
		}

		if (!sendRes && i == 0) return;

		var action = type == "Obj" ? "Objednat" : "KalkulaceVyrobcem";
		var error = await Http.sendDemandor(this.ID, action);

		if (error) {
			Ref.notify(error, 5, 'error');

			if (error.includes("(10012)")) { //pokud neni vyplnena adresa, otevre vyber a pak se to popta
				if ((await this.selectDlAdr())) {
					i++; //zabranit zacykleni
					this.inquireOrSend(i, type);
				}

				return;
			}
		}
		else {
			var msg = type == "Obj" ? _.orderWasSendedToManufacturer : _.inquryWasSuccessfullSentToManufacturer;
			Ref.notify(msg, 4, 'success');
			await this.loadDO();
			this.refresh();
		}
	}

	delPos = async rec => {
		await rec.del();
		await this.polTable?.current?.reload();
		this.calculateRequired = true;
		this.selectedWare = null;
		this.refresh();
	}

	selectMaskMaster = async () => {
		const sw = this.selectedWare || {};

		if (!sw.mask) {
			sw.mask = await Http.getMask(sw.nsPrdVrbcKod, sw.nsPrdKod);
			sw.mask.hasMaster = !!((sw.mask[0] || {}).Object?.filter(f => f.MainParent == "MASTER")?.length) || false;
		}

		if (!sw.mask.hasMaster) {
			console.warn("noMaskMaster", sw);
			return false;
		}
		else {
			return await Ref.modal.open(this.selectedWare.nsPrdNazev,
				<Mask
					pm={sw.bPrdIsPM}
					ware={sw.nsPrdKod}
					producer={sw.nsPrdVrbcKod}
					data={this.selectedWare}
					onSave={this.saveMaskMaster}
					mask={sw.mask}
					readOnly={this.data[`b${this.pf}ReadOnly`]}
					type="MASTER"
				/>
			);
		}
	}

	rnd = (s, p) => {
		return (
			<div className="comp demandord">
				<div className="compBar buttons">
					<If is={this.data[`i${this.pf}ParentZak`]}>
						<Link href={"/deals/" + this.data[`i${this.pf}ParentZak`]}>
							<Btn action id="backToDeal"><RIcons.Fa.FaArrowLeft /> {_.backToDeal}</Btn>
						</Link>
					</If>
					<Btn action
						id="recalc"
						onClick={this.calculate}
						disabled={
							// this.isCalculating ||
							this.data[`b${this.pf}ReadOnly`] ||
							!this.pozTable.current?.rows?.length ||
							!this.polTable.current?.rows?.length || (
								!this.data.reCalculateRequired() &&
								this.data[`ie${this.pf}Stav`] === 3 &&
								this.poziceCnt < 0 &&
								!this.everyPozHasItems
							)}
					>
						<RIcons.Fa.FaCalculator /> {_.recalculate}
					</Btn>
					<Btn open
						id="customerOfferLink"
						onClick={this.customersOffer}
						disabled={
							[1/*zsRozepsana*/, 11/*zsZrusenaVR*/, 9/*neprepocitana*/].includes(this.data[`ie${this.pf}Stav`]) ||
							this.data.reCalculateRequired() ||
							this.isCalculating ||
							!this.pozTable?.current?.rows?.length
						}

					><RIcons.Fa.FaFile /> {_.customersOffer}</Btn>
					<Btn back
						className="continue-to-summary btn-info hidden"
						onClick={this.backToRecapitulation}
					><RIcons.Fa.FaArrowLeft /> {_.backToRecapitulation}</Btn>
					<Btn close
						id="backAgenda"
						onClick={this.close}
					><RIcons.Fa.FaTimes /> {_.close}</Btn>
					<ButtonMenu name={_.othOpt}>
						<me-nu
							onClick={this.importCSV}
							id="importCSV"
							className={this.cln({
								dnone:
									!Repo.stg.cs__popobjAllowCSVImport ||
									this.data[`b${this.pf}ReadOnly`]
									|| this.isCalculating
							})}
						>
							<RIcons.Fa.FaPlus /> Import CSV
						</me-nu>
						<me-nu
							id="reklamovat"
							onClick={this.claim}
							className={this.cln({
								dnone:
									this.pf != "Obj" ||
									this.data[`ie${this.pf}Stav`] == 12 ||
									!this.data[`b${this.pf}ReadOnly`] ||
									Repo.prod.nsVrbcVrbcKod != this.data[`ns${this.pf}VrbcKod`] ||
									!Repo.stg.cs__rekSrvEnabled
							})}
						>
							<RIcons.Fa.FaTimesCircle /> {_.claim}
						</me-nu>
						<me-nu
							id="costRecap"
							onClick={this.recapCosts}
							className={this.cln({
								disabled: !(
									!['1'/*zsRozepsana*/, '11'/*zsZrusenaVR*/].includes(this.data[`ie${this.pf}Stav`]) &&
									!this.data.reCalculateRequired() &&
									!this.isCalculating &&
									// speciální podmínka pro zobrazení rekapitulace nákladů (zadání z DP) a to:
									// "Rekapitulace nákladů nesmí jít zobrazit pro čerstvě odeslanou objednávku v případě, že pochází z poptávky a od svého odeslání ještě nebyla zpětně importovaná od výrobce"
									(!this.pf != "Obj" ||
										this.data[`ie${this.pf}Stav`] !== '4'/*zsObjednana*/ ||
										!this.data[`i${this.pf}ParentPop`] ||
										this.data[`d${this.pf}Imported`]
									)
								)
							})}
						><RIcons.Fa.FaPrint /> {_.recapCosts}</me-nu>
						<me-nu
							id="inquire"
							onClick={() => this.inquireOrSend(0, "Pop")}
							className={this.cln({
								disabled: (
									this.pf != "Pop" ||
									this.data[`bObjReadOnly`] ||
									this.data[`ie${this.pf}Stav`] !== 2 /*zsVypocitanaVL*/ ||
									this.isCalculating ||
									this.data.reCalculateRequired()
								)
							})}
						><RIcons.Fa.FaSeedling /> {_.inquire}</me-nu>
						<me-nu
							id="sendOrder"
							onClick={this.sendOrder}
							className={this.cln({
								disabled: !(
									(this.data[`ie${this.pf}Stav`] === 2 /*zsVypocitanaVL*/ ||
										this.pf == "Pop" && this.data[`ie${this.pf}Stav`] === 3 /*zsKalkulovanaVR*/) &&
									!this.isCalculating && (!this.data.reCalculateRequired()) && (!this.xlsErrors || !Repo.stg.cs__popobjCantOrderIfXErrMsg)

								)
							})}
						><RIcons.Fa.FaSeedling /> {_.order}</me-nu>
					</ButtonMenu>

				</div>
				<div className='grid-2'>
					<div className="panel basicData">
						<header>{_.basicData}</header>
						<div className="grid-2">
							<RInput tname="yourMark" state={this.data} item={`ns${this.pf}Oznaceni`} onChange={this.saveDO} timer={800} disabled={this.data[`b${this.pf}ReadOnly`]} />
							<DInput tname="requiredTerm" state={this.data} item={`d${this.pf}TerminPozad`} onChange={this.saveDO} timer={800} disabled={this.data[`b${this.pf}ReadOnly`]} />
						</div>
						<RInput
							tname="nextMark1"
							state={this.data}
							item={`ns${this.pf}DalsiOznaceni`}
							onChange={this.saveDO}
							timer={800} disabled={this.data[`b${this.pf}ReadOnly`]}
							visible={Repo.stg.cs__demandVaseZnacka}
						/>
						<div className={this.cln({ "grid-2": Repo.stg.cs__demandDodAddr })} style={{alignItems: 'end'}}>
							<RInput
								type="tarea"
								name={<>
									{_.deliveryAdress}
									<div className={this.cln({ dnone: this.data[`b${this.pf}ReadOnly`] !== false })}>
										<Btn add onClick={e => this.selectDlAdr()}><RIcons.Fa.FaPlus /></Btn>
										<Btn del onClick={e => this.saveDlAdr(0)}><RIcons.Fa.FaTrash /></Btn>
									</div>
								</>}
								state={this.data}
								item={`ns${this.pf}DodaciAdr`}
								//onChange={this.saveDO}
								timer={800}
								disabled={true}
								visible={Repo.stg.cs__demandDodAddr}
							/>
							<RInput
								type="tarea"
								tname="comment"
								state={this.data}
								item={`ns${this.pf}KomentProdej`}
								onChange={this.saveDO}
								timer={800}
								maxChars={100}
								className={Repo.stg.cs__demandDodAddr ? "fill" : ""}
								disabled={this.data[`b${this.pf}ReadOnly`]}
							/>
						</div>
					</div>
					<AtchTable
						name={_.attachments}
						ref={this.atchList}
						list={this.attchm.rows}
						onChange={this.addAtch}
						alwex={Repo.stg.alwex}
						canDelete={true}
						readOnly={this.data[`ie${this.pf}Stav`] === 4}
						visible={Repo.stg.cs__demandDocu}
						panel={_.attachments}
					/>
				</div>
				<div className='grid-2'>
					<div>
						<If is={this.data.ID}>
							<Table
								recordset="pozice"
								sw_iZakID={this.data.ID}
								detail={row => this.selectWare(row)}
								showFilters={false}
								showAll={true}
								onLoad={this.tabPozLoad}
								panel={_.products}
								expand={true}
								classTR={r => r.ID == this.selectedWare?.ID ? "selected" : ""}
								/*
								cols={[
									"ID",
									"nsPrdNazev",
									"iPozCislo",
									"iPozPocetKs",
									"fPozCNM_celkem_bezDPH",
									"nsPozArisValues"
								]}
								*/
								ref={this.pozTable}
							>
								<bar-button
									id="newProduct"
									click={this.addProduct}
									//text={_.addProduct}
									icon="Plus"
									disabled={this.data[`b${this.pf}ReadOnly`]}
								/>
								<tab-button
									type="primary"
									icon="Cog"
								>
									<b-item visible={r => !this.data[`b${this.pf}ReadOnly`]} icon="Trash" text={_.delete} click={this.delPos} />
								</tab-button>
							</Table>
						</If>
					</div>
					<If is={this.pozTable?.current?.rows?.length}>
						{this.createRekap()}
					</If>
				</div>
				<div>
					<If is={this.selectedWare?.ID && this.pozTable?.current?.rows?.length}>
						<Table
							recordset="polozka"
							cols="0"
							ref={this.polTable}
							sw_iZakID={this.ID}
							sw_iPoziceID={this.selectedWare?.ID}
							detail={row => this.openMask(row)}
							showFilters={false}
							showAll={true}
							expand={true}
							classTR={r => r.bPolImportError ? "warn1" : ""}
							panel={this.selectedWare?.nsPrdNazev || _.prodDetail}
						>
							<bar-button
								type="add"
								icon="Plus"
								disabled={this.data[`b${this.pf}ReadOnly`]}
								click={this.addItemMask}
							/>
							<menu-button
								type="add"
								icon="Plus"
								text={_.posMsk}
								click={this.selectMaskMaster}
							/>
							<menu-button
								type="add"
								icon="Plus"
								text={_.checkCSV}
								click={this.checkCSV}
							/>
							<menu-button
								type="add"
								icon="Plus"
								text={_.exportCSV}
								click={this.exportCSV}
							/>
							<tab-button
								type="primary"
								icon="Cog"
							>
								<b-item icon="Copy"
									text={_.copy}
									click={rec => this.copyWare(rec)}
									visible={r => !this.data[`b${this.pf}ReadOnly`]}
								/>
								<b-item icon="Trash"
									text={_.delete}
									click={rec => (rec.del() && (this.calculateRequired = true), this.refresh())}
									visible={r => !this.data[`b${this.pf}ReadOnly`]}
								/>
								<b-item icon="Upload"
									text="Import"
									click={rec => this.importCSVWare(rec)}
									visible={r => !this.data[`b${this.pf}ReadOnly`]}
								/>
							</tab-button>

							<tab-col name="nsPolArisValues" type="hide" />
							<tab-col name="blbPrdThumb" type="hide" />
						</Table>
					</If>
				</div>
			</div>
		)
	}
}