import { Repo } from "../dump";

export class Ariscat {
    //DODELAT
    static async hasPrivilege(item) {
        return true;
    }

    userInfo() {

    }

	static async selectFile(read = "base", filter, multiple = false) {
		let inp = document.createElement("input");
		inp.type = "file";
		inp.style.opacity = 0;
		multiple && inp.setAttribute("multiple", "");
		filter && inp.setAttribute("accept", filter);

		document.body.appendChild(inp);
		inp.click();

		return new Promise((resolve, reject) => {
			inp.oninput = async e=> {
				for(let file of e.target.files) {
					file.read = this.readFile;

					if(read) {
						file.data = await this.readFile(file, read);

						//zjisti velikost obrazku
						if(file.type.includes("image")) {
							const img = await this.createImage(file.b64);
							file.width = img.width;
							file.height = img.height;
						}
					}
				}
				
				const files = multiple ? e.target.files : e.target.files[0];

				resolve(files);
				document.body.removeChild(inp);
				inp.oninput = null
				inp = null;
			}
		})
	}

	static readFile(file, read = "base", encoding = "utf-8") {
		file = file || this;

		return new Promise((resolve, reject) => {
			let reader = new FileReader();

			if(read == "base") {
				reader.readAsDataURL(file);
				reader.onload = () => {
					const sp = reader.result.split(',');
					file.header = sp[0];
					const data = sp[1];
					file.b64 = reader.result;
					//const resData = Ariscat.formatB64(data, file.header);
					resolve(data);
					reader = null;
				}
			}
			else if(read == "text" || read == "txt") {
				reader.readAsText(file, encoding);
				reader.onload = () => {
					resolve(reader.result);
					reader = null;
				}
			}
			//zkusi utf-8 a pak w1250
			else if(read == "utfw") {
				reader.readAsText(file, encoding);
				reader.onload = () => {
					var res = reader.result;

					//pokud najde neznamy znak, prepne se na w1250
					if(res.includes("\uFFFD")) {
						reader.readAsText(file, "windows-1250");
						return;
					}

					resolve(reader.result);
					reader = null;
				}
			}
			else {
				reject("Chyba!!! Spatny typ cteni: " + read);
			}


			reader.onerror = error => reject(error);
		});
	}

	static async createImage(url) {
		let image = new Image();
		image.src = url;
		let ret;

		//jelikož načtení obrázku do image trvá nějakou chvíli a s dotazem na 
		//image.width bychom tak mohli předejít načtení, musíme to řešit v události
		image.onload =e=> {
			image = null; 
			ret(e.target) 
		}
		image.onerror =e=> ret(false);

		return new Promise(rs => ret = rs);
	}

	//vlozi na zacatek base64 priponu souboru
	static formatB64(binStr, mime) {
		let ext = "JPG";
		if(mime.includes("png")) ext = "PNG";
		if(mime.includes("gif")) ext = "GIF";
		return btoa(ext + binStr);
	}

	//opak formatB64 - vytvori spravne base64
	static createB64(b64f, decode = true) {
		if(!b64f) return "";
		const binStr = atob(b64f);
		
		const ext = binStr.substr(0,3);
		const b64Str = binStr.slice(3);
		let mime = "image/jpeg";

		if(ext.toLowerCase() == "png") mime = "image/png";
		if(ext.toLowerCase() == "gif") mime = "image/gif";

		return "data:" + mime + ";base64," + (decode ? btoa(b64Str) : b64Str);
	}

    static get domainID() {
        return Repo.dealer.Vyrobce.nsVrbcDomainID;
    }

    updateDuplicateRecordById() {

    }

    updateSendOrder() {
        
    }

    static decodeHTMLEntities(text) {
        text = text || "";

		var entities = [
			['&lt;script&gt;',  ''],
			['&lt;/script&gt;', ''],
			['amp', '&'],
			['apos', '\''],
			['#x27', '\''],
			['#x2F', '/'],
			['#39', '\''],
			['#47', '/'],
			['lt', '<'],
			['gt', '>'],
			['nbsp', ' '],
			['quot', '"']
		];
	
		for (var i = 0, max = entities.length; i < max; ++i) 
			text = (text || "").replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);
	
		return text;
	}

	static encodeHTMLEntities(text) {
		return text.replace(/[\u00A0-\u9999<>\&]/g, code => '&#'+code.charCodeAt(0)+';');
	}

    static createB64(b64f, decode = true) {
		if(!b64f) return "";
		const binStr = atob(b64f);
		
		const ext = binStr.substr(0,3);
		const b64Str = binStr.slice(3);
		let mime = "image/jpeg";

		if(ext.toLowerCase() == "png") mime = "image/png";
		if(ext.toLowerCase() == "gif") mime = "image/gif";

		return "data:" + mime + ";base64," + (decode ? btoa(b64Str) : b64Str);
	}

	static printSize(size) {
		var sizes = "kMGT" //PEZY";
	
		for(let i=sizes.length-1;i>=0;i--) {
			if(size >= Math.pow(1024, i+1)) return (size/Math.pow(1024, i+1)).toFixed(2) + " " + sizes[i] + "B";
		}
	
		return size + " B";
	}

	static async wait(ms) {
		return new Promise(rs => setTimeout(rs, ms))
	}

	static clickOnLink(link) {
		let a = document.createElement('a');
		a.target = '_blank';
		a.href = link;
		a.click();
		a = null;
	}
	  
    static money(m, places = 2) {
		if(m === undefined || m === null) return "-";
		if(isNaN(m)) m = 0;
		return parseFloat(m).toLocaleString("cs-CZ", {style: "currency", currency: "BBB", minimumFractionDigits: places}).replace("BBB", "").trim()
	}

	static testEmail(email, openMsg = false) {
		var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var res = (new RegExp(emailRegex)).test(email);
		
		if(!res && openMsg) {
			Ref.notify(`${_.invalidEmail}: "${email}"`, 5, "error");
		}

		return res;
	}

	static testPsw(email, openMsg = false) {
		var pswRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?:.+){6,}$/;
		var res = (new RegExp(pswRegex)).test(email);
		
		if(!res && openMsg) {
			Ref.notify(_.passwordRequirements, 5, "error");
		}

		return res;
	}


	static round(fnum, digits) {
		// a) ["1.005"], b) ["1.005", "-7"], c) ["1.005", "+21"]
		var ee = fnum.toString().split('e');
		
		// posunutí desetinné čárky o <digits> míst vpravo (vynásobení 10^digits bez násobení), následuje zaokrouhlení
		fnum = Math.round(+(ee[0] + 'e' + ((ee[1])? +ee[1] + digits : digits)));
		ee = fnum.toString().split('e');
		
		// posunutí desetinné čárky zpět o <digits> míst vlevo
		return +(ee[0] + 'e' + ((ee[1])? +ee[1] - digits : - digits));
	}

	static downloadFile(name, blob) {
		var a = document.createElement("a");
		var url = URL.createObjectURL(blob);
	
		a.href = url;
		a.download = name;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}


	static repairTypeError(typEr, strList) {
		var lengths = strList.map(m => this.jaroWinklerDistance(m, typEr))
		var min = Math.max(...lengths)
		var okIdx = lengths.indexOf(min)
	
		return strList[okIdx]
	}

	//detekce preklepu
	static jaroWinklerDistance(s1, s2) {
		// If the strings are equal
		if (s1 == s2) return 1.0;
	 
		// Length of two strings
		var len1 = s1.length;
		var len2 = s2.length;
	 
		// Maximum distance upto which matching
		// is allowed
		var max_dist = Math.floor(Math.max(len1, len2) / 2) - 1;
	 
		// Count of matches
		var match = 0;
	 
		// Hash for matches
		var hash_s1 = Array(s1.length).fill(0);
		var hash_s2 = Array(s1.length).fill(0);
	 
		// Traverse through the first string
		for (var i = 0; i < len1; i++) {
	 
			// Check if there is any matches
			for (var j = Math.max(0, i - max_dist);
				 j < Math.min(len2, i + max_dist + 1); j++)
	 
				// If there is a match
				if (s1[i] == s2[j] && hash_s2[j] == 0) {
					hash_s1[i] = 1;
					hash_s2[j] = 1;
					match++;
					break;
				}
		}
	 
		// If there is no match
		if (match == 0)
			return 0.0;
	 
		// Number of transpositions
		var t = 0;
	 
		var point = 0;
	 
		// Count number of occurrences
		// where two characters match but
		// there is a third matched character
		// in between the indices
		for (var i = 0; i < len1; i++)
			if (hash_s1[i]) {
	 
				// Find the next matched character
				// in second string
				while (hash_s2[point] == 0)
					point++;
	 
				if (s1[i] != s2[point++])
					t++;
			}
	 
		t /= 2;
	 
		// Return the Jaro Similarity
		return ((match) / (len1)
				+ (match) / (len2)
				+ (match - t) / (match))
			   / 3.0;
	}

	static htmlEntities(str) {
		return String(str)
			.replaceAll("&", '&amp;')
			.replaceAll("<", '&lt;')
			.replaceAll(">", '&gt;')
			.replaceAll("'", '&apos;')
			.replaceAll('"', '&quot;');
	}

	static isNumber(str) {
		var n = String(str).replace(',', '.');
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	//udela z objektu pole, pokud je ale vstup pole, vystup je taky pole
	static arrayObj(arob) {
		return Array.isArray(arob || []) ? (arob || []) : [arob];
	}

	static isNul(val) {
		return val === undefined || val === null || (isNaN(val) && typeof val === "number") || val === Infinity;
	}

	static isEmpty(val) {
		return val === undefined || val === null || (isNaN(val) && typeof val === "number") || val === Infinity || val === "" || val === 0;
	}

	static setChanged(changed) {
		changed ? 
			document.body.classList.add("changed") :
			document.body.classList.remove("changed");

	}

	
    static compareText(search, ...texts) {
        const cropIt = t => t.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        //slouci texty do 1ho
        const text = cropIt(texts.join(" "));
        search = cropIt(search);

        return text.includes(search);
    }
}