import './styl.scss';
import { SComponent, _, createRef, useRef } from "../../dump";

export class Tooltip extends SComponent {
    X = 0;
    Y = 0;
    htm = [];
    opened = false;
    ttRef = createRef(null)

    componentDidMount() {
        window.addEventListener('wheel', this.handleScroll, { passive: false });
      }
    
      componentWillUnmount() {
        window.removeEventListener('wheel', this.handleScroll);
      }

    close = e => {
        e && e.stopPropagation();
        this.opened = false;
        this.refresh();
    }

    open(htm, event) {
        this.htm = htm;

        event.stopPropagation();

        if (event) {
            this.X = event.pageX;
            this.Y = event.pageY;
            event.target.onmouseleave = () => this.close();
        }

        this.opened = true;
        this.refresh();
    }

    handleScroll = (event) => {
        if (this.ttRef.current && this.opened) {
            event.preventDefault();
            this.ttRef.current.scrollTop += event.deltaY;
        }
    }

    set title(txt) { this.set.title = txt; }
    set jsx(jsx) { this.set.jsx = jsx }

    rnd = (s, p) => {
        return (
            <div ref={this.ttRef} className="tooltip" style={{ left: this.X, top: this.Y, opacity: this.opened ? "1" : "0" }}>
                <div className="tooltip-content">
                    {this.htm}
                </div>
            </div>
        );
    }
}
