import { DInput } from "./dinput";
import './input.scss';
import { SComponent, _, SDate, h, Btn, RIcons, If } from "../../dump";
import { createElement } from "preact";

export class RInput extends SComponent {
    componentDidMount() {
        this.update();
    }

    componentDidUpdate(np) {
        this.checkUpdate(np);
    }

    componentWillReceiveProps(np) {
        this.props = np;
        this.update();
    }

    checkUpdate(np) {
        //console.log("upd", p, np);

        const p = this.props;
        this.type = np.type == "date" ? "text" : "text";
        let old = this.state.value;

        if (np.item && np && np.state) {
            let nw = np.state && np.state[np.item];
            if (old !== nw) this.update();
        }
    }

    update() {
        const p = this.props;
        this.set.value = (p.state && p.item) ? p.state[p.item] : p.value;
    }

    change = e => {
        //zmeny jeste nejsou ulozeny
        console.log("ch", document.body.className.includes("changed"));

        if (this.props.timer > 0 && document.body.className.includes("changed") && !this.state.changed) {
            this.set.value = this.state.value;
            e.preventDefault();
            return;
        }

        if (this.timerHandle) clearTimeout(this.timerHandle);
        let value = e.target.value;

        window.changedCount = window.changedCount || 0;
        window.changedCount++;
        var inpChangedCount = window.changedCount;

        const p = this.props;
        this.set.value = value;

        if (p.state && p.item) {
            p.state[p.item] = value;
        }

        if (p.type == "date") {
            value = SDate(value);
        }

        //nastaveni prodlevy pro onchange (spusti se az po zadanem case)
        console.log("CH", p.timer)

        this.set.currectChars = value.length

        if (p.timer > 0) {
            document.body.classList.add("changed");

            this.set.changed = true;

            this.timerHandle = setTimeout(() => {
                if (inpChangedCount == window.changedCount) {
                    p.onChange && p.onChange(value, p.item, p.state, e);
                    this.set.changed = false;
                }
            }, p.timer);
        }
        else {
            p.onChange && p.onChange(value, p.item, p.state, e);
        }
    }

    dateChange = (date, e) => {
        this.set.value = date;
        const p = this.props;
        //p.onChange && p.onChange(date, p.item, e);
        //console.log("dch", a,b,c);
    }

    click = () => {
        this.set.clicked = true;
    }

    rnd = (s, p) => {
        if (this.props.visible === false) {
            return null;
        }

        let type = p.type || "input";
        if (type == "tarea") type = "textarea";

        if (type == "date") {
            return <DInput
                className={"form-control " + (p.item || "")}
                dateFormat="PP"
                selected={s.value || ""}
                value={s.value || ""}
                onChange={this.dateChange}
            />
        }
        else {
            let elType = type || "text";

            if (type != "textarea") {
                type = "input";
            }
            else {
                elType = null;
            }

            const pCopy = { ...p };
            delete pCopy.state;
            delete pCopy.item;
            delete pCopy.name;

            let value = (s.value === undefined || s.value === null) ? undefined : s.value;

            const input = h(type, {
                type: elType,
                value: p.format ? p.format(value) : value,
                //value: null,
                ...pCopy,
                className: this.cln({
                    rinput: 1,
                    [p.item]: 1,
                    [p.className]: 1,
                    error: p.error,
                    inputActive: s.clicked,
                    changed: s.changed || false,
                }),
                onChange: this.change,
                onClick: this.click,
                style: p.style,
                required: "required"
            });

            let name = [];

            if (p.name) name = <span className="riLabel">{p.name}</span>
            else if (p.tname) name = <span className="riLabel">{_[p.tname]}</span>
            else name = [];

            return (
                <div className={this.cln({ rinputWrapper: 1, [p.item]: 1 })}>
                    {name}
                    <span className="inp">
                        <If is={!value && p.placIcon}>
                            {(createElement(RIcons.Fa["Fa" + p.placIcon], { className: "inpPlaceholder" }))}
                        </If>
                        <div className="input-container">
                            {input}
                            {p.maxChars && <p style={{color: this.state.currectChars > p.maxChars ? 'red' : null}}
                            >{this.state.currectChars ? this.state.currectChars : 0}/{p.maxChars}</p>}
                        </div>
                        {p.button && <Btn type={p.buttonType} className="inpButton" disabled={p.disabled} onClick={e => p.buttonClick(e)}>{p.button}</Btn>}
                    </span>
                </div>
            )
        }
    }
}
