import { EventDetail } from "../../components/eventDetail/eventDetail";
import { Tab } from "../../components/tab/tab";
import { Repo, Table, createRef, route } from "../../dump"

const eventDetail = createRef();
let evType = 0;
let tabFilter = row => row.iKalDruhZaznamu == evType;

async function showDetail(rec, events, ID) {
    var res = await eventDetail.current?.openDetail(rec, events.current.rows, ID);
    rec?.reload?.();
}

async function newEvent(events, ID) {
    const now = SDate();
    now.min = 0;
    now.addTime(0,0,1);

    var event = {
        nsKalDatumOd: now,
        nsKalDatumDo: now.copy.addDate(1),
        iKalDruhZaznamu: evType
    }

    await showDetail(event, events, ID);
    events.current?.reload();
}

function tabChange(num, events) {
    evType = num-1;
    tabFilter = row => row.iKalDruhZaznamu == evType;
    events.current?.refresh();
}

export const Events = ({ID, events}) => {
        const table = (
            <Table
                recordset="kalendar_m" 
                cols="1" 
                sw_iZakID={ID}
                customCols={true} 
                changeCols={false}
                showFilters={false}
                showAll={true}
                ref={events}
                expand={true}
                detail={row => showDetail(row, events, ID)}
                //filtruje co tabulka zobrazi
                filter={tabFilter}
            >
                <bar-button
                    type="add"
                    icon="Plus"
                    text=""
                    click={e=> newEvent(events, ID)}
                    visible={() => Repo.stg.cs__DealsMont || Repo.stg.cs__DealsKontJednani}
                />
                <menu-button
                    type="add"
                    icon="Calendar"
                    text={_.scheduleCalendar}
                    click={e=> route("/calendar/" + ID)}
                    visible={() => Repo.stg.cs__DealsMont || Repo.stg.cs__DealsKontJednani}
                />
                <tab-button
                    type="warning"
                    icon="Edit"
                    disabled={() => !vrJoin}
                    link={rec => "/deals/" + rec.ID}
                />
                <tab-button
                    type="primary"
                    icon="Cog"
                    click={rec => rec.reload()}
                >
                    <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                </tab-button>
                {/*<tab-col name="deal" type="format" format={this.formatDeal} caption={_.deal} />*/}
                <tab-col name="nsKalNazevJednani" caption="Název" />
                <tab-col name="nsKalMistoRealizace" />
                {/*<tab-col name="customer" type="format" format={this.formatCustomer} caption={_.customer} />*/}
                <tab-col name="nsKalDatumOd" type="date" />
                <tab-col name="nsKalDatumDo" type="date" />
                {/*<tab-col name="iKalDruhZaznamu" />*/}
            </Table>)

    return (
        <div className="eventsTab"> 
            <EventDetail ref={eventDetail} />
            <Tab onChange={i => tabChange(i, events)}>
                <tab-content title={_.montages}>{table}</tab-content>
                <tab-content title={_.contactMeetings}>{table}</tab-content>
                <tab-content title={_.service}>{table}</tab-content>
                <tab-content title={_.reclamation}>{table}</tab-content>
            </Tab>
        </div>
    )
}