import { _, SComponent, RIcons, RInput, Table, createRef, Comp, Btn } from '../../dump'

export class UserState extends SComponent {
    async cmount() {
        this.table = createRef();
    }

    async save(ustate) {
        this.set.saved = "saving";
        const {ID, nsUstStav, nsUstPozn } = ustate;
        const { ferror } = await Rq.up("zakuzivstav", 1, { ID, nsUstStav, nsUstPozn });
        Ref.modal.close();
        
        this.table?.current?.reload();
    }

    edit = async ustate => {
        Ref.modal.open(ustate.ID ? _.userStateEditingTitle : _.userStateAddingTitle,
            <div id="uStateForm">
                <RInput state={ustate} item="nsUstStav" tname="userState" />
                <RInput type="tarea" state={ustate} item="nsUstPozn" tname="userNote" />
                <Btn ok onClick={e=> this.save(ustate)}>{_.confirm}</Btn>
            </div>
        )
    }

    rnd() {
        return (
            <Comp className="userState">
                <Table 
                    recordset="zakuzivstav" 
                    cols="1" 
                    customCols={true} 
                    changeCols={false} 
                    detail={this.edit} 
                    ref={this.table}
                    panel={_.userState}
                >
                    <bar-button
                        type="add"
                        icon="Plus"
                        text=""
                        click={() => this.edit({ nsUstStav: "", nsUstPozn: "" })}
                    />
                    <tab-button
                            type="primary"
                            icon="cog"
                        >
                        <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                    </tab-button>
                    <tab-col name="nsUstPoridil" />
                    <tab-col name="nsUstStav" />
                    <tab-col name="nsUstPozn" />
                </Table>
            </Comp>

        )
    }
}