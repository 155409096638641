import './styl.scss'
import { SComponent, DInput, HSelect, RInput, SDate, Rq, _, createRef, Repo, Http, Ref, route, If, Btn, RIcons } from "../../dump";
import { AtchList } from '../../components/attachments/atchList';
import { Comments } from '../../components/comments/comments';
import { Link } from 'preact-router';

export class ComplaintIssued extends SComponent {
    cmount(s, p) {
        this.checkedAll = false;
        this.recordset = 'organizace_prehled';
        this.atchPoz = [];
        this.atchVyr = [];
        this.rc = {};
        this.ID = p.ID;
        console.log("ID", this.ID);
        this.timer = 500;
        this.timer2 = 500;


        this.state = {
            search: "",
            saved: "saved",
        }

        this.dateDisabled = false;

        this.objednavky = [];
        this.faktury = [];
        this.polkusy = [];
        this.ktg = [];
        this.prilohy = [];
        this.zamList = {};
        this.prilohyPoz = [];
        this.prilohyVyr = [];
        this.dlrStg = {};

        this.lastSeek = {
            rq: "",
            rs: {}
        }

        //data z backendu
        this.rcd = {
            objednavky: [],
            faktury: [],
            polkusy: [],
        }

        this.sel = {
            polkus: {},
            obj: {},
            fakt: {},
            vrCislo: {},
            zam: {},
            produkt: {}
        }

        this.ok = {
            polkus: false,
            obj: false,
            fakt: false,
            produkt: false,
        }

        this.error = {
            polkus: false,
            obj: false,
            fakt: false,
            produkt: false,
        }

        this.edited = {
            polkus: false,
            obj: false,
            fakt: false,
            produkt: false,
        }

        this.disabled = {
            polkus: false,
            obj: false,
            fakt: false,
            produkt: false,
        }

        this.out = {};

        //data z seekinfo
        this.createSeekObj();

        this.ref = {
            obj: createRef(),
            fakt: createRef(),
            polkus: createRef(),
            produkt: createRef(),
            cm: createRef(),
        }

        this.domain = Repo.company;

        //document.querySelector[".navbar-breadcrumb"].innerHTML = `<li><a href='/r/reclamations'>${_.reclamation}</a></li><li>${_.reclamation}</li>`;
        this.getData();
    }

    async getData() {
        console.log("gd");

        this.showVC = Repo.stg.cs__rekSrvUseVyrCis;
        const alwex = Repo.stg.cs__uploadAllowedExts || "";
        this.alwex = alwex.replaceAll(" ", "").split(",");

        /*DODELAT
        if(!Repo.stg.cs__rekSrvEnabled) {
            Ref.notify(_.complNotAlow, 4, "error");
            return;
        }
        */

        this.pk = [];

        console.log("info", this.ID);
        this.rc = await Rq.info("reksrv", this.ID);

        const { rows } = await Rq.sd("rskatg", 0, 0, { nsJustDomainID: this.domain });
        this.ktg = rows;

        this.rc.fRsMnKs = (+this.rc.fRsMnKs || 0).toLocaleString();

        await this.loadZam();

        this.dlrStg = await Http.dealerStg();

        await this.loadAtch();

        if (!this.rc.bRsReadOnly) {
            await this.loadObjd();
            await this.loadFakt();
            await this.loadSeek();
        }

        this.sendRead();
        this.changeBr();

        this.onLoad(this.rc.nsRsIntCislo);
        Ref.Header.tagLinks = ["/cm-issued"];
        ////this.onLoad(this.data[`ns${this.pf}IntCislo`]);
        await this.refreshPage();
    }

    changeBr() {
        var txt = [this.rc.nsRsVrbcCisloRS, this.rc.nsRsIntCislo, this.rc.nsRsUzivZnacka].filter(f => f).join(" - ");
        var ktg = this.ktg.find(f => f.ID == this.rc.iRsKatgID);
        var ktgTxt = ktg.nsKtNazev || _.reclamation;

        //document.querySelector[".navbar-breadcrumb"].innerHTML = `<li><a href='/r/reclamations'>${_.reclamation}</a></li><li><b>${ktgTxt} ${txt}</b></li>`;
    }

    createSeekObj() {
        this.seek = {
            obj: [],
            fakt: [],
            polkus: [],
            objednavkyEmpty: true,
            fakturyEmpty: true,
            polkusyEmpty: true
        }
    }

    async loadSeek() {
        let data = {
            faktPrij: {},
            objednavka: {},
            polkus: {},
            produkt: {}
        };

        if (this.rc.nsRsCisloObj) data.objednavka.num = this.rc.nsRsCisloObj;
        if (this.rc.nsRsCisloFakt) data.faktPrij.num = this.rc.nsRsCisloFakt;
        if (this.rc.nsRsVyrCis) data.polkus.num = this.rc.nsRsVyrCis;
        if (this.rc.nsRsProdukt) data.produkt.num = this.rc.nsRsProdukt;

        if (this.rc.iRsObjID) data.objednavka.id = this.rc.iRsObjID;
        if (this.rc.iRsFaktID) data.faktPrij.id = this.rc.iRsFaktID;
        if (this.rc.iRsPolKusID) data.polkus.id = this.rc.iRsPolKusID;
        if (this.rc.iRsProdID) data.produkt.id = this.rc.iRsProdID;

        let res = await this.seekObj(data);

        this.ok.obj = false;
        this.ok.fakt = false;
        this.ok.polkus = false;
        this.ok.produkt = false;

        if (res.tfOut) {
            const ot = res.tfOut;
            this.ok.obj = (ot.objednavka || []).find(f => (f.ID == this.rc.iRsObjID || !this.rc.iRsObjID) && f.num == this.rc.nsRsCisloObj) || false;
            this.ok.fakt = (ot.faktPrij || []).find(f => (f.ID == this.rc.iRsFaktID || !this.rc.iRsFaktID) && f.num == this.rc.nsRsCisloFakt) || false;
            this.ok.polkus = (ot.polkus || []).find(f => (f.ID == this.rc.iRsPolKusID || !this.rc.iRsPolKusID) && f.num == this.rc.nsRsVyrCis) || false;
            this.ok.produkt = (ot.produkt || []).find(f => (f.ID == this.rc.iRsProdID || !this.rc.iRsProdID) && f.num == this.rc.nsRsProdukt) || false;

            this.seek.obj = ot.objednavka || [];
            this.seek.fakt = ot.faktPrij || [];
            this.seek.polkus = ot.polkus || [];
            this.seek.produkt = ot.produkt || [];

            this.objednavky = this.seek.obj.length ? this.seek.obj : this.rcd.objednavky;
            this.faktury = (this.seek.fakt.length || this.ok.obj) ? this.seek.fakt : this.rcd.faktury;
            this.polkusy = this.seek.polkus || [];
            this.produkty = this.seek.produkt || [];

            if (this.ok.obj && !this.rc.iRsObjID) this.rc.iRsObjID = this.ok.obj.ID;
            if (this.ok.fakt && !this.rc.iRsFaktID) this.rc.iRsFaktID = this.ok.fakt.ID;
            if (this.ok.polkus && !this.rc.iRsPolKusID) this.rc.iRsPolKusID = this.ok.polkus.ID;
            if (this.ok.produkt && !this.rc.iRsProdID) this.rc.iRsProdID = this.ok.produkt.ID;

            //console.log("PK", this.ok.polkus.ID, this.rc.iRsPolKusID);
        }

        //pokud se stane chyba
        this.showErrors(res);

        this.dateDisabled = this.ok.obj || this.ok.fakt;
        this.disabled.polkus = this.ok.polkus;

        //this.refreshPage();
        return res;
    }

    showErrors(res) {
        this.error = {
            polkus: false,
            obj: false,
            fakt: false,
            produkt: false
        }

        if (res.errors && res.errors.length) {
            for (let er of res.errors) {
                if (er.id === 10054/*Invalid order id*/ || er.id === 10055/*Order ID/number mismatch*/) {
                    this.ok.obj = false;
                    this.error.obj = true;
                }
                else if (er.id === 10056/*Invalid invoice id*/ || er.id === 10057/*Invoice ID/number mismatch*/ || er.id === 10060/*Invoice ID/Order ID mismatch*/) {
                    this.ok.fakt = false;
                    this.error.fakt = true;
                }
                else if (er.id === 10061/*Invalid item piece ID*/ || er.id === 10062/*Item piece ID/number mismatch*/ || er.id === 10086/*Item piece ID x obj ID mismatch*/) {
                    this.ok.polkus = false;
                    this.error.polkus = true;
                }
                else if (er.id === 10124/*Item piece ID x product ID mismatch*/ || er.id === 10122/*Invalid product ID*/ || er.id === 10123/*Product ID x order ID mismatch*/) {
                    this.ok.produkt = false;
                    this.error.produkt = true;
                }
            }

            const erMsg = res.errors.map(m => m.msg).join(", ");
            Ref.notify(erMsg, 8, "error");
        }
    }

    async loadZam() {
        const { rows } = await Rq.sd("zamestnanec", 1, 0, { nsDomainID: this.domain });
        this.zamList = rows;
        this.sel.zam = this.zamList.find(f => f.ID == this.rc.iRsObchodnikID);
    }

    async loadObjd() {
        const { rows } = await Rq.sd("objednavka", ["ID", ['nsObjCislo', 100], ['nsObjOznaceni', 80]], 0, { bTemporaryFields: 1, nsJustDomainID: this.domain });
        this.rcd.objednavky = rows.filter(f => f.nsObjCislo).map(m => { return { ID: m.ID, num: m.nsObjCislo, custSign: m.nsObjOznaceni ? `${(m.nsObjOznaceni)}` : "" } });
        this.objednavky = this.rcd.objednavky;
    }

    async loadFakt() {
        const { rows } = await Rq.sd("fakt_prij", 1, 0, { bTemporaryFields: 1, nsJustDomainID: this.domain });
        this.rcd.faktury = rows.map(m => { return { ID: m.ID, num: m.nsFpCislo } });
        this.faktury = this.rcd.faktury;
    }


    loadAtch = async () => {
        const { rows } = await Rq.sd('attchm', 1, 0, { iPOID: this.ID, nsDomainID: this.domain, nsParentType: "reksrv" });
        this.prilohyPoz = rows.filter(f => !f.bAttOdVyrobce);
        this.prilohyVyr = rows.filter(f => f.bAttOdVyrobce);
        return rows;
    }

    async sendRead() {
        await Rq.up("touch", 1, { ID: this.rc.ID, nsTchAgenda: "reksrv" })
    }

    save = async (val, key, data) => {
        this.set.saved = "saving";

        //vynulovat datum pokud je prazdny
        if (key == "dRsDatProdeje") {
            val = val ? SDate(val).ymd : "1900-01-01";
        }

        if (key == "fRsMnKs") {
            val = String(val).replace(",", ".");
        }

        if (key == "iRsKatgID") {
            this.rc.iRsKatgID = val;
        }

        const res = await Rq.up("reksrv", 1, { ID: this.ID, [key]: val }, { nsDomainID: this.domain });

        if (res.ferror) {
            Ref.notify(res.error, 8, "error");
            this.set.saved = "error";
        }
        else {
            this.set.saved = "saved";
        }

        this.changeBr();
        return res;
    }

    saveSale = async () => {
        this.set.saved = "saving";

        const { ID, nsRsCisloObj, nsRsCisloFakt, dRsDatProdeje, nsRsVyrCis, nsRsProdukt } = this.rc;
        const data = { ID, nsRsCisloObj, nsRsCisloFakt, dRsDatProdeje, nsRsVyrCis, nsRsProdukt };

        data.iRsFaktID = this.ok.fakt ? this.rc.iRsFaktID : 0;
        data.iRsObjID = this.ok.obj ? this.rc.iRsObjID : 0;
        data.iRsPolKusID = this.ok.polkus ? this.rc.iRsPolKusID : 0;
        data.iRsProdID = this.ok.produkt ? this.rc.iRsProdID : 0;

        //vynulovat datum nebo zformatovat
        data.dRsDatProdeje = data.dRsDatProdeje ? SDate(data.dRsDatProdeje).ymd : "1900-01-01";

        console.log("saveSale", data);

        const res = await Rq.up("reksrv", 1, data, { nsDomainID: this.domain });

        if (res.ferror) {
            Ref.notify(res.error.join(", ").replace(/catch: /g, ""), 8, "error");
            this.set.saved = "error";
        }
        else {
            this.set.saved = "saved";
        }

        return res;
    }

    send = async () => {
        await this.save(2, "iRsStavID");
        this.rc = await Rq.info("reksrv", this.ID);
        await this.refreshPage();
    }

    delete = async () => {
        var res = await Ref.confirm.open(_.info, _.delete_record_confirm);

        if (res) {
            const r = await Rq.del("reksrv", this.ID);
            Rq.showError(r);
            route("/cm-issued");
        }
    }

    TopBar = () => (
        <div className="topBar">
            <div className="info">
                {/*<SaveState>{this.state.saved}</SaveState>*/}
            </div>
            <div className="buttons">
                {this.rc.bRsReadOnly ? [] : <Btn del onClick={this.delete}> {_.delCompl}</Btn>}
                {this.rc.bRsReadOnly ? [] : <Btn send onClick={this.send}> {_.submitCompl}</Btn>}
                {!this.rc.nsRsVrbcCisloRS ? [] : <Btn print onClick={this.print}><RIcons.Fa.FaPrint /> {_.print}</Btn>}
            </div>
        </div>
    )

    print = () => {
        window.open(
            Repo.serverURL + `/api/pdf/rs_pruv_list/reksrv/${this.ID}/0`, 
            "_blank", 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=595', '_blank'
        );
    }

    seekObj = async data => {
        const json = {
            scope: "reksrv",
            producer: { domainid: this.domain },
            focused: "objednavka",
            tfIn: { ...data }
        }

        //cache na seek, aby se stejne pozadavky neposilali nekolikrat
        if (JSON.stringify(data) === this.lastSeek.rq) {
            //console.log("seekcache", data);
            return this.lastSeek.rs;
        }

        const res = await Http.seek(json);
        this.lastSeek.rq = JSON.stringify(data);
        this.lastSeek.rs = res;

        //console.log("SEEK", res);

        return res;
    }

    //////////ZMENA V SELECTECH////////////

    changeNumberObj = async (number, key, data) => {
        this.rc.iRsObjID = data.ID;
        await this.searchObj(data.num);
        this.saveSale();
    }

    changeNumberFakt = async (number, key, data) => {
        this.rc.iRsFaktID = data.ID;
        await this.searchFakt(data.num);
        this.saveSale();
    }

    changeNumberPolkus = async (number, key, data) => {
        this.rc.iRsPolKusID = data.ID;
        await this.searchPolkus(data.num);
        this.saveSale();
    }

    changeNumberProdukt = async (number, key, data) => {
        this.rc.iRsProdID = data.ID;
        await this.searchProdukt(data.num);
        this.saveSale();
    }


    ///////////RUCNI ZADANI HODNOTY DO SELECTU//////////

    //rucni hledani objednavky
    searchNumberObj = async number => {
        //zneplatnit cislo objednavky
        this.rc.iRsObjID = 0;
        this.edited.obj = true;

        await this.searchObj(number);
        //zneplatnit znacku
        this.rc.nsRsObjZnacka = "";
        this.saveSale();

        return false; //this.objednavky.filter(f => f.num.includes(number))
    }

    //jen hledani, aby se projevilo pred casovym limitem
    onSearchNumberObj = async number => {
        return this.objednavky; //this.seek.obj.length ? this.seek.obj : this.objednavky.filter(f => f.num.includes(number))
    }
    onSearchNumberFakt = async number => {
        return this.faktury; //this.seek.fakt.length ? this.seek.fakt : this.faktury.filter(f => f.num.includes(number))
    }
    onSearchNumberPolkus = async number => {
        return this.seek.polkus; //this.seek.polkus.length ? this.seek.polkus : this.polkusy.filter(f => f.num.includes(number))
    }
    onSearchNumberProdukt = async number => {
        return this.seek.produkt;
    }

    //rucni hledani faktury
    searchNumberFakt = async number => {
        //zneplatnit cislo faktury
        this.rc.iRsFaktID = 0;
        this.edited.fakt = true;

        await this.searchFakt(number);
        await this.saveSale();
        return false; //this.faktury.filter(f => f.num.includes(number))
    }

    searchNumberPolkus = async number => {
        //zneplatnit cislo polkusu
        this.rc.iRsPolKusID = 0;
        this.ok.polkus = false;
        this.edited.polkus = true;

        this.rc.nsRsVyrCis = number;

        await this.searchPolkus(number);
        await this.saveSale();

        return false; //this.polkusy.filter(f => f.num.includes(number))
    }

    searchNumberProdukt = async number => {
        this.rc.nsRsProdukt = number;

        await this.searchProdukt(number);
        await this.saveSale();

        return false;
    }

    ////////////HLEDANI PODLE HODNOTY V SELECTECH///////////////

    searchObj = async number => {
        this.ok.obj = false;

        //console.log("OBJEDNAVKA")

        //aktualizovat cislo objednavky
        this.rc.nsRsCisloObj = number;

        await this.loadSeek();

        this.rc.nsRsObjZnacka = "";

        //vybere se 1. faktura a vyrobni cislo
        //datum prodeje se vybere nejdriv z faktury a pokud faktura chybi, z objednavky
        if (this.seek.fakt && this.seek.fakt.length == 1 && !this.edited.fakt) {
            this.rc.nsRsCisloFakt = this.seek.fakt[0].num;
            this.rc.iRsFaktID = this.seek.fakt[0].ID;
            this.rc.dRsDatProdeje = SDate(this.seek.fakt[0].datProdej);

            await this.loadSeek();
        }
        else if (this.seek.obj && this.seek.obj.length == 1) {
            const obj = this.seek.obj[0];
            this.rc.dRsDatProdeje = obj.datProdej ? SDate(obj.datProdej) : "";
            this.rc.nsRsObjZnacka = this.seek.obj[0].custSign || "-";
        }

        //vyber znacky
        if (this.seek.obj && this.seek.obj.length == 1) {
            this.rc.nsRsObjZnacka = this.seek.obj[0].custSign || "-";
        }
        else if (this.seek.obj && this.seek.obj.length > 1) {
            const obj = this.seek.obj.find(f => f.num == number) || {};
            this.rc.nsRsObjZnacka = obj.custSign || "-";
        }
        else {
            //console.log("nic", this.seek.obj);
        }

        if (this.seek.produkt && this.seek.produkt.length == 1) {
            this.rc.nsRsProdukt = this.seek.produkt[0].num;
        }

        /*
        if(this.seek.obj && this.seek.obj.length >= 1) {
          this.rc.nsRsObjZnacka = this.seek.obj[0].custSign || "-";
        }
        */

        /*
        else {
            this.rc.nsRsCisloFakt = "";
            this.rc.iRsFaktID = 0;

            if(this.ok.obj && this.ok.obj.datProdej) {
                this.rc.dRsDatProdeje = SDate(this.ok.obj.datProdej);
            }
        }
        */

        this.selectProd();

        //znovu se zkontroluje, jestli je vsechno ok
        await this.loadSeek();

        //i kdyz faktura neni vybrana, pouzije se spravny seznam
        this.faktury = this.seek.fakt;

        this.refreshPage();

        return this.faktury.filter(f => f.num.includes(number))
    }

    searchFakt = async number => {
        this.ok.fakt = false;

        //aktualizovat cislo faktury
        this.rc.nsRsCisloFakt = number;

        await this.loadSeek();

        //this.rc.nsRsObjZnacka = "";

        //vybere se 1. objednavka a vyrobni cislo
        if (this.seek.obj && this.seek.obj.length == 1 && !this.edited.obj) {
            this.rc.nsRsCisloObj = this.seek.obj[0].num;
            this.rc.iRsObjID = this.seek.obj[0].ID;
            this.rc.nsRsObjZnacka = this.seek.obj[0].custSign;

            if (this.seek.fakt && this.seek.fakt.length && this.seek.fakt.datProdej) {
                this.rc.dRsDatProdeje = SDate(this.seek.fakt[0].datProdej);
            }
            else {
                this.rc.dRsDatProdeje = SDate(this.seek.obj[0].datProdej);
            }

            this.rc.nsRsObjZnacka = this.seek.obj[0].custSign || "";
        }
        else if (!this.seek.obj && !this.seek.obj.length) {
            this.rc.nsRsObjZnacka = "";
        }

        if (this.seek.produkt && this.seek.produkt.length == 1) {
            this.rc.nsRsProdukt = this.seek.produkt[0].num;
        }

        await this.loadSeek();

        //pokud neni vyplneno vyrobni cislo, doplnit
        this.selectProd();

        //znovu se zkontroluje, jestli je vsechno ok
        await this.loadSeek();

        this.refreshPage();
    }

    selectProd = () => {
        if (this.seek.polkus && this.seek.polkus.length == 1 && !this.edited.polkus) {
            const pk = this.seek.polkus[0];
            this.rc.iRsPolKusID = pk.ID;
            this.rc.nsRsVyrCis = pk.num;

            if (pk.prodID && this.seek.produkt && this.seek.produkt.length) {
                const searchProd = this.seek.produkt.find(f => f.ID == pk.prodID) || {};
                this.rc.iRsProdID = pk.prodID;
                this.rc.nsRsProdukt = searchProd.num;
            }
        }
        //pokud existuje vic vyrobnich cisel, ale 1 produkt, vybrat ho
        //nebo pokud jsou vyrobni cisla zakazany, ale 1 produkt
        else if (
            (!this.showVC || this.seek.polkus && this.seek.polkus.length > 1)
            && this.seek.produkt && this.seek.produkt.length == 1
        ) {
            const pd = this.seek.produkt[0];
            this.rc.iRsProdID = pd.ID;
            this.rc.nsRsProdukt = pd.num;
        }
    }

    searchPolkus = async number => {
        this.ok.polkus = false;
        this.rc.nsRsVyrCis = number;
        await this.loadSeek();

        console.log("cislo", number);
        //console.log("LS", this.ok.polkus);

        if (this.ok.polkus && this.ok.polkus.prodID) {
            this.rc.iRsPolKusID = this.ok.polkus.ID;
            const pk = this.ok.polkus;
            this.rc.iRsPolKusID = pk.ID;

            if (this.ok.polkus.intruder) {
                this.ok.polkus = false;
                this.error.polkus = true;
                this.set.saved = "error";
                this.rc.iRsPolKusID = 0;
                Ref.notify(_.serialNumberNotCor.replace("%%", number), 6, "error");
                return;
            }

            if (this.seek.produkt && this.seek.produkt.length) {
                const searchProd = this.seek.produkt.find(f => f.ID == pk.prodID) || {};
                console.log("sp", searchProd);
                this.rc.iRsProdID = pk.prodID;
                this.rc.nsRsProdukt = searchProd.num;
            }
        }

        await this.loadSeek();

        //vybere se 1. objednavka a vyrobni cislo, pokud neni vyplnena
        if (this.seek.obj && this.seek.obj.length == 1 && !this.edited.obj) {
            this.rc.nsRsCisloObj = this.seek.obj[0].num;
            this.rc.iRsObjID = this.seek.obj[0].ID;
            this.rc.dRsDatProdeje = SDate(this.seek.obj[0].datProdej);
            this.rc.nsRsObjZnacka = this.seek.obj[0].custSign || "";
        }
        else if (!this.seek.obj && !this.seek.obj.length) {
            this.rc.nsRsObjZnacka = "";
        }

        //vybere se 1. faktura a vyrobni cislo, pokud neni vyplnena
        if (this.seek.fakt && this.seek.fakt.length == 1 && !this.edited.fakt) {
            this.rc.nsRsCisloFakt = this.seek.fakt[0].num;
            this.rc.iRsFaktID = this.seek.fakt[0].ID;
            this.rc.dRsDatProdeje = SDate(this.seek.fakt[0].datProdej);
        }
        /*
        else {
            this.rc.nsRsCisloFakt = "";
            this.rc.iRsFaktID = 0;

            if(this.ok.obj && this.ok.obj.datProdej) {
                this.rc.dRsDatProdeje = SDate(this.ok.obj.datProdej);
            }
        }
        */

        await this.loadSeek();
        this.refreshPage();
    }

    searchProdukt = async (number, b, c) => {
        this.ok.produkt = false;
        this.rc.nsRsProdukt = number;
        await this.loadSeek();

        if (this.ok.produkt && this.ok.produkt.prodName) {
            this.rc.nsRsProdukt = this.ok.produkt.prodName;
            this.rc.iRsProdID = this.ok.produkt.ID;

            if (this.ok.produkt.intruder) {
                this.ok.produkt = false;
                this.error.produkt = true;
                this.set.saved = "error";
                //this.rc.iRsPolKusID = 0;
                Ref.notify(_.serialNumberNotCor.replace("%%", number), 6, "error");
                return;
            }
        }

        await this.loadSeek();
        this.refreshPage();
    }


    ///////////////////////////////////////


    changeSubscriber = (id, key, data) => {
        this.sel.zam = data;
        this.save(id, "iRsObchodnikID")
    }

    saveComment = async (data, atchList) => {
        //console.log("CM", data, atchList);
        await this.loadAtch();
        this.refreshPage();
        this.sendRead();
    }

    addAtch = async file => {
        this.set.saved = "saving";

        try {
            var res = await Http.uploadAttchm("reksrv", this.props.ID, 0, file.name, "", file.data);
            const [a, ok, msg] = res.result;

            if(!ok) {
                this.set.saved = "error";
                return Ref.notify(msg, 5, "error");
            }

            await this.loadAtch();
            this.set.saved = "saved";
        }
        catch {
            this.set.saved = "error";
        }
        this.refreshPage();
        this.ref.cm.current.reload();
    }

    resetSel = () => {
        this.ref.fakt.current && this.ref.fakt.current.update();
        this.ref.obj.current && this.ref.obj.current.update();
        this.ref.polkus.current && this.ref.polkus.current.update();
        this.ref.produkt.current && this.ref.produkt.current.update();
    }

    refreshPage = () => {
        this.changeBr();
        this.resetSel();
        this.refresh();
        this.resetSel();
    }

    reset = async () => {
        this.rc.nsRsCisloObj = "";
        this.rc.nsRsCisloFakt = "";
        this.rc.nsRsVyrCis = "";
        this.rc.nsRsProdukt = "";

        this.rc.dRsDatProdeje = "";
        this.rc.nsRsProdukt = "";

        this.rc.iRsObjID = 0;
        this.rc.iRsPolKusID = 0;
        this.rc.iRsFaktID = 0;
        this.rc.iRsProdID = 0;

        this.rc.nsRsObjZnacka = "";

        this.edited = {
            obj: false,
            fakt: false,
            polkus: false,
            produkt: false
        }

        this.error = {
            obj: false,
            fakt: false,
            polkus: false,
            produkt: false,
        }

        this.polkusy = [];
        this.produkty = [];

        this.createSeekObj();

        await this.loadSeek();

        this.objednavky = this.rcd.objednavky;
        this.faktury = this.rcd.faktury;

        this.refreshPage();
        //this.refreshPage();
        this.saveSale();
    }

    produktSel = () => (
        <HSelect
            className={this.cln({ ok: this.ok.produkt, edited: this.edited.produkt, error: this.error.produkt })}
            state={this.rc}
            item="nsRsProdukt"
            label="num"
            values={this.produkty}
            th={this}
            editable={!this.ok.obj}
            onChange={this.changeNumberProdukt}
            disabled={this.rc.bRsReadOnly}
            ref={this.ref.produkt}
        >
            <h-up
                onUpdate={this.searchNumberProdukt}
                onSearch={this.searchNumberProdukt}
                timer={this.timer}
            />
            <h-empty>{_.noProducts}</h-empty>
        </HSelect>
    )

    panel1 = () => (
        <div className="fr2 cmPanelInner">
            <div className="panel">
                <header>{_.basicData}</header>
                <div className="grid-3 m-4">
                    <HSelect
                        tname="category"
                        state={this.rc}
                        item="iRsKatgID"
                        label="nsKtNazev"
                        values={this.ktg}
                        onChange={(val, key, data) => this.save(data.ID, "iRsKatgID")}
                        disabled={this.rc.bRsReadOnly}
                    >
                        <h-op disabled={op => !op.bKtAktivni}>{`$nsKtNazev`}</h-op>
                    </HSelect>
                    <HSelect
                        tname="forCustomer"
                        state={this.sel.zam}
                        item="nsZamNazev"
                        label="nsZamNazev"
                        values={this.zamList}
                        //nemenit vychozi stav!
                        stateChange={false}
                        onChange={this.changeSubscriber}
                    >
                        <h-sc>{_.search}</h-sc>
                    </HSelect>
                    <RInput
                        tname="desCompl"
                        onChange={this.save}
                        timer={this.timer2}
                        state={this.rc}
                        item="nsRsUzivZnacka"
                        disabled={this.rc.bRsReadOnly}
                        maxLength="30"
                    />
                </div>
            </div>

            <div className="panel">
                <header className="flex">
                    <div className="headerText">{_.salesInfo}</div>
                    <Btn clean title={_.clearFieldsOrder} onClick={this.reset} disabled={this.rc.bRsReadOnly}>{_.clearData}</Btn>
                </header>
                <div className="panel-body">
                    <div className="grid-3">
                        <HSelect
                            className={this.cln({ ok: this.ok.obj, edited: this.edited.obj, error: this.error.obj })}
                            state={this.rc}
                            values={this.objednavky}
                            item="nsRsCisloObj"
                            label="num"
                            name={<span>
                                {_.manfNumber}
                                <If is={Repo.stg.cs__rekSrvMustCisObj}>
                                    <span title={_.orderNumReq}> *</span>
                                </If>
                            </span>}
                            onChange={this.changeNumberObj}
                            disabled={this.disabled.obj || this.rc.bRsReadOnly}
                            ref={this.ref.obj}
                        >
                            <h-up onUpdate={this.searchNumberObj} onSearch={this.onSearchNumberObj} timer={this.timer} />
                            <h-op format={opt => "<b>" + opt.num + "</b><div>" + (opt.custSign || `<i>${_.withoutBrand}</i>`) + "</div>"} />
                            <h-empty>{_.noOrders}</h-empty>
                            <h-sc>{_.search}</h-sc>
                        </HSelect>

                        <HSelect
                            tname="numberInv"
                            className={this.cln({ ok: this.ok.fakt, edited: this.edited.fakt, error: this.error.fakt })}
                            state={this.rc}
                            item="nsRsCisloFakt"
                            label="num"
                            values={this.faktury}
                            th={this}
                            disabled={this.disabled.fakt || this.rc.bRsReadOnly}
                            onChange={this.changeNumberFakt}
                            ref={this.ref.fakt}
                        >
                            <h-up onUpdate={this.searchNumberFakt} onSearch={this.onSearchNumberFakt} timer={this.timer} />
                            <h-empty>{_.noInvoices}</h-empty>
                            <h-sc>{_.search}</h-sc>
                        </HSelect>

                        <DInput
                            tname="dateSale"
                            onChange={this.save}
                            timer={this.timer2}
                            state={this.rc}
                            item="dRsDatProdeje"
                            disabled={this.dateDisabled || this.rc.bRsReadOnly}
                        />
                    </div>

                    <If is={this.rc.bRsReadOnly}>
                        <RInput type="tarea"
                            tname="troubDesc"
                            state={this.rc}
                            item="nsRsPopisOdstrZavady"
                            disabled={true}
                        />
                    </If>
                </div>
            </div>

            <div className="panel">
                <header>{_.subjComplaint}</header>
                    <div className="predmet-grid">
                        <div className="flex flex-col">
                            <div className="vyr-cislo">
                                <HSelect
                                    className={this.cln({ ok: this.ok.polkus, edited: this.edited.polkus, error: this.error.polkus, dnone: !this.showVC })}
                                    state={this.rc}
                                    item="nsRsVyrCis"
                                    label="num"
                                    values={this.polkusy}
                                    th={this}
                                    name={<>{_.snNumber}<If is={Repo.stg.cs__rekSrvMustVyrCis}><span title={_.snNumReq}> *</span></If></>}
                                    onChange={this.changeNumberPolkus}
                                    disabled={this.rc.bRsReadOnly}
                                    ref={this.ref.polkus}
                                >
                                    <h-up onUpdate={this.searchNumberPolkus} onSearch={this.searchNumberPolkus} timer={this.timer} />
                                    <h-empty>{_.noSnNum}</h-empty>
                                </HSelect>
                                <HSelect
                                    className={this.cln({ ok: this.ok.produkt, edited: this.edited.produkt, error: this.error.produkt })}
                                    state={this.rc}
                                    item="nsRsProdukt"
                                    label="num"
                                    values={this.produkty}
                                    th={this}
                                    tname="nameProduct"
                                    editable={!this.ok.obj}
                                    onChange={this.changeNumberProdukt}
                                    disabled={this.rc.bRsReadOnly}
                                    ref={this.ref.produkt}
                                >
                                    <h-up
                                        onUpdate={this.searchNumberProdukt}
                                        onSearch={this.searchNumberProdukt}
                                        timer={this.timer}
                                    />
                                    <h-empty>{_.noProducts}</h-empty>
                                </HSelect>

                                <RInput tname="quantity" onChange={this.save} timer={this.timer2} state={this.rc} item="fRsMnKs" disabled={this.rc.bRsReadOnly} />
                            </div>

                            <RInput type="tarea"
                                tname="defectDesc"
                                onChange={this.save}
                                timer={this.timer2}
                                state={this.rc}
                                item="nsRsPopisZavady"
                                disabled={this.rc.bRsReadOnly}
                            />

                        </div>

                        <div>
                            <RInput
                                tname="fulAssemAdr"
                                onChange={this.save}
                                timer={this.timer2}
                                state={this.rc}
                                item="nsRsAdrMontaze"
                                disabled={this.rc.bRsReadOnly}
                                className={this.cln({ dnone: !Repo.stg.cs__rekSrvUseAdrMont })}
                            />

                            <div className="grid-1">
                                <AtchList
                                    list={this.prilohyPoz}
                                    readOnly={this.rc.bRsReadOnly}
                                    onChange={this.addAtch}
                                    name={_.atchReq}
                                    onClear={atch => this.prilohyPoz = atch}
                                    allowRm={!this.rc.bRsReadOnly}
                                    alwex={this.alwex}
                                    label="nsAttNazevSPrip"
                                />
                                <AtchList 
                                    list={this.prilohyVyr} 
                                    readOnly={true} 
                                    name={_.manufAtch}
                                    disabled={this.rc.bRsReadOnly} 
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )

    complInfo = () => (
        <div className="panel">
            <header>Informace</header>
            <table className="reklamaceTable">
                <tbody>
                    <tr>
                        <th>{_.state}</th>
                        <td>{this.rc.nsRsStav || "-"}</td>
                    </tr>
                    <tr>
                        <th>{_.company}</th>
                        <td>{this.dlrStg.nsDealerNazev || "-"}</td>
                    </tr>
                    <tr>
                        <th>{_.telephone}</th>
                        <td>{this.sel.zam.nsZamTelefon || "-"}</td>
                    </tr>
                    <tr>
                        <th>{_.email}</th>
                        <td>{this.sel.zam.nsZamLogin || "-"}</td>
                    </tr>
                    <tr>
                        <th>{_.sign}</th>
                        <td>{this.rc.nsRsObjZnacka || "-"}</td>
                    </tr>

                    <If is={this.rc.nsRsVrbcCisloRS}>
                        <tr>
                            <th>{_.rqMfNum}</th>
                            <td>{this.rc.nsRsVrbcCisloRS || "-"}</td>
                        </tr>
                    </If>
                </tbody>
            </table>
        </div>
    )


    rnd = (s) => {
        const rc = this.rc;
        //if(!rc.ID) return [];

        return (
            <div className="comp complaintIssued">
                <div className="compBar buttons">
                    {<If is={!this.rc.bRsReadOnly}>
                        <Btn del className="fas fa-trash" onClick={this.delete}> {_.delCompl}</Btn>
                    </If>}
                    {<If is={!this.rc.bRsReadOnly}>
                        <Btn warn className="fas fa-send" onClick={this.send}> {_.submitCompl}</Btn>
                    </If>}
                    {<If is={this.rc.nsRsVrbcCisloRS}>
                        <Btn ok className="btn btn-sm btn-primary fas fa-print" id="print" onClick={this.print}><RIcons.Fa.FaPrint /> {_.print}</Btn>
                    </If>}
                    <Link href="/cm-issued/"><Btn inf className="fas fa-times" id="back-agenda"><RIcons.Fa.FaTimes /> {_.close}</Btn></Link>
                </div>
                <div className="panels grid-21">
                    <this.panel1 />
                    <div>
                        <this.complInfo />
                        <If is={!this.rc.bRsEditable}>
                            <Comments
                                recordset="chat"
                                switches={{ nsChParType: "reksrv", nsParentGuid: this.rc.nsRsKod, iChParID: this.rc.ID }}
                                canAddChat={true}
                                ref={this.ref.cm}
                                onSave={this.saveComment}
                                alwex={this.alwex}
                                isDisabled={!this.rc.bRsAktivni}
                            />
                        </If>
                    </div>
                </div>
            </div>
        )
    }
}