import * as AiIcons from 'react-icons/ai'
import * as BsIcons from 'react-icons/bs'
import * as BiIcons from 'react-icons/bi'
import * as FaIcons from 'react-icons/fa'
import * as FiIcons from 'react-icons/fi'
import * as HiIcons from 'react-icons/hi'
import * as ImIcons from 'react-icons/im'
import * as IoIcons from 'react-icons/io'
import * as GoIcons from 'react-icons/go'
import * as TbIcons from 'react-icons/tb'

import { Component, h, createRef } from "preact";
import { Router, route } from 'preact-router';
import { useRef } from "preact/hooks";
import { motion } from 'framer-motion';

import { PreactHTMLConverter } from 'preact-html-converter';

import { XMLParser } from "fast-xml-parser";
import { lang } from "./functions/lng";
import langs from "./functions/langs";

import { SDate } from "./class/SDate";
import { Rq } from './class/Rq';
import { Datapacket } from './class/Datapacket';
import { Http } from './class/Http';
import { Ariscat } from './class/Ariscat';


import { SComponent } from './components/base/base';
import { RInput } from './components/base/input';
import { InputLn } from './components/base/inputLn';

import { DInput } from './components/base/dinput';
import { HSelect } from './components/base/hselect';
import { If } from './components/base/if'
import { Table } from './components/table/table';
import { ColSettings } from './components/table/colSettings';
import { Checkbox } from './components/checkbox/checkbox';
import { DpComponent } from './components/dpComponent/dpComponent';
import { Comp } from "./components/base/comp";
import { Btn } from "./components/button/button";

const settings = {
    //serverURL: "http://localhost:8080",
    serverURL: "",
    osmKeyOstry: "pk.fa764edbd82c70ea40ed6b9e866f973f",
    osmKey: "pk.fa764edbd82c70ea40ed6b9e866f973f"
}

let xmlParser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: ""
});

let RIcons = {
    Ai: AiIcons,
    Bs: BsIcons,
    Bi: BiIcons,
    Fa: FaIcons,
    Fi: FiIcons,
    Hi: HiIcons,
    Im: ImIcons,
    Io: IoIcons,
    Go: GoIcons,
    Tb: TbIcons,
}

//const intern = {company: "intern", port: 808}
//const invern = {company: "invern", port: 823}

const Ref = { confirm: {}, uuu: {} };

const Repo = {
    loaded: false,
    tables: {},
    tabList: [],
    cache: {},
    ...settings,
    lang: "cs",
    langNum: 1,
    //company: "invern",
    //port: 823
    company: "intern",
    port: 808
};

Repo.clear = () => {
    const allowed = ["loaded", "lang", "langNum", "company", "port", "clear", "serverURL", "osmKeyOstry", "osmKey"];

    for (const key in Repo) {
        if (allowed.includes(key)) continue;
        delete Repo[key];
    }

    Repo.cache = {};
    Repo.tables = {};
    Repo.tables = {};

}

const HtmlParser = PreactHTMLConverter();

export {
    RIcons,
    xmlParser,
    Component,
    h,
    createRef,
    SComponent,
    Comp,
    Router,
    route,
    useRef,
    PreactHTMLConverter,
    HtmlParser,
    motion,
    SDate,
    Rq,
    Datapacket,
    Repo,
    Http,
    Ariscat,
    RInput,
    InputLn,
    DInput,
    HSelect,
    Table,
    Checkbox,
    DpComponent,
    If,
    Ref,
    Btn,
    ColSettings
}

window.RIcons = RIcons;
window.repo = Repo;
window.Datapacket = Datapacket;
window.Ariscat = Ariscat;

// console.log("lng", langs);


function toUnderscore(text) {
    var newString = text.length && text[0].toLowerCase();

    for (var i = 1; i < text.length; i++) {
        if (/[A-Z]/.test(text[i])) {
            newString += "_" + text[i].toLowerCase();
        } else {
            newString += text[i];
        }
    }

    return newString;
}

export const _ = new Proxy(Function, {
    apply(target, tArg, args) {
        var key = args[0];
        var defaultLang = args[1];

        if (_this.showKeys) return key;

        return lang[key] || defaultLang || "";
    },
    get(obj, prop) {
        var trans = langs.find(f => f.name == prop);

        if (trans) {
            return trans[Repo.lang];
        }

        prop = toUnderscore(prop);
        trans = langs.find(f => f.name == prop);
        return trans ? trans[Repo.lang] : prop;

        //return "aaaa";
        /*
        if(prop in lang)
          return lang[prop];
        else if(prop.startsWith(":")) { //hledání přeloženého textu v překladech
          prop = prop.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          return Object.fromEntries(Object.entries(lang).filter(f => { 
            const find = f[1].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            return find.includes(prop.substr(1)) 
          }));
        }
        else { //převede nejakaHodnota na nejaka_hodnota a vyhleda
          var toUnderscore = text => {
            var newString = text.length && text[0].toLowerCase();

            for (var i=1;i<text.length;i++) {
              if (/[A-Z]/.test(text[i])) {
                newString += "_" + text[i].toLowerCase();     
              }
              else {
                newString += text[i];
              }
            }

            return newString;
          }

          var propUns = toUnderscore(prop);
          
          if(propUns in lang)
            return lang[propUns];
          else {
            console.warn("Překlad neexistuje -", prop, lang);
            return propUns;
          }
        }
        */
    }
});