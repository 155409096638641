import { Link } from "preact-router";
import { SComponent, Table, _ } from "../../dump";

export class HmOrders extends SComponent {
    async cmount() {
        this.rows = [];
        this.cols = [];
        const reqCols = [
            'ID',
            'dObjDatum',
            'fObjPrdjTotal_bezDPH',
            'fObjPrdjTotal_sDPH',
            ['nsObjIntCislo', 60],
            ['nsMenaKod', 6],
            ['nsObjStav', 100],
        ]

        const {cols, rows} = await Rq.sd("objednavka", reqCols, 0, {bTemporaryFields: 1});
        this.rows = rows;
        this.cols = cols;
    }

    rnd(s,p) {
        return (
            <>
            {/* <h3>{_.requisitions}</h3> */}
            <Table 
                rows={this.rows} 
                cols={this.cols} 
                showMenu={false} 
                showPages={false} 
                detail={rec => route("/orders/" + rec.ID)} 
            />
            </>
        )
    }
}