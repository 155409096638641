import './styl.scss'
import { SComponent, Rq, Ariscat, Comp } from "../../dump";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

export class Wares extends SComponent {
	async cmount(s, p) {
        this.data = [];
        this.colCount = 4;
        const {rows} = await Rq.sd("dvgroups", 1);

        //this.data = rows.sort((a, b) => a.iGrpPoradi - b.iGrpPoradi).map(row => {
        this.data = rows.sort((a, b) => a.iGrpPoradi - b.iGrpPoradi).map(row => {
            row.id = row.ID;
            row.icon = Ariscat.createB64(row.blbGrpIkona);
            return row;
        });

        if(rows.length <= 4) this.colCount = rows.length;
	}

    sortItems = data => {
        this.data = data;
        this.save();
        this.refresh();
    }

    async selectFile(row) {
		const file = await Ariscat.selectFile("base", ".jpg, .png, .gif, .webp");

        console.log("file", file, row);
		
		if(file.size > 70*1024) {
			//return Ariscat.alert.hlaska(_.bigFile + ' 70 kB.', 4, 'error');
		}

		if(!file.type.startsWith("image/")) {
			//return Ariscat.alert.hlaska(_.fileNotPic, 4, 'error');
		}

		row.icon = file.b64;
        console.log("row", row)
        this.refresh();
	}
    
	save = async () => {
        const data = this.data.map((d,i) => {
            return { 
                ID: d.ID, bGrpOblibene: d.bGrpOblibene, iGrpPoradi: i, blbGrpIkona: d.blbGrpIkona
            }
        });

        //console.log("data", data);

        setTimeout(async () => {
            await Rq.up("dvgroups", 2, data);
        }, 1000);

        //this.ddd = data;

        /*
        try   { 
			await Ariscat.app.updateRecordListAs(true, 'dvgroups', dp.result);
			Ariscat.alert.hlaska(_.setting_saved, 4, 'success');
		}
		catch(err) { 
			Ariscat.alert.hlaska(_.setting_saved, 4, 'success') 
		}
        */
	}

    viewWare = () => (<div className="waresModal" >
        <div>{_.dragTiles} Ukládá se automaticky</div>
        <RLDD
            items={this.data}
            itemRenderer={row => 
                <div class="item ware vybrat" onClick={e=> this.props.onChange ? this.props.onChange(row) : this.selectFile(row)}>
                    <span class="ikona" style={{background: `url(${row.icon})`}}></span>
                    <span class="popis">{row.nsGrpNazev || _.allItems}</span>
                </div>
            }
            onChange={this.sortItems}
        />
    </div>)

	rnd = (s,p) => {
        return p.inModal 
            ? <this.viewWare /> 
            : (
                <Comp className='panel'>
                    <header>Nastavení výrobků</header>
                    <this.viewWare />
                </Comp>
            )
	}
}