import { Repo, Rq, Ref } from "../dump";
import { Datapacket } from "./Datapacket";

export class Http {
    static async fetchx(url, json, stg = {}) {
        var send = {};

        Ref?.Loading?.loading();

        if(json) {
            send.method = "POST";
            send.body = JSON.stringify(json);
            send.credentials = 'include';
            //send.mode = 'no-cors';
            send.headers = { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            };
        }
        else {
            send.method = "GET"
        }

        return await fetch(url, {...send, ...stg});
    }

    static async fetchj(url, json) {
        try {
            const response = await this.fetchx(url, json);
            Ref?.Loading?.saved();
            return await response.json();
        }
        catch(e) {
            Ref?.Loading?.error();
            Ref?.notify && Ref?.notify(e.message, 10, "error");
            return {error: true, msg: e.message}
        }
    }

    static async fetcht(url, json) {
        try {
            const response = await this.fetchx(url, json);
            Ref?.Loading?.saved();
            return await response.text();
        }
        catch(e) {
            Ref?.Loading?.error();
            Ref?.notify && Ref.notify(e.message, 10, "error");
            return {error: true, msg: e.message}
        }
    }

    static async seek(json) {
        var res = await this.fetchj(Repo.serverURL+"/api/seek", json);
        return this.showRes(res, true);
    }

    static async getMaskSKDescr(json) {
        var res = await this.fetchj(Repo.serverURL+"/api/getMaskSKDescr", json);
        return res?.result[1] ? res.result[2] : {error: true, msg: res?.result[2]?.errMsg}
    }

    static async ses() {
        let res = await this.fetchj(Repo.serverURL+ "/api/ses", {});
        
        if(res && res.result && res.result.length == 3) {
            try {
                let data = res.result[0].replace(/\"/g, "").split("/");

                let keys = {};

                for(let dt of data) {
                    const [key,val] = dt.split(":");

                    if(key && val !== undefined) {
                        keys[key] = val.trim();

                        if(keys[key] === "0") keys[key] = false;
                        else if(keys[key] === "1") keys[key] = true;
                    }
                }

                return keys;
            }
            catch(e) {
                return { error: true, msg: e, res: res }
            }
        }

        return { error: true, msg: res }
    }

    static async vrbInfo(vrbc, langID) {
        let res = await this.fetchj(Repo.serverURL+"/api/vrInfo", [vrbc, langID]);
        let xml = this.showRes(res);
        let data = Datapacket.parseXML(xml);
        return data.DATAPACKET.ROWDATA.ROW;
    }

    static async getMask(vrbc, ware) {
        let res = await this.fetchj(Repo.serverURL+"/api/mask", [vrbc, ware]);
        let xml = this.showRes(res);
        let data = Datapacket.parseXML(xml);
        return Object.values(data.Masks || {});
    }

    static async duplicateRecordByID(recordset, id) {
        let res = await this.fetchj(Repo.serverURL+"/api/duplicateRecordByID", [recordset, id]);
        return this.showRes(res, true);
    }

    static async waitForEvent(lastMsg = 0) {
        let res = await this.fetchj(Repo.serverURL+"/api/waitForEvent", [lastMsg]);
        let xml = this.showRes(res);
        let data = Datapacket.parseXML(xml);
        return data?.DATAPACKET?.ROWDATA?.ROW || "";
    }

    //Http.uploadAttchm("reksrv", 315, 0, "obrazek.png", "", "iVBORw0KGgoAAAANSUhEUgAAAQ4AAADdCAYAAABKbJyQAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAQpSURBVHhe7d29bRxXFIDRpQpgxgoUqA7n7sZNmIG7ca46FKgJNrCCwAFMLUYWPwE7P++dk/CFxAD3e3dBYvbh+nK5XoBVz49Py4m3Piw/Ad5NOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIBMOIDsmC8r/ufTcoDFX1+Ww7a8rHidjQPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIhAPIHq4vl+tyBm48Pz4tJ96ycQCZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZt5wP6OPnP5fT+3z949/lxC1vOV8nHCdXI/EeQvIf4VgnHCdzj1D8yswhEY51wnESewTj1owBEY51wnFwRwjGrZkCIhzrhOOgjhiMWzMERDjWCcfBnCEYt0YOiHCs838cB3LGaHx31t+b32fjOICRBm+07cPGsc7GsbPRbmvbxxyEY0ejDpl4jE84djL6cInH2IRjB7MMlXiMSzg2NtswiceYhAPIhGNDs96+to7xCMdGZh8e8RiLcACZcGzAbfvKcxiHcACZcACZcNyZ9fxHnscYhAPIhAPIhIPN+bhyfsJxRwaEUQkHkAkHkAkHkAkHkAkHkAkHkAnHHc34Jc3MQTjYnKCen3AAmXAAmXDcmbX8R57HGIQDyIQDyIRjA9bzV57DOIQDyIRjI7PftraNsQjHhmYdHtEYj3AAmXBsbLbb17YxJuHYwSzDJBrjEo6djD5UojE24djRqMMlGuMTjp2NNmSiMYeH68vlupzZ2Zm/h2XUYDw/Pi0n3rJxHMhZh8+WMR8bx0GdYfuYIRg2jnU2joM6+lDaMuZm4ziJI2wgM8bCxrFOOE5mj4DMvF0IxzrhOLl7hcRHkVfCsU44BvM7IRGJnxOOdcIB/0M41vmrCpAJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5AJB5A9/H15ui5ngHexcQCZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcACZcADR5fINhBi14EdrRvIAAAAASUVORK5CYII=");
    static async uploadAttchm(parent, parID, dealID, fname, note, data64) {
        let res = await this.fetchj(Repo.serverURL+"/api/uploadAttchm", [parent, parID, dealID, fname, note, data64.length, data64]);
        Rq.deleteCache("attchm");
        return this.showResMsg(res);
    }

    static async deleteAttchm(id) {
        let res = await this.fetchj(Repo.serverURL+"/api/DeleteAttchm", [id]);
        return this.showResMsg(res);
    }

    static async sendDemandor(id, action = "Objednat") {
        let res = await this.fetchj(Repo.serverURL+"/api/sendOrder", [id, action]);
        return this.showRes(res);
    }

    static async setCredentials(oldPass, newPass, userID) {
        let res = await this.fetchj(Repo.serverURL+"/api/setCredentials", [oldPass, newPass, userID]);
        return this.showRes(res);
    }

    static async calculateOrder(id) {
        let sw = "";
        if(Repo.user?.bZamJeAdmin) sw = `/bAdminMode:"1"`;
        
        let res = await this.fetchj(Repo.serverURL+"/api/calculateOrder", [id], sw);
        return this.showRes(res);
    }

    static async dealerSettings(xml) {
        let res = await this.fetchj(Repo.serverURL+"/api/dealerSettings", [xml]);
        return this.showRes(res);
    }

    static async dealerAprRej(reqID, approve = false) {
        let res = await this.fetchj(Repo.serverURL+"/api/dealerAprRej", [reqID, approve]);
        return this.showRes(res);
    }

    static async dealerStg() {
        let res = await this.fetchj(Repo.serverURL+"/api/gds", {});
        let xml = this.showRes(res);
        let data = Datapacket.parseXML(xml);
        return data.Dealer;
    }

    static async login(data) {
        let res = await this.fetchj(Repo.serverURL+"/api/login", data);
        return res;
    }

    static async register(data) {
        //console.log("ddd", data);
        let res = await this.fetchj(Repo.serverURL+"/api/registerDealer", data);
        return this.showRes(res);
    }

    static async logout(user, psw, port) {
        let res = await this.fetchj(Repo.serverURL+"/api/logout");
        Repo.clear();
        return res;
    }

    static async resetPsw(email) {
        let res = await this.fetchj(Repo.serverURL+"/api/resetPassword", [email]);
        return this.showRes(res);
    }

    static async getMsgHistory() {
        let res = await Http.fetchj(Repo.serverURL+"/api/getMsgHistory", [1,0,10]);
        let xml = this.showRes(res);
        let data = Datapacket.parseXML(xml);
        let rows = data.DATAPACKET.ROWDATA.ROW;
        return Datapacket.prototype.convertRows(rows);
    }

    static async impersonate(guidDealer) {
		const res = await Http.fetchj(Repo.serverURL+"/api/impersonate", [guidDealer]);
		return res?.result || [];
	}

    static encodeHTMLEntities(text) {
        return text.replace(/[\u00A0-\u9999<>\&]/g, i=> '&#'+i.charCodeAt(0)+';');
    }

    static async loadStg(repo) {
        repo.stg = await this.ses();

        if(repo.stg?.iUserID) {
            repo.user = await Rq.info("zamestnanec", repo.stg.iUserID);
        }

        repo.stg.alwex = [];

        if(repo.stg.cs__uploadAllowedExts) {
            repo.stg.alwex = repo.stg.cs__uploadAllowedExts?.toLowerCase()?.replaceAll(" ", "").split(",").filter(f=> f != "") || [];
        }

        if(!repo.stg.error) {
            repo.prod = await this.vrbInfo(Repo.company, 1);
            repo.dealer = await this.dealerStg();
        }
        repo.tables = {};
        repo.loaded = true;

        //const dataLoad = new CustomEvent("dataLoaded", { detail: "react" });
        //document.dispatchEvent(dataLoad);

        return repo.stg;
    }
    
    static showRes(res, parseJson = false) {
        if(res && res.result && res.result.length == 3) {
            try {
                return parseJson ? JSON.parse(res.result[2]) : res.result[2];
            }
            catch(e) {
                return { error: true, msg: e, res: res }
            }
        }

        return { error: true, msg: res }
    }

    static showResMsg(res) {
        return res?.result || ["", false, res.error];
    }

    static async sendImage(imgB64, fname) {
        return await Http.fetchj(Repo.serverURL+"/api/sendImage", [imgB64, fname]);
    }
}