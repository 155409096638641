import { SComponent, _, Table, route, SDate, createRef } from '../../dump'
import './RangeMul.scss'

export class RangeMultiple extends SComponent {
    state = {
        open: false
    }

    cmount() {
        this.circleWidth = 0;
        this.per1 = 0;
        this.per2 = 100;
        this.barWidth = 100;
        this.barLeft = 0;
        this.el = createRef();
        this.values = [];
        this.refresh();
        setTimeout(() => this.init(), 1);
    }

    componentWillReceiveProps(p) {
        return false;
    }

    init(vals, max) {
        if(!vals) {
            this.per1 = this.props.values[0] / this.props.max;
            this.per2 = this.props.values[1] / this.props.max;
            this.max = this.props.max;
        }
        else {
            this.per1 = vals[0] / max;
            console.log("per2", vals[1] / max, vals[1], this);
            this.per2 = vals[1] / max;
            /*
            this.max = max;
            this.barLeft = 0;
            this.barWidth = 100;
            */
        }

        this.widthRange = this.el.current.offsetWidth;
        //this.barWidth = this.widthRange;
        this.moveBar();
    }

    moveBar() {   
        //pozice v pixelech v liste
        const posPx1 = (this.per1 * this.widthRange);
        const posPx2 = (this.per2 * this.widthRange);
        const difPx = posPx2 - posPx1;
        const max = this.max;

        if(difPx <= 0) {
            return;
        }

        this.barWidth = difPx;
        this.values[0] = this.per1 * max;
        this.values[1] = this.per2 * max;

        //vypocita pozici v liste z procent a nastavi na stred
        this.barLeft = posPx1;
        this.barWidth = posPx2 - posPx1;
        this.refresh();
    }


    update() {
        this.init();
    }

    startMove = e=> {
        this.moveEl = e.target;
        
        const htm = document.querySelector("html").style;
        htm.userSelect = "none";
        htm.cursor = "e-resize";
        this.clickPos = e.pageX - e.target.offsetLeft;
        
        document.onmousemove = this.move.bind(this);
        document.onmouseup = this.stopMove.bind(this);
    }

    move(e) {
        this.widthRange = this.el.current.offsetWidth;
        var startBar = this.el.current.getBoundingClientRect().left;
        var diff = e.pageX - startBar;
        var percent = diff / this.widthRange;

        if(percent < 0) percent = 0;
        if(percent > 1) percent = 1;
        
        if(this.moveEl.id == "d1") {
            this.per1 = percent;
            this.moveBar()
        }
        else if(this.moveEl.id == "d2") {
            this.per2 = percent;
            this.moveBar()
        }
        else if(this.moveEl.tagName == "SPAN") {
            percent = e.pageX / this.widthRange;

            //vypocita procento z mista kliku mysi a posune listu na spravne misto
            this.per1 = percent - (this.clickPos / this.widthRange);

            //nastaveni limitu vlevo
            if(this.per1 < 0) this.per1 = 0;

            this.per2 = this.per1 + this.barWidth / this.widthRange;

            //nastaveni limitu vpravo
            if(this.per2 > 1) {
                this.per2 = 1;
                this.per1 = 1 - (this.barWidth / this.widthRange);
            }

            const posPx1 = (this.per1 * this.widthRange);
            const posPx2 = (this.per2 * this.widthRange);
            const max = this.max;
            
            this.values[0] = this.per1 * max;
            this.values[1] = this.per2 * max;

            this.barLeft = posPx1;
            this.barWidth = posPx2 - posPx1;
        }

        //e.value = [...this.value];
        //this.inputFn && this.inputFn(e);
        console.log("val", this.values);
        this.props.onChange && this.props.onChange([...this.values]);
        this.refresh();
    }

    stopMove(e) {
        if(!this.moveEl) return;
            
        this.moveEl = null;
        const htm = document.querySelector("html").style;
        htm.userSelect = "";
        htm.cursor = "auto";
        document.onmousemove = null;
    }

    setValue(i, v) { 
        this.vals[i] = v; 
        this.init();
    };

    rnd =(s,p)=> {
        return (
            <div ref={this.el} className="range-mul" onMouseDown={this.startMove}>
                <span style={{left: (this.per1*100) +"%", width: (this.per2-this.per1)*100 +"%"}}>
                    <div id="d1"></div>
                    <div id="d2"></div>
                </span>
            </div>
        )
    }
}