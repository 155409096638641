import { route } from "preact-router";
import './styl.scss';
import { SComponent, Table, HSelect, createRef, Ariscat, Repo, Rq, SDate, _, xmlParser, Ref, Comp, Btn } from "../dump";

export class InvoicesSup extends SComponent {
    async cmount() {
        if (this.props.url == "/invoices") {
            this.recordset = "fakt_prij";
            this.label = _.invoices_received;
        }
        else {
            this.recordset = "expedice";
            this.label = _.supplies;
        }

        this.showDealer = false;
        this.obchodnici = {};
        this.record = {};
        this.refDeal = createRef();
        this.hasCSO = Ariscat.hasPrivilege("customerOffer");
        this.today = SDate();

        if (!Repo.stg.bAnyVrbcJoined) {
            Ref.notify(_.noVrbcConn, 20, 'error');
        }

        this.refresh();
    }

    viewPDF = (rec) => {
        var pdfInfo = xmlParser.parse(rec.nsFpXMLPDFInfo);
        var pdf = pdfInfo?.Version?.HEAD_pdf?.pdf;

        if(!pdf) {
            pdf = pdfInfo?.PDFList.Version.HEAD_pdf.pdf;
        }

        console.log("PDF", pdfInfo);
        
        if(pdf) {
            const url = `${Repo.serverURL}/api/file/${rec.nsFpVrbcKod}/FT_PDF/${pdf.guidPDF}.pdf/${pdf.nsDataName}`;
            window.open(url, "text", `width=${1000},height=${800},`);
        }
        else {
            Ref.notify(_.pdfNotExist, 6);
        }
    }

    novaReklamace = async rec => {
        let data = await Rq.info(this.recordset, rec.ID);
        var req = { nsRsPopisZavady: "" }

        if(this.recordset == "fakt_prij") {
            req.iRsFaktID = rec.ID;
            req.nsRsCisloFakt = rec.nsFpCislo;

            //predani cisla objednavek, pokud obsahuje jen 1
            if (rec.nsFpObjLst && !rec.nsFpObjLst.includes(",")) {
                req.nsRsCisloObj = rec.nsFpObjLst;
            }
        }
        else {
            req.iRsObjID = rec.ID;
            req.nsRsCisloObj = rec.nsObjCislo;
        }

        const res = await Rq.up("reksrv", 1, req, { nsDomainID: Ariscat.domainID });

        if(res.ferror) {
            Ref.notify(res.error, 4, 'error');
        }
        
        if (res.rows.length) {
            route("/cm-issued/" + res.rows[0].ID);
        }
    }

    doReklamace = (key, val, data) => {
        route("/cm-issued/" + data.id);
    }

    changeDealer = async (show, rec) => {
        if (rec && show) {
            this.rec = rec;
            this.record = await Rq.info(this.recordset, rec.ID);
        }

        if (!this.dealers) {
            const { rows } = await Rq.sd("zamestnanec", 1);
            this.dealers = rows;
        }

        Ref.modal.open(_.changeDealer,
            <div className="modal-content">
                {_.selectDealer}
                <HSelect
                    state={this.record}
                    item={`i${this.pf}ObchodnikID`}
                    label="nsZamNazev"
                    values={this.dealers}
                    onChange={val => this.record[`i${this.pf}ObchodnikID`] = val}
                >
                </HSelect>
                <div className="modal-footer">
                    <Btn ok onClick={e => this.saveDealer()}>{_.confirm}</Btn>
                </div>
            </div>
        )

        this.refresh();
    }

    rekServis = (rec, col) => {
        const val = rec[col.attrname] || "";

        if (!val) return "-";

        try {
            const json = JSON.parse(val || {});

            const format = m => {
                var inf = (m.draft || m.news) ? "inf1" : 'inf2';
                var title = m.catg + (m.num ? " " + m.num : "") + ", " + _.created + " " + SDate(m.crt);
                var img = "";

                if (m.draft)
                    img = `<span class="info fas fa-pen"></span>`;
                else if (m.news)
                    img = `<span class="info"><span class="txt">1</span></span>`;

                return `    
                    <span class="tabReklamace ${inf}">
                        ${img}
                        <span title="${title}">${m.catg}${m.num ? " " + m.num : ""}</span>
                    </span>
                `;
            }

            return <HSelect
                values={json}
                onChange={this.doReklamace}
                uid="id"
                empty={`${_.selComplaint} (${json.length})`}
            >
                <h-fop disabled={true}>{`<b>${_.selComplaint}</b>`}</h-fop>
                <h-op format={format} />
            </HSelect>
        }
        catch {
            return "";
        }
    }

    viewVisible = rec => {
        return this.recordset == "expedice";
    }

    async showOffer(rec) {
        route(`/custOffer/poptavka/${rec.ID}`);
    }

    saveDealer = async () => {
        Ref.modal.close();
        const res = await Rq.up(this.recordset, 1, { ID: this.record.ID, [`iObjObchodnikID`]: this.record[`iObjObchodnikID`] });

        //if(res.status) {
        Ref.notify(_.dealerWasChanged, 4, 'success');
        //}
        this.rec.reload();
    }

    xmlList = (rec, col) => {
        const val = rec[col.attrname] || "";
        const invoices = xmlParser.parse(val);

        if (invoices.C === undefined) return "";

        const inv = Array.isArray(invoices.C) ? invoices.C : [invoices.C];

        return inv.map(m => <a href={"/invoices/search/" + m.iDocID}>{m.nsCisloDoc || m.nsDataName}</a>);
    }
    
    highlightValidity = row => {
        if((row.dFpSplatnost < this.today) && !row.dFpUhrada && row.fFpCenaBezDPH > 0) return "warn1";
        if((row.fFpZaplacenoVal < row.fFpCenaSDPH) && row.dFpUhrada == "" && row.fFpCenaBezDPH > 0) return "warn2";

        //console.log("splatnost", row.dFpSplatnost);
        //if((row.fFpZaplacenoVal == row.fFpCenaSDPH)) return "warn2";

        return "";
    }

    rnd(s) {
        return (
            <Comp className="invoicesSup">
                <Table recordset={this.recordset} cols="0" classTR={this.highlightValidity}>
                    <tab-button
                        type="info"
                        icon="Eye"
                        disabled={() => !Repo.stg?.bAnyVrbcJoined}
                        visible={this.viewVisible}
                        link={rec => route(`/custOffer/poptavka/${rec.ID}`)}
                    />
                    <tab-button
                        type="primary"
                        icon="Cog"
                    >
                        <b-item icon="Print"
                            text={_.print}
                            visible={r => this.recordset == "fakt_prij"}
                            click={this.viewPDF}
                        />
                        <b-item
                            icon="Users"
                            text={_.changeDealer}
                            visible={() => false}
                            click={rec => this.changeDealer(true, rec)}
                        />
                        <b-item
                            icon="Users"
                            text={_.customers_offer}
                            visible={r => this.hasCSO && !r[`b${this.pf}AnyMissingDV`] && ![1, 11, 5, 22].includes(r.ieObjStav) && r.iObjParentZak}
                            click={rec => this.showOffer(rec)}
                        />
                        <b-item
                            icon="Users"
                            visible={r => (
                                (r.bObjReadOnly && (r.ieObjStav != 12) && r.nsObjVrbcKod == Repo.prod.nsVrbcVrbcKod) || //objednavka (dodavka)
                                (r.nsFpVrbcKod == Repo.prod.nsVrbcVrbcKod) //faktura
                            ) && Repo.stg.cs__rekSrvEnabled
                            }
                            text={_.claim}
                            click={rec => this.novaReklamace(rec)}
                        />

                    </tab-button>
                    <tab-col name="nsFpRekSrvJS" type="format" format={this.rekServis} />
                    <tab-col name="nsObjRekSrvJS" type="format" format={this.rekServis} />
                    <tab-col name="nsObjXMLFPLst" type="format" format={this.xmlList} />
                </Table>
            </Comp>
        )
    }
}



export class EditableCell extends SComponent {
    async cmount() {
        this.isEditing = false;
        this.currentText = this.props.text;
    }

    handleDoubleClick = () => {
        this.isEditing = true;
        this.refresh();
    };

    handleBlur = () => {
        this.isEditing = false;
        this.refresh();
    }

    handleChange = (e) => {
        this.currentText = e.target.value;
        this.refresh();
    }

    rnd() {
        return (
          <td onClick={this.handleDoubleClick}>
            {this.currentText}

            {(this.isEditing || true) && (
              <textarea
                value={this.currentText}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
              />
            )}
          </td>
        );
    }
}

/*
export class InvoicesSup extends SComponent {
    async cmount() {
    }

    rnd(s) {
        return (
            <Comp className="invoicesSup">
                aaa
                <table className="testTable">
                    <tbody>
                    <tr>
                        <EditableCell text="txt1" />
                        <EditableCell text="txt2" />
                        <td>gasdgsdgssd sdgsddg ssgd gasa ssd adg gsa gas dg a dg asfasas saf a fafs as safaf fas </td>
                    </tr>
                    </tbody>
                </table>
            </Comp>
        )
    }
}
*/