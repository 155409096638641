import { SComponent, _, Table, route, createRef, ColSettings } from '../../dump'

export class Recordsets extends SComponent {
    cmount() {
        this.filtersRef = createRef();
        this.vrJoin = false;
        this.refTab = createRef();
    }

   async openFilters(row) {
        console.log("of", row);

        this.filtersRef.current.recordset = row.nsRcRecordset;
        await this.filtersRef.current.loadData();
        this.filtersRef.current.open();
    }

    rnd = (s, p) => {
        return (
            <div className='panel'>
                <header>{_.recordsets}</header>
                <ColSettings ref={this.filtersRef} save={() => this.refTab.current?.reload()} data={this.data} all={true} />
                <Table 
                    recordset="ufl_recordset" 
                    cols="1" 
                    customCols={true} 
                    changeCols={false}
                    showAll={true}
                    ref={this.refTab}
                    detail={row => this.openFilters(row)}
                >
                    <tab-col name="nsRcDescr" />
                    <tab-col name="iRcSecretFldCnt" />
                </Table>
            </div>
        )
    }
}