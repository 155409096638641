import './styl.scss';
import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, useRef, Ref, Repo, Comp, route, Btn, InputLn, Ariscat, If, RIcons } from "../../dump";
import { OSM } from '../../components/OSM/OSM';
import { createRef } from 'preact';

export class Customer extends SComponent {
    async cmount() {
        this.ID = this.props.ID;
        this.customer = {
            iOrgLangID: 5
        };
        this.changed = {};
        this.tabRec = {};
        this.mapLoaded = false;
        this.defaultAdr = "Praha";
        this.selRef = {};//useRef();
        this.osm = createRef();
        this.adreses = [];
        this.timer = 500;
        //this.loadMap();
        await this.getData();
    }

    async getData() {
        this.customer = await Rq.info("organizace_prehled", this.ID);
        this.onLoad(this.customer.nsOrgNazev);
        Ref.Header.tagLinks = ["/customers"];
        //console.log("init", this.customer.nsOrgAdresa);
        this.adreses = await this.osm.current.initAdreses(this.customer.nsOrgAdresa);
        await Ariscat.wait(1);
        this.osm.current.centerMap();

        this.refresh();
    }

    delete = async () => {
        if(await Ref.confirm.open(_.info, _.deleteCustomer)) {
            await Rq.del("organizace_prehled", this.ID);
            route("/customers");
        }
    }

    save = async () => {
        if(this.changed.nsOrgEmail && !Ariscat.testEmail(this.changed.nsOrgEmail, false)) {
            this.errorEmail = true;
            delete this.changed.nsOrgEmail;
            this.refresh();
        }
        else {
            this.errorEmail = false;
        }

        if (this.customer.ID > 0) {
            this.changed.ID = this.customer.ID;
        }
        else {
            this.changed.bOrgAktivni = true;
        }

        if (!this.customer.nsOrgNazev) {
            return Ref.notify(_.nameCustomerMustFil, 4, 'error');
        }

        if(this.changed.nsOrgNazev); {
            //this.onLoad(this.changed.nsOrgNazev);
        }

        this.cusID = this.changed.ID;

        const res = await Rq.up("organizace", 1, this.changed);
        //Rq.showError(res, _.custChnCrt);
        //aby se pak spravne nacetl prehled zakazniku
        Rq.deleteCache("organizace_prehled");

        this.changed = {};
        this.ID = 0;
        //this.customer.ID > 0 ? this.tabRec.reload() : this.updTable();
        //this.customer = {};
        this.refresh();
    }

    backDeal = async () => {
        if(this.props.dealID && this.cusID) {
            const zak = await Rq.info("zakazka", this.props.dealID);
            const data = {ID: this.props.dealID, iZakOrgID: this.cusID}
            
            if(!zak.nsOrgAdresa && this.changed.nsOrgAdresa) {
                data.nsOrgAdresa = this.changed.nsOrgAdresa;
            }
            
            await Rq.up("zakazka", 2, data);
            route("/deals/" + this.props.dealID);
        }
        else if(this.props.dealID) {
            route("/deals/" + this.props.dealID);
        }
    }

    change = (val, key, data) => {
        if(key == "nsOrgNazev") {
            this.onLoad(this.customer.nsOrgNazev);
        }
        
        this.changed[key] = val;
        this.save();
    }

    changeAdr = (val, key, data) => {
        console.log("change adr", val, key, data);

        val = data.description;
        //this.osm1.showAddress(data.lat, data.lon, data.display_name);
        this.changed[key] = data.display_name;
        this.osm.current.initAdreses(data.display_name);
        this.save(data.display_name, "nsZakDodaciAdr");
    }

    async onLoadMap(data) {
        console.log("map", data);
        this.adreses = data.adreses;
        this.refresh();
        //const adr = this.customer.nsOrgAdresa || this.defaultAdr;
    }

    clickAdr1 = (adr, data) => {
        this.zakazka.nsZakDodaciAdr = adr;
        this.save(adr, "nsZakDodaciAdr");
    }

    clickAdrModal = (adr, data) => {
        this.orgSel.nsOrgAdresa = adr;
        this.refresh();
    }

    updateAdr = async adr => {
        this.changed.nsOrgAdresa = adr;
        this.customer.nsOrgAdresa = adr;
        this.save(adr, "nsOrgAdresa");
        return await this.osm.current.initAdreses(adr);
    }

    newCustomer = async (e, table) => {
        this.updTable = table.reload;
        this.customer = {};
        this.ID = -1;
        this.refresh();
        //this.loadMap();
    }

    rnd = () => {
        return (
            <Comp className="customer">
                <div className="compBar buttons">
                    <If is={this.props.dealID}><Btn save onClick={this.backDeal}><RIcons.Fa.FaArrowLeft />{_.backToDeal}</Btn></If>
                    <Btn del onClick={this.delete}><RIcons.Fa.FaTrash />{_.delete}</Btn>
                </div>
                <div className="panels grid-2">
					<div className="panel">
                        <header>{_.custData}</header>
                        <RInput tname="fullName" onChange={this.change} state={this.customer} item="nsOrgNazev" timer={this.timer} />
                        <HSelect  //select pro OSM mapy - modal
                            tname="billing_address"
                            state={this.customer}
                            values={this.adreses}
                            item="nsOrgAdresa"
                            label="display_name"
                            uid="osm_id"
                            onChange={this.changeAdr}
                            visible={true}
                            timer={1000}
                        >
                            <h-up onUpdate={this.updateAdr} timer="2000" />
                            <h-op format={r => `<b>${r.display_place}</b><br>${r.display_address}`} />
                        </HSelect>
                        <RInput tname="telephone" onChange={this.change} state={this.customer} item="nsOrgTelefon" timer={this.timer} />
                        <RInput tname="email" onChange={this.change} state={this.customer} item="nsOrgEmail" timer={1000} error={this.errorEmail} />
                        <div className="grid-2">
                            <RInput tname="ico" onChange={this.change} state={this.customer} item="nsOrgICO" timer={this.timer} />
                            <RInput tname="dic" onChange={this.change} state={this.customer} item="nsOrgDIC" timer={this.timer} />
                        </div>
                        <RInput tname="contact_person" onChange={this.change} type="tarea" state={this.customer} item="nsOrgKontOsoba" timer={this.timer} />
                        <HSelect  //select pro OSM mapy - modal
                            tname="user_language"
                            state={this.customer}
                            item="iOrgLangID"
                            label="lng"
                            timer={this.timer}
                            values={[
                                { ID: 1, lng: _.cs },
                                { ID: 2, lng: _.en },
                                { ID: 3, lng: _.de },
                                { ID: 4, lng: _.sk },
                                { ID: 5, lng: _.fr },
                                { ID: 6, lng: _.pl },
                                { ID: 7, lng: _.hu },
                            ]}
                            onChange={this.change}
                        >
                        </HSelect>
					</div>
                    <div className="panel">
                        <header>{_.map}</header>
                        <OSM ref={this.osm} onLoad={e => this.onLoadMap(e)} />
					</div>
				</div>
            </Comp>
        )
    }
}