import { SComponent, Table, Rq, _, route, Repo, Comp } from "../dump";

export class ComplaintsIssued extends SComponent {
    async cmount() {
        //xc["navbar-breadcrumb"].h = `<li>${_.reclamation}</li>`;

        this.rEnabled = 1;
        /*
        this.rEnabled = await Ariscat.casc("rekSrvEnabled");


        if(!this.rEnabled) {
            Ariscat.alert.hlaska(_.complNotAlow, 4, "error");
        }
        this.refresh();
        */
    }

    new = async () => {
        const res = await Rq.up("reksrv", 1, { nsRsPopisZavady: "" }, { nsDomainID: Repo.dealer.Vyrobce.nsVrbcDomainID })
        Rq.showError(res);

        if(res.rows.length) {
            route("/cm-issued/" + res.rows[0].ID);
        }
    }

    rnd(s) {
        if (!this.rEnabled) {
            return <div></div>
        }

        return (
            <Comp className="complaintsIssue">
                <Table recordset="reksrv" cols="0" detail={rec => route("/cm-issued/" + rec.ID)}>
                    <bar-button
                        type="add"
                        icon="Plus"
                        text=""
                        click={this.new}
                    />
                    <tab-button
                        type="warning"
                        icon="Edit"
                        link={rec => "r/reclamation/" + rec.ID}
                    />
                    <tab-button
                        type="primary"
                        icon="Cog"
                        disabled={rec => rec.bRsReadOnly}
                    >
                        <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                    </tab-button>
                    <tab-col name="nsRsPopisZavady" type="format" format={(rec, col) =>
                        rec[col.attrname].length > 200 ? rec[col.attrname].substring(0, 200) + "..." : rec[col.attrname]
                    } />
                </Table>
            </Comp>
        )
    }
}