import './styl.scss';
import logo from "../../../images/cathedral.svg";

import background from "../../../images/pozadi.svg";
import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, useRef, Ref, RIcons, Http, Checkbox, Repo, motion, Btn } from "../../dump";
import { loadAriscat } from '../../main';

export class LoginPage extends SComponent {
    async cmount() { 
        this.reg = {};
        this.lost = {};
        this.loginData = {
            username: localStorage.username || "",
            password: localStorage.psw || "",
        };
        this.langs = ["cs", "en", "de", "sk", "fr", "pl", "hu", "ro"];
        this.langsImg = [];
        this.lang = 1;
        this.port = 808;
        this.error = "";
        this.viewed = 1;

        for(var lng of this.langs) {
            let img = await import(`../../../images/flags/${lng}.svg`);
            this.langsImg.push(img.default);
        }
    }

    setLang(lng) {
        this.lang = this.langs.indexOf(lng) + 1;
        Repo.lang = lng;
        Repo.langNum = this.lang;
        this.refresh();
    }

    restore = async () => {
        if(this.lost.email) {
            this.error = await Http.resetPsw(this.lost.email);

            if(this.error === "") {
                this.error = _.forgotPassEmailSent;
            }

            this.refresh();
        }
    }

    async login(e) {
        e && e.preventDefault();
        
        const ld = this.loginData;

        var res = await Http.login({
            user: ld.username,
            psw: ld.password, 
            port: Repo.port, 
            company: Repo.company, 
            lang: Repo.langNum
        });

        if(!res?.error) {
            Repo.login = true;
            loadAriscat();
        }
        else {
            this.error = _.loginIncorrect;
            this.refresh();
        }

        //return false;
    }

    loginForm = () => {
        return (
            <form id="loginForm" onSubmit={e=> this.login(e)} action="#" className={this.cln({dnone: this.viewed != 1})}>
                <div className='heading'>{_.login2}</div>
                <RInput type="text" tname="username" item="username" state={this.loginData} />
                <RInput type="password" tname="password" item="password" state={this.loginData} />
                <div className='flex btnBar'>
                    <input type="submit" class="btn" value={_.loginText} />
                    <Btn action onClick={e=> this.showForm(e,2)}>{_.registration}</Btn>
                    <span id="lostPsw" onClick={e=> this.showForm(e,3)}>{_.forgotPassword}</span>
                </div>
            </form>
        )
    }

    footer = () => {
        return (
            <motion.div initial={{ y: 300 }} animate={{ y: 0 }} transition={{ duration: 0.8 }} className="footer">
                <span className="fas fa-info-circle footer-i dnone"></span>
                <span className="footer-text">
                    <span>&copy; 2016 - {SDate.year} </span>
                    <a href="http://www.cathedral.cz" target="_blank" className="hrefCathedral">CATHEDRAL Software, s.r.o. </a><br/>
                    <div>versionF arismarket:mz.26.5.6645,</div>
                    <div>versionB 1.78 - 2023.07.21.1 @6634.49376</div>
                    <a href="https://locationiq.com/">Open Street {_.maps}</a>
                </span>
            </motion.div>
        )
    }

    lostPsw = () => {
        return (
            <div id="lostPswForm" className={this.cln({dnone: this.viewed != 3})}>
                <RInput tname="emailRecov" item="email" state={this.lost} />
                <div className='flex btnBar'>
                    <Btn className="sloupec" id="restore" onClick={this.restore}>{_.confirm}</Btn>
                    <Btn className="sloupec" id="loginBack" onClick={e=> this.showForm(e,1)}>{_.loginText}</Btn>
                    <div className="sloupec hlaska"></div>
                </div>
            </div>
        )
    }

    testData(txt = "ggg") {
        var y = 0;

        ["username", "name", "password", "password2", "company", 
            "street", "telephone", "city", "psc", "country", "code"].forEach((f, i) => this.reg[f] = txt + (i+y))

        this.reg.username = txt + "@" + txt + ".cc";
        this.reg.password = txt + "A123";
        this.reg.password2 = txt + "A123";
        this.reg.code = Repo.company;
        this.reg.ico = 123;
        this.reg.dic = 456;
        this.reg.consent = true;

        this.refresh();
    }

    register = async () => {
        this.error = "";

        const notFilled = ["username", "name", "password", "password2", "company", 
            "street", "telephone", "city", "psc", "country", "code"].filter(f => !this.reg[f]);

        if(!this.reg.consent) {
            this.error = _.mustAgreeGtc;
        }
        if(!Ariscat.testEmail(this.reg.username)) {
            this.error = _.invalidEmail;
        }
        if(notFilled.length) {
            this.error = _.frstNeedFilRedFld;
        }

        if(!this.error) {
            this.reg.lang = this.lang;
            const error = await Http.register(this.reg);

            if(error) {
                this.error = error;
            }
            else {
                this.loginData = {
                    username: this.reg.username,
                    password: this.reg.password,
                }

                this.login();
            }

            //console.log(error);
        }

        this.refresh();
    }

    regForm = () => {
        return (
            <div id="regForm" className={this.cln({dnone: this.viewed != 2, error: this.error })}>
                <div className="grid-2">
                    <RInput tname="username" item="username" state={this.reg} />   
                    <RInput tname="yourName" item="name" state={this.reg} /> 
                    <RInput tname="password" item="password" state={this.reg} type="password" />   
                    <RInput tname="passwordConfirmation" item="password2" state={this.reg} type="password" />   
                    <RInput tname="companyName" item="company" state={this.reg} />    
                    <RInput tname="street" item="street" state={this.reg} />    
                    <RInput tname="telephone" item="telephone" state={this.reg} />
                    <RInput tname="city" item="city" state={this.reg} />
                    <RInput tname="psc" item="psc" state={this.reg} />
                    <RInput tname="country" item="country" state={this.reg} />
                    <RInput tname="ico" item="ico" state={this.reg} className="noreq" />
                    <RInput tname="dic" item="dic" state={this.reg} className="noreq" />
                    <RInput tname="domainId" item="code" state={this.reg} /*alpg napr*/ />
                </div>

                <Checkbox name={_.reg_lic_sml_A + " " + _.reg_lic_sml_B} item="consent" state={this.reg} />
                <Btn className="sloupec" id="zaregistrovat" onClick={this.register}>{_.register}</Btn>
                <Btn className="sloupec" id="loginBack" onClick={e=> this.showForm(e,1)}>{_.loginText}</Btn>
                <div className="sloupec hlaska"></div>
            </div>
        )
    }

    showForm=(e,val)=> {
        e.preventDefault();
        this.viewed = val;
        this.refresh();
    }

    rnd() {
        return (
            <div className="loginPage" style={{backgroundImage: `url(${background})`}}>
                <motion.div initial={{ x: -1000 }} animate={{ x: 0 }} transition={{ duration: 0.8 }} id="companyLogo">
                    <img src={logo} alt="Cathedral Software" />
                </motion.div>

                <motion.div initial={{ opacity: 0, scale: 0.0 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.8 }} id="login">
                    <div id="languages">{this.langs.map((lng,i) => (
                        <div key={lng} 
                            className={this.cln({lang: 1, [lng]: 1, selected: Repo.lang == lng})} 
                            onClick={e=> this.setLang(lng)} 
                            style={{backgroundImage: `url(${this.langsImg[i]})`}}
                        />
                    ))}
                    </div>
                    <div id="forms">
                        <this.loginForm />
                        <this.regForm />
                        <this.lostPsw />
                    </div>
                    <div id="msgError">{this.error}</div>
                </motion.div>
 
                <this.footer />
            </div>
        )
    }
}