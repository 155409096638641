import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import './styl.scss';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { SComponent, Http } from "../../dump";
import { createRef } from "preact";

export class OSM extends SComponent {
    cmount(s,p) {
        this.position = [59.4333, 17.1167];
        this.zoom = 12;
        this.popup = "Praha";
        this.map = createRef();
        this.marker = createRef();
        this.url = `https://eu1.locationiq.com/v1/`;

        if(!this.loaded) {
            //this.initAdreses(p.address);
        }
    }

    async initAdreses(adr) {
        if(!adr) adr = this.popup;

        console.log("init", adr)
        
        this.loaded = true;
        this.adreses = await this.autocomplete(adr);
        
        setTimeout(() => {
            this.adreses.length && this.showAddressData(this.adreses[0]);            
        }, 100);
        
        this.props.onLoad && this.props.onLoad(this);

        this.centerMap();
        return this.adreses;
    } 

    showAddressData(data) {
        this.showAddress(data.lat, data.lon, data.display_address);
    }

    moveMap(x, y) {
        const size = this.map.getSize();
        var ofsX = size.x * x;
        var ofsY = size.y * y;
        this.map.panBy(new L.Point(ofsX,ofsY), {animate: false});
    }

    async showAddress(x, y, text) {
        this.position = [x, y];
        //this.marker.current.openPopup();
        var pos = L.latLng(x, y);
        console.log("xy", pos, text);

        //this.map.current.setView(pos, 12);
        this.marker?.current?.setPopupContent(text);
        this.marker?.current?.openPopup();
        this.marker.current.setLatLng(pos).update().openPopup();
        this.map?.current?.panTo(pos);
        await this.refresh();

        setTimeout(() => {
            this.map?.current?.invalidateSize();
            this.centerMap();
        }, 10);
    }

    async autocomplete(adr) {
        return await Http.fetchj(this.url + `autocomplete.php?q=${adr}&autocomplete=1&key=${Repo.osmKey}&format=json&source=v1`);
    }

    async reverseGeo(x, y) {
        return await Http.fetchj(this.url + `reverse.php?key=${Repo.osmKey}&lat=${x}&lon=${y}&format=json`);
    }

    async findAddress(adr, show = true, x = 17.118, y = 49.43) {
        var data = await Http.fetchj(this.url + `autocomplete.php?q=${adr}&autocomplete=0&key=${Repo.osmKey}&format=json&source=v1`);

        if(show && data.length) {
            const d = data[0];
            const name = this.formatAddress(d);
            this.showAddress(d.lat, d.lon, name);
        }

        if(data.length) {
            return data[0];
        }

        return {};
    }

    formatAddress =data=> {
        const a = data.address;

        if(a.country_code == "cz" && a.city) {
            if(a.name == a.house_number) {
                a.name = a.city;
                a.city = null;
            }

            if(a.suburb == a.city) {
                a.suburb = null;
            }

            return [a.name, a.house_number, a.city, a.suburb, a.postcode].filter(f=>f).join(", ");
        }
        else {
            return `${a.city} ${a.house_number}, ${a.postcode}, ${a.state}, ${a.country}`;
        }
    }

    centerMap = () => {
        this.map.current.panTo(this.marker.current.getLatLng());
    }

    rnd =(s,p)=> {
        return (
            <MapContainer 
                center={this.position} 
                zoom={this.zoom} 
                scrollWheelZoom={true}
                ref={this.map}
            >
                <TileLayer
                    attribution='&copy; <a href="https://locationiq.com"LocationIQ</a>'
                    url={`https://{s}-tiles.locationiq.com/v3/streets/r/{z}/{x}/{y}.png?key=${Repo.osmKey}`}
                />
                <Marker position={this.position} ref={this.marker}><Popup></Popup></Marker>
            </MapContainer>
        )
    }
}