import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, Ref, RIcons, Checkbox, HtmlParser, h, If, Comp, Btn, Ariscat } from "../../dump";
import './styl.scss'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class MessagesCenter extends SComponent {
    async cmount() {
        this.searchText = "";
        this.messages = [];
        this.countries = [];
        this.dealers = [];

		this.mesFields = [
			"ID", 
			//["nsMsgText", { fieldtype: "bin.hex", SUBTYPE: "WideText" }],
			["nsMsgSubject", { width: 102 }], 
			["nsMsgType", { width: 102 }],
			["nsMsgLang", { width: 22 }],
			["nsMsgIconExt", { width: 510 }],
			"nsMsgPoridil", 
			"dMsgPorizeno", 
			"iMsgState", 
			"bMsgConfirmation", 
			"iMsgPocOdeslano", 
			'dMsgValidTill',
			['blbMsgIcon', 'Binary', {visible: false}],
			['nsMsgText', 'WideText', {visible: false}],
		];

		await this.loadMsgs();
        this.countries = (await Rq.sd("strv_dealer_list", ["nsDlistZeme"], 0, {bVsechnyVozy: 1, bTemporaryFields: 1})).rows;
        this.dealers = await this.initDealers();
    }

    async initDealers() {
		var dl = [
			'ID',
			'iDlistJazyk',
			'iDlistStav',
			'iDlistZamID',
			['nsDlistEmail', 200],
		];
		
		const {rows} = await Rq.sd("strv_dealer_list", dl, 0, {bVsechnyVozy: "1", bTemporaryFields: "1"})
	    return rows;
	}

    async loadMsgs() {
        const {rows} = await Rq.sd('strv_msg', this.mesFields);
        this.messages = rows.sort((a,b) => b.dMsgPorizeno - a.dMsgPorizeno); 
    }

    msgDel = async msg => {
		let res = await Ref.confirm.open(_.delete, _.wantDelMessage);
		if(!res) return;

        await Rq.del('strv_msg_send', msg.ID)
        await Rq.del('strv_msg', msg.ID);
        await this.loadMsgs();
        this.refresh();
    }

    new = async () => {
        this.msgEdit({});
    }

    
    save = async (msg, send = false) => {
        if(!msg.nsMsgSubject || !msg.nsMsgText) {
            return Ref.notify(_.mustFilled, 4, 'danger');
        }

        let row = { 
            nsMsgText: msg.nsMsgText, 
            nsMsgSubject: msg.nsMsgSubject || "?", 
            bMsgConfirmation: msg.bMsgConfirmation, 
            nsMsgType: msg.nsMsgType, 
            nsMsgLang: msg.nsMsgLang,
            dMsgValidTill: msg.dMsgValidTill || SDate.addDate(0,2),
        }

        if(msg.ID) row.ID = msg.ID;

        let res = await Rq.up("strv_msg", this.mesFields, row);
        Rq.showError(res);

        if(!msg.ID && res.rows.length) {
            msg.ID = res.rows[0].ID;
        }

        //console.log("res", res);

        let dealers = this.dealers;

        if(msg.nsMsgLang > 0) dealers = dealers.filter(f => f.iDlistJazyk == msg.nsMsgLang);
        //if(msg.country != "")
        //    dealers = dealers.filter(f => f.nsDlistZeme == msg.country);

        dealers = dealers
            .map(m => { return { 
                iMsndIDmsg: msg.ID, iMsndIDReceiver: m.iDlistZamID, 
                bMsndRead: msg.bMsgConfirmation
            }});

        if(send) {
            let resDl = await Rq.up("strv_msg_send", 1, dealers);
            Rq.showError(resDl);
        }

        Ref.modal.close()
        await this.loadMsgs();
        this.refresh();
    }

    send0 = async msg => {
        var d = new Date();
        var txt = $('#messageBox').trumbowyg('html');
        var text = $("<p/>").text(txt).html() || $('#messageBox').html();

        let row = { 
            nsMsgText: msg.nsMsgText, 
            nsMsgSubject: msg.nsMsgSubject, 
            bMsgConfirmation: msg.bMsgConfirmation, 
            nsMsgType: msg.nsMsgType, 
            nsMsgLang: msg.nsMsgLang,
            dMsgValidTill: msg.dMsgValidTill,
        };

        var msg = {
            id: this.messages.length + 1,
            date: d.getUTCDate() + '.' + d.getUTCMonth() + '.' + d.getUTCFullYear(),
            category: $("#messageType")[0].value,
            name: $("#InputMessageHead")[0].value,
            confirm: $("#requireConfirmation")[0].checked,
            text: text,
            lang: $("#dealJazyk").val(),
            country: $("#dealStat").val() || "",
            icon: this.icon,
            validity: $("#msgValidity").val() ? $("#msgValidity").data('datepicker').getFormattedDate('yyyy-mm-dd') : "",
            ext: this.iconHeader
        };

        if(msg.text == "" || msg.text == "<br>" || msg.name == "") {
            Ariscat.alert.dialog(_.error, _.mustFilled, "OK", null);
            return;
        }

        if(msg.text.length >= 512000) {
            Ariscat.alert.dialog(
                _.error, 
                _.errorMsgLong.replace("%1", Ariscat.printSize(msg.text.length)).replace("%2", "500 kB"), 
                "OK", 
                null
            );
            return;
        }

        let dp = new Datapacket();

        const msgFields = [...this.mesFields];
        msgFields.push(["nsMsgText", { fieldtype: "bin.hex", SUBTYPE: "WideText" }]);

        dp.addFields(msgFields);


        if(msg.validity) {
            row.dMsgValidTill = msg.validity
        }
    
        if(this.icon) {
            row.blbMsgIcon = btoa(msg.icon);
            row.nsMsgIconExt =  msg.ext;
        }

        if(this.selMesID) row.ID = this.selMesID;

        dp.addRow(row);

        try {
            if(send) {
                var prijemci = this.dealers;

                if(!$("#allDealers")[0].checked) {
                    var prList = xi.dlList.v.split("\n");

                    prijemci = prList.map(email => this.dealers
                        .find(f => f.nsDlistEmail.replace("@", "%40") == email.replaceAll("@", "%40"))
                    );

                    console.log("prijemci", prijemci);

                    var neplatne = prList.filter((f,i) => !prijemci[i]);

                    console.log("nep", neplatne);

                    if(neplatne.length) {
                        return Ariscat.alert.hlaska("Opravde prosím seznam dealerů. Tyto adresy neexistují: " + neplatne.join(", "), 10, "error");
                    }
                }

                if(msg.lang != "")
                    prijemci = prijemci.filter(f => f.iDlistJazyk == msg.lang);
                if(msg.country != "")
                    prijemci = prijemci.filter(f => f.nsDlistZeme == msg.country);
            }

            var dpacket = await Ariscat.app.updateRecordListAs(true, 'strv_msg', dp.result);
            var array = $.xml2json(dpacket);
            var msgID = array.ROWDATA.ROW ? array.ROWDATA.ROW.ID : this.selMesID;

            if(send) {
                const data = prijemci.map(m => { return { iMsndIDmsg: msgID, iMsndIDReceiver: m.iDlistZamID, bMsndRead: msg.confirm }});
                await Rq.up("strv_msg_send", 1, data);
            }

            //this.messages.push(msg);
            this.selMesID = undefined;
            this.initMessages();
            this.$modal_msg.modal('hide');
        }
        catch(error) {
            console.log(error)

            Ariscat.alert.showAlert(this.alertDiv, 'error', error, false);
        }      
    }

    changeHTML = html => {
        console.log("html", html);
    }

    search=()=> {

    }

    msgInfo = async msg => {
        await this.msgGetText(msg);
        msg.textViewed = !msg.textViewed;
        
        this.refresh();
    }

    async msgGetText(msg) {
        if(!msg.nsMsgText && msg.ID) {
            var info = await Rq.info("strv_msg", msg.ID);
            msg.nsMsgText = info.nsMsgText;
        }

        return msg;
    }

    msgEdit = async msg => {
        this.msg = msg;
        var title = _.newMessage;

        if(msg.iMsgPocOdeslano) title = _.detailMessage;
        else if(msg.ID) title = _.editMessage;
        else if(msg.isCopy) title = _.copyMessage;

        await this.msgGetText(msg);

        Ref.modal.open(title,
            <div className={this.cln({msgCenter: 1, copy: msg.isCopy, send: msg.iMsgPocOdeslano})}>
                <div className="grid-2">
                    <RInput tname="title" state={msg} item="nsMsgSubject" disabled={msg.iMsgPocOdeslano} />
                    <HSelect
                        state={msg} 
                        values={[
                            {ID: 1, label: _.system}, 
                            {ID: 2, label: _.marketing}, 
                            {ID: 3, label: _.agendaDeal}, 
                            {ID: 4, label: _.info}, 
                            {ID: 5, label: _.other}
                        ]}
                        item="nsMsgType" 
                        label="label" 
                        tname="messageKind"
                        disabled={msg.iMsgPocOdeslano}
                    ></HSelect>
                    <HSelect
                        state={msg} 
                        values={[
                            {ID: 0, label: _.allLangs}, 
                            {ID: 1, label: _.cs}, 
                            {ID: 2, label: _.en}, 
                            {ID: 3, label: _.de}, 
                            {ID: 4, label: _.sk}, 
                            {ID: 5, label: _.fr}, 
                            {ID: 6, label: _.pl}, 
                            {ID: 7, label: _.hu}, 
                        ]}
                        item="nsMsgLang" 
                        label="label" 
                        tname="msgLang"
                        disabled={msg.iMsgPocOdeslano}
                    ></HSelect>
                    <DInput tname="validityMsg" state={msg} item="dMsgValidTill" disabled={msg.iMsgPocOdeslano} />
                </div>
                <Checkbox state={msg} item="bMsgConfirmation" tname="requireRead" disabled={msg.iMsgPocOdeslano} />
                <ReactQuill theme="snow" value={msg.nsMsgText} onChange={html => msg.nsMsgText = html} readOnly={msg.iMsgPocOdeslano} />
            </div>,
            <div className="modal-buttons">
                <If is={!msg.iMsgPocOdeslano}>
                    <Btn save onClick={() => this.save(msg)}><RIcons.Fa.FaCheck /> {_.save}</Btn>
                    <Btn save
                        className="saveSend" 
                        onClick={() => this.save(msg, true)}
                    ><RIcons.Fa.FaCheck /> {_.saveSend}</Btn>
                </If>
                <Btn close className="close" onClick={() => Ref.modal.close()}>{_.close}</Btn>
            </div>
        )
    }

    msgCopy = async msg => {
        const msgCopy = {...msg};
        msgCopy.ID = null;
        msgCopy.isCopy = true;
        msgCopy.iMsgPocOdeslano = false;
        this.msgEdit(msgCopy);
    }

    renderMsgs(s,p) {
        return this.messages.filter(f => Ariscat.compareText(this.searchText, f.nsMsgSubject)).map(m => 
            <div className="message">
                <div className="info">
                    <span className="msgDate">{m.dMsgPorizeno.toString()}</span>
                    <span className="msgSubj" onClick={()=>this.msgInfo(m)}>{m.nsMsgSubject}</span>
                    <div className='buttons'>
                        <Btn info className="msgInfo" onClick={()=>this.msgInfo(m)}>{m.textViewed ? <RIcons.Fa.FaCaretUp /> : <RIcons.Fa.FaCaretDown />}</Btn>
                        <Btn edit className="msgEdit"  onClick={()=>this.msgEdit(m)}>{m.iMsgPocOdeslano ? <RIcons.Fa.FaEye /> : <RIcons.Fa.FaEdit />}</Btn>
                        <Btn copy className="msgCopy" onClick={()=>this.msgCopy(m)}><RIcons.Fa.FaCopy /></Btn>
                        <Btn del className="msgDel"  onClick={()=>this.msgDel(m)}><RIcons.Fa.FaTrash /></Btn>
                    </div>
                </div>
                <div className={this.cln({msgText:1, hide: !(m.textViewed && m.nsMsgText)})}>{(m.textViewed && m.nsMsgText) ? HtmlParser.convert(m.nsMsgText) : ""}</div>
            </div>
        )
    }

    clear() {
        this.searchText = "";
        this.refresh();
    }

    rnd(s,p) {
        return (
            <Comp className="messageCenter">
                <div className="bar">
                    <Btn add onClick={e=> this.new()}><RIcons.Fa.FaPlus /></Btn>
                    <RInput placeholder={_.search} state={this} item="searchText" onChange={() => this.refresh()} />
                    <Btn clear onClick={e=> this.clear()}><RIcons.Fa.FaTimes /></Btn>
                </div>
                <div className="messages">{this.renderMsgs(s,p)}</div>
            </Comp>
        )
    }
}

//<motion.div initial={{ x: -100 }} animate={{ x: 0 }} className="comp messageCenter"></motion.div>
//<div className="comp messageCenter"></div>