import { SComponent, _ } from "../../dump";
import { HmDemands } from './hmDemands';
import { HmOrders } from './hmOrders';

export class HmOrdersDemands extends SComponent {
    constructor(props) {
        super(props),
            this.state = {
                switch: false,
            }
    }


    rnd(s, p) {
        return (
            <>
                <div style={{ display: 'flex', cursor: 'pointer', gap: '1rem', userSelect: 'none' }}>
                    <div onClick={() => {if(this.state.switch) this.setState({ switch: false })}}>
                        <h3 style={this.state.switch ? 'color: gray' : null}
                        >{_.inquiries}</h3>
                    </div>

                    <div onClick={() => {if(!this.state.switch) this.setState({ switch: true })}}>
                        <h3 style={!this.state.switch ? 'color: gray' : null}
                        >{_.requisitions}</h3>
                    </div>
                </div>

                {this.state.switch ? <HmOrders /> : <HmDemands />}
            </>
        )

    }
}