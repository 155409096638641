import './input.scss';
import { SComponent, SDate, _, createRef, RIcons } from "../../dump";

export class DInput extends SComponent {
    componentWillMount() {
        const now = SDate();
        this.state = {};

        this.inpRef = createRef();

        this.setState({
            date: null,
            showDate: now.copy,
            inputValue: "",
            open: false,
        });
    }

    componentDidMount() {
        //ZMĚNA HODNOTY PŘÍSTUPNÁ PŘES DOM
        Object.defineProperty(this.inpRef.current, 'value', {
            //get: () => this.props.state[this.props.item],
            get: () => this.state.date,
            set: val=> {
                const date = SDate(val);
                this.updateDate(date);
            }
        });
    
        Object.defineProperty(this.inpRef.current, 'open', {
            //get: () => this.props.state[this.props.item],
            get: () => this.state.open,
            set: val=> (this.set.open = val, console.log("val", val)),
        });

        Object.defineProperty(this.inpRef.current, 'it', {
            get: () => this
        });

        //funkce aby se komponenta zavrela po kliknuti jinam
        this.componentsClose();
        this.update();
    }
    

    componentDidUpdate(np) {
        const p = this.props;
        this.type = p.type == "date" ? "text" : "text";

        if(p.state && p.item && np && np.state) {
            let old = p.state[p.item];
            let nw = np.state[p.item];
            if(old !== nw) this.update();
        }
    }

    componentWillReceiveProps(np) {
        this.props = np;
        this.update();
    }

    update() {
        const p = this.props;
        const date = (p.state && p.item) ? p.state[p.item] : p.value;


        if(!date) return this.empty();

        //datum se musi zkopirovat, jinak by platilo date == showDate
        this.setState({
            date: date.copy,
            showDate: date.copy,
            inputValue: date.dmy,
            open: false,
        });
    }

    empty() {
        this.setState({
            date: "",
            showDate: SDate(),
            inputValue: "",
            open: false,
        });
    }

    createMonth(date = SDate()) {
        date = SDate(date);
        //nastavi 1. den v mesici
        date.day = 1;
        //nastavi 1. den v tydnu
        date.addDate(-date.wDay + 1);

        let weekList = [];
        let week = 0;
        
        //vytvoreni kalendare - zobrazi se 6. tydnů
        while(date.wDay <= 7 && week < 6) {
            weekList[week] = weekList[week] || [];
            weekList[week].push(date);

            if(date.wDay == 7) week++;
            
            date = date.copy.addDate(1);
        }
        
        return weekList;
    }

    createMonthTable = (date = SDate()) => {
        const list = this.createMonth(date);
        const showMonth = this.state.showDate.month;

        return list.map((week,i) => 
            <tr className={"week " + (i+1)} key={i}>
                { week.map(date => {
                    var dayClass = "month";
                    var {day, month, year} = date;

                    if(month < showMonth) dayClass = "last";
                    if(month > showMonth) dayClass = "next";
                    if(this.state.date && this.state.date.isDate(day, month, year)) dayClass = "selected";

                    return <td key={day} onClick={e=> this.dayClick(date)} className={"day " + dayClass}>{day}</td>
                })}
            </tr>
        );
    }

    openCalendar =()=> {
        this.set.open = true;
        
        setTimeout(() => {
            console.log("cl", this.state);
        }, 1000);
    }

    dayClick = (date) => {
        console.log("DC", date);

        this.setState({
            date: date.copy,
            inputValue: date.dmy,
            open: false
        })

        const p = this.props;
        const dc = date.copy;

        if(p.state && p.item) {
            p.state[p.item] = dc; 
        }

        p.onChange && p.onChange(dc, p.item);

        if(!p.onChange && p.state.refresh) {
            p.state.refresh();
        }     
    }

    lastMonth = () => {
        this.state.showDate.addDate(0,-1);
        this.refresh();
    }

    nextMonth = () => {
        this.state.showDate.addDate(0,+1);
        this.refresh();
    }

    selectMonth = () => {
        
    }

    inputChange =e=> {
        if(this.timerHandle) clearTimeout(this.timerHandle);
        
        const value = e.target.value;
        this.set.inputValue = value;

        this.timerHandle = setTimeout(() => {
            const p = this.props;
            let date = value === "" ? "" : SDate(value);

            //pokud je datum neplatny, vymazat
            if(isNaN(date.day)) date = "";

            if(p.state && p.item) {
                p.state[p.item] = date === "" ? "" : date.copy;
            }

            this.setState({
                date: date === "" ? "" : date.copy,
                showDate: date === "" ? SDate() : date.copy,
                inputValue: date === "" ? "" : date.dmy,
            })

            p.onChange && p.onChange(date, p.item, e);

            console.log("ch", p);

            if(!p.onChange && p.state.refresh) {
                p.state.refresh();
            }          
        }, 1000);
    }

    updateDate(date) {
        this.setState({
            date: date.copy,
            showDate: date.copy,
            inputValue: date.dmy,
            open: false,
        });
    }

    rnd =(s,p)=> {
        let name = [];

        if(p.name) name = <label className="riLabel">{p.name}</label>
        else if(p.tname) name = <label className="riLabel">{_[p.tname]}</label>

        return (
            <span className={this.cln({dinput:1, [p.item]:1, openComponent: 1, open: s.open })} ref={this.inpRef} no-close>
                {name}
                <div className="dinputWrapper">
                    <input 
                        type="text" 
                        className="rinput dateInput" 
                        value={s.inputValue} 
                        onClick={this.openCalendar} 
                        onChange={this.inputChange} 
                        disabled={p.disabled} 
                        no-close
                    />
                    <RIcons.Bs.BsCalendarDate className='iconCalendar' />
                </div>
                <table className={this.cln({calendar: 1, open: s.open, closed: !s.open})}>
                    <thead>
                        <tr className="head" no-close>
                            <th onClick={this.lastMonth}><RIcons.Fa.FaArrowLeft no-close /></th>
                            <th onClick={this.selectMonth} className="month" colSpan="5" no-close>{s.showDate.format("M. YYYY")}</th>
                            <th onClick={this.nextMonth}><RIcons.Fa.FaArrowRight no-close /></th>
                        </tr>
                        <tr className="days">
                            <th>{_.mondayShort}</th>
                            <th>{_.tuesdayShort}</th>
                            <th>{_.wednesdayShort}</th>
                            <th>{_.thursdayShort}</th>
                            <th>{_.fridayShort}</th>
                            <th>{_.saturdayShort}</th>
                            <th>{_.sundayShort}</th>
                        </tr>
                    </thead>
                    <tbody>{this.createMonthTable(s.showDate)}</tbody>
                </table>
            </span>
        )
    }
}