import { SComponent, _, createRef } from "../../dump";

export class If extends SComponent {
  rnd(s,p) {
    if(p.and !== undefined) {
      return (p.is && p.and) ? p.children : null;
    }
    else if(p.or !== undefined) {
      return (p.is || p.or) ? p.children : null;
    }
    else if (p.is) {
      return p.children;
    }
    return null;
  }
}