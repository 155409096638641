import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, Table, createRef } from "../../dump";
import './styl.scss'

export class DStats extends SComponent {
    overview = [];
    obratTab = [];
    dateFrom = SDate.addDate(0,0,-1);
    dateTo = SDate();
    
    months =[_.january, _.february, _.march, _.april, _.may, _.june, _.july, _.august, _.september, _.october, _.november, _.december];

    async cmount() {
        const {rows} = await Rq.sd("ve_stat_overview", 1);

        this.overview = rows.map(m => <section className={m.nsStSekce}>
            <div>{m.nsStPopis}</div>
            <b>{m.iStPocet}</b>
            <div>{_.worth}</div>
            <b><span>{Ariscat.money(m.fStCena)} </span><span>{m.nsStMena}</span></b>
        </section>)

        await this.loadObrat();
    }
    
    async loadObrat() {
        const years = {}
        const {rows} = await Rq.sd("ve_stat_obrat_pm", 1);


        for(let row of rows) {
            years[row.iStRok] = years[row.iStRok] || {};
            years[row.iStRok][row.iStMesic] = row.fStCena;
        }

        for(let [year, prices] of Object.entries(years)) {
            this.obratTab.push({year: year, ...prices});
        }

        this.obrat = years;
    }

    rnd(s,p) {
        return (
            <div className="comp dstats">
                <div className="overview grid-4">{this.overview}</div>
                <div className="grid-12">
                    <Table recordset="ve_stat_obrat_py" customCols={false} expand={true} />
                    <Table rows={this.obratTab} customCols={true} changeCols={false} expand={true}>
                        <tab-col attrname="year" caption={_.year} />
                        <tab-col attrname="1" caption={_.january} fieldtype="fixed" />
                        <tab-col attrname="2" caption={_.february} fieldtype="fixed"  />
                        <tab-col attrname="3" caption={_.march} fieldtype="fixed"  />
                        <tab-col attrname="4" caption={_.april} fieldtype="fixed" />
                        <tab-col attrname="5" caption={_.may} fieldtype="fixed" />
                        <tab-col attrname="6" caption={_.june} fieldtype="fixed" />
                        <tab-col attrname="7" caption={_.july} fieldtype="fixed" />
                        <tab-col attrname="8" caption={_.august} fieldtype="fixed" />
                        <tab-col attrname="9" caption={_.september} fieldtype="fixed" />
                        <tab-col attrname="10" caption={_.october} fieldtype="fixed" />
                        <tab-col attrname="11" caption={_.november} fieldtype="fixed" />
                        <tab-col attrname="12" caption={_.december} fieldtype="fixed" />
                    </Table>
                </div>

                <Table 
                    recordset="ve_stat_obrat_dt" 
                    sw_dDTFr={this.dateFrom.ymd}
                    sw_dDTTo={this.dateTo.ymd}
                    expand={true}
                >
                    <tab-bar>
                        <DInput 
                            placeholder={_.dateFrom} 
                            state={this} 
                            item="dateFrom" 
                        />
                        <DInput 
                            placeholder={_.dateTo} 
                            state={this} 
                            item="dateTo" 
                        />
                    </tab-bar>
                </Table>
            </div>
        )
    }
}