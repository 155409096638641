import './styl.scss'
import { SComponent, _, Repo, If } from "../../dump";
import { Stats } from './stats';
import { HmMessages } from './hmMessages';
import { HmOrdersDemands } from './hmOrdersDemands';

export class Home extends SComponent {
    cmount(){
        if(!Repo.stg.cs__homePage){
            route('/demands')
        }

    }
    rnd(s,p) {
        return (
            <div className="comp home">
                <div className="grid-2">
                
                <div className="col">
                    <Stats />
                    <HmOrdersDemands/>
                </div>

                <div className="col">
                    <div>
                        <If is={!Repo.stg.cs__hideHomeScreenMessages}><HmMessages /></If>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}