import "./styl.scss";

/*
TYPY

add
edit
del
delete
close
cancel
clean
back
change
menu
open
info
toggle
ok

action
send
print
set
copy
view
download
move
tab
*/

export const Btn =p=> {
    //hleda prop, ktery je == true
    const type = Object.entries(p).find(([k,v]) => v === true)?.[0] || p.type || "";

    return (<button {...p} className={[(p.className || ""), type].filter(f=>f).join(" ")}>{p.children}</button>)
}