import "./styl.scss";
import { SComponent, _, If, RIcons, createRef, motion, Btn } from "../../dump";
import { cloneElement, createElement } from "preact";
import { TbRuler2 } from "react-icons/tb";

export class ButtonMenu extends SComponent {
  cmount(s, p) {
    s.open = false;
    this.switch = true;

    this.menuRef = createRef();

    this.icon = p.icon ? p.icon : <RIcons.Fa.FaBars />;

    if (p.icon === "") this.icon = "";

    this.items = this.getChildrens(p.children).map((child) => {
      child.type = "li";
      return cloneElement(child, { onClick: (e) => this.menuClick(child, e) });
    });
  }

  componentDidMount() {
    //console.log("mount", this.menuRef.current);P

    //ZMĚNA HODNOTY PŘÍSTUPNÁ PŘES DOM
    Object.defineProperty(this.menuRef.current, "open", {
      get: () => this.state.open || false,
      set: (val) => (this.set.open = val),
    });

    Object.defineProperty(this.menuRef.current, "it", {
      get: () => this,
    });

    this.componentsClose();
  }

  menuClick = (child, e) => {
    //console.log("cl", child);
    this.set.open = false;
    child?.props?.onClick && child.props.onClick(e);
  };

  openMenu = (e) => {
    this.props.onOpen && this.props.onOpen();

    setTimeout(() => {
      if (this.switch) {
        this.set.open = !this.state.open
      }
    }, 10);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open !== prevState.open) {
      if (this.state.open === false && this.switch === true) {
        this.switch = false

        setTimeout(() => {
          this.switch = true
        }, 100)
      }
    }
  }


  rnd(s, p) {
    const comp = document.querySelector(".comp");

    return (
      <btn-menu
        class={this.cln({
          buttonMenu: 1,
          openComponent: 1,
          open: s.open,
          [p.className]: p.className,
        })}
        ref={this.menuRef}
      >

        <Btn menu ref={this.buttonRef} onClick={this.openMenu} >
          {this.icon} {p.name === undefined ? "" : p.name}
        </Btn>

        <div className="btnMenuWrapper" style={{ right: '0', top: '0px', position: 'relative' }}>
          <If is={s.open}>
            <motion.ul
              initial={{ y: 20, opacity: 0.3 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.1 }}
              onAnimationStart={() => comp && (comp.style.overflowX = "hidden")}
              onAnimationComplete={() => comp && (comp.style.overflowX = "")}
            >
              {this.items}
            </motion.ul>
          </If>
        </div>


      </btn-menu>

    );
  }
}
