import './styl.scss';
import { SComponent, h, _, Ref, Http, RIcons, HtmlParser, xmlParser, route, createRef } from "../../dump";
import { ButtonMenu } from '../buttonMenu/buttonMenu';

let notifInterval;

export class Notifications extends SComponent {
    messages = [];
    alerts = [];
    lastEvent = 0;

    constructor(p) {
        super(p)
        this.state = {
            unread: 0,
        }
    }
    
    cmount() {
        Ref.Notification = this;
        this.getData();
        
        notifInterval && clearTimeout(notifInterval);
        notifInterval = setInterval(() => this.getEvents(), 60000);

        // console.log("cmount", this);
    }

    async getEvents() {
        //let events = await Http.waitForEvent(this.lastEvent);

        //this.lastEvent = Math.max(...events.map(m => +m.ID));
    }
    
    async getData(p) {
        this.messages = await Http.getMsgHistory();

        for(let msg of this.messages) {
            msg.xmlData = xmlParser.parse(msg.nsMsgHstXMLData)?.Notification?.Event || {};
            const {color, icon} = this.getType(msg.xmlData.nsName);
            msg.color = color;
            msg.icon = icon;
        }

        await this.refresh();
    }

    getType(type) {
        let color = "danger";
        let icon;

        switch(type) {
            case 'calculation':
                color = 'success';
                icon = <RIcons.Fa.FaCalculator />;
            break;
            case 'calculation-failed':
                color = 'danger';
                icon = <RIcons.Fa.FaCalculator />;
            break;
            case 'OnPopRcvd':
            case 'OnObjRcvd':
                color = 'success';
                icon = <RIcons.Fa.FaIndustry />;
            break;
            case 'OnDealerApproved_ToDealer':
                color = 'success';
                icon = <RIcons.Fa.FaPlug />;
            break;
            case 'OnDealerRejected_ToDealer':
                color = 'danger';
                icon = <RIcons.Fa.FaPlug />;
            break;
            case 'OnRegDealer_ToDealer_Domain':
                color = 'success';
            break;
            case 'OnSendOrder_PopToObj_Edit':
                color = 'success';
                icon = <RIcons.Fa.FaCopy />;
            break;
            case 'OnSendOrder_PopToObj_Send':
                color = 'success';
                icon = <RIcons.Fa.FaSquare />;
            break;
            case 'OnSendOrder_ObjSent':
                color = 'success';
                icon = <RIcons.Fa.FaSquare />;
            break;
            case 'error':
                color = 'error';
                icon = <RIcons.Fa.FaExclamationTriangle />;
            break;
            default:
                color = 'success';
                icon = <RIcons.Fa.FaInfoCircle />;
        }

        return {color, icon}
    }
    
    showMsg(msg) {
        console.log("msg", msg);
        const xml = msg.xmlData;
        this.set.unread = 0;

        const types = {
            objednavka: "orders",
            poptavka: "demands",
            reksrv: "cm-issued",
        }

        if(xml && types[xml.nsRecordAgenda]) {
            const url = "/" + types[xml.nsRecordAgenda] + "/" + xml.iRecordID;
            console.log(url);
            route(url);
        }
        else if(msg.url) {
            route(msg.url);
        }

        this.refresh();
    }

    addMessage(text, type = "error") {
        console.error("addm", text, type);
        const {color, icon} = this.getType(type);
        this.alerts.push({nsMsgHstText: text, color, icon, url: location.pathname});
        
        if(type != "success" && type != "ok") {
            this.set.unread = this.state.unread + 1;
        }

        this.refresh();
    }

    openMenu() {
        if (this.state.unread > 0) {
            this.set.unread = 0;
        }
        // this.refresh();
    }

    rnd =(s,p)=> (
        <div className="notifications">
            <div className={this.cln({um: 1, dnone: !this.state.unread})}>{this.state.unread}</div>
            <ButtonMenu className="center" onOpen={() => this.openMenu()} icon={<RIcons.Fa.FaInfoCircle />}>
                {[...this.alerts, ...this.messages].map(m => 
                    <me-nu className={m.color} onClick={e=>this.showMsg(m)}>
                        {m.icon} <span className="text">{typeof m.nsMsgHstText === "string" ? HtmlParser.convert(m.nsMsgHstText) : m.nsMsgHstText}</span>
                    </me-nu>
                )}
            </ButtonMenu>
        </div>
    )
}