import { SComponent, Table, route, Comp } from "../dump";

export class Users extends SComponent {
    new = async () => {
        route("users/0");
    }

    rnd = s => (
        <Comp className="users">
            <Table recordset="zamestnanec" cols="0" detail={rec => route("/users/" + rec.ID)}>
                <bar-button
                    type="add"
                    icon="Plus"
                    text=""
                    click={this.new}
                />
                <tab-button
                    type="primary"
                    icon="Cog"
                    click={rec => rec.reload()}
                >
                    <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                </tab-button>
            </Table>
        </Comp>
    )
}