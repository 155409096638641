import './styl.scss';
import { SComponent, h, _, Ariscat, Btn, RIcons } from "../../dump";
import { MdClose } from 'react-icons/md'

export class Modal extends SComponent {
    state = {
        open: false,
        size: 800, 
    }
    savedState = {};

    saveState = () => {
        const s = this.state;
        this.savedState = {...s};
    }

    back = () => {
        this.setState(this.savedState);
        this.savedState = {};
    }

    close = data => {
        if(this.onClose && this.onClose(this, data) === false) {
            Ref.notify(_.confirmRequiere, 5, "error");
            return false;
        }

        this.onClose = null;

        this.set.open = false;
        this.dialogProm && this.dialogProm(data);
        this.dialogProm = null;
        return data;
    }

    return = data => {
        this.dialogProm && this.dialogProm(data);
        this.dialogProm = null;
        return data;
    }

    hide = () => {
        this.set.open = false;
    }

    show = () => {
        this.set.open = true;
    }

    open = async (ar1, ar2, ar3) => {
        console.log("md", ar1, ar2, ar3);

        //vymazani predchoziho stavu
        if(!this.state.open) {
            this.setState({ 
                jsx1: "", 
                jsx2: "",
                open: false,
            });
            
            await Ariscat.wait(1);
        }

        if (ar1 === undefined && ar2 === undefined) {
            this.setState({ open: true });
        }
        else if (ar2 === undefined) {
            this.setState({ jsx: ar1, open: true });
        }
        else {
            this.setState({ 
                jsx1: ar2, 
                jsx2: ar3, 
                title: ar1, 
                open: true 
            });
        }

        if(!this.dialogProm) {
            return new Promise(rs => this.dialogProm = rs);
        }

        return this.dialogProm;
    }

    set title(txt) { this.set.title = txt; }
    set jsx(jsx) { this.set.jsx1 = jsx1 }
    set size(sz) { this.set.size = sz }

    rnd = (s, p) => {
        return (
            <div id="modalBg" className={this.cln({ open: s.open })}>
                <div id="modal">
                    <header>
                        <h3>{s.title || "Dialog"}</h3>
                        <Btn modalClose onClick={e=> this.close(null)}><RIcons.Fa.FaTimes /></Btn>
                    </header>
                    {s.jsx1 || ""}
                    {s.jsx2 || ""}
                </div>
            </div>
        )
    }
}