import './styl.scss';
import { SComponent, _, Table, route, SDate, Ariscat, Rq, DInput, HSelect, createRef, Checkbox, RInput, If, Comp, Btn } from '../../dump'

export class Alerts extends SComponent {
    async cmount() {
        this.onLoad();
        this.selAlert = {};
        this.state = {};
        this.condIsValid = true;
        this.colsValues = [];

        this.selRefVal = createRef();
        this.selRefOp = createRef();
        this.selRefJoin = createRef();
        this.alertsTab = createRef();
        this.alertsTabLst = createRef();
        this.previewRef = createRef();

        this.rows = [];
        this.cols = [];
        this.devices = [];
        this.deleted = [];

        this.hideCols = [];//Ariscat.session.nsIotAlrcDevdIgnoreCols.split(",").map(col => <tab-col name={col} type="hide" />)

        this.operators = [
            {ID: 0, val: "="},
            {ID: 1, val: "≠"},
            {ID: 2, val: ">"},
            {ID: 3, val: "<"},
            {ID: 4, val: "≥"},
            {ID: 5, val: "≤"},
        ];

        //zmenit na:
        var res = await Rq.sd("iot_device_data", 1, 0, { sDeviceIdLst: 1 });

        var dev = await Rq.sd("iot_device");
        this.devices = dev.rows;

        this.colsValues = res.cols.filter(f => 
            (f.fieldtype == "i4" || f.fieldtype == "fixed") && 
            (f.attrname != "ID")
        ).map((m,i) => { 
            //var name = m.attrname;
            var name = m.attrname.replace("iDevd", "").replace("fDevd", "");
            name= name.charAt(0).toLowerCase() + name.slice(1);
            return {ID: i, val: m.caption, attrname: m.attrname, name}
        })
    }

    loadTable(t) {
        console.log("LT", t.rows);
        this.genPreview(t.rows);
        this.refresh();
    }

    save = async () => {
        var { ID, dAlrCheckMessageAfter, iAlrZarizeniID, nsAlrSubject, nsAlrTo, bAlrIsActive, nsAlrBody, nsAlrName } = this.selAlert;
	
        if(!Ariscat.testEmail(nsAlrTo, true)) return;

        var req = { ID, dAlrCheckMessageAfter, iAlrZarizeniID, nsAlrSubject, nsAlrTo, bAlrIsActive, nsAlrBody, nsAlrName };

        var res1 = await Rq.up("iot_user_alert", 2, req);

        if(res1.rows.length) {
            this.selAlert.ID = res1.rows[0].ID;
        }

        //ZISKA SEZNAM PODMINEK
        var condRows = this.alertsTab.current.rows.map(row => {
            var rowCp = {...row};
            rowCp.iAlrcAlertID = this.selAlert.ID;
            delete rowCp._saved;
            delete rowCp.reload;
            delete rowCp.del
            return rowCp;
        })

        if(ID === undefined) delete req.ID;

        var res2 = {};

        console.log(condRows);

        ////AKTUALIZUJE NEBO VYTVORI PODMINKY
        if(condRows.length) {
            res2 = await Rq.up("iot_user_alert_cond", 2, condRows);;
        }

        var errors = "";

        if(res1.ferror) {
            errors = res1.error;
        }
        if(res2.ferror) {
            errors += " " + res2.error;
        }
        if(errors) {
            return Ref.notify(errors, 6, "error");
        }

        //smaze podminky, ktere uz byly ulozeny v db
        for(let delID of this.deleted) {
            await Rq.del("iot_user_alert_cond", delID);
        }

        this.set.selAlert = null;

        //aktualizuje hlavni tabulku
        this.alertsTabLst.current?.reload();
        Ref.modal.close();
    }

    addAlert() {
        var tab = this.alertsTab.current;
        this.setAlertOrder(tab);
        var newRow = {...tab.rows[tab.rows.length-1]} || {};
        var newOrdNo = Math.max(...tab.rows.map(m => m.iAlrcOrderNo)) + 1;
        if(newOrdNo == -Infinity || newOrdNo == Infinity) newOrdNo = 0;

        if(!newRow.ID) {
            newRow.nsAlrcLeftBrackets = "(";
            newRow.nsAlrcRightBrackets = ")";
            newRow.iAlrcJoinOperator = 0;
            newRow.iAlrcOperator = 0;
            newRow.nsAlrcLeftValue = this.colsValues[0].attrname;
            newRow.nsAlrcRightValue = 0;
        }

        newRow.iAlrcOrderNo = newOrdNo;
        newRow._saved = false;

        delete newRow.ID;

        if(this.selAlert.ID) {
            newRow.iAlrcAlertID = this.selAlert.ID;
        }

        tab.rows.push(newRow);
        tab.refresh();
        this.genPreview();
    }

    removeAlert(r) {
        //this.alertsTab.current.rows.push({});
        var tab = this.alertsTab.current;
        var idx = tab.rows.indexOf(r);
        tab.rows = tab.rows.filter((f,i) => i != idx);

        if(r.ID) {
            this.deleted.push(r.ID);
        }

        this.setAlertOrder(tab);
        this.genPreview();
    }

    setAlertOrder(tab) {
        //nastavi spravny poradi
        [...tab.rows].sort((a,b) => a.iAlrcOrderNo - b.iAlrcOrderNo).forEach((f,i) => f.iAlrcOrderNo = i);
    }

    sortAlert(curRow,index) {
        var tab = this.alertsTab.current;
        var idx = tab.rows.indexOf(curRow);
        var nextIdx = idx + index;
        curRow = {...curRow};
        var nextRow = {...tab.rows[nextIdx]};

        //prohodi hodnoty v tabulce
        tab.rows[idx] = nextRow;
        tab.rows[nextIdx] = curRow;

        nextRow.iAlrcOrderNo = idx;
        curRow.iAlrcOrderNo = nextIdx;
        
        tab.refresh();
        this.genPreview();
        console.log(curRow, nextRow);
    }

    openAlert = async () => {
        return await Ref.modal.open(this.selAlert?.ID ? "Upravit výstrahu" : "Přidat výstrahu",
            <div className="modal-data alertDetail">
                <div className="grid-6">
                    <RInput name="Název" state={this.selAlert} item="nsAlrName" />
                    <DInput name="Kontrolovat od" state={this.selAlert} item="dAlrCheckMessageAfter" />
                    <HSelect 
                        state={this.selAlert} 
                        item="iAlrZarizeniID" 
                        values={this.devices}
                        label="nsDevNazev"
                        uid="ID"
                        name="Zařízení"
                        onChange={(ID,key) => this.selAlert[key] = ID}
                    />
                    <RInput name="Předmět" state={this.selAlert} item="nsAlrSubject" />
                    <RInput name="Komu" state={this.selAlert} item="nsAlrTo" />
                    <Checkbox name="Aktivní" state={this.selAlert} item="bAlrIsActive" />
                </div>
                <RInput name="Zpráva" type="tarea" state={this.selAlert} item="nsAlrBody" />
                <RInput 
                    name="Náhled" 
                    type="tarea" 
                    className={this.cln({error: !this.condIsValid})} 
                    disabled={true} 
                    state={this.selAlert} 
                    item="preview"
                    ref={this.previewRef}
                />
                
                <If is={this.colsValues && this.selAlert}>
                    <Table
                        customCols={true}
                        //cols={this.cols}
                        //rows={this.rows}
                        className="alertsTabDetail"
                        recordset="iot_user_alert_cond"
                        sw_iParentID={this.selAlert.ID}
                        customCols={false}
                        showFilters={false}
                        autoReload={false}
                        onLoad={t=>this.loadTable(t)}
                        ref={this.alertsTab}
                        classTR={(rec,i,tab) => this.cln({first: rec.iAlrcOrderNo == 0, last: rec.iAlrcOrderNo == (tab.rows.length-1) })}
                    >
                        <bar-button 
                            type="add"
                            icon="Plus"
                            text=""
                            click={async ()=> this.addAlert()}
                        />
                        <tab-button
                            type="primary"
                            icon="Cog"
                            click={rec => rec.reload()}
                        >
                            <b-item icon="ArrowUp" className="arrowUp" text="Nahoru" click={r => this.sortAlert(r,-1)} />
                            <b-item icon="ArrowDown" className="arrowDown" text="Dolů" click={r => this.sortAlert(r,1)} />
                            <b-item icon="Trash" text={_.delete} click={r => this.removeAlert(r)} />
                        </tab-button>

                        <tab-col name="nsAlrcLeftBrackets" type="format" format={this.fmLeftBracket} />
                        <tab-col name="nsAlrcRightBrackets" type="format" format={this.fmRightBracket} />
                        <tab-col name="nsAlrcLeftValue" type="format" format={this.fmLeftVal} />
                        <tab-col name="nsAlrcRightValue" type="format" format={this.fmRightVal} />
                        <tab-col name="iAlrcOperator" type="format" format={this.fmRelOp} />
                        <tab-col name="iAlrcJoinOperator" type="format" format={this.fmJoinOp} />
                        {/*...this.hideCols*/}
                    </Table> 
                </If>
            </div>,              
            <div className="modal-buttons">
                <Btn ok id="saveDocument" className="fas fa-check" onClick={this.save}>{" " + _.save}</Btn>
                <Btn light id="closeDocument" onClick={() => this.set.selAlert = null}>{_.close}</Btn>
            </div>
        );
    }

    fmJoinOp = (rec, col) => {
        return (
            <HSelect
                ref={this.selRefJoin}
                state={rec} 
                style={{width: "auto"}}
                values={[
                    {ID: 0, val: "A zároveň"},
                    {ID: 1, val: "Nebo"},
                ]}
                item={col.attrname}
                uid="ID"
                label="val" 
                onChange={(val,key)=> {
                    rec[key] = val;
                    this.genPreview();
                }}
            ></HSelect>
        )
    }

    fmRelOp = (rec, col) => {
        return (
            <HSelect
                state={rec} 
                ref={this.selRefOp}
                style={{width: "auto"}}
                values={this.operators}
                uid="ID"
                item={col.attrname}
                label="val" 
                onChange={(val,key)=> {
                    rec[key] = val;
                    this.genPreview()
                }}
            ></HSelect>
        )
    }

    fmLeftVal = (rec, col) => {
        return (
            <HSelect
                ref={this.selRefVal}
                state={rec} 
                style={{width: "auto"}}
                values={this.colsValues}
                item={col.attrname}
                label="val" 
                uid="attrname"
                onChange={(val,key)=> {
                    rec[key] = val;
                    this.genPreview()
                }}
            ></HSelect>
        )
    }

    fmRightVal = (rec, col) => {
        return (
            <RInput
                type="number"
                state={rec} 
                regexp="^-?\d+([\.\,]\d+)?$"
                item={col.attrname}
                onChange={()=>this.genPreview()}
            />
        )
    }

    fmLeftBracket = (rec, col) => {
        return (
            <RInput
                state={rec} 
                item={col.attrname}
                regexp='^\(*$'
                onChange={()=>this.genPreview()}
            />
        )
    }
    
    fmRightBracket = (rec, col) => {
        return (
            <RInput
                state={rec} 
                item={col.attrname}
                regexp='^\)*$'
                onChange={()=>this.genPreview()}
            />
        )
    }
    
    genPreview =(rows)=> {
        var joinOperators = ["&", "|"];
        
        //console.log("genprev", rows);

        if(!rows) {
            rows = this.alertsTab.current ? [...this.alertsTab.current.rows] : [];
        }

        var preview = rows.sort().sort((a,b) => a.iAlrcOrderNo - b.iAlrcOrderNo).map((m,i) => 
            (m.nsAlrcLeftBrackets || "") + 
            ((m.nsAlrcLeftValue || "?") + " ") +
            ((this.operators[m.iAlrcOperator || 0].val || "=") + " ") +
            ((m.nsAlrcRightValue === null || m.nsAlrcRightValue === undefined || m.nsAlrcRightValue == "") ? 0 : m.nsAlrcRightValue) + 
            ((m.nsAlrcRightBrackets || "") + " ") + 
            (i+1 == this.alertsTab.current.rows.length ? "" : joinOperators[m.iAlrcJoinOperator || 0])
        ).join(" ");

        this.condIsValid = this.isConditionValid(preview);

        this.selAlert.preview = preview;
        this.previewRef?.current?.refresh();
        this.alertsTab?.current?.refresh();
    }

    editAlert =async rec=> {
        this.deleted = [];

        this.selAlert = rec;
        this.genPreview();;

        if(this.alertsTab.current) await this.alertsTab.current.reload();
        this.genPreview();
        this.openAlert();
    }

    newAlert =async ()=> {
        this.deleted = [];

        this.selAlert = { 
            dAlrCheckMessageAfter: SDate(), 
            iAlrZarizeniID: (this.devices[0] || {}).ID,
            nsAlrSubject: "", 
            nsAlrTo: "", 
            bAlrIsActive: true,
            nsAlrBody: "",
            nsAlrName: "",
        };
        this.genPreview();  
        this.openAlert();
    }

    isConditionValid(condition) {
        let parentheses = [];
      
        for(let i = 0; i < condition.length; i++) {
          if(condition[i] === "(") {
            parentheses.push(condition[i]);
          } 
          else if(condition[i] === ")") {
            if(parentheses.length === 0) {
              return false;
            } 
            else {
              parentheses.pop();
            }
          }
        }
      
        return parentheses.length === 0;
    }

    rnd(s, p) {
        return (
        <Comp className="alerts">
            {/*this.alertsDialog()*/}
            <Table 
                recordset="iot_user_alert" 
                cols="0" 
                ref={this.alertsTabLst}
                autoReload={false}
                detail={this.editAlert}
            >
                <bar-button 
                    type="add"
                    icon="Plus"
                    text=""
                    click={this.newAlert}
                />
                <tab-button
                    type="primary"
                    icon="Cog"
                    click={rec => rec.reload()}
                >
                    <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                </tab-button>
            </Table>
        </Comp>
        )
    }
}