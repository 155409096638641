import { ButtonMenu } from '../components/buttonMenu/buttonMenu';
import { SComponent, _, Table, route, Repo, Rq, Ref, Comp } from '../dump'

export class Deals extends SComponent {
    cmount() {
    }

    new = async () => {
        const idZak = await Rq.newRecord("zakazka", {bZakAktivni: true});
        route("/deals/" + idZak);
    }

    rnd = (s, p) => {
        return (
            <Comp className="deals">
                <Table recordset="zakazka" cols="0" detail={rec => route("/deals/" + rec.ID)}>
                    <bar-button
                        type="add"
                        icon="Plus"
                        text=""
                        visible={() => Repo.stg.bAnyVrbcJoined}
                        click={async e => {

                            const idZak = await Rq.newRecord("zakazka", {bZakAktivni: true});
                            route("/deals/" + idZak);
                        }}
                    />
                    <tab-button
                        type="warning"
                        icon="Edit"
                        disabled={() => !Repo.stg.bAnyVrbcJoined}
                        link={rec => "r/deal/" + rec.ID}
                    />
                    <tab-button
                        type="primary"
                        icon="Cog"
                        click={async rec => {
                            //await Ariscat.app.updateDuplicateRecordById(true, "zakazka", rec.ID, '', '');
                            rec.reload();
                        }}
                    >
                        <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                    </tab-button>
                </Table>
            </Comp>
        )
    }
}