/*
Třída která převede JSON na XML datapacket a odpověď zase převede zpět na JSON.
Vrátí objekt ve kterém jsou sloupce, řádky, info o chybách a další informace.

POUŽITÍ:
  Získá sloupce a řádky, 0 = neodešle hlavičku, 1 = odešle (hlavička je definovaná v této třídě)
  const {cols, rows} = await Rq.sd("zamestnanec", 0);
  
  Parametry: recordset, sloupce, řádky, switche (v JSONu)

  Hlavička se může vložit i přímo do parametru (v JSONu):
  const fields = [
      'ID', 'bAttOdVyrobce',
      ['nsAttPozn', { fieldtype: "bin.hex", SUBTYPE: "WideText" }],
      ['nsAttZmenil', { width: 102}],
  ]
  const r = await Rq.sd('attchm', fields, 0, { iPOID: this.iPOID });

  Datové typy se automaticky převedou podle 1-2 písmen nebo zadáním typu - např: { fieldtype: "dateTime"}

  Aktualizace záznamu: 
    - "row" obsahuje data k aktualizaci,
    - když zadám 2, doplní se jenom sloupce z 1. záznamu a nepošlou se všechny jak u 1
    await Rq.up("kalendar_m", 2, row, { iZakID: row.iKalZakID, iDruhZaznamu: row.iKalDruhZaznamu });

*/
import { Ref, Repo } from "../dump";
import { Datapacket } from "./Datapacket";

export class Rq {
  //Send = GetRecordList - získání dat
	static async sd(recordset, cols, rows, rqSwitches = {}, action = "g") {
    const sw = JSON.stringify(rqSwitches);
    const rcsw = recordset + " " + sw;

    if(Repo.cache[rcsw] && !rqSwitches.frReload && action == "g") {
      Repo.cache[rcsw].cached = true;
      //Ref.Header.ok();
      return Repo.cache[rcsw];
    }
    else if(action != "g") {
      console.log("del", action);
      delete Repo.cache[rcsw];
    }

    Ref.Loading?.loading();

    if(cols == 1 || (cols == 2 && !rows)) {//automaticky se pridaji vsechny sloupce
      cols = this.fd[recordset] || "";
      rqSwitches.bTemporaryFields = 1;
    }
    if(cols == 2 && rows) {//automaticky se pridaji sloupce z 1. zaznamu
      const keys = Object.keys(Array.isArray(rows) ? rows[0] : rows);
      cols = this.fd[recordset] || {};
      cols = keys.map(key => cols.find(f => typeof f === "string" ? (f === key) : key === f[0]));
      console.log("cols", cols);
      rqSwitches.bTemporaryFields = 1;
    }
    else if(cols == 10) {//stejne jak 1, ale neposle se temporary
      cols = this.fd[recordset] || "";
    }

    let DP = new Datapacket(cols, 0, recordset, rqSwitches);
    
    if(rows && Array.isArray(rows)) DP.addRows(rows);
    if(rows && !Array.isArray(rows)) DP.addRow(rows);

    const res = DP.send(action);

    if((window.localStorage||{}).debug == 1) {
      const rs = await res;
      const obj = {
        rows: rs.rows,
        res: rs
      }

      console.log("DP", action, recordset, rs);
    }

    var ares = await res;

    if(!ares.ferror && action == "g") {
      Repo.cache[rcsw] = ares;
      Repo.cache[rcsw].cached = false;
    }
      
    if(ares.ferror) {
      Ref.Loading?.error();
      if(!ares.catchError) {
        var errorMsg = ares.error.filter(f => !["noRowdata", "noMetadata", "emptyCols", "emptyRows"].includes(f)).join(", ");
        if(!this.onError(ares, errorMsg, recordset, rqSwitches)) {
          Ref.notify(errorMsg, 10, "error");
        }
      }
    }
    else if(!ares.ferror) Ref.Loading?.ok()

		return res;
  }
  
  //Update = UpdateRecordList - aktualizace dat
	static up(recordset, cols, rows, rqSwitches = {}) {
    this.deleteCache(recordset);
    return this.sd(recordset, cols, rows, rqSwitches, "update");
  }

  static async reset(recordset, rqSwitches) {
    return this.sd(recordset, 0, [], {...rqSwitches, bResetColumns: 1}, "g");
  }

  static async del(recordset, recID, okMsg) {
    this.deleteCache(recordset);
    const res = await Datapacket.deleteRecord(recordset, recID);

    if(res === true) {
      Ref.notify(okMsg || _.recDelSucf.replace("%1", recID), 4, "success");
    }
    else if(res === null) {
      Ref.notify(_.recColNotDel.replace("%1", recID), 10, "error");
    }
    else if(res?.error) {
      Ref.notify(res.result, 10, "error");
    }

    return res;
  }

  static async info(...args) {
    Ref.Loading?.loading();

    const info = await Datapacket.getRecInfo(...args);

    if(info.error) {
      Ref.Loading?.error();

      if(!info.catchError) {
        Ref.notify(info.error, 10, "error");
      }
    }
    else if(!info.error) Ref.Loading?.ok()


    if((window.localStorage||{}).debug == 1) {
      console.log("DP Info", ...args, info);
    }

    return info;
  }

  static async duplicateRecord(recordset, ID, row = {}, switches = {}) {
    var cols = this.fd[recordset] || {};
    switches.bTemporaryFields = 1;

    let DP = new Datapacket(cols, 0, recordset, switches);
    DP.addRow(row);

    const info = await DP.duplicateRecord(ID);

    return info;
  }

  static async newRecord(recordset, data, sw = {}) {
    const r = await Rq.up(recordset, 2, data, sw);

    return r.erc ? 0 : r.rows[0].ID;
  }

  //vlastni reakce na chyby pri zpracovani pozadavku
  static onError(res, errorMsg, recordset, rqSwitches) {

  }

  /*
  static async uploadFile(file) {
    var formData = new FormData();
    formData.append('photo', file);
  
    const res = await $.ajax({
        type: "POST",
        url: '/functions/app.uploadUserPhoto.php',
        data: formData,
        processData: false,
        contentType: false,
        async: true,
        cache: false
    });

    return res ? JSON.parse(res) : { success: false, error: true }
  }
  */

  static showError(res, msg) {
    //console.log("sh", res);

    if(this.onError(res, msg)) return;

    if(res.ferror) {
      Ref.notify(res.error, 4, "error");
      return false;
    }
    else if(msg) {
      Ref.notify(msg, 4, "success");
    }
    return true;
  }


  static deleteCache(recordset) {
    Object.keys(Repo.cache)
      .filter(f => f.includes(recordset + " "))
      .forEach(f => delete Repo.cache[f])
  }

  static async delConfirm(recordset, rowOrId) {
    var res = await Ref.confirm.open("Info", _.deleteRecordConfirm);
    var id = rowOrId.ID;

    if(!rowOrId?.ID) id = rowOrId;

    if(res) {
        const r = await Rq.del(recordset, id);
        // r.error ? Ref.notify(r.result, 5, "error") : this.getData();
    }
  }

  //Hlavičky na 1 místě
  static get fd() {   
    return {
      zamestnanec: [
        'ID',
        'bZamAktivni',
        'bZamJeAdmin',
        'bZamJeMonter',
        'bZamUsesGoogleCal',
        'bZamVidiGlobStat',
        'bZamVidiZakazkyJinychObchodniku',
        'dZamPorizeno',
        'dZamZmeneno',
        'iZamCislo',
        'iZamPobockaID',
        'ieZamTypVychoziUdalosti',
        ['nsZamGoogleHeslo', 200],
        ['nsZamGoogleLogin', 200],
        ['nsZamHeslo', 100],
        ['nsZamHexColor', 20],
        ['nsZamLogin', 100],
        ['nsZamMistoDopravy', 200],
        ['nsZamNazev', 200],
        ['nsZamPictureURL', 1000],
        ['nsZamPoridil', 100],
        ['nsZamPozn', 2000],
        ['nsZamPublicKod', 'Guid'],
        ['nsZamTelefon', 100],
        ['nsZamUzivZkratka', 100],
        ['nsZamZmenil', 100],
      ],
      kalendar_m: [
        "ID","ieKalTyp","iKalZakID","iKalMonterID", "bKalZaplanovat", 'ieKalStav',
        "iKalDruhZaznamu",
        'dKalPorizeno',
        'dKalZmeneno',
        ["nsKalNazevJednani", 512],
        ["nsKalMistoRealizace", 2002],
        ["nsKalUcastnici", 2002],
        ["nsKalZapis", { ftype: "wt" }],
        ["nsKalDatumOd", 102],
        ["nsKalDatumDo", 102],
        ["nsKalPozn", 2002],
        ['nsKalPoridil', 102],
        ['nsKalZmenil', 102],
        /*['nsKalStav', 102],*/
      ],
      zakazka: [
        'ID',
        'UstID',
        'bOrgAktivni',
        'bZakAktivni',
        'bZakReadOnly',
        'dOrgDatumNarozeni',
        'dOrgPorizeno',
        'dOrgZmeneno',
        'dUstPorizeno',
        'dUstZmeneno',
        'dZakDatum',
        'dZakPorizeno',
        'dZakTerminPozad',
        'dZakZmeneno',
        'fOrgDefPrcSleva_Prd',
        'fOrgDefPrcSleva_Pripl',
        'fOrgDefPrcSleva_Prisl',
        'iOrgCislo',
        'iOrgID',
        'iOrgLangID',
        'iUstUzivData',
        'iZakObchodnikID',
        'iZakOrgID',
        'iZakUzivStav',
        'iZakZdrojPOID',
        'ieZakStav',
        'iZakPobockaID',
        ['nsOrgAdresa', 202],
        ['nsOrgDIC', 32],
        ['nsOrgEmail', 102],
        ['nsOrgFAX', 102],
        ['nsOrgGPSLati', 42],
        ['nsOrgGPSLong', 42],
        ['nsOrgICO', 42],
        ['nsOrgKontOsoba', 202],
        ['nsOrgLangDescr', 102],
        ['nsOrgMena', 8],
        ['nsOrgMesto', 102],
        ['nsOrgNazev', 202],
        ['nsOrgPSC', 22],
        ['nsOrgPoridil', 102],
        ['nsOrgPozn', 2002],
        ['nsOrgStat', 82],
        ['nsOrgTelefon', 102],
        ['nsOrgZmenil', 102],
        ['nsOrgZpusZaslFakt', 102],
        ['nsUstPoridil', 102],
        ['nsUstPozn', 8002],
        ['nsUstStav', 202],
        ['nsUstUzivData', 8002],
        ['nsUstZmenil', 102],
        ['nsZakCislo', 62],
        ['nsZakDodaciAdr', 202],
        ['nsZakGlobStav', 102],
        ['nsZakKomentProdej', 8002],
        ['nsZakObchodnik', 202],
        ['nsZakOznaceni', 102],
        ['nsZakPoridil', 102],
        ['nsZakStav', 102],
        ['nsZakZdrojCisObj', 32],
        ['nsZakZdrojIntCis', 62],
        ['nsZakZmenil', 102],
      ],
      dealer_pob: [
        'ID',
        'bPobAktivni',
        'dPobPorizeno',
        'dPobZmeneno',
        'iPobCislo',
        ['nsPobEmail', 102],
        ['nsPobNazev', 102],
        ['nsPobPoridil', 102],
        ['nsPobPozn', 2002],
        ['nsPobTelefon', 102],
        ['nsPobZmenil', 102],
      ],
      organizace: [
        'ID',
        'bOrgAktivni',
        'dOrgDatumNarozeni',
        'dOrgPorizeno',
        'dOrgZmeneno',
        'fOrgDefPrcSleva_Prd',
        'fOrgDefPrcSleva_Pripl',
        'fOrgDefPrcSleva_Prisl',
        'iOrgCislo',
        'iOrgLangID',
        ['nsOrgAdresa', 600],
        ['nsOrgDIC', 32],
        ['nsOrgEmail', 102],
        ['nsOrgFAX', 102],
        ['nsOrgGPSLati', 42],
        ['nsOrgGPSLong', 42],
        ['nsOrgICO', 42],
        ['nsOrgKontOsoba', 202],
        ['nsOrgLangDescr', 102],
        ['nsOrgMena', 8],
        ['nsOrgMesto', 102],
        ['nsOrgNazev', 202],
        ['nsOrgPSC', 22],
        ['nsOrgPoridil', 102],
        ['nsOrgPozn', 2002],
        ['nsOrgStat', 82],
        ['nsOrgTelefon', 102],
        ['nsOrgZmenil', 102],
        ['nsOrgZpusZaslFakt', 102],
      ],
      obj_cen_rekap: [
        'ID',
        'bMenaAktivni',
        'bOrgAktivni',
        'bPopAktivni',
        'bPopAnyMissingDV',
        'bPopEveryDivHasItem',
        'bPopPrdjReadOnly',
        'bPopReadOnly',
        'dMenaPorizeno',
        'dMenaZmeneno',
        'dOrgDatumNarozeni',
        'dOrgPorizeno',
        'dOrgZmeneno',
        'dPopDatum',
        'dPopPorizeno',
        'dPopTerminPozad',
        'dPopZmeneno',
        'fMenaRealKurz',
        'fOrgDefPrcSleva_Prd',
        'fOrgDefPrcSleva_Pripl',
        'fOrgDefPrcSleva_Prisl',
        'fPopBalne',
        'fPopCenaBezDPH',
        'fPopCenaBezDPHOsvob',
        'fPopCenaBezDPHPozic',
        'fPopCenaBezDPHSnizena',
        'fPopCenaBezDPHZaklad',
        'fPopCenaDPHSnizena',
        'fPopCenaDPHZaklad',
        'fPopCenaSDPH',
        'fPopCenaZaZamereni',
        'fPopCenaZaloha',
        'fPopDopravne',
        'fPopHmotnost',
        'fPopKurz',
        'fPopMetraz',
        'fPopOstSaP_abs',
        'fPopOstSaP_proc',
        'fPopPostovne',
        'fPopPrdjPrijateZalohy_sDPH',
        'fPopPrdjProdejKuNakupMenaKurz',
        'fPopPrdjSazbaDPH',
        'fPopPrdjTotal_DPH',
        'fPopPrdjTotal_bezDPH',
        'fPopPrdjTotal_sDPH',
        'fPopPrdjZbyvaUhradit',
        'fPopSazbaSn',
        'fPopSazbaZk',
        'fPopZbyvaUhradit',
        'iMenaID',
        'iMenaZaokr',
        'iOrgCislo',
        'iOrgID',
        'iOrgLangID',
        'iPopCenikID',
        'iPopDealerAddrID',
        'iPopMenaID',
        'iPopObchodnikID',
        'iPopOrgID',
        'iPopParentZak',
        'iPopPrdjLangID',
        'iPopPrdjMenaID_Prodej',
        'iPopPreDPH',
        'iPopUzivStav',
        'iPopVrbcID',
        'iPopZdrojPOID',
        'iePopStav',
        ['nsPopVrbcKod', 'Guid'],
        ['nsMenaKod', 8],
        ['nsMenaPopis', 42],
        ['nsMenaPoridil', 102],
        ['nsMenaZeme', 102],
        ['nsMenaZmenil', 102],
        ['nsOrgAdresa', 202],
        ['nsOrgDIC', 32],
        ['nsOrgEmail', 102],
        ['nsOrgFAX', 102],
        ['nsOrgGPSLati', 42],
        ['nsOrgGPSLong', 42],
        ['nsOrgICO', 42],
        ['nsOrgKontOsoba', 202],
        ['nsOrgLangDescr', 102],
        ['nsOrgMena', 8],
        ['nsOrgMesto', 102],
        ['nsOrgNazev', 202],
        ['nsOrgPSC', 22],
        ['nsOrgPoridil', 102],
        ['nsOrgPozn', 2002],
        ['nsOrgStat', 82],
        ['nsOrgTelefon', 102],
        ['nsOrgZmenil', 102],
        ['nsOrgZpusZaslFakt', 102],
        ['nsPopCislo', 102],
        ['nsPopDalsiOznaceni', 102],
        ['nsPopDodaciAdr', 202],
        ['nsPopIntCislo', 62],
        ['nsPopKomentProdej', 8002],
        ['nsPopObchodnik', 202],
        ['nsPopOznaceni', 102],
        ['nsPopParentZak', 42],
        ['nsPopPath', 102],
        ['nsPopPoridil', 102],
        ['nsPopPrdjMena_Prodej', 8],
        ['nsPopStav', 102],
        ['nsPopVrbcNazev', 202],
        ['nsPopVyrobky', 202],
        ['nsPopXMLCenRekap', 8002],
        ['nsPopXMLPDFInfo', 8002],
        ['nsPopZdrojCisObj', 32],
        ['nsPopZdrojIntCis', 62],
        ['nsPopZmenil', 102],
      ],
      produkt: [
        'ID',
        'bPrdDummyRcmp',
        'bPrdIsPM',
        'blbPrdThumb',
        'fPrdMarzePrc',
        'fPrdPrcKeKoncCeniku_Prd',
        'fPrdPrcKeKoncCeniku_Pripl',
        'fPrdPrcKeKoncCeniku_Prisl',
        'iPrdDealMenaID',
        'iPrdMaskNo',
        'iPrdT10ID',
        'iPrdVrbcID',
        'iPrdZarukaM',
        'nsPrdKod',
        'nsPrdVrbcKod',
        ['nsPrdDealMenaKod', 8],
        ['nsPrdInpJSBT', 'WideText'],
        ['nsPrdNazev', 512],
        ['nsPrdNazevTrans', 8002],
        ['nsPrdPrdObecnyPopis', 8002],
        ['nsPrdPrdTextZaruky', 8002],
        ['nsPrdSkupVyr', 102],
        ['nsPrdVrbcNazev', 202],
        ['nsPrdZkratka', 102],
        ['nsPrdZkratkaTrans', 8002],
      ],
      dvgroupLinks: [
        'ID',
        'dGrlPorizeno',
        'dGrlZmeneno',
        'iGrlPoradi',
        'iGrlSkupinaID',
        'iGrlVyrobekID',
        ['nsGrlPoridil', 102],
        ['nsGrlSkupina', 202],
        ['nsGrlVyrobek', 202],
        ['nsGrlZmenil', 102],
      ],
      produkt_poz: [
        'ID',
        'bPrdDummyRcmp',
        'bPrdIsPM',
        'blbPrdThumb',
        'fPrdMarzePrc',
        'fPrdPrcKeKoncCeniku_Prd',
        'fPrdPrcKeKoncCeniku_Pripl',
        'fPrdPrcKeKoncCeniku_Prisl',
        'iPrdDealMenaID',
        'iPrdMaskNo',
        'iPrdT10ID',
        'iPrdVrbcID',
        'iPrdZarukaM',
        'nsPrdKod',
        'nsPrdVrbcKod',
        ['nsPrdDealMenaKod', 8],
        ['nsPrdNazev', 512],
        ['nsPrdNazevTrans', 8002],
        ['nsPrdPrdObecnyPopis', 8002],
        ['nsPrdPrdTextZaruky', 8002],
        ['nsPrdSkupVyr', 102],
        ['nsPrdVrbcNazev', 202],
        ['nsPrdZkratka', 102],
        ['nsPrdZkratkaTrans', 8002],
      ],
      casc_setting: [
        'ID',
        'bStAktivni',
        'bStEditable',
        'bStMakeReadOnly',
        'bStOwned',
        'dStHodnota',
        'dStPlatnostDo',
        'dStPorizeno',
        'dStZmeneno',
        'fStHodnota',
        'iStHodnota',
        'iStPriorita',
        'nsStTyp',
        ['nsStHodnota', 8000],
        ['nsStName', 510],
        ['nsStPoridil', 100],
        ['nsStZmenil', 100],
      ],
      zakuzivstav: [
        'ID',
        'bUstOdVyrobce',
        'bUstReadOnly',
        'dUstPorizeno',
        'dUstZmeneno',
        'iUstUzivData',
        ['nsUstPoridil', 100],
        ['nsUstPozn', 8000],
        ['nsUstStav', 200],
        ['nsUstUzivData', 8000],
        ['nsUstZmenil', 100],
      ],
      obj_cen_rekap: [
        'ID',
        'bOcrAktivni',
        'bOcrDirtyPPS',
        'bOcrDirtySlevaAbs',
        'bOcrForbidden',
        'bOcrHiddenToCust',
        'bOcrKusPripl',
        'bOcrKusPrisl',
        'bOcrReadOnly',
        'bOcrZeroCosts',
        'dOcrPorizeno',
        'dOcrZmeneno',
        'fOcrCenikVNakMene_JC',
        'fOcrMarze',
        'fOcrMnozstvi',
        'fOcrNaklVNakMene_JC',
        'fOcrNaklVProdMene_CC',
        'fOcrNaklVProdMene_JC',
        'fOcrNavyseniCeny_Prc',
        'fOcrNavyseniCeny_Prc_BACKUP',
        'fOcrProdejPoSleve_CC',
        'fOcrProdejPoSleve_JC',
        'fOcrProdejPredSlevou_CC',
        'fOcrProdejPredSlevou_JC',
        'fOcrSlevaCelkem',
        'fOcrSleva_Abs',
        'fOcrSleva_Abs_BACKUP',
        'fOcrSleva_Prc',
        'fOcrSleva_Prc_BACKUP',
        'iOcrKusSKID',
        'iOcrLevel',
        'iOcrPerAgendaID',
        'iOcrPopObjID',
        'iOcrPozID',
        'iOcrSectionNo',
        'iOcrSequenceNo',
        'iOcrVrbcID',
        ['nsOcrAgenda', 100],
        ['nsOcrLevelDesc', 100],
        ['nsOcrNazevTrans', 8000],
        ['nsOcrParentPop', 40],
        ['nsOcrParentZak', 40],
        ['nsOcrPath', 100],
        ['nsOcrPerAgendaKod', 'Guid'],
        ['nsOcrPoridil', 100],
        ['nsOcrUserData', 100],
        ['nsOcrVrbcKod', 'Guid'],
        ['nsOcrVrbcNazev', 200],
        ['nsOcrZmenil', 100],
      ],
      iot_device_data: [
        'ID',
        'dDevdCasZpravy',
        'dDevdPorizeno',
        'dDevdZmeneno',
        ['fDevdTemperature', { fieldtype: "fixedFMT" }],
        'iDevdAverageCurrent',
        'iDevdCountSetSwitch1',
        'iDevdCountSetSwitch2',
        'iDevdCurrentLimit',
        'iDevdDetectRain',
        'iDevdDisableCurrentLimit',
        'iDevdDisableTemperature',
        'iDevdErrorCommunication_0',
        'iDevdErrorCommunication_1',
        'iDevdErrorCommunication_2',
        'iDevdNumberOfCycleDown',
        'iDevdNumberOfCycleUp',
        'iDevdNumberOfImpulseDown',
        'iDevdNumberOfImpulseUp',
        'iDevdTemperatureError',
        'iDevdVersionMajor',
        'iDevdVersionMinor',
        'iDevdZarzeniID',
        ['nsDevdPoridil', 100],
        ['nsDevdZmenil', 100],
      ],
      iot_user_alert_cond: [
        'ID',
        'dAlrcPorizeno',
        'dAlrcZmeneno',
        'iAlrcAlertID',
        'iAlrcJoinOperator',
        'iAlrcOperator',
        'iAlrcOrderNo',
        ['nsAlrcLeftBrackets', 40],
        ['nsAlrcLeftValue', 256],
        ['nsAlrcPoridil', 100],
        ['nsAlrcRightBrackets', 40],
        ['nsAlrcRightValue', 256],
        ['nsAlrcZmenil', 100],
      ],
      iot_user_alert: [
        'ID',
        'bAlrIsActive',
        'dAlrCheckMessageAfter',
        'dAlrPorizeno',
        'dAlrZmeneno',
        'dDevPorizeno',
        'dDevZmeneno',
        'iAlrZarizeniID',
        'iDevID',
        ['nsAlrBody', 8000],
        ['nsAlrDescription', 510],
        ['nsAlrName', 100],
        ['nsAlrPoridil', 100],
        ['nsAlrSubject', 510],
        ['nsAlrTo', 510],
        ['nsAlrZmenil', 100],
        ['nsDevKodZarizeni', 100],
        ['nsDevNazev', 100],
        ['nsDevPopis', 510],
        ['nsDevPoridil', 100],
        ['nsDevSkupina', 100],
        ['nsDevZmenil', 100],
      ],
      poptavka: [
        'ID',
        'bMenaAktivni',
        'bOrgAktivni',
        'bPopAktivni',
        'bPopAnyMissingDV',
        'bPopEveryDivHasItem',
        'bPopPrdjPrnShowAdvPaymDisc',
        'bPopPrdjReadOnly',
        'bPopReadOnly',
        'dMenaPorizeno',
        'dMenaZmeneno',
        'dOrgDatumNarozeni',
        'dOrgPorizeno',
        'dOrgZmeneno',
        'dPopDatum',
        'dPopPorizeno',
        'dPopTerminPozad',
        'dPopZmeneno',
        'fMenaRealKurz',
        'fOrgDefPrcSleva_Prd',
        'fOrgDefPrcSleva_Pripl',
        'fOrgDefPrcSleva_Prisl',
        'fPopBalne',
        'fPopCenaBezDPH',
        'fPopCenaBezDPHOsvob',
        'fPopCenaBezDPHPozic',
        'fPopCenaBezDPHSnizena',
        'fPopCenaBezDPHZaklad',
        'fPopCenaDPHSnizena',
        'fPopCenaDPHZaklad',
        'fPopCenaSDPH',
        'fPopCenaZaZamereni',
        'fPopCenaZaloha',
        'fPopDopravne',
        'fPopHmotnost',
        'fPopKurz',
        'fPopMetraz',
        'fPopOstSaP_abs',
        'fPopOstSaP_proc',
        'fPopPostovne',
        'fPopPrdjPrijateZalohy_sDPH',
        ['fPopPrdjProdejKuNakupMenaKurz', { fieldtype: "fixedFMT", DECIMALS: 12, WIDTH: 32 }],
        'fPopPrdjSazbaDPH',
        'fPopPrdjTotal_DPH',
        'fPopPrdjTotal_bezDPH',
        'fPopPrdjTotal_sDPH',
        'fPopPrdjZbyvaUhradit',
        'fPopSazbaSn',
        'fPopSazbaZk',
        'fPopZbyvaUhradit',
        'iMenaID',
        'iMenaZaokr',
        'iOrgCislo',
        'iOrgID',
        'iOrgLangID',
        'iPopCenikID',
        'iPopDealerAddrID',
        'iPopMenaID',
        'iPopObchodnikID',
        'iPopOrgID',
        'iPopParentZak',
        'iPopPrdjLangID',
        'iPopPrdjMenaID_Prodej',
        'iPopPreDPH',
        'iPopUzivStav',
        'iPopVrbcID',
        'iPopZdrojPOID',
        'iePopStav',
        ['nsMenaKod', 6],
        ['nsMenaPopis', 40],
        ['nsMenaPoridil', 100],
        ['nsMenaZeme', 100],
        ['nsMenaZmenil', 100],
        ['nsOrgAdresa', 200],
        ['nsOrgDIC', 30],
        ['nsOrgEmail', 200],
        ['nsOrgFAX', 100],
        ['nsOrgGPSLati', 40],
        ['nsOrgGPSLong', 40],
        ['nsOrgICO', 40],
        ['nsOrgKontOsoba', 200],
        ['nsOrgLangDescr', 100],
        ['nsOrgMena', 6],
        ['nsOrgMesto', 200],
        ['nsOrgNazev', 200],
        ['nsOrgPSC', 20],
        ['nsOrgPoridil', 100],
        ['nsOrgPozn', 2000],
        ['nsOrgStat', 200],
        ['nsOrgTelefon', 200],
        ['nsOrgZmenil', 100],
        ['nsOrgZpusZaslFakt', 100],
        ['nsPopCSVImpErrLst', 510],
        ['nsPopCislo', 100],
        ['nsPopDalsiOznaceni', 100],
        ['nsPopDodaciAdr', 510],
        ['nsPopIntCislo', 60],
        ['nsPopKomentProdej', 8000],
        ['nsPopObchodnik', 200],
        ['nsPopOznaceni', 80],
        ['nsPopParentZak', 40],
        ['nsPopPath', 100],
        ['nsPopPoridil', 100],
        ['nsPopPrdjMena_Prodej', 6],
        ['nsPopPrdjPrnFooterFR3', 'WideText'],
        ['nsPopPrdjPrnFooterFR3_100', 200],
        ['nsPopStav', 100],
        ['nsPopVrbcKod', 'Guid'],
        ['nsPopVrbcNazev', 200],
        ['nsPopVyrobky', 2000],
        ['nsPopXMLCenRekap', 8000],
        ['nsPopXMLPDFInfo', 8000],
        ['nsPopZdrojCisObj', 30],
        ['nsPopZdrojIntCis', 60],
        ['nsPopZmenil', 100],
      ],
      objednavka: [
        'ID',
        'bMenaAktivni',
        'bObjAktivni',
        'bObjAnyMissingDV',
        'bObjEveryDivHasItem',
        'bObjPrdjPrnShowAdvPaymDisc',
        'bObjPrdjReadOnly',
        'bObjReadOnly',
        'bOrgAktivni',
        'dMenaPorizeno',
        'dMenaZmeneno',
        'dObjDatum',
        'dObjImported',
        'dObjKonecVyr',
        'dObjPorizeno',
        'dObjTerminDodani',
        'dObjTerminPozad',
        'dObjZmeneno',
        'dOrgDatumNarozeni',
        'dOrgPorizeno',
        'dOrgZmeneno',
        'fMenaRealKurz',
        'fObjBalne',
        'fObjCenaBezDPH',
        'fObjCenaBezDPHOsvob',
        'fObjCenaBezDPHPozic',
        'fObjCenaBezDPHSnizena',
        'fObjCenaBezDPHZaklad',
        'fObjCenaDPHSnizena',
        'fObjCenaDPHZaklad',
        'fObjCenaSDPH',
        'fObjCenaZaZamereni',
        'fObjCenaZaloha',
        'fObjDopravne',
        'fObjHmotnost',
        'fObjKurz',
        'fObjMetraz',
        'fObjOstSaP_abs',
        'fObjOstSaP_proc',
        'fObjPostovne',
        'fObjPrdjPrijateZalohy_sDPH',
        ['fObjPrdjProdejKuNakupMenaKurz',  { fieldtype: "fixedFMT", DECIMALS: 12, WIDTH: 32 }],
        'fObjPrdjSazbaDPH',
        'fObjPrdjTotal_DPH',
        'fObjPrdjTotal_bezDPH',
        'fObjPrdjTotal_sDPH',
        'fObjPrdjZbyvaUhradit',
        'fObjSazbaSn',
        'fObjSazbaZk',
        'fObjZbyvaUhradit',
        'fOrgDefPrcSleva_Prd',
        'fOrgDefPrcSleva_Pripl',
        'fOrgDefPrcSleva_Prisl',
        'iMenaID',
        'iMenaZaokr',
        'iObjCenikID',
        'iObjDealerAddrID',
        'iObjMenaID',
        'iObjObchodnikID',
        'iObjOrgID',
        'iObjParentPop',
        'iObjParentZak',
        'iObjPrdjLangID',
        'iObjPrdjMenaID_Prodej',
        'iObjPreDPH',
        'iObjUzivStav',
        'iObjVrbcID',
        'iObjZdrojPOID',
        'iOrgCislo',
        'iOrgID',
        'iOrgLangID',
        'ieObjStav',
        ['nsObjRekSrvJS', 'WideText'],
        ['nsMenaKod', 6],
        ['nsMenaPopis', 40],
        ['nsMenaPoridil', 100],
        ['nsMenaZeme', 100],
        ['nsMenaZmenil', 100],
        ['nsObjCSVImpErrLst', 510],
        ['nsObjCislo', 100],
        ['nsObjDalsiOznaceni', 100],
        ['nsObjDodaciAdr', 510],
        ['nsObjIntCislo', 60],
        ['nsObjKomentProdej', 8000],
        ['nsObjObchodnik', 200],
        ['nsObjOznaceni', 80],
        ['nsObjParentPop', 40],
        ['nsObjParentPopIN', 60],
        ['nsObjParentZak', 40],
        ['nsObjPath', 100],
        ['nsObjPoridil', 100],
        ['nsObjPrdjMena_Prodej', 6],
        ['nsObjPrdjPrnFooterFR3', 'WideText'],
        ['nsObjPrdjPrnFooterFR3_100', 200],
        ['nsObjStav', 100],
        ['nsObjVrbcKod', 'Guid'],
        ['nsObjVrbcNazev', 200],
        ['nsObjVyrobky', 2000],
        ['nsObjXMLCenRekap', 8000],
        ['nsObjXMLFPLst', 8000],
        ['nsObjXMLPDFInfo', 8000],
        ['nsObjZdrojCisObj', 30],
        ['nsObjZdrojIntCis', 60],
        ['nsObjZmenil', 100],
        ['nsOrgAdresa', 200],
        ['nsOrgDIC', 30],
        ['nsOrgEmail', 200],
        ['nsOrgFAX', 100],
        ['nsOrgGPSLati', 40],
        ['nsOrgGPSLong', 40],
        ['nsOrgICO', 40],
        ['nsOrgKontOsoba', 200],
        ['nsOrgLangDescr', 100],
        ['nsOrgMena', 6],
        ['nsOrgMesto', 200],
        ['nsOrgNazev', 200],
        ['nsOrgPSC', 20],
        ['nsOrgPoridil', 100],
        ['nsOrgPozn', 2000],
        ['nsOrgStat', 200],
        ['nsOrgTelefon', 200],
        ['nsOrgZmenil', 100],
        ['nsOrgZpusZaslFakt', 100],
      ],
      strv_msg: [
        'ID',
        'bMsgConfirmation',
        'dMsgPorizeno',
        'dMsgZmeneno',
        'iMsgPocOdeslano',
        'iMsgState',
        ['blbMsgIcon', 'Binary'],
        ['nsMsgText', 'WideText'],
        ['nsMsgIconExt', 510],
        ['nsMsgLang', 20],
        ['nsMsgPoridil', 100],
        ['nsMsgSubject', 100],
        ['nsMsgType', 100],
        ['nsMsgZmenil', 100],
      ],
      strv_msg_send: [
        'ID',
        'bMsgConfirmation',
        'bMsndRead',
        'dMsgPorizeno',
        'dMsgZmeneno',
        'dMsndPorizeno',
        'dMsndReadDate',
        'dMsndZmeneno',
        'iMsgID',
        'iMsgPocOdeslano',
        'iMsgState',
        'iMsndIDReceiver',
        'iMsndIDmsg',
        ['blbMsgIcon', 'Binary'],
        ['nsMsgText', 'WideText'],
        ['nsMsgIconExt', 510],
        ['nsMsgLang', 20],
        ['nsMsgPoridil', 100],
        ['nsMsgSubject', 100],
        ['nsMsgType', 100],
        ['nsMsgZmenil', 100],
        ['nsMsndPoridil', 100],
        ['nsMsndZmenil', 100],
      ],
      dealer_addr: [
        'ID',
        'bAddrOblibene',
        'bAddrSystemRecord',
        'dAddrPorizeno',
        'dAddrZmeneno',
        ['nsAddrCislo', 20],
        ['nsAddrFirma', 200],
        ['nsAddrJmeno', 100],
        ['nsAddrMesto', 100],
        ['nsAddrPSC', 20],
        ['nsAddrPoridil', 100],
        ['nsAddrPrijmeni', 100],
        ['nsAddrTelefon', 100],
        ['nsAddrUlice', 100],
        ['nsAddrZeme', 100],
        ['nsAddrZmenil', 100],
      ],
      strv_dealer_list: [
        'ID',
        'bDlistAktivni',
        'bDlistDph',
        'bDlistPlatny',
        'iDlistJazyk',
        'iDlistLicLevel',
        'iDlistStav',
        'iDlistZamID',
        ['nsDlistDic', 30],
        ['nsDlistEmail', 200],
        ['nsDlistGuid', 'Guid'],
        ['nsDlistIco', 30],
        ['nsDlistLogin', 100],
        ['nsDlistMena', 6],
        ['nsDlistMesto', 200],
        ['nsDlistPsc', 20],
        ['nsDlistSchvalURL', 1000],
        ['nsDlistSpolecnost', 200],
        ['nsDlistStav', 100],
        ['nsDlistTelefon', 200],
        ['nsDlistUlice', 200],
        ['nsDlistZamitURL', 1000],
        ['nsDlistZeme', 200],
      ],
      reksrv: [
        'ID',
        'bRsAktivni',
        'bRsEditable',
        'bRsOdVyrobce',
        'bRsReadOnly',
        'bRsUnread',
        'bRsVrbcSent',
        'dRsDatProdeje',
        'dRsPorizeno',
        'dRsVrbcClosed',
        'dRsVrbcDatZmeny',
        'dRsVrbcSent',
        'dRsZmeneno',
        'fRsCenaZaOdstrZavady',
        'fRsCenaZaZjistZavady',
        'fRsMnKs',
        'iRsFaktID',
        'iRsFromVrbcAttchmCnt',
        'iRsJakResID',
        'iRsKatgID',
        'iRsObchodnikID',
        'iRsObjID',
        'iRsPolID',
        'iRsPolKusID',
        'iRsProdID',
        'iRsStavID',
        'iRsVrbcID',
        'iRsVrbcSndErr',
        ['nsRsAdrMontaze', 510],
        ['nsRsCisloFakt', 40],
        ['nsRsCisloObj', 40],
        ['nsRsIntCislo', 60],
        ['nsRsKatg', 200],
        ['nsRsKod', 'Guid'],
        ['nsRsObchodnik', 200],
        ['nsRsObjZnacka', 80],
        ['nsRsOpravnenost', 100],
        ['nsRsPopisOdstrZavady', 8000],
        ['nsRsPopisZavady', 8000],
        ['nsRsPoridil', 100],
        ['nsRsProdukt', 510],
        ['nsRsStav', 200],
        ['nsRsUzivZnacka', 100],
        ['nsRsVrbcCisloRS', 60],
        ['nsRsVrbcKod', 'Guid'],
        ['nsRsVrbcNazev', 200],
        ['nsRsVyrCis', 100],
        ['nsRsZmenil', 100],
      ],
      chat: [
        'ID',
        'bChOdVyrobce',
        'bChPoOdeslani',
        'bChVrbcSent',
        'dChPorizeno',
        'iChObchodnikID',
        'iChParID',
        'iChPoradi',
        'iChPrilohaID',
        'iChVrbcID',
        ['nsChText', 'WideText'],
        ['nsChChatKod', 'Guid'],
        ['nsChParKod', 'Guid'],
        ['nsChParType', 100],
        ['nsChPoridil', 100],
        ['nsChPrilohaKod', 'Guid'],
      ],
      attchm: [
        'bAttOdVyrobce',
        'dAttPorizeno',
        'dAttZmeneno',
        'iAttJednaniID',
        'iAttVelikost',
        ['nsAttPozn', 'WideText'],
        ['nsAttNazevSPrip', 100],
        ['nsAttPoridil', 100],
        ['nsAttZmenil', 100],
      ],
      skscenou: [
        'ID',
        'SkldID',
        'bSkAktivni',
        'bSkJeSluzba',
        'bSkldAktivni',
        'bSkldJeSluzbovy',
        'dSkPorizeno',
        'dSkZmeneno',
        'dSkldPorizeno',
        'dSkldZmeneno',
        'fSkCenaMena',
        'fSkCenaNakupKc',
        'fSkHmotnost',
        'fSkMinMnozstvi',
        'fSkMnozstvi',
        'iSkSkladID',
        'iSkVrbcID',
        'iSkldVrbcID',
        ['nsSkCenaMena', 6],
        ['nsSkCislo', 100],
        ['nsSkCislo_A', 100],
        ['nsSkCislo_B', 100],
        ['nsSkEAN', 40],
        ['nsSkJedn', 20],
        ['nsSkJednTrans', 8000],
        ['nsSkNazev', 200],
        ['nsSkNazevTrans', 8000],
        ['nsSkObjCislo', 100],
        ['nsSkPicSHA256_RURL', 510],
        ['nsSkPopis', 8000],
        ['nsSkPoridil', 100],
        ['nsSkSkladSkups', 8000],
        ['nsSkVrbcKod', 'Guid'],
        ['nsSkVrbcNazev', 200],
        ['nsSkZmenil', 100],
        ['nsSkldKod', 'Guid'],
        ['nsSkldNazev', 100],
        ['nsSkldPoridil', 100],
        ['nsSkldVrbcKod', 'Guid'],
        ['nsSkldVrbcNazev', 200],
        ['nsSkldZkratka', 4],
        ['nsSkldZmenil', 100],
      ],
      touch: [
        'ID',
        'bTchUnread',
        ['nsTchAgenda', 100],
      ],
      polozka: [
        'ID',
        'bPolImportError',
        'bPozHasItem',
        'bPrdDummyRcmp',
        'bPrdIsPM',
        'dPolPorizeno',
        'dPolZmeneno',
        'dPozPorizeno',
        'dPozZmeneno',
        'fPolAVPM_CelkovaCena',
        'fPolAVPM_Pocet',
        'fPolAVPM_ProdejCenaKs',
        'fPozCNM_AV_DMSleva',
        'fPozCNM_AV_MontPripl',
        'fPozCNM_AV_OstPripl',
        'fPozCNM_AV_SlevaSkup',
        'fPozCNM_Abs_Sleva',
        'fPozCNM_celkem_PredSlevou_bezDPH',
        'fPozCNM_celkem_bezDPH',
        'fPozCNM_celkem_sDPH',
        'fPozCPM_celkem_bezDPH',
        'fPozCPM_user_bezDPH',
        'fPozCPM_vrbc_bezDPH',
        'fPozMetraz',
        'fPozPrdj_Marze_bezDPH',
        'fPozPrdj_SlevaCelkem_bezDPH',
        'fPozPrdj_celkem_PredSlevou_bezDPH',
        'fPozPrdj_celkem_bezDPH',
        'fPrdMarzePrc',
        'fPrdPrcKeKoncCeniku_Prd',
        'fPrdPrcKeKoncCeniku_Pripl',
        'fPrdPrcKeKoncCeniku_Prisl',
        'iPolAV_Pocet',
        'iPolCislo',
        'iPolPozID',
        'iPozCislo',
        'iPozID',
        'iPozPocetKs',
        'iPozPrdID',
        'iPozSourcePozID',
        'iPozZakID',
        'iPrdDealMenaID',
        'iPrdID',
        'iPrdMaskNo',
        'iPrdT10ID',
        'iPrdVrbcID',
        'iPrdZarukaM',
        'nsPolAVPM_ArisValues',
        ['nsPolArisValues', {width: 8000, fieldtype: "string"}],
        ['nsPozArisValues', {width: 8000, fieldtype: "string"}],
        ['blbPrdThumb', 'Binary'],
        ['nsPolAVPM_Jedn', 100],
        ['nsPolAVPM_Sklad', 100],
        ['nsPolAVPM_SkladCislo', 66],
        ['nsPolAVPM_SkladNazev', 200],
        ['nsPolAV_Poznamka', 510],
        ['nsPolCislo_A', 100],
        ['nsPolCislo_B', 100],
        ['nsPolPoridil', 100],
        ['nsPolZmenil', 100],
        ['nsPozCNM_Mena', 6],
        ['nsPozCPM_Mena', 6],
        ['nsPozPoridil', 100],
        ['nsPozZmenil', 100],
        ['nsPrdDealMenaKod', 6],
        ['nsPrdKod', 'Guid'],
        ['nsPrdNazev', 510],
        ['nsPrdNazevTrans', 8000],
        ['nsPrdPrdObecnyPopis', 8000],
        ['nsPrdPrdTextZaruky', 8000],
        ['nsPrdSkupVyr', 100],
        ['nsPrdVrbcKod', 'Guid'],
        ['nsPrdVrbcNazev', 200],
        ['nsPrdZkratka', 100],
        ['nsPrdZkratkaTrans', 8000],
      ],
      pozice: [
        'ID',
        'bPozHasItem',
        'bPrdDummyRcmp',
        'bPrdIsPM',
        'dPozPorizeno', 
        'dPozZmeneno',
        'fPozCNM_AV_DMSleva',
        'fPozCNM_AV_MontPripl',
        'fPozCNM_AV_OstPripl',
        'fPozCNM_AV_SlevaSkup',
        'fPozCNM_Abs_Sleva',
        'fPozCNM_celkem_PredSlevou_bezDPH',
        'fPozCNM_celkem_bezDPH',
        'fPozCNM_celkem_sDPH',
        'fPozCPM_celkem_bezDPH',
        'fPozCPM_user_bezDPH',
        'fPozCPM_vrbc_bezDPH',
        'fPozMetraz',
        'fPozPrdj_Marze_bezDPH',
        'fPozPrdj_SlevaCelkem_bezDPH',
        'fPozPrdj_celkem_PredSlevou_bezDPH',
        'fPozPrdj_celkem_bezDPH',
        'fPrdMarzePrc',
        'fPrdPrcKeKoncCeniku_Prd',
        'fPrdPrcKeKoncCeniku_Pripl',
        'fPrdPrcKeKoncCeniku_Prisl',
        'iPozCislo',
        'iPozPocetKs',
        'iPozPrdID',
        'iPozSourcePozID',
        'iPozZakID',
        'iPrdDealMenaID',
        'iPrdID',
        'iPrdMaskNo',
        'iPrdT10ID',
        'iPrdVrbcID',
        'iPrdZarukaM',
        ['nsPozArisValues', {width: 8000, fieldtype: "string"}],
        ['blbPrdThumb', 'Binary'],
        ['nsPozCNM_Mena', 6],
        ['nsPozCPM_Mena', 6],
        ['nsPozPoridil', 100],
        ['nsPozZmenil', 100],
        ['nsPrdDealMenaKod', 6],
        ['nsPrdKod', 'Guid'],
        ['nsPrdNazev', 510],
        ['nsPrdNazevTrans', 8000],
        ['nsPrdPrdObecnyPopis', 8000],
        ['nsPrdPrdTextZaruky', 8000],
        ['nsPrdSkupVyr', 100],
        ['nsPrdVrbcKod', 'Guid'],
        ['nsPrdVrbcNazev', 200],
        ['nsPrdZkratka', 100],
        ['nsPrdZkratkaTrans', 8000],
        ['nsPozLastErrPoz', 'WideText'],
        ['nsPozLastWrnPoz', 'WideText'],
      ],
      vyr_file: [
        'ID',
        'dVFPorizeno',
        'dVFZmeneno',
        'iVFKomprimace',
        'iVFPoradi',
        'iVFVrbcID',
        ['nsVFExt', 510],
        ['nsVFKod', 'Guid'],
        ['nsVFNazev', 510],
        ['nsVFPoridil', 100],
        ['nsVFTreePath', 8000],
        ['nsVFVrbcKod', 'Guid'],
        ['nsVFVrbcNazev', 200],
        ['nsVFZmenil', 100],
      ],
      ufl_recordset: [
        'ID',
        'iRcSecretFldCnt',
        ['nsRcDescr', 200],
        ['nsRcRecordset', 100],
        ['nsRcSecretFldLst', 4100],
      ],
      stromnab: [
        'ID',
        'bMenaAktivni',
        'bStnAktivni',
        'bStnAnyMissingDV',
        'bStnEveryDivHasItem',
        'bStnPrdjPrnShowAdvPaymDisc',
        'bStnPrdjReadOnly',
        'bStnReadOnly',
        'dMenaPorizeno',
        'dMenaZmeneno',
        'dStnDatum',
        'dStnPorizeno',
        'dStnTerminPozad',
        'dStnZmeneno',
        'fStnCenaBezDPH',
        'fStnCenaBezDPHOsvob',
        'fStnCenaBezDPHPozic',
        'fStnCenaBezDPHSnizena',
        'fStnCenaBezDPHZaklad',
        'fStnCenaDPHSnizena',
        'fStnCenaDPHZaklad',
        'fStnCenaSDPH',
        'fStnCenaZaloha',
        'fStnKurz',
        'fStnPrdjPrijateZalohy_sDPH',
        'fStnPrdjProdejKuNakupMenaKurz',
        'fStnPrdjSazbaDPH',
        'fStnPrdjTotal_DPH',
        'fStnPrdjTotal_bezDPH',
        'fStnPrdjTotal_sDPH',
        'fStnPrdjZbyvaUhradit',
        'fStnZbyvaUhradit',
        'iMenaID',
        'iMenaZaokr',
        'iStnLevel',
        'iStnMenaID',
        'iStnOrgID',
        'iStnParentTag',
        'iStnPrdjLangID',
        'iStnPrdjMenaID_Prodej',
        'iStnTag',
        'iStnUzivStav',
        'iStnVrbcID',
        'iStnZakID',
        'iStnZdrojPOID',
        'ieStnStav',
        ['nsStnPrdjPrnFooterFR3', 'WideText'],
        ['nsMenaKod', 6],
        ['nsMenaPopis', 40],
        ['nsMenaPoridil', 100],
        ['nsMenaZeme', 100],
        ['nsMenaZmenil', 100],
        ['nsStnAgenda', 100],
        ['nsStnCislo', 100],
        ['nsStnDalsiOznaceni', 100],
        ['nsStnDodaciAdr', 510],
        ['nsStnIntCislo', 60],
        ['nsStnKomentProdej', 8000],
        ['nsStnOrderBy', 100],
        ['nsStnOznaceni', 80],
        ['nsStnPoridil', 100],
        ['nsStnPrdjMena_Prodej', 6],
        ['nsStnPrdjPrnFooterFR3_100', 200],
        ['nsStnStav', 100],
        ['nsStnTreePath', 100],
        ['nsStnVrbcKod', 'Guid'],
        ['nsStnVrbcNazev', 200],
        ['nsStnVyrobky', 2000],
        ['nsStnXMLPDFInfo', 8000],
        ['nsStnZdrojCisObj', 30],
        ['nsStnZdrojIntCis', 60],
        ['nsStnZmenil', 100],
      ],
      dvgroups: [
        'ID',
        'bGrpAktivni',
        'bGrpOblibene',
        'bGrpVsechny',
        'dGrpPorizeno',
        'dGrpZmeneno',
        'iGrpID_Vyrobce',
        'iGrpPoradi',
        ['blbGrpIkona', 'Binary'],
        ['nsGrpNazev', 100],
        ['nsGrpPoridil', 100],
        ['nsGrpVyrobce', 200],
        ['nsGrpZmenil', 100],
      ],
      ve_stat_overview: [
        'fStCena',
        'iStPocet',
        'iStSekce',
        ['nsStMena', 6],
        ['nsStPopis', 100],
        ['nsStSekce', 100],
      ],
      ve_stat_obrat_py: [
        'fStCena',
        'iStPocet',
        'iStRok',
        ['nsStMena', 6],
      ],
      ve_stat_obrat_pm: [
        'fStCena',
        'iStMesic',
        'iStPocet',
        'iStRok',
        ['nsStMena', 6],
      ],
      ve_stat_obrat_dt: [
        'ID',
        'bObjAktivni',
        'dObjDatum',
        'dObjImported',
        'dObjKonecVyr',
        'dObjPorizeno',
        'dObjTerminDodani',
        'dObjTerminPozad',
        'dObjZmeneno',
        'fObjBalne',
        'fObjCenaBezDPH',
        'fObjCenaBezDPHOsvob',
        'fObjCenaBezDPHPozic',
        'fObjCenaBezDPHSnizena',
        'fObjCenaBezDPHZaklad',
        'fObjCenaDPHSnizena',
        'fObjCenaDPHZaklad',
        'fObjCenaSDPH',
        'fObjCenaZaZamereni',
        'fObjCenaZaloha',
        'fObjDopravne',
        'fObjKurz',
        'fObjOstSaP_abs',
        'fObjOstSaP_proc',
        'fObjPostovne',
        'fObjSazbaSn',
        'fObjSazbaZk',
        'fObjZbyvaUhradit',
        'iObjCenikID',
        'iObjMenaID',
        'iObjOrgID',
        'iObjParentPop',
        'iObjParentZak',
        'iObjPreDPH',
        'iObjUzivStav',
        'iObjVrbcID',
        'iObjZdrojPOID',
        'ieObjStav',
        ['nsObjCislo', 100],
        ['nsObjDalsiOznaceni', 100],
        ['nsObjDealer', 200],
        ['nsObjDealerKod', 'Guid'],
        ['nsObjDealerLogin', 100],
        ['nsObjKomentProdej', 8000],
        ['nsObjMenaKod', 6],
        ['nsObjOznaceni', 80],
        ['nsObjParentPop', 40],
        ['nsObjPath', 100],
        ['nsObjPoridil', 100],
        ['nsObjStav', 100],
        ['nsObjVrbcKod', 'Guid'],
        ['nsObjVrbcNazev', 200],
        ['nsObjVyrobky', 2000],
        ['nsObjXMLFPLst', 8000],
        ['nsObjZdrojCisObj', 30],
        ['nsObjZmenil', 100],
      ],
      malc_month: [
        "dLvDen",
        "iLvHodina",
        "iLvCelkem",
        "iLvVypadky",
        ["nsLvOrigin", 100],
        ["nsLvHost", 100],
        ["nsLvDatumTZ", 60]
      ],
      malc_day: [
        'ID',
        'bLvAnyErr',
        'dLvPorizeno',
        ['nsLvErrMsg', 8000],
        ['nsLvHost', 100],
        ['nsLvInfo', 1000],
        ['nsLvOrigin', 100],
      ],
      stat_private_overview: [
        'fStCena',
        'iStMesíc',
        'iStPocet',
        'iStSekce',
        ['nsStMena', 6],
        ['nsStPopis', 100],
        ['nsStSekce', 100],
      ],
      subdealer: [
        'ID',
        'bSbdAcceptStrvMsg',
        'bSbdActive',
        'bSbdCanCreateOffers',
        'bSbdCanSeeObjState',
        'bSbdCanUseSKDisc',
        'dSbdChanged',
        'dSbdCreated ',
        'iSbdTrgParUserID_Staff',
        ['nsSbdPrivateNote', 'WideText'],
        ['nsSbdBankDetails', 200],
        ['nsSbdChanger', 100],
        ['nsSbdCity', 200],
        ['nsSbdCompany', 200],
        ['nsSbdCountry', 200],
        ['nsSbdCreator', 100],
        ['nsSbdDIC', 30],
        ['nsSbdEmail', 200],
        ['nsSbdFullName', 200],
        ['nsSbdIBAN', 82],
        ['nsSbdICO', 30],
        ['nsSbdLogin', 100],
        ['nsSbdNumber', 20],
        ['nsSbdOnSubDActionParNtfEmail', 200],
        ['nsSbdPassword', 100],
        ['nsSbdPhone', 200],
        ['nsSbdStreet', 200],
        ['nsSbdTrgParUserStaff', 200],
        ['nsSbdWebURL', 2000],
        ['nsSbdZIP', 20],
      ],
      subd_prod: [
        'ID',
        'bSbpSubDAccessible',
        'dSbpChanged',
        'dSbpCreated ',
        'fSbpMarzePrc',
        'iSbpVrbcID',
        'iSbpZarukaM',
        ['blbSbpThumb', 'Binary'],
        ['nsSbpChanger', 100],
        ['nsSbpCreator', 100],
        ['nsSbpKod', 'Guid'],
        ['nsSbpNazev', 510],
        ['nsSbpSkupVyr', 100],
        ['nsSbpVrbcKod', 'Guid'],
        ['nsSbpVrbcNazev', 200],
        ['nsSbpZkratka', 100],
      ],
      imp_order: [
        'ID',
        'bImpAktivni',
        'bImpJeObjedn',
        'bImpReadOnly',
        'dImpPorizeno',
        'dImpZmeneno',
        ['nsImpDataJSBT', 'WideText'],
        ['nsImpCallerPairingId', 100],
        ['nsImpDealGuid', 'Guid'],
        ['nsImpDealNazev', 200],
        ['nsImpOznaceni1', 100],
        ['nsImpOznaceni2', 100],
        ['nsImpPoridil', 100],
        ['nsImpRowGuid', 'Guid'],
        ['nsImpSeznVyrob', 510],
        ['nsImpUsedObjIDLst', 8000],
        ['nsImpZmenil', 100],
      ]
    }
  }
}