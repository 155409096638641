import './styl.scss'
import * as Chartjs from 'chart.js'
import { SComponent, _, Table, route, SDate, Rq, DInput, createRef, Checkbox, Comp, RInput, Btn } from '../../dump'

window.ch = Chartjs;

export class SysDetail extends SComponent {
    tableRows = [];
    datasets = {};
    rows = [];
    
    async cmount(s,p) {
        this.onlyBlackouts = false
        this.date = SDate();
        this.chartEl = createRef();
        await this.loadData();
    }

    async loadData() {
        console.log("ld", this.onlyBlackouts);
        const {cols, rows} = await Rq.sd("malc_day", 1, 0, {bDirtyOnly: /*Number(this.onlyBlackouts)*/0, dKalDatum: this.date.ymd})
        this.cols = cols;
        this.rows = rows;
        this.processData();
        this.renderTable();
    }

    async processData() {
        this.rowCount = 0;
        this.timeMin = null;
        this.timeMax = null;

        let reFilter = null;
        let aColNames = this.cols.filter(col => col.visible).map(m => m.attrname);
        
        try { // catch
            let minMS = 0;
            
            for(let row of this.rows) {
                if(!row) continue;

                if(row.nsLvInfo) {
                    let av = [];
                    
                    row.nsLvInfo.split('\r\n').forEach(x => {
                        let a = x.split('=');
                        av.push(Number.parseInt(a[1]));
                    });

                    row.nsLvInfo_fMAX = av.reduce((ac,v) => (!ac || v > ac ? v : ac), 0);
                }
                
                // filtering
                row.valid = true;
            
                // odezva od
                if (minMS && row.nsLvInfo_fMAX < minMS) {
                    row.valid = false;
                }
                
                // reg exp
                if(row.valid && reFilter) {
                    let sRow = aColNames.map(colName => row[colName]).join(" ");
                    row.valid = reFilter.test(sRow.replace(/(?:\r|\n)+/g, ' '));
                }
            
                if(row.valid) {
                    this.rowCount++;
                }
            }
        
        } 
        catch(e) {
            //this.ErrorMessage.innerHTML += e.message;
        }
        
        this.drawChart();
    }

    drawChart() {
        this.datasets = {};
        this.times = [];
        let total = 0;
        let totalErrors = 0;

        let colors = [
            'rgba(255,0,0,0.5)', 
            'rgba(0,255,0,0.5)', 
            'rgba(0,0,255,0.5)', 
            'rgba(255,255,0,0.5)',
            'rgba(255,0,255,0.5)',
            'rgba(0,255,255,0.5)',
        ];

        let dsNum = 0;

        for(let row of this.rows) {
            total += row.iLvCelkem;
            totalErrors += row.iLvVypadky;
            const ds = row.nsLvOrigin + ":" + row.nsLvHost;

            if(!this.datasets[ds]) {
                this.datasets[ds] = {
                    data: [],
                    label: row.nsLvOrigin + " (" + row.nsLvHost + ")",
                    fill: false,
                    num: dsNum,
                    backgroundColor: colors[dsNum],
                    borderColor: colors[dsNum],
                    borderWidth: 1,
                    barThickness: 2
                }
                dsNum++;
            }

            this.datasets[ds].data.push({
                x: row.dLvPorizeno,
                y: row.nsLvInfo_fMAX,
            });
        }
                
        this.totalProc = total == 0 ? "0%" : (100 - (totalErrors / total * 100).toFixed(3) + "%");

        this.refresh();
        this.draw();
    }

    async renderTable() {
        let countErrors = 0;
        let onlyErrors = /*$("#chBlackouts")[0] ? $("#chBlackouts").hasClass("checked") :*/ true;
        let minMS = 0;//+$('#ms-filter').val();

        this.tableRows = this.rows.filter(f=> f.valid && ((this.onlyBlackouts && f.bLvAnyErr) || !this.onlyBlackouts)).map(m => {
            const infoLogin = m.nsLvInfo.match(/login=(\d+)/) || [];
            const infoLogout = m.nsLvInfo.match(/logout=(\d+)/) || [];
            const infoInitLoad = m.nsLvInfo.match(/initial-load=(\d+)/) || [];
            const infoGSI = m.nsLvInfo.match(/get-session-info=(\d+)/) || [];
            const infoGDP = m.nsLvInfo.match(/get-demands-page=(\d+)/) || [];
            const infoGDX = m.nsLvInfo.match(/get-demands-xml=(\d+)/) || [];

            m.infoLogin = infoLogin[1] || "";
            m.infoLogout = infoLogout[1] || "";
            m.infoInitLoad = infoInitLoad[1] || "";
            m.infoGSI = infoGSI[1] || "";
            m.infoGDP = infoGDP[1] || "";
            m.infoGDX = infoGDX[1] || "";

            m.max = Math.max(m.infoLogin, m.infoLogout, m.infoInitLoad, m.infoGSI, m.infoGDP, m.infoGDX);

            m.data = m.nsLvInfo
                .replace(infoLogin[0], "")
                .replace(infoLogout[0], "")
                .replace(infoInitLoad[0], "")
                .replace(infoGSI[0], "")
                .replace(infoGDP[0], "")
                .replace(infoGDX[0], "")

            if(m.nsLvErrMsg || m.max > 7000) countErrors++;
            
            m.hidden = false;

            if(onlyErrors) {
                m.hidden = !(m.nsLvErrMsg || m.max >= 7000);
            }

            if(minMS > 0 && minMS > m.max) {
                m.hidden = true;
            }

            return m;
        })

        this.dostupnost = this.tableRows.length ? (((1 - (countErrors / this.tableRows.length)) * 100).toFixed(2)) : "0";
        this.refresh();
    }

    chs = () => {
        return {
            type: 'bar',
            data: {},
            responsive: true,
            options: {
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            tooltipFormat: 'DD.MM.YY HH:mm',
                            displayFormats: {
                            day: 'DD.MM.YY',
                            hour: 'DD. HH:mm',
                            minute: 'HH:mm',
                            second: 'HH:mm:ss',
                            }
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Datum'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        }
                    }]
                }
            }
        }
    }

    draw = () => {  
        const config = this.chart ? this.chart.config : this.chs();
        const aDatasets = Object.values(this.datasets);

        config.data.labels = this.rows.map(m => m.dLvPorizeno);
        config.data.datasets = aDatasets;

        this.chart ? 
            this.chart.update() : 
            this.chart = new Chart(this.chartEl.current, config);
    }

    setDate(dif) {
        dif ? this.date.addDate(dif) : this.date = SDate();
        this.loadData();
    }

    rnd = (s, p) => {
        return (
            <Comp className="sysDetail">
                <div class="filter flex">
                    <div class="buttons">
                        <Btn add id="reload" onClick={e=> this.loadData()}>{_.refresh}</Btn>
                        <Btn add id="dateBefore" onClick={e=>this.setDate(-1)}>&lt;&lt;</Btn>
                        <Btn add id="today" onClick={e=>this.setDate()}>{_.today}</Btn>
                        <Btn add id="dateNext" onClick={e=>this.setDate(1)}>&gt;&gt;</Btn>
                    </div>

                    <div className="availability">
                        <span>{_.availDay}: </span>
                        <span>{this.dostupnost}</span> %
                    </div>
                    
                    <div className="inputs flex">
                        <Checkbox tname="onlyBlackouts" state={this} item="onlyBlackouts" onChange={e => this.renderTable()}  />
                        <DInput state={this} item="date" tname="date" onChange={e => this.loadData()} />
                    </div>
                </div>
                
                <div className="sysDetailData">
                    <canvas ref={this.chartEl} id="canvasB" style="display: block;width: 500px;height: 150px;"></canvas>
                    <Table 
                        rows={this.tableRows} 
                        cols={this.cols} 
                        customCols={true} 
                        showSearch={false}
                        changeCols={false}
                        expand={true}
                    >
                        <tab-button
                            type="primary"
                            icon="Cog"
                            click={rec => rec.reload()}
                        >
                            <b-item icon="Table" text={_.dashboard} click={rec => route("/iotTable/" + rec.ID)} />
                            <b-item icon="ChartLine" text={_.chart} click={rec => route("/iotGraph/" + rec.ID)} />
                        </tab-button>
                        <tab-col name="dLvPorizeno" type="date" caption={_.date} />
                        <tab-col name="nsLvErrMsg" type="format" caption="Chybová zpráva" format={r => r.nsLvErrMsg} />
                        <tab-col name="bLvAnyErr" type="format" caption={_.error} format={r => r.data ? _.yes : _.no} />
                        <tab-col name="nsLvHost" caption="Host" />
                        <tab-col name="nsLvOrigin" caption="Původce" />
                        <tab-col name="data" caption="Info" />
                        <tab-col name="max" caption={_.maxResponse} />
                        <tab-col name="infoLogin" caption="Login [ms]" />
                        <tab-col name="infoLogout" caption="Logout [ms]" />
                        <tab-col name="infoInitLoad" caption="Init Load [ms]" />
                        <tab-col name="infoGSI" caption="GSI [ms]" />
                        <tab-col name="infoGDP" caption="GDP [ms]" />
                        <tab-col name="infoGDX" caption="GDX [ms]" />
                    </Table>
                </div>
            </Comp>
        )
    }  
}