import { Link } from "preact-router";
import { SComponent, Table, _ } from "../../dump";

export class HmDemands extends SComponent {
    async cmount() {
        this.rows = [];
        this.cols = [];
        const reqCols = [
            'ID',
            'dPopDatum',
            'fPopPrdjTotal_bezDPH',
            'fPopPrdjTotal_sDPH',
            ['nsPopIntCislo', 60],
            ['nsMenaKod', 6],
            ['nsPopStav', 100],
        ]

        const {cols, rows} = await Rq.sd("poptavka", reqCols, 0, {bTemporaryFields: 1});
        this.rows = rows;
        this.cols = cols;
    }

    //detail={rec => this.recordset == "objednavka" ? route("/orders/" + rec.ID) : route("/demands/" + rec.ID)}>

    rnd(s,p) {
        return (
            <>
            {/* <h3>{_.inquiries}</h3> */}
            <Table 
                rows={this.rows} 
                cols={this.cols} 
                showMenu={false} 
                showPages={false} 
                detail={rec => route("/demands/" + rec.ID)} 
            />
            </>
        )
    }
}