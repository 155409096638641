import { route } from "preact-router";
import { Repo, Table } from "../../dump";
import { Documents } from "./documents";

const download = rec => {
    window.location.href = (Repo.serverURL + `/api/attchm/${rec.ID}/1/${rec.nsAttNazevSPrip}`);
}

const view = rec => {
    route(Repo.serverURL + `/api/attchm/${rec.ID}/0/${rec.nsAttNazevSPrip}`);
}

export function DocTab({zakID}) {
    return (
        <Table
            recordset="attchm" 
            sw_iPOID={zakID}
            cols="1" 
            customCols={true} 
            changeCols={false}
            showFilters={false}
            showAll={true}
            detail={row => Documents.editDocument(row)}
        >
            <bar-button
                type="add"
                icon="Plus"
                text=""
                click={async e => {

                }}
            />
            <tab-button
                type="warning"
                icon="Edit"
                disabled={() => !vrJoin}
                link={rec => "r/deal/" + rec.ID}
            />
            <tab-button
                type="primary"
                icon="Cog"
                click={rec => rec.reload()}
            >
                <b-item icon="Download" text={_.download} click={download} />
                <b-item icon="Share" text={_.view} click={view} />
                <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
            </tab-button>
            <tab-col name="nsAttNazevSPrip" />
            <tab-col name="nsAttPozn" />
            <tab-col name="dAttZmeneno" />
            <tab-col name="iAttVelikost" type="size" />
            <tab-col name="nsKalDatumDo" type="date" />
        </Table>
    )
}