import { motion, AnimatePresence } from 'framer-motion';

export const Comp =p=> 
    <AnimatePresence>
    <motion.div 
        initial={{ y: 0, opacity: 0 }} 
        animate={{ y: 0, opacity: 1 }} 
        transition={{ duration: 0.5 }} 
        //exit={{ y: 200, opacity: 0 }}
        className={"comp " + p.className}
    >
        {p.children}
    </motion.div>
    </AnimatePresence>