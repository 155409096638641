import './styl.scss'
import * as Chartjs from 'chart.js'
import { SComponent, _, SDate, Rq, createRef, Comp } from '../../dump'

window.ch = Chartjs;

export class SysMonth extends SComponent {
    async cmount(s,p) {
        this.chartEl = createRef();
        this.rows = [];
        this.datasets = {};
        this.times = []; 
        this.loadSummary();
        console.log("cm");
    }

    async loadSummary() {
        const {rows} = await Rq.sd("malc_month", 1);
        rows.sort((a,b) => a.dLvDen - b.dLvDen);
        this.rows = rows
        this.drawChart();
    }

    drawChart() {
        this.datasets = {};
        this.times = [];
        let total = 0;
        let totalErrors = 0;

        let colors = [
            'rgba(255,0,0,0.5)', 
            'rgba(0,255,0,0.5)', 
            'rgba(0,0,255,0.5)', 
            'rgba(255,255,0,0.5)',
            'rgba(255,0,255,0.5)',
            'rgba(0,255,255,0.5)',
        ];

        let dsNum = 0;

        for(let row of this.rows) {
            total += row.iLvCelkem;
            totalErrors += row.iLvVypadky;
            const ds = row.nsLvOrigin + ":" + row.nsLvHost;

            if(!this.datasets[ds]) {
                this.datasets[ds] = {
                    data: [],
                    label: row.nsLvOrigin + " (" + row.nsLvHost + ")",
                    fill: false,
                    num: dsNum,
                    backgroundColor: colors[dsNum],
                    borderColor: colors[dsNum],
                    borderWidth: 1,
                    barThickness: 10
                }
                dsNum++;
            }

            if(row.iLvVypadky > 0) {
                this.datasets[ds].data.push({
                    x: row.dLvDen,
                    y: row.iLvVypadky,
                });

            }
        }
                
        this.totalProc = total == 0 ? "0%" : (100 - (totalErrors / total * 100).toFixed(3) + "%");

        this.refresh();
        this.draw();
    }

    chs = () => {
        return {
            type: 'bar',
            data: {},
            responsive: true,
            options: {
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        tooltipFormat: 'DD.MM.YY HH:mm',
                        displayFormats: {
                           day: 'DD.MM.YY',
                           hour: 'DD. HH:mm',
                           minute: 'HH:mm',
                           second: 'HH:mm:ss',
                        }
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'Datum'
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: 'Value'
                    }
                }]
            }
        }
    }
    }

    draw = () => {  
        const config = this.chart ? this.chart.config : this.chs();
        const aDatasets = Object.values(this.datasets);

        config.data.labels = this.rows.map(m => m.dLvDen);
        config.data.datasets = aDatasets;

        this.chart ? 
            this.chart.update() : 
            this.chart = new Chart(this.chartEl.current, config);
    }

    rnd = (s, p) => {
        return (
            <Comp class="sysMonth columns">
                <div class="availability2">
                    <div class="info">{_.serviceAvailability}</div>
                    <div class="proc">{this.totalProc}</div>
                </div>
                <canvas ref={this.chartEl} style="display: block;width: 500px;height: 150px;"></canvas>
            </Comp>
        )
    }  
}