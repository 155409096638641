import './styl.scss';
import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, useRef, Ref, RIcons, Repo, Comp } from "../../dump";

export class Documentation extends SComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
        };
    }

    async cmount() {
        var r = await Rq.sd("vyr_file", 1, 0, { frReload: true });
        var tree = {};

        r.rows.map(m => {
            m.path = (m?.nsVFTreePath || "").split("\\").filter(f => f);
            m.level = m.path.length;

            var array = ["0", ...m.path];
            var o = tree;

            for (var i = 0; i < array.length; i++) {
                o = o[array[i]] = o[array[i]] || { _children: m };
            }

            return m;
        });

        this.tree = tree;
        this.html = this.generateTree(tree, 1);
    }

    generateTree(tree, i) {
        var otree = Object.entries(tree);

        return otree.map(([key, data]) => {
            var styl = { left: (i * 0.5) + "rem", position: "relative" };

            if (key == "_children") {
                return (
                    <a href={`${Repo.serverURL}/api/maskImg/VRBC/${data.nsVFVrbcKod}/OF_ANY/${data.nsVFKod}${data.nsVFExt}`} className="tree file" style={styl}>
                        <RIcons.Fa.FaFile /> {data.nsVFNazev}
                    </a>
                );
            } else {
                if (key == "0") {
                    key = data?._children?.nsVFVrbcNazev || _.documents;
                }

                return (
                    <div className="tree folder" style={styl}>
                        <div className="key"><RIcons.Fa.FaFolder /> {key}</div> {this.generateTree(data, i + 1)}
                    </div>
                );
            }
        });
    }

    handleSearchInput = (e) => {
        this.setState({ searchQuery: e.target.value });
        this.filterTree(e.target.value);
    };

    filterTree(query) {
        if (!query) {
            this.html = this.generateTree(this.tree, 1);
        } else {
            const filteredTree = this.filterTreeNodes(this.tree, query.toLowerCase());
            this.html = this.generateTree(filteredTree, 1);
        }
        this.forceUpdate();
    }

    filterTreeNodes(tree, query) {
        let filteredTree = {};

        for (let [key, data] of Object.entries(tree)) {
            if (key === "_children") {
                if (data.nsVFNazev.toLowerCase().includes(query)) {
                    filteredTree[key] = data;
                }
            } else {
                let filteredSubtree = this.filterTreeNodes(data, query);
                if (Object.keys(filteredSubtree).length > 0 || key.toLowerCase().includes(query)) {
                    filteredTree[key] = filteredSubtree;
                }
            }
        }

        return filteredTree;
    }

    rnd() {
        return (
            <div className='container'>
                <Comp className="documentation">
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder={_.Search}
                            value={this.state.searchQuery}
                            onChange={this.handleSearchInput}
                        />
                        <button type="button" className="btn btn-sm searchclrbtn" onClick={() => this.handleSearchInput({ target: { value: '' } })}>
                            <RIcons.Fa.FaTimes />
                        </button>
                    </div>
                    {this.html}
                </Comp>
            </div>
        );
    }
}
