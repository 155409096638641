import { SComponent, _, Ref, Btn } from "../../dump";

export class AtchView extends SComponent {
    cmount() {
        this.file = {};
        this.state = { display: "dnone" }
    }

    componentWillReceiveProps(np) {
        this.file = np.file;
        this.set.display = np.open ? "dblock" : "none";
    }

    close =()=> {
        //this.set.display = "none";
        this.props.close();
    }

    cancel =e=> {
        if(e.target.id == "atchDialog" || e.target.id == "zrusit" || e.target.className == "close") 
            Ref.modal3.close();
    }

    createPreview() {
        const href = this.file.url || "";

        switch(this.file.type) {
            case "image":   return <img src={href}></img>
            case "pdf":     return <embed src={href} type="application/pdf" />
            case "video":   return <video controls autoPlay src={href} />
            case "text":    return <iframe charset="UTF-8" src={href}></iframe>
        }

        return [];
    }

    download=()=> {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = this.file.url.replace("/0/", "/1/");
        a.download = this.file.name;
        a.click();
        document.body.removeChild(a);
    }

    show=()=> {
        window.open(this.file.url, '_blank').focus();
    }

    async open(file) {
        this.file = file;
        const prew = await this.createPreview();

		const res = await Ref.modal3.open(`${_.filePreview} - ${this.file.name}`,
            <div className="modal-data atchView">{prew}</div>,
            <div className="modal-buttons">
                <Btn download id="dlFile" onClick={this.download}>{_.download}</Btn>
                <Btn view id="showFile" onClick={this.show}>{_.view}</Btn>
                <Btn back id="back" onClick={this.close}>{_.back}</Btn>
            </div>
		);
	}

    rnd(s,p) {
        return (
            <div></div>
        );
    }
}