import { RIcons, Component, _, motion, Repo, SComponent, If } from "../../dump";
import './style.scss'
import { useRouter } from "preact-router";

const MenuItem = ({ name, icon, link, visible }) => {
    const router = useRouter()
    const url = router[0].url.split("/")[1]

    if(visible === false) return;

    return (
        <li id={"menu-" + name}>
            <a className={url === link.split("/")[1] && "activeMenuItem"} href={link}>
                {icon}
                <span className="title">{_[name] || name}</span>
            </a>
        </li>
    )
}

class DealerMenu extends SComponent {
    hideMenu = false;

    closeMenu = ()=> {
        this.hideMenu = !this.hideMenu;
        document.querySelector("#main").style.gridTemplateColumns = this.hideMenu ? "4rem 1fr" : "";
        this.refresh();
    }

    rnd() {
        return (
            <motion.div 
                initial={{ x: -300, opacity: 0 }} 
                animate={{ x: 0, opacity: 1 }} 
                transition={{ duration: 0.5 }} 
                id="menu"
                className={this.cln({hideMenu: this.hideMenu})}
            >
                <div className="nav-wrapper">
                    <ul className="navigation">
                        <MenuItem name="orders" link="/deals" icon={<RIcons.Bs.BsShop />} />
                        <MenuItem name="inquiries" link="/demands" icon={<RIcons.Bs.BsBorderStyle />} />
                        <MenuItem name="requisitions" link="/orders" icon={<RIcons.Bs.BsBoxSeam />} />
                        <MenuItem name="supplies" link="/supplies" icon={<RIcons.Bs.BsTruck />} />
                        <MenuItem name="invoices_issued" link="/invoices" icon={<RIcons.Fa.FaFileInvoiceDollar />} />
                        <MenuItem name="complaintsReceived" link="/cm-received" icon={<RIcons.Bs.BsBorderStyle />} />
                        <MenuItem name="complaintsIssued" link="/cm-issued" icon={<RIcons.Bs.BsBorderStyle />} visible={Repo.stg.cs__rekSrvEnabled} />
                        <MenuItem name="calendar" link="/calendar" icon={<RIcons.Bs.BsCalendarDate />} />
                        <MenuItem name="customers" link="/customers" icon={<RIcons.Bs.BsPeople />} />
                        {/*<MenuItem name="statistics" link="/" icon={<RIcons.Bs.BsBorderStyle />} />*/}
                        <MenuItem name="users" link="/users" icon={<RIcons.Fi.FiUsers />} />
                        <MenuItem name="montages" link="/montages" icon={<RIcons.Fa.FaScrewdriver />} visible={Repo.stg.cs__DealsMont} />
                        <MenuItem name="contactMeetings" link="/meetings" icon={<RIcons.Fa.FaHandshake />} visible={Repo.stg.cs__DealsKontJednani} />
                        <MenuItem name="service" link="/service" icon={<RIcons.Fa.FaWrench />} visible={Repo.stg.cs__DealsMont} />
                        <MenuItem name="settings" link="/settings" icon={<RIcons.Hi.HiOutlineCog visible={Repo.stg.cs__DealsMont} />} />
                        <MenuItem name="dlrBranches" link="/branches" icon={<RIcons.Fa.FaHome />} visible={Repo.stg.cs__subDealersEnabled} />
                        <MenuItem name="iotModules" link="/iot" icon={<RIcons.Hi.HiOutlineCog />} visible={Repo.stg.cs__iotModuleEnabled} />
                        
                        <MenuItem name="help" link="http://help.ariscat.com/cz" icon={<RIcons.Fa.FaHandsHelping />} />
                        <MenuItem name="documentation" link="/documentation" icon={<RIcons.Hi.HiDocument />} />
                        <MenuItem name="extendFeatures" link="/extFeatures" icon={<RIcons.Hi.HiOutlineCog />} />
                    </ul>
                </div>
                <div className="menu-shrinker" onClick={this.closeMenu}>
                    <If is={this.hideMenu}>
                        <div className="inner">
                            <RIcons.Bs.BsFillArrowRightSquareFill />
                        </div>
                    </If>
                    <If is={!this.hideMenu}>
                        <div className="inner">
                            <RIcons.Bs.BsFillArrowLeftSquareFill /> {_.hideMenu}
                        </div>
                    </If>
                </div>
            </motion.div>
        )
    }
}

class EmpMenu extends SComponent {
    hideMenu = false;

    closeMenu = ()=> {
        this.hideMenu = !this.hideMenu;
        document.querySelector("#main").style.gridTemplateColumns = this.hideMenu ? "3.5rem 1fr" : "";
        this.refresh();
    }

    rnd() {
        return (
            <div id="menu" className={this.cln({hideMenu: this.hideMenu})}>
                <div className="nav-wrapper">
                    <ul className="navigation">
                        <MenuItem name="dealers" link="/dealers" icon={<RIcons.Ai.AiFillAccountBook />} />
                        <MenuItem name="settings" link="/settings" icon={<RIcons.Hi.HiOutlineCog />} />
                        <MenuItem name="messages" link="/messages" icon={<RIcons.Fa.FaAddressBook />} />
                        <MenuItem name="statistics" link="/dstats" icon={<RIcons.Fa.FaTable />} />
                        <MenuItem name="iotModules" link="/iot" icon={<RIcons.Hi.HiOutlineCog />} visible={Repo.stg.cs__iotModuleEnabled} />
                        <MenuItem name="sysInfo" link="/sysInfo" icon={<RIcons.Fa.FaInfoCircle />} visible={Repo.stg.cs__messages} />
                        <MenuItem name="help" link="http://help.ariscat.com/cz" icon={<RIcons.Fa.FaHandsHelping />} />
                        <MenuItem name="documentation" link="/documentation" icon={<RIcons.Hi.HiDocument />} />
                        <MenuItem name="extendFeatures" link="/extFeatures" icon={<RIcons.Hi.HiOutlineCog />} />
                    </ul>
                </div>
                <div className="menu-shrinker" onClick={this.closeMenu}>
                    <If is={this.hideMenu}>
                        <div className="inner">
                            <RIcons.Bs.BsFillArrowRightSquareFill />
                        </div>
                    </If>
                    <If is={!this.hideMenu}>
                        <div className="inner">
                            <RIcons.Bs.BsFillArrowLeftSquareFill /> {_.hideMenu}
                        </div>
                    </If>
                </div>
            </div>
        )
    }
}

export class Menu extends Component {
    render() {
        const DlEmpMenu = Repo.stg?.bIsProducEmp ? EmpMenu : DealerMenu;

        return <DlEmpMenu />
    }
}
