import './styl.scss'
import { SComponent, Rq, Ariscat, Comp } from "../../dump";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

export class WaresLinks extends SComponent {
	async cmount(s, p) {
        this.data = [];
        this.colCount = 4;
        this.groups = (await Rq.sd("dvgroups", 1)).rows;
        const {rows} = await Rq.sd("dvgroupLinks", 1);

        //this.data = rows.sort((a, b) => a.iGrpPoradi - b.iGrpPoradi).map(row => {
        this.data = rows.sort((a, b) => a.iGrlPoradi - b.iGrlPoradi).map(row => {
            row.id = row.ID;
            row.icon = Ariscat.createB64(this.groups.find(f => f.nsGrpNazev == row.nsGrlSkupina)?.blbGrpIkona)
            return row;
        });

        if(rows.length <= 4) this.colCount = rows.length;
	}

    sortItems = data => {
        console.log("sort", this.timerHandle);
        this.timerHandle && clearInterval(this.timerHandle);
        this.data = data;
        this.save();
        this.refresh();
    }
    
	save = async () => {
        const data = this.data.map((d,i) => {
            return { 
                ID: d.ID, iGrlPoradi: i, iGrlSkupinaID: 0
            }
        });

        this.timerHandle = setTimeout(async () => {
            console.log("save");
            await Rq.up("dvgroupLinks", 2, data);
            Rq.deleteCache("produkt");
        }, 1200);
	}

	rnd = (s) => {
        return (
            <Comp className='panel'>
                <header>Nastavení výrobků</header>
                <RLDD
                    items={this.data}
                    itemRenderer={row => 
                        <div class="item ware vybrat" onClick={e=> this.props.onChange ? this.props.onChange(row) : this.selectFile(row)}>
                            <span class="ikona" style={{background: `url(${row.icon})`}}></span>
                            <span class="popis">{row.nsGrlVyrobek || _.allItems}</span>
                        </div>
                    }
                    onChange={this.sortItems}
                />
            </Comp>
        )
	}
}