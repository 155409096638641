import { SComponent, _, Table, route, Ref, Comp, Repo } from '../dump';

export class Customers extends SComponent {
    newCustomer = async () => {
        const ID = await Rq.newRecord("organizace", {nsOrgNazev: _.new, bOrgAktivni: true, iOrgLangID: Repo?.langNum || 1 });
        route("/customer/" + ID);
        Ref.notify(_.custChnCrt, 8, "success");
    }

    rnd = (s, p) => {
        return (
            <Comp className="customers">
                <Table recordset="organizace_prehled" cols="0" detail={rec => route("/customer/" + rec.ID)}>
                    <bar-button
                        type="add"
                        icon="Plus"
                        text=""
                        click={this.newCustomer}
                    />
                    <tab-button
                        type="warning"
                        icon="Edit"
                        click={async rec => {
                            this.customerID = rec.ID;
                            this.tabRec = rec;
                            this.customer = await Rq.info("organizace_prehled", rec.ID);
                            this.refresh();
                        }}
                    />
                    <tab-button
                        type="primary"
                        icon="cog"
                    >
                        <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                    </tab-button>
                </Table>
            </Comp>
        )
    }
}
