import { RIcons, Ref, SComponent, _ } from "../../dump";

export class HmMessages extends SComponent {
    async cmount() {
        this.msgColumns = [
            'ID',
            'bMsgConfirmation',
            'bMsndRead',
            'dMsgPorizeno',
            'dMsgZmeneno',
            'dMsndPorizeno',
            'dMsndReadDate',
            'iMsndIDmsg',
            ['blbMsgIcon', 'Binary'],
            ['nsMsgIconExt', 510],
            ['nsMsgSubject', 100],
            ['nsMsgText', 'WideText'],
            ['nsMsgType', 100],
        ];

        this.messages = [];
        this.unreaded = [];
        this.readed = [];
        const {rows} = await Rq.sd('strv_msg_send', this.msgColumns);
        this.messages = rows.sort((a,b) => b.dMsgPorizeno - a.dMsgPorizeno); 
        this.readed = this.messages.filter(f => f.bMsndRead == true);
        this.unreaded = this.messages.filter(f => f.bMsndRead == false);
    }

    filterHTML = (nsMsgText) => {
        const div = document.createElement("div");
        div.innerHTML = nsMsgText;
        div.innerHTML = div.innerText;
        const text = div.innerText.substring(0,100) + '...';
        return text;
    }

    openMsg(msg) {
        Ref.Messages?.openMsg(msg);
    }

    rnd(s,p) {
        return (
            <div className="hmMessages">
                {this.messages.sort((a,b) => b.dMsgPorizeno - a.dMsgPorizeno).filter((f, i) => i < 5).map(msg => {
                    let msgImg = "/src/img/empty.jpg";
            
                    if(msg.blbMsgIcon) {
                        msgImg = msg.nsMsgIconExt + "," + atob(msg.blbMsgIcon);
                    }
            
                    return (
                        <div key={msg.ID} className="hmMsg grid-12" onClick={()=>this.openMsg(msg)}>
                            <div className="msgImg" style={{backgroundImage: `url(${msgImg})`}} />
                            <div className="msgData">
                                <div className="msgType">{Ariscat.isNumber(msg.nsMsgType) ? _.other : msg.nsMsgType}</div>
                                <div className="msgTitle">{msg.nsMsgSubject}</div>
                                <div className="msgText">{this.filterHTML(msg.nsMsgText || "")}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}