import { RInput, _, SDate, Rq, RIcons, Ref, Ariscat } from "../../dump";

export class Imports {	
	constructor(ID) {
		this.ID = ID;
	}

	async getPolozkaData() {
		var pd = await Rq.info("polozka", this.polozkaID, 0, "", { iPoziceID: Ariscat.agenda.poziceID });

		if(pd.error) {
			Ref.notify(_.csvImpEr, 5, "error");
		}

		var vals = pd.nsPolArisValues.split('\r\n');
		var retVals = {};

		for(let val of vals) {
			if(val) {
				var kv = val.split('=');
				retVals[kv[0]] = kv[1];
			}
		}

		return retVals;
	}

	//prevede csv na json
	csvOnJson(csv) {
		//odstrani \r a utf-8 hlavicku
		csv = csv.replace("\uFEFF", "").replace(/\r/g, "");
		var data = csv.split("\n").filter(f => f);
		var sep = ";"

		//format libre office
		if(!csv.includes(";")) sep = ",";

		//format excel (oddelovac taby)
		if(csv.includes("\t")) sep = "\t";

		var tab = data.map(m => m.split(sep))
		var vaseZnacka = false;

		let keyRow = 0;
		for(let row of tab) {		
			if(row[0] == "Vase_znacka") {
				vaseZnacka = true;
				break;
			}
			keyRow++
		}

		if(!vaseZnacka) {
			return {cols: [], rows: [], error: "Vase_znacka"};
		}

		var dkeys = tab[keyRow];

		//odfiltruji se radky nad Vase_znacka
		tab.splice(0,keyRow+1);

		var json = {cols: dkeys};

		json.rows = tab.map(m => {
			var obj = {};
			m.forEach((n,y) => obj[dkeys[y]] = n);
			return obj
		});

		return json;
	}

    importCSV = async () => {
		let file = await Ariscat.selectFile(false, ".csv");

		if(file.size > (1024*1024)) {
		  Ref.notify(_.bigFile + " 1 MB!", 4, 'danger');
		  return false;
		}

		var txt = await file.read(file, "utfw");
		this.json = this.csvOnJson(txt);

		if(!this.json.length) {
			return Ref.notify(_.csvEmpty, 4, 'danger');
		}

		var j = this.json[0];

		if(
			!j.hasOwnProperty("Vase_znacka") ||
			!j.hasOwnProperty("Pozadovany_Datum") ||
			!j.hasOwnProperty("Komentar")
		) {
			return Ref.notify(_.csvContainCol + " Vase_znacka, Pozadovany_Datum, Komentar!", 8, 'danger');
		}

		const same = this.json.every((val, i, ar) => val.Vyrobek === ar[0].Vyrobek);

		if(!same) {
			return Ref.notify(_.onlyOneType, 6, 'danger');
		}

		//Ariscat.loading.showLoading('#form', _.dataLoading);

		//upravi udaje k pop/obj
		console.log("aktualizace");

		await Rq.up(this.recordset, 2, {
			ID: this.ID, 
			[`d${this.pf}TerminPozad`]: SDate(j.Pozadovany_Datum), 
			[`ns${this.pf}Oznaceni`]: j.Vase_znacka, 
			[`ns${this.pf}KomentProdej`]: j.Komentar
		});

        this.data[`d${this.pf}TerminPozad`] = j.Pozadovany_Datum;
        this.data[`ns${this.pf}Oznaceni`] = j.Vase_znacka;
        this.data[`ns${this.pf}KomentProdej`] = j.Komentar;
		
		//ziska seznam vyrobku a zalozi novy vyrobek
		const {rows} = await Rq.sd("produkt_poz", 1);
		var fnd = rows.find(f => f.nsPrdZkratka == j.Vyrobek);

		if(!fnd) {
			//Ariscat.loading.hideLoading('#form');
			return Ref.notify(_.productNotFound + ` (${j.Vyrobek})!`, 6, 'danger');
		}

		var res = await Rq.up("pozice", 2, {iPozZakID: this.ID, iPozPrdID: fnd.ID, nsPozArisValues: "" });
		this.polozkaID = 0;
		this.poziceID = res.rows.length ? res.rows[0].ID : 0;

		var crows = await this.createRows();
		var res = await Rq.up("polozka", 2, crows, { raw: true });

		if(res.ferror) {
			//Ariscat.loading.hideLoading('#form');
			//Ariscat.agenda.pozice.poziceTable();
			console.error(res);
			return Ref.notify(_.uploadError + ` CSV!`, 6, 'danger');
		}

		//ukaze tabulku s vyrobkem
		//await this.updateVals();
		//Ariscat.agenda.pozice.poziceTable();
		
		//location.reload();
		//Ariscat.loading.hideLoading('#form');
		console.log("JSON", this.json, res);

		this.refresh();
	}

	async checkItems(e) {
		e.preventDefault();
		var $target = $(e.currentTarget);
		Ariscat.agenda.polozky.checkItems();
		return false;
	}

	async createRows() {
		this.pd = {};
		
		if(this.polozkaID) {
			this.pd = await this.getPolozkaData();
		}

		var rows = [];

		for(let jrow of this.json.rows) {
			var newItem = {...this.pd};

			for(let [key,val] of Object.entries(jrow)) {
				newItem[key] = Ariscat.htmlEntities(val);
			}
			
			var row = {
				bPolImportError: true,
				iPolPozID: this.poziceID,
				nsPolArisValues: Object.entries(newItem)
					.filter(([k,v]) => k != "Vase_znacka" && k != "Pozadovany_Datum" && k != "Komentar" && k != "Vyrobek")
					.map(([k,v]) => k + "=" + v + "\n")
					.join("")
			}
			
			rows.push(row);
		}

		console.log("ROWs", rows);

		return rows;
	}

	async updateVals() {
		//aktualizuje tabulku
		await Ariscat.agenda.polozky.polozkyTable();

		var dataTab = Ariscat.agenda.tabs.getActiveTab();
		var aItems = await Ariscat.agenda.polozky.polozkyTable() || {};

		for(let item of aItems) {
			if (!dataTab.items[item.ID]) {
				dataTab.items[item.ID] = {id: ""+item.ID, data: null, saved: true};
			}
		}
	}

	async exportFile() {
		var tabData = Ariscat.agenda.tabs.getActiveTab();
		var cols = [['nsPolArisValues', { width: 8000, fieldtype: "string" }]];
		var { rows } = await Rq.sd("polozka", cols, 0, { iZakID: Ariscat.agenda.ID, iPoziceID: tabData.positionID });
		var prodInfo = (await Rq.info("produkt", tabData.productID)) || {};
		var jsbt = prodInfo.nsPrdInpJSBT;
		var prodData = JSON.parse(jsbt.replaceAll('`', '"').replaceAll('__backtick__', '`'))
		var csvVals = [];
		this.prodData = prodData;
	
		var types = {
			C: _.selection,
			E: _.text,
			S: _.text,
			M: _.longText,
			K: _.yesNo,
			I: _.img,
			L: _.label
		}
	
		var colsCap = ["Vaše značka", "Požadovaný datum", "Komentář", "Výrobek", ...prodData.map(m => m.capt)];
		var infoCap = ["Text", "Datum", "Text", "Text", ...prodData.map(m => types[m.type] || m.type)];
		var csvCols = ["Vase_znacka", "Pozadovany_Datum", "Komentar", "Vyrobek", ...prodData.map(m => m.name)];
		var csvRows = [];
		var csvHelp = [
			[_.hlpFilCsv], 
			[_.rowBeginVsZnacka],
			[_.folowLinFldTyp],
			[_.typYesNoMsk.replace("$1", _.yesNo)],
			[_.typSelMeanSelect.replace("$1", _.selection)],
			[_.wrtDownValsAc],
			[_.somValsMand],
			[_.folwLnFldName],
			[_.frst3ColsNotTrMsk],
			[``]
		];
	
		for(let row of rows) {
			var rowObj = {};
			var arisVals = row.nsPolArisValues.split("\r\n");
	
			for(let av of arisVals) {
				var [key, val] = av.split("=");
				val = val === undefined ? "" : val;
				key && (rowObj[key] = val);
			}
	
			//console.log("rob", rowObj)
			csvVals.push(rowObj)
		}
	
		csvRows.push(colsCap);
		csvRows.push(infoCap);
		csvRows.push(csvCols);

		csvHelp.push(...csvRows);
		var enuMax = Math.max(...this.prodData.map(m => m.enum ? m.enum.length : 0));
	
		for(let av of csvVals) {
			var crow = csvCols.map(key => {
				return (av[key] === undefined ? "" : av[key])
			});
	
			//crow[0] = "123";
			csvRows.push(crow);
		}

		//prida zakladni udaje
		csvRows[3][0] = document.getElementsByName("ns%aOznaceni")[0].value;
		csvRows[3][1] = document.getElementsByName("d%aTerminPozad")[0].value;
		csvRows[3][2] = document.getElementsByName("ns%aKomentProdej")[0].value;
		csvRows[3][3] = prodInfo.nsPrdZkratka;
	
		console.log(`{"${prodInfo.nsPrdZkratka}": ` + JSON.stringify(csvVals, null, 2) + "}");
		
		for(let i=0;i<enuMax;i++) {
			var pz = i==0 ? prodInfo.nsPrdZkratka : "";
			var crow = [`;;;${pz}`, ...prodData.map((m => m.enum ? m.enum[i] === undefined ? "" : m.enum[i] : ""))];
			csvHelp.push(crow);
		}
	
		var content = "\uFEFF" + csvRows.map(m => m.join(";")).join("\r\n");
		var file = new Blob([content], { type: 'text/csv' });
		this.downloadFile("tab.csv", file);

		//console.log("csvH", csvHelp);

		content = "\uFEFF" + csvHelp.map(m => m.join(";")).join("\r\n");
		file = new Blob([content], { type: 'text/csv' });
		this.downloadFile(`tab-${_.help2}.csv`, file);
		
		return content;
		//return csvRows
	}

	async importFile2(e) {
		e.preventDefault();
		var ag = Ariscat.agenda;

		let file = await Ariscat.app.selectFile(false, ".csv");

		if(file.size > (1024*1024)) {
		  Ref.notify(_.bigFile + " 1 MB!", 4, 'danger');
		  return false;
		}

		var txt = await file.read(file, "utfw");
		this.txt = txt;
		this.json = this.csvOnJson(txt);

		if(
			!this.json.cols.includes("Vase_znacka") ||
			!this.json.cols.includes("Pozadovany_Datum") ||
			!this.json.cols.includes("Komentar") || 
			this.json.error
		) {
			return Ref.notify(_.csvContainCol + " Vase_znacka, Pozadovany_Datum, Komentar!", 8, 'danger');
		}

				
		if(!this.json.rows.length) {
			return Ref.notify(_.csvEmpty, 4, 'danger');
		}

		//upravi udaje k pop/obj
		console.log("aktualizace");
		const popob = ag.recordset == "objednavka" ? "Obj" : "Pop";
		const fr = this.json.rows[0];

		//pokud se zaklada novy vyrobek, aktualizuje i tyto pole
		await Rq.up(ag.recordset, 1, {
			ID: ag.__ID, 
			[`d${popob}TerminPozad`]: SDate(fr.Pozadovany_Datum), 
			[`ns${popob}Oznaceni`]: fr.Vase_znacka, 
			[`ns${popob}KomentProdej`]: fr.Komentar
		});

		document.getElementsByName("d%aTerminPozad")[0].value = fr.Pozadovany_Datum;
		document.getElementsByName("ns%aOznaceni")[0].value = fr.Vase_znacka;
		document.getElementsByName("ns%aKomentProdej")[0].value = fr.Komentar;

		if(!(await this.checkCSVImport())) {
			return;
		}

		//vyrobekID - ziska se z this.checkCSVImport()
		var res = await Rq.up("pozice", 1, {iPozZakID: ag.__ID, iPozPrdID: this.vyrobekID, nsPozArisValues: "" });
		this.polozkaID = 0;
		this.poziceID = res.rows.length ? res.rows[0].ID : 0;

		var crows = await this.createRows();

        const fields = [
			'bPolImportError',
			'iPolPozID',
			['nsPolArisValues', {width: 8000, fieldtype: "string"}],
        ]

		var res = await Rq.up("polozka", fields, crows, { raw: true });

		if(res.ferror) {
			Ariscat.agenda.pozice.poziceTable();
			console.error(res);
			return Ref.notify(_.uploadError + ` CSV!`, 6, 'danger');
		}

		var pozice = res.rows[0].iPolPozID;

		//ukaze tabulku s vyrobkem
		//await this.updateVals();
		$('a[href="#summary"]').tab("show");
		Ariscat.agenda.pozice.renderTabs = true;
		Ariscat.agenda.tabs.deleteTabs();
		await Ariscat.agenda.pozice.poziceTable();

		var fndTab = Ariscat.agenda.tabs.tabs.find(f => f && f.positionID == pozice);
		await Ariscat.agenda.tabs.selectTab(fndTab.tabID);
		Ref.notify(_.checkingVals, 2, "info");
		await Ariscat.wait(1000);
		Ariscat.agenda.polozky.checkItems();
		//console.log("JSON", this.json, res);
	

		return false;
	}	

	async checkCSVImport(add = false) {
		//ziska seznam vyrobku a zalozi novy vyrobek
		const {rows} = await Rq.sd("produkt", 1);
		const fr = this.json.rows[0];
		var vyrobek = rows.find(f => f.nsPrdZkratka == fr.Vyrobek);
		var dataTab = Ariscat.agenda.tabs.getActiveTab();

		if(add && (!vyrobek || vyrobek.ID != dataTab.productID)) {
			Ref.notify(_.prodInCsvNotCor, 10, 'error');
			return false;
		}

		if(!vyrobek) {
			Ref.notify(_.productNotFound + ` (${fr.Vyrobek})!`, 6, 'error');
			return false;
		}

		this.vyrobekID = vyrobek.ID;

		let csvErrors = [];
		var jsbt = vyrobek.nsPrdInpJSBT;
		var prodData = JSON.parse(jsbt.replaceAll('`', '"').replaceAll('__backtick__', '`'))

		var origCols = ["Vase_znacka", "Pozadovany_Datum", "Komentar", "Vyrobek", ...prodData.map(m => m.name)];
		var csvCols = this.json.cols;
		this.prodData = prodData;

		if(origCols.join(";") != csvCols.join(";")) {
			csvErrors.push(_.colNmsNotMtch);
		}

		if(origCols.length != csvCols.length) {
			csvErrors.push(`${_.numColsCSVDif} ${_.mask}/CSV = ${origCols.length}/${csvCols.length}`)
		}

		var badFils = this.json.rows.filter(
			(f,i) => i > 0 && (
				f.Vyrobek != "" || 
				f.Komentar != "" || 
				f.Pozadovany_Datum != "" || 
				f.Vase_znacka != ""
			)
		);

		if(badFils.length) {
			csvErrors.push(`
				${_.cols1234FilIn.replace("$1", "Vase_znacka").replace("$2", "Pozadovany_datum").replace("$3", "Komentar").replace("$4", "Vyrobek")}
				<b>${_.valsFromNextIgn}</b><br>${_.csvOnlySup1Prod}
			`)
		}

		if([...new Set(this.json.rows.map(m => m.Vyrobek).filter(f => !!f))].length != 1) {
			csvErrors.push(_.onl1ProdTypFil);
			await Ariscat.alert.dialog(_.badCSV, csvErrors.join("<br><br>"), 0);
			return false;
		}

		var badCols = [];

		for(let b of csvCols) {
			if(!origCols.includes(b)) {
				badCols.push(b)
			}
		}

		if(badCols.length) {
			csvErrors.push(`
				<b>${_.csvContBadCols}</b> ${badCols.join(", ")}.
				<br>${_.ifTypeErNotLoad}
				<br>${_.plsCheckCsv}
			`)
		}

		//pokusi nastavit hodnotu bud 1 nebo 0, podle textove hodnoty
		var checkboxes = prodData.filter(f => f.type == "K").map(m => m.name);
		var selects = prodData.filter(f => f.type == "C" && f.enum).map(m => m.name);
		//jako "Ano" se berou vsechny hodnoty, krome tohoto seznamu:
		var no = [_.no, "ne", "nie", "no", "0", "-", "00", "--", "---",];
		var yes = [_.yes, "ano", "yes", "ok", "1", "jo", "11", "a", "y", "j"];

		for(var row of this.json.rows) {
			for(var [key,val] of Object.entries(row)) {
				//pokusi se opravit velikost pismen, aby sedela s hodnotou ze seznamu
				if(selects.includes(key)) {
					var enm = prodData.find(f => f.name == key).enum;

					if(enm && !enm.includes(val)) {
						const okVal = enm.find(f => f.toLowerCase() == val.toLowerCase())
						if(okVal !== undefined) row[key] = okVal;
					}
				}
			}
		}

		//zkontroluje preklepy v hodnotach ze seznamu, pokud jsou vyplneny
		var enums = {}
		var checkboxes = {};
		prodData.filter(f => (f.enum||{}).length).forEach(f => enums[f.name] = f);
		prodData.filter(f => f.type == "K").forEach(f => checkboxes[f.name] = f);
		var badSelects = [];

		var ii = 4;
		for(let rw of this.json.rows) {
			for(let [key,val] of Object.entries(rw)) {
				if(val != "" && enums[key] && enums[key].enum && !enums[key].enum.includes(val)) {
					var repairVal = Ariscat.repairTypeError(val, enums[key].enum);
					rw[key] = repairVal;
					badSelects.push(`<tr><td>${ii}</td><td>${enums[key].capt}</td><td>${val.replace(/^\s+|\s+$/g, "␣")}</td><td>${this.repairSelect(enums[key].enum, ii, key, repairVal, val)}</td>`)
				}

				if(checkboxes[key] && no.includes(val)) {
					rw[key] = 0;
				}
				else if(checkboxes[key] && yes.includes(val)) {
					rw[key] = 1;
				}
				//pokud hodnota ano/ne je neznama, da na vyber, kterou hodnotu vyplnit
				else if(checkboxes[key] && !no.includes(val) && !yes.includes(val)) {
					//csvErrors.push(`spatna hodnota!!! ` + key);
					var repairVal = Ariscat.repairTypeError(val, [...yes, ...no]);
					repairVal = no.includes(repairVal) ? 0 : 1;
					rw[key] = repairVal;
					badSelects.push(`<tr><td>${ii}</td><td>${checkboxes[key].capt}</td><td>${val}</td><td>${this.repairSelect([1, 0], ii, key, repairVal, val)}</td>`)
				}
			}
			ii++;
		}

		if(badSelects.length) {
			csvErrors.push(
				`<b>${_.csvContBadVals}</b> ${_.canEditValsTab}<br>
				<table id="csvWarningTab"><tr><th>${_.row}</th><th>${_.column}</th><th>${_.wrongVal}</th><th>${_.corVal}</th></tr>` 
				+ badSelects.join("\n") + "</table>"
			)
		}

		if(csvErrors.length) {
			this.origc = prodData;
			var contn = await Ariscat.alert.dialog(_.badCSV, csvErrors.join("<br><br>"), _.continue, _.cancUpld);
			if(!contn) return false;
		}

		return true;
	}

	repairSelect(enms, row, key, selVal, origVal) {
		var options = ["", origVal, ...enms].map(m => `<option class="${m == origVal ? "error" : ""}" data-row="${row}" data-key="${key}" ${selVal == m ? "selected" : ""}>${m}</option>`).join("");
		return `<select data-row="${row}" data-key="${key}" class="repairSelect">${options}</select>`;
	}

	repairValue(e) {
		console.log("eee", e);
		var row = +e.target.dataset.row;
		var key = e.target.dataset.key;
		//console.log("oprava", e.target.value, row, key);
		this.json.rows[row-4][key] = e.target.value;
	}
	
	downloadFile(name, blob) {
		var a = document.createElement("a");
		var url = URL.createObjectURL(blob);
	
		a.href = url;
		a.download = name;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	async importJSONGuid(guid, pobobID) {
		Ref.notify(_.impDtJson, 3, 'info');

		const res = await Rq.sd("imp_order", 1, {}, {nsImpGuid: guid});

		if(res.ferror) return Ref.notify(res.error.join(", "), 6, 'danger');

		if(!res.rows.length) return Ref.notify(_.badGuid + "!", 6, 'danger');

		this.popob = res.rows[0];
		this.jsonStr = (this.popob.nsImpDataJSBT || "").replaceAll('`', '"').replaceAll('__backtick__', '`');

		try {
			this.json = JSON.parse(this.jsonStr);
		}
		catch {
			return Ref.notify(_.erJson, 4, 'danger');
		}

		//umela chyba pro testovani
		//this.json.prods.VII = this.json.prods.VIC;
		//delete this.json.prods.VIC;

		this.imorProds = Object.keys(this.json.prods || {});
	
		//Ref.notify(`Vytváří se pozice...`, 2, 'info');
		const resProd = await Rq.sd("produkt", 1);
		this.prods = resProd.rows;

		if(!this.imorProds.length) {
			return Ref.notify(_.erJsonNoProd, 4, 'danger');
		}

		for(var prod of this.imorProds) {
			var ok = await this.createProd(prod);
			
			if(!ok) break;
		}

		//ukaze tabulku s vyrobkem
		//await this.updateVals();
		/*
		$('a[href="#summary"]').tab("show");
		Ariscat.agenda.pozice.renderTabs = true;
		Ariscat.agenda.tabs.deleteTabs();
		await Ariscat.agenda.pozice.poziceTable();
		*/

		/*
		for(var tab of Ariscat.agenda.tabs.tabs) {
			if((tab||{}).tabID === undefined) continue;

			await Ariscat.agenda.tabs.selectTab(tab.tabID);
			Ref.notify(_.checkingVals, 2, "info");
			await Ariscat.wait(1000);

			try {
				await Ariscat.agenda.polozky.checkItems(true, false);
			}
			catch(e) {
				Ref.notify(_.failChkItm, 5, "error");
				console.error(_.failChkItm, e);
			}

			await Ariscat.wait(1000);
		}
		*/

		Ref.notify(_.impCompCheckVals, 5, "info");
		return true;
	}

	async createProd(prod) {
		var prodInfo = this.prods.find(f => f.nsPrdZkratka == prod);

		if(!prodInfo) {
			Ref.notify(_.erProdNotExst.replace("%1", prod), 5, "error");
			return false;
		}

		var jsonErrors = {
			colLength: false,
			badCols: []
		}

		var jsbt = prodInfo.nsPrdInpJSBT || "";
		try {
			var prodData = JSON.parse(jsbt.replaceAll('`', '"').replaceAll('__backtick__', '`'))
		}
		catch {
			Ref.notify(_.erImpFilProd.replace("%1", prod), 5, "error");
			return false;
		}
		
		var jsonProd = this.json.prods[prod] || [];
		var origCols = prodData.map(m => m.name);
		var has__row = jsonProd.map(m => m.__row).some(e => e !== undefined);

		if(has__row) {//pokud ma v sobe __row, tak se podle toho setridi
			jsonProd.sort((a,b) => a.__row - b.__row);
		}

		for(var jsp of jsonProd) {
			delete jsp.__row;//uz neni potreba
			var jsonCols = Object.keys(jsp);
			
			for(var jc of jsonCols) {
				if(!origCols.includes(jc) && !jsonErrors.badCols.includes(jc)) {
					jsonErrors.badCols.push(jc);
				}
			}

			if(jsonCols.length != prodData.length) jsonErrors.colLength = true;
			//console.log("PRODDATA", jsonErrors);
		}

		//if(jsonErrors.colLength) {
		if(false) {//nebude se pouzivat		
			var msg1 = _.impFilProdNotCont.replace("%1", prod);
			var msg2 = null;
			var msg3 = _.wntContinue;

			if(jsonErrors.badCols.length) {
				msg2 = _.csvContBadCols.replace("%1", prod) + " " + jsonErrors.badCols.join(", ");
			}

			var ok = await Ariscat.alert.dialog(_.importEr, [msg1, msg2 , msg3].join("<br>"), "Pokračovat", "Přerušit");	
			if(!ok) return false;
		}

		var res = await Rq.up("pozice", 1, {iPozZakID: this.ID, iPozPrdID: prodInfo.ID, nsPozArisValues: "" });

		if(res.ferror) {
			Ref.notify(_.erCrtProdPos + ": " + prod, 5, "error");
			return false;
		}

		this.polozkaID = 0;
		this.poziceID = res.rows.length ? res.rows[0].ID : 0;
		var crows = await this.createPolRows(jsonProd);

        const cols = [
			'bPolImportError',
			'iPolPozID',
			['nsPolArisValues', {width: 8000, fieldtype: "string"}],
        ]

		var resPol = await Rq.up("polozka", cols, crows, { raw: true });

		if(resPol.ferror) {
			Ref.notify(_.erUpdItm, 5, "error");
			return false;
		}

		return true;
	}

	async createPolRows(prodRows) {
		var rows = [];

		for(let jrow of prodRows) {
			var newItem = {};

			for(let [key,val] of Object.entries(jrow)) {
				newItem[key] = Ariscat.htmlEntities(val);
			}
			
			var row = {
				bPolImportError: true,
				iPolPozID: this.poziceID,
				nsPolArisValues: Object.entries(newItem).map(([k,v]) => k + "=" + v + "&#x0D;&#x0A;").join("")
			}
			
			rows.push(row);
		}

		return rows;
	}
}