import './style.scss'
import { InfoComp } from './infoComp'
import { Functions } from './functions'
import { _, SComponent, RIcons, Comp as div } from '../../dump'
import { UserState } from './userState'
import { Products } from './products'
import { Branches } from './branches'
import { Wares } from '../../components/wares/wares';
import { Recordsets } from './recordsets'

import { Tab } from '../../components/tab/tab'
import { WaresLinks } from '../../components/wares/waresLinks'
import { createRef } from 'preact'

const tab = createRef();

function DlSettings() {
    return (
        <div className="comp settings dealerStg">
            <Tab ref={tab}>
                <tab-content title={_.branches}><Branches /></tab-content>
                <tab-content title={_.products}><Products tab={tab} /></tab-content>
                <tab-content title={_.prodOrder}><WaresLinks /></tab-content>
                <tab-content title={_.infoAboutComp}><InfoComp /></tab-content>
                <tab-content title={_.userState}><UserState /></tab-content>
                <tab-content title={_.features}><Functions /></tab-content>
            </Tab>
        </div>
    )
}

function EmpSettings() {
    return (
        <div className="comp settings empStg">
            <Tab ref={tab}>
                <tab-content title={_.features}><Functions /></tab-content>
                <tab-content title={_.recordsets}><Recordsets /></tab-content>
                <tab-content title={_.productGroups}><Wares /></tab-content>
            </Tab>
        </div>
    )
}

export class Settings extends SComponent {
    cmount() {
        this.isEmp = Repo.stg?.bIsProducEmp;
    }

    rnd() {
        return this.isEmp ? <EmpSettings /> : <DlSettings />
    }
}