import './styl.scss'
import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, createRef, RIcons, Ref, route, Comp, Ariscat, Btn, InputLn } from "../../dump";
import { OSM } from '../../components/OSM/OSM';
import { Events } from './events';
import { DocTab } from './docTab';
import { Strom } from './strom';
import { Documents } from './documents';
import { ButtonMenu } from '../../components/buttonMenu/buttonMenu';
import { AtchTable } from '../../components/attachments/atchTable';

export class Deal extends SComponent {
    activeTab = "";
    zakazka = {};
    pobocky = [];
    stavyZakazky = [];
    strom = [];
    adreses = [];
    changed = {};
    atchList = createRef();
    eventsRef = createRef();
    attchm = [];
    loaded = false;

    tabChange = () => { }

    save = (val,key) => { 
        console.error("s", val, key);

        Rq.up(this.recordset, 2, {ID: this.ID, [key]: val});
    }

    async cmount(s, p) {
        this.ID = this.props.ID;
        this.loaded = false;
        this.recordset = "zakazka";

        this.mapLoaded = {
            osm1: false,
            osm2: false,
        }

        this.setState({
            saved: "saved",
            modalHidden: "hidden",
            open: false,
            gmap: false
        });

        this.zakazka = [];
        this.defaultAdr = "Praha";
        this.selAdrRef1 = createRef();
        this.selAdrRef2 = createRef();
        this.orgRef = createRef();
        this.selObRef1 = createRef();
        this.osm = createRef();

        this.vrJoin = true;//this.si.bAnyVrbcJoined == "1";

        if (!this.vrJoin) {
            return Ref.notify(_.noVrbcConn, 20, 'error');
        }

        await this.getData();
    }

    async getData() {
        const { rows } = await Rq.sd("zamestnanec", 1);
        //http://localhost/react/deals/168646

        this.zamList = rows;

        this.zakazka = await Rq.info(this.recordset, this.ID);
        console.log("zak", this.zakazka);
        this.org = (await Rq.sd("organizace", 1)).rows;
        this.strom = (await Rq.sd("stromnab", 1, 0, { iParentZakID: this.ID })).rows;
        this.orgSel = this.org.find(f => f.ID == this.zakazka.iZakOrgID) || {};
        this.loadAtch();

        this.pobocky = (await Rq.sd("dealer_pob")).rows;
        this.stavyZakazky = (await Rq.sd("zakuzivstav")).rows;
        this.set.kontaktniOsoba = this.zakazka.nsOrgKontOsoba;

        this.langs = [
            { ID: 1, lang: _.cs },
            { ID: 2, lang: _.en },
            { ID: 3, lang: _.de },
            { ID: 4, lang: _.sk },
            { ID: 5, lang: _.fr },
            { ID: 6, lang: _.pl },
        ]

        //xc["navbar-breadcrumb"].h = `<li><a href='/r/deals'>${_.orders}</a></li><li>${_.deal} - ${this.zakazka.nsZakCislo || "?"}</li>`;
        this.loaded = true;
        this.onLoad(this.zakazka.nsZakCislo);
        Ref.Header.tagLinks = ["/deals"];
        this.loaded = true;
        this.adreses = await this.osm.current.initAdreses(this.zakazka.nsZakDodaciAdr);
        await Ariscat.wait(1);
        this.osm.current.centerMap();
    }

    async loadAtch() {
		console.log("la");
		this.attchm = await Rq.sd('attchm', 1, 0, { iPOID: this.ID });
		this.refresh();
	}

    cusOffer() {

    }

    async onLoadMap(e) {
        console.log("map", e);
        this.adreses = e.adreses;
        this.refresh();
    }

    changeAdr = (val, key, data) => {
        const adr = data.display_name;
        this.changed[key] = adr;
        console.log("change", adr, key);
        this.save(adr, "nsZakDodaciAdr");
        this.osm.current.showAddressData(data);
    }

    updateAdr = async adr => {
        console.log("upd", adr);
        this.zakazka.nsZakDodaciAdr = adr;
        this.changed.nsZakDodaciAdr = adr;
        this.save(adr, "nsZakDodaciAdr");
        this.adreses = await this.osm.current.initAdreses(adr);
        return this.adreses;
    }

    formatDeal = row => {
        const deal = this.deals.find((f => f.ID == row.iZakID)) || {};
        row.deal = deal;
        return deal.nsZakCislo || "-";
    }

    formatCustomer = row => {
        const deal = this.deals.find((f => f.ID == row.iZakID)) || {};
        row.deal = deal;
        return deal.nsOrgNazev || "-";
    }

    delDeal = async () => {
        await Rq.delConfirm(this.recordset, this.ID);
        route("/deals/")
    }

    customerTab = () => {
        return (
        <div className="dealMainData">
            <div className='grid-2'>
                <RInput tname="nameDeal" onChange={this.save} timer="500" state={this.zakazka} item="nsZakOznaceni" />
                <HSelect
                    tname="userState"
                    state={this.zakazka}
                    onChange={this.save}
                    item="iZakUzivStav"
                    label="nsUstStav"
                    values={this.stavyZakazky}
                >
                    <h-sc>{_.search}</h-sc>
                    <h-fop val="0" disabled={true}>{`<b>${_.selectState}</b>`}</h-fop>
                    <h-op format={r => `<b>${r.nsUstStav}</b><br>${r.nsUstPozn || ""}`} />
                </HSelect>

                <DInput tname="date" onChange={this.save} timer="500" state={this.zakazka} item="dZakDatum" />
                <DInput tname="requiredTerm" onChange={this.save} timer="500" state={this.zakazka} item="dZakTerminPozad" />

                <HSelect
                    tname="branch"
                    state={this.zakazka}
                    onChange={this.save}
                    item="iZakPobockaID"
                    label="nsPobNazev"
                    values={this.pobocky}
                >
                    <h-sc>{_.search}</h-sc>
                    <h-fop disabled={true} val="0">{`<b>${_.selectBranch}</b>`}</h-fop>
                </HSelect>

                <HSelect
                    tname="customer"
                    state={this.zakazka}
                    item="iZakOrgID"
                    onChange={this.save}
                    label="nsOrgNazev"
                    values={this.org}
                    ref={this.orgRef}
                >
                    <h-sc>{_.search}</h-sc>
                    <h-fop val="0">{`------`}</h-fop>
                </HSelect>
            </div>

            <RInput tname="comment" onChange={this.save} type="textarea" timer="500" state={this.zakazka} item="nsZakKomentProdej" />

            <HSelect
                tname="orderAdr"
                state={this.zakazka}
                values={this.adreses}
                item="nsZakDodaciAdr"
                label="display_name"
                uid="osm_id"
                key={2}
                onChange={this.changeAdr}
                visible={!this.state.gmap}
            >
                <h-up onUpdate={this.updateAdr} timer="2000" />
                <h-op format={r => `<b>${r.display_place}</b><br>${r.display_address}`} />
            </HSelect>
            <div id="osmMap">

                <OSM ref={this.osm} onLoad={e => this.onLoadMap(e)} />

            </div>

        </div>
        )
    }

    async addCustomer() {
        Repo.selectDeal = this.ID;
        const ID = await Rq.newRecord("organizace", {nsOrgNazev: _.new, bOrgAktivni: true});
        route("/customer/" + ID + "/" + this.ID);
        Ref.notify(_.custChnCrt, 8, "success");
    }

	addAtch = async (file, newList) => {
        //this.set.saved = "saving";
        
        if(newList?.length) {
            const events = this.eventsRef.current.rows;
            var res = await Documents.edit(file, events);
            if(!res) return;
        }
        
        try {
            var res = await Http.uploadAttchm("popobj", this.ID, file.eventID || 0, file.name, file.nsAttPozn || "", file.data);
            const [a, ok, msg] = res;

            if(!ok) {
                this.set.saved = "error";
                return Ref.notify(msg, 5, "error");
            }

            await this.loadAtch();
			this.atchList.current.set.newAtch = [];
			this.atchList.current.set.atchList = this.attchm.rows;
        }
        catch(e) {
            this.set.saved = "error";
			console.error("error", e)
        }
    }

    async editDoc() {
        const events = this.eventsRef.current.rows;
        var res = await Documents.edit({}, events);
        console.log("res", res);
    }

    rnd = () => {
        return (
            <Comp className="deals">
                <div className="compBar buttons">
                    <Btn ok onClick={e=> this.addCustomer()}><RIcons.Fa.FaPlus /> {_.addCust}</Btn>
					<Btn del onClick={async e=> {
                        await Rq.delConfirm(this.recordset, this.ID)
                        route('/deals/')
                    }}><RIcons.Fa.FaTrash /> {_.delDeal}</Btn>
				</div>	
				<div className="panels grid-2 dealPanel">
                    <div>
                        <div className="panel">
                            <header>{_.dataForDeal}</header>
                            <this.customerTab />
                        </div>
                        <AtchTable
                            ref={this.atchList}
                            className="dealAtch "
                            list={this.attchm.rows}
                            onChange={this.addAtch}
                            alwex={Repo.stg.alwex}
                            canDelete={true}
                            panel={_.attachments}
                        />
                    </div>
                    <div>
                        <Strom ID={this.ID} />
                        <Events ID={this.ID} events={this.eventsRef} />
                        <div className="panel dnone">
                            <header>{_.documents}</header>
                            <DocTab ID={this.ID} />
                        </div>
                    </div>	
				</div>
            </Comp>
        )
    }
}