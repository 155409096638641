import { SComponent, _, Table, route, Comp } from '../dump'

export class IOT extends SComponent {
    rnd = (s, p) => {
        return (
            <Comp className="iot">
                <Table recordset="iot_device" cols="0" detail={rec => route("/iotGraph/" + rec.ID)}>
                    <bar-button
                        type="add"
                        icon="ExclamationTriangle"
                        text="Výstrahy"
                        click={rec => route("/iot/alerts")}
                    />
                    <tab-button
                        type="primary"
                        icon="Cog"
                        click={rec => rec.reload()}
                    >
                        <b-item icon="Table" text={_.dashboard} click={rec => route("/iotTable/" + rec.ID)} />
                        <b-item icon="ChartLine" text={_.chart} click={rec => route("/iotGraph/" + rec.ID)} />
                    </tab-button>
                </Table>
            </Comp>
        )
    }
}