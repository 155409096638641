import { AtchList } from '../../components/attachments/atchList';
import { SComponent, _, Ref, RInput, Checkbox, RIcons, Table, HSelect, Ariscat, Rq, DInput, Btn } from '../../dump';

export class Documents {
    static async editDocument(rec, events = {}) {
        this.attchm = await Rq.sd('attchm', 1, 0, { iPOID: rec.iKalZakID });

        Ref.modal.open(_.editDocument,
            <div className="modal-data">
                <AtchList
                    list={this.attchm.rows}
                    onChange={this.addAtch}
                />
                <div>
                    {rec.nsAttNazevSPrip}
                </div>
                <HSelect 
                    tname="followUpEvent" 
                    className="docEventSelect" 
                    values={events}
                    label="nsKalNazevJednani"
                    item="eventID"
                    state={rec}
                />
                <RInput tname="description" type="tarea" state={rec} item="nsAttPozn" />
            </div>,
            <div className="modal-buttons">
                <Btn save className="saveDocument"><RIcons.Fa.FaCheck /> {_.save}</Btn>
                <Btn close className="closeDocumen" onClick={() => Ref.modal.close()}>{_.close}</Btn>
                <Btn back className="goBack" onClick={() => this.openDetail(this.rec)}><RIcons.Fa.FaCheck /> {_.back}</Btn>
            </div>
        )
    }

    static async edit(file, events = {}) {
        return Ref.modal.open(_.editDocument + " - " + file.name,
            <div className="modal-data">
                <HSelect 
                    tname="followUpEvent" 
                    className="docEventSelect" 
                    values={events}
                    label="nsKalNazevJednani"
                    item="eventID"
                    state={file}
                />
                <RInput tname="description" type="tarea" state={file} item="nsAttPozn" />
            </div>,
            <div className="modal-buttons">
                <Btn save className="saveDocument" onClick={() => Ref.modal.close(file)}><RIcons.Fa.FaCheck /> {_.save}</Btn>
                <Btn close className="closeDocument" onClick={() => Ref.modal.close(null)}>{_.close}</Btn>
            </div>
        )
    }

}
