import { Chart } from 'chart.js';
import { SComponent, _, Table, route, SDate, createRef } from '../../dump'

export class IOTGraphMini extends SComponent {
    async cmount(s,p) {
        this.chartEl = createRef();
        this.dotCount = 10;
        this.showLines = true;
        this.lineTension = 0.3;
        this.dateEnd = SDate();
        this.data = {};
        this.dateEvent = true;

        this.stg = {
            dotCount: 10,
            showLines: true,
            lineTension: 0.3,
            dateStart: SDate("1.1.2021"),
            dateEnd: SDate(),
        }

        setTimeout(() => this.init(), 10);
    }

    init() {
        this.data = this.props.data;
        this.dateMin = this.data.rows.length ? this.data.rows[0].dDevdCasZpravy : SDate();
        this.dateMax = (this.data.rows[this.data.rows.length-1]||{}).dDevdCasZpravy || SDate();
        this.dateEvent = false;

        //je potreba pockat, az se nactou data z props
        //this.draw();
        this.dateEvent = true;
        this.draw();
        //this.refresh();
    }

    componentWillUpdate(p) {
        console.log("upd")
        //this.chart.update();
    }

    componentWillReceiveProps(p) {
        return false;
    }

    draw = () => {  
        this.ds = {}

        for(let row of this.props.dataCopy) {
            const d = SDate(row.dDevdCasZpravy);

            for(let vs of this.props.cols) {
                var key = vs.attrname;
                this.ds[key] = this.ds[key] || [];

                if(key == "iDevdAverageCurrent" && !row.bDevdAvgCurrentAnomaly && row.iDevdAverageCurrent !== undefined)
                    this.ds[key].push({x: d, y: +row.iDevdAverageCurrent });    
                if(key == "iDevdAverageCurrent2" && row.bDevdAvgCurrentAnomaly && row.iDevdAverageCurrent !== undefined)
                    this.ds[key].push({x: d, y: +row.iDevdAverageCurrent });    
                else if(row[key] !== undefined) {
                    this.ds[key].push({x: d, y: (+row[key] || 0) });
                }
            }
        }

        const config = this.chart ? this.chart.config : this.chartConfig();

        for(let ds of config.data.datasets) {
            ds.data = this.ds[ds.key];
        }

        config.options.showLines = true;
        config.options.elements && (config.options.elements.line.tension = this.state.lineTension);

        if(this.chart) {
            this.chart.update();
        }
        else {
            this.chart = new Chart(this.chartEl.current, config);
        }
    }

    getDataset(atrName, color) {
        const col = this.data.cols.find(f => f.attrname == atrName) || {};

        return {
            label: col.caption,
            fill: false,
            backgroundColor: color,
            borderColor: color,
            
        }
    }

    chartConfig() {
        var colors = [
            "red", "blue", "green", "#FFFF00", "#00FFFF", "#FF00FF", "orange", "purple", "black", "brown", 
            "gold", "grey", "#7CFC00", "#EE82EE", "#FF6347", "#B22222", "#D2691E", "#7FFF00", "#7FFF00", "#FF1493", "#000088", "#008800", "#880000",
        ];

        return {
            type: 'line',
            data: {
                datasets: this.props.cols.map((col,i) => {
                    return {
                        label: col.caption,
                        fill: false,
                        backgroundColor: colors[i],
                        borderColor: colors[i],
                        key: col.attrname
                    }
                })
            },
            options: {
                responsive: true,
                legend: {display: false},
                title: {display: false},
                tooltips: {enabled: false},
                hover: {mode: null},
                parsing: false,
                normalized: true,
                animation: false,
                spanGaps: true, // enable for all datasets
                scales: {
                    xAxes: [{
                        type: 'time',
                        //display: false,
                        ticks: {
                            display: true
                        },
                        time: {
                            tooltipFormat: 'DD.MM.YY HH:mm',
                            displayFormats: {
                               day: 'DD.MM.YY',
                               hour: 'DD. HH:mm',
                               minute: 'HH:mm',
                               second: 'HH:mm:ss',
                            }
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Datum'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: "'Value'"
                        }
                    }]
                },
                plugins:{
                    legend: {
                        display: false
                    }
                }
            }
        }
    }

    rnd = (s, p) => {
        return (
            <canvas ref={this.chartEl} id="chartB" style={{display: "block", width: "500px", height: "30px"}} />
        )
    }
}