import './styl.scss';
import { SComponent, _, Ref, route, RIcons, SDate } from "../../dump";

export class Loading extends SComponent {
    cmount() {
        Ref.Loading = this;
        this.lastError = 0;

        this.saveStates = {
            ok: <RIcons.Fa.FaSave className="saved" />,
            saved: <RIcons.Fa.FaSave className="saved" />,
            error: <RIcons.Fa.FaExclamationCircle className="error" />,
            loading: <RIcons.Fa.FaSpinner className="rotation" />,
            warning: <RIcons.Fa.FaExclamationTriangle className="warning" />,
        }

        this.state = {
            saveState: this.saveStates.saved,
        }
    }

    loadState(type) {
        //chyba vydrzi minimalne 3s
        if(type != "error" && SDate() - this.lastError < 3000) {
            document.body.classList.remove("loading");
            document.body.classList.remove("changed");
            return;
        }

        if(this.state.saveState != this.saveStates[type]) {
            document.body.className = type;
            this.set.saveState = this.saveStates[type];
        }
    }

    loading() {
        document.body.classList.add("loading");
        this.loadState("loading");
    }

    error() {
        document.body.classList.remove("loading");
        document.body.classList.remove("changed");
        this.loadState("error");
        this.lastError = +SDate();
    }

    saved() {
        document.body.classList.remove("loading");
        this.loadState("saved");
    }

    ok() {
        document.body.classList.remove("loading");
        this.loadState("ok");
    }

    rel() {
        var url = window.location.pathname;
        // route("/");
        setTimeout(() => route(url), 300);
    }

    rnd =(s,p)=> (
        <span className="saveState" onClick={e => this.rel()}>{s.saveState}</span>
    )
}