import './styl.scss'
import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, useRef, Ref, RIcons, Comp, Btn } from "../../dump";

export class ExtFeatures extends SComponent {
    cmount() { 
        this.tab = [
            [
                "CRM",
                false,
                true,
                true,
                _.efCRM,
                "fas",
                "hand-holding-usd"
            ],
            [
                _.customers,
                false,
                true,
                true,
                _.efCustomers,
                "fas",
                "users"
            ],
            [
                _.inquiries,
                true,
                true,
                true,
                _.efInquiries,
                "fas",
                "list"
            ],
            [
                _.requisitions,
                true,
                true,
                true,
                _.efRequisitions,
                "fas",
                "list"
            ],
            [
                _.supplies,
                true,
                true,
                true,
                _.efSupplies,
                "fas",
                "truck"
            ],
            [
                _.invoices_received,
                true,
                true,
                true,
                _.efInvReceived,
                "fas",
                "file"
            ],
            [
                _.custPrintMat,
                false,
                true,
                true,
                _.efCustPrintMat,
                "fas",
                "file"
            ],
            [
                _.calendar,
                false,
                true,
                true,
                _.efCalendar,
                "fas",
                "file"
            ],
            [
                _.plan_assembly,
                false,
                true,
                true,
                _.efPlanAssembly,
                "fab",
                "android"
            ],
            [
                _.service,
                false,
                true,
                true,
                _.efService,
                "fas",
                "wrench"
            ],
            [
                _.complaintsReceived,
                false,
                true,
                true,
                _.efComplaintsReceived,
                "fas",
                "times-circle"
            ],
            [
                _.complaintsIssued,
                true,
                true,
                true,
                _.efComplaintsIssued,
                "fas",
                "times-circle"
            ],
            [
                _.docStorage + " 500MB",
                false,
                true,
                true,
                _.efDocStorage,
                "fas",
                "hdd"
            ],
            [
                _.statistics,
                true,
                true,
                true,
                _.efStatistics,
                "fas",
                "chart-line"
            ],
            [
                _.users,
                true,
                true,
                true,
                _.efUsers,
                "fas",
                "user-tie"
            ],
            [
                _.documentation,
                true,
                true,
                true,
                _.efDocumentation,
                "fas",
                "book"
            ],
            [
                _.offerEndCust,
                true,
                true,
                true,
                _.efOfferEndCust,
                "fas",
                "handshake"
            ],
            [
                _.operatDoc,
                false,
                false,
                true,
                _.efOperatDoc,
                "fas",
                "file-pdf"
            ],
            [
                "Modul EET CZ",
                false,
                false,
                true,
                _.efEET,
                "fas",
                "credit-card"
            ],
            [
                _.invoices_issued,
                false,
                false,
                true,
                _.efInvoicesIssued,
                "fas",
                "file-invoice-dollar"
            ],
            [
                _.mobilApp,
                false,
                true,
                true,
                _.efMobilApp,
                "fas",
                "file-invoice-dollar"
            ]
        ]

        this.e0 = this.tab.filter(f => f[1]).map(this.mapItem);
        this.e1 = this.tab.filter(f => (f[2] && !f[1])).map(this.mapItem);
        this.opened = false;

        this.state = {
            opened: false,
            storage: 0,
            storageTotal: 0,
            usersE1: 1,
            usersE2: 0,
            priceE1: 299 * 12,
            priceE2: 0,
            total: 299 * 12,
        }
    }

    mapItem(item) {
        return (
            <div 
                className="mb-3" 
                onMouseOver={e=> Ref.tooltip.open(<div className='efTooltip'>{item[4]}</div>, e)} 
                onMouseLeave={e=> (e.stopPropagation(), Ref.tooltip.close())}
            >
                <RIcons.Fa.FaCheck color='#337ab7'/>
            <span className='txt'>{item[0]}</span>
        </div>)
    }

    showNotes(e) {
        const allowClass = ["mb-3"]
        let include = false;
        //const note = xi.tabNote;

        for(let ac of allowClass) {
            include = include || e.target.className.includes(ac);
        }

        if(!include || !e.target.dataset.info) {
            note.classList.add("dnone")
            return;
        }

        /*
        note.innerHTML = e.target.dataset.info;
        note.style.top = e.clientY + 5 + "px";
        note.style.left = e.clientX + 5 + "px";
        note.classList.remove("dnone");
        */
    }

    poptat =()=> {
        Ref.modal.open("Poptat",
            <div className='efModal'>
                <div className="modal-body">
                    <table id="basketTable" style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <th></th>
                                <th style={{width: "80px"}}></th>
                                
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="arE1">
                                <td>
                                    <div className="detail">ArisCAT Market E1</div>
                                    <div className="info">{_.price1user} 299 Kč, {_.next} 199 Kč <br/>
                                        {_.priceValid1YSubs}</div>
                                </td>
                                <td>
                                    <div><RInput type="number" state={this.state} min="1" max="20" item="usersE1" className="priceInput E1" size="5" onChange={this.calc} /></div>
                                    <div className="info center">{_.numUsers}</div>
                                </td>
                                {/*
                                <td className="center dnone">
                                    <span className="detail"><span className="priceE1 prices">{this.state.priceE1}</span> Kč</span>
                                    <div className="info">Cena za rok, další uživatel 2388 Kč</div>
                                </td>
                                */}
                            </tr>
                            {/*
                            <tr id="arE2" className="dnone">
                                <td>
                                    <div className="detail">Ariscat E2</div>
                                    <div className="info">Cena za měsíc 490 Kč, za dalšího uživatele 349 Kč</div>
                                </td>
                                <td>
                                    <div><input type="number" value={this.usersE2} className="priceInput E2" size="5" price_a="490" price_b="349" p_type="E2"/></div>
                                    <div className="info center">Počet PC</div>
                                </td>
                                <td className="center dnone">
                                    <span className="detail"><span className="priceE2 prices">{this.state.priceE2}</span> Kč</span>
                                    <div className="info">Cena za rok, další licence 4188 Kč</div>
                                </td>
                            </tr>
                            */}
                            <tr id="storageDocs">
                                <td className="docsInfo">
                                    <div className="detail">{_.docStorage} 1TB</div>
                                    <div className="info">{_.pricePerMonth}</div>
                                </td>
                                <td>
                                    <div><RInput type="number" state={this.state} min="0" max="20" item="storage" className="priceInput docs" size="5" onChange={this.calc} /></div>
                                    <div className="info center">{_.quantity} TB</div>
                                </td>
                                {/*
                                <td className="center dnone">
                                    <span className="detail"><span className="priceDocs prices">{this.state.storageTotal}</span> Kč</span>
                                    <div className="info">Cena za rok</div>
                                </td>
                                */}
                            </tr>
                            {/*
                            <tr id="total" className="dnone">
                                <td className="detail">Celkem</td>
                                <td></td>
                                <td className="detail center dnone"><span id="totalPrice" className="prices">{this.state.total}</span> Kč</td>
                            </tr>
                            */}
                        </thead>
                    </table>
                    <div className="grid-2" style={{margin: "8px"}}>
                        <div className="form-group">
                            <label>{_.telephone}</label>
                            <RInput state={this.state} item="tel" />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <RInput state={this.state} item="email" />
                        </div>
                    </div>
                    <div id="navic" className="dnone">
                        <div className="detail">{_.extraFeatures}</div>
                        <div className="flex storage1">
                            <div>{_.docStorage} 1TB - 99 Kč / 1TB</div>
                            <Btn add id="addStorage">{_.addBasket}</Btn>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Btn add onClick={this.send}>{_.inquire}</Btn>
                    <Btn close onClick={() =>  Ref.modal.close()}>{_.cancel}</Btn>
                </div>
            </div>
        );
    }

    send = async () => {
        this.set.opened = false;

        const res = await Ariscat.alert.dialog("Potvrzení", _.wantSendInquiry, 10);
        if(!res) return;
        
        const msg = `${_.numUsers}: ${this.state.usersE1}, ${this.state.storage}TB.<br>`;
        Ariscat.alert.dialog("Poptávka odeslána (TEST)", msg + _.sentAMerchantWilContact, 1);
    }

    calc = () => {
        const s = this.state;
        if(s.usersE1 <= 0) s.usersE1 = 1;
        if(s.storage < 0) s.storage = 0;

        s.storageTotal = s.storage * 99 * 12;
        s.priceE1 = 299*12 + ((s.usersE1-1) * 199 * 12);
        s.total = s.storageTotal + s.priceE1 + s.priceE2;
        this.refresh();
    }


    rnd() {
        return (
            <Comp className="extFeatures">
                {/*this.modal()*/}
                <div id="tabNote" className="dnone"></div>
                <div className="efColumns">
                    <div className="extCol">
                        <div className="ver">{_.manfProvVer}</div>
                        <div className="price">{_.free}</div>
                        <div className="custom-separator my-4 mx-auto bg-primary"></div>
                        <div className="extValues">
                            <div className="bonus">{_.verIncludes}:</div>
                            {this.e0}
                            <div className="mb-3"> </div>
                        </div>
                        <Btn action className="btn btn1" disabled>{_.curUsing}</Btn>
                    </div>
                    <div className="extCol">
                        <div className="ver">{_.premiumVer} E1</div>
                        <div className="price">299 Kč<span> / {_.month} / {_.user2}</span></div>
                        <div className="custom-separator my-4 mx-auto bg-primary"></div>
                        <div className="extValues">
                            <div className="bonus">{_.verIncAddition}:</div>
                            {this.e1}
                        </div>
                        <Btn action className="btn btn2" onClick={this.poptat}>{_.inquire}</Btn>
                    </div>

                </div>
                
                <div className="efInf">
                    <div className="efInfo">{_.anotherUser}: <span>199 Kč {_.monthly}.</span></div>
                    <div className="efInfo">{_.storageExpan} 1TB: <span>99 Kč {_.monthly}.</span></div>
                    <div className="efInfo">{_.priceUnlimitedUsers}: <span>3 990 Kč {_.monthly}.</span></div>
                    <div className="efInfo">{_.prices1yrVAT}</div>

                </div>

            </Comp>
        )
    }
}