import { Component, _ } from "../../dump";

window.RC = {};
window.RI = {};

let Ref = {};
window.ref = Ref;

window.reactClass = (clsObject) => {
    //if((window.localStorage||{}).debug != 1) return;

    var name = clsObject.constructor.name;

    window.RI[name] = clsObject;
    window.RC[name] = clsObject.constructor;
}

const StateProxy = it => {
    return new Proxy(it, {
        set(obj, key, val) {
            it.setState({ [key]: val });
            return true;
        },
        get(obj, key) {
            return it.state[key];
        }
    });
}

export class SComponent extends Component {
    constructor(p) {
        super(p)
        //this.props = p || {};
        this.state = {};
        this.props = p || {};
        this.set = StateProxy(this);
        window.reactClass(this);

        this.ID = this.props.ID;

        if (p?.cmount !== false) {
            const res = this.cmount(this.state, p);

            //pocka na dokonceni asynchroni funkce a az pak se prekresli komponenta
            if (res?.constructor?.name == "Promise") {
                res.then(() => this.refresh());
            }
        }
    }

    //vytvoří html jména tříd podle podmínky
    cln1(clsNames, ...args) {
        return clsNames.filter((cn, i) => args[i]).join(" ");
    }

    cln(clsObject) {
        return Object.entries(clsObject).filter(([key, val]) => val).map(([key]) => key).join(" ");
    }

    cmount() { }

    render = () => {
        this.rndBefore(this.state, this.props);
        const ret = this.rnd(this.state, this.props);

        setTimeout(() => this.rndAfter(this.state, this.props), 1);

        return ret;
    }

    componentWillReceiveProps(np) {
        this.props = np;
        this.cmount && this.cmount(this.state, this.props);
    }

    rndBefore = (s, p) => { }
    rndAfter = (s, p) => { }

    refresh(v) {
        return new Promise((rs, rj) => this.setState(s => s, rs));
    }

    set update(u) {
        this.setState(s => s);
    }
    get update() {
        return this.state;
    }

    getChildrens(ch) {
        return Array.isArray(ch) ? ch : [ch || {}];
    }

    //zavre ostatni komponenty po kliknuti mimo ne, spousti se jen 1x
    componentsClose() {
        //if(!window._hcomponent) {
        window._hcomponent = true;

        const disableTargets = ["hselSearch", "dateInput"]

        document.onclick = e => {
            //pokud kliknu na jiny select nebo na date input 
            //nebo jinde na strance, nezavre automaticky       

            //kliknuti na hledani v hSelectu by ho zavrelo nebo vyber datumu
            if (e.target?.attributes["no-close"]) return;

            document.querySelectorAll(".openComponent.open").forEach(el => {
                el.open = false
            })
            /*
            dqa("h-select").forEach(el => {
                if(el != hSelect) el.open = false;
            });
 
            dqa(".dInput").forEach(el => {
                if(el != dInput) el.open = false;
            });
            */
        }
        //}
    }

    onLoad() {

    }
}