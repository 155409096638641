import { Rq, _, Router, route, Repo, Http, Ref, SDate } from './dump';

import { Menu } from './components/menu';
import { Header } from './components/header';
import { Deals } from './tables/deals';
import { Customers } from './tables/customers';
import { Customer } from './pages/customer/customer';
import { CalendarEvents } from './pages/calendar/calendar';
import { Users } from './tables/users';
import { Settings } from './pages/settings';

import { ComplaintsIssued } from './tables/complaintsIssued';
import { ComplaintIssued } from './pages/complaintIssued';
import { Deal } from './pages/deal/deal.jsx';
import { User } from './pages/user/user';
import { InvoicesSup } from './tables/invoicesSup';
import { Popob } from './tables/popob';
import { Notify } from './components/notify/notify';
import { CustomerOffer } from './components/custOffer/custOffer';
import { Demandord } from './pages/demand';
import { Montages } from './tables/montages';
import { Confirm } from './components/modal/confirm';
import { Modal } from './components/modal/modal';
import { Tooltip } from './components/tooltip/tooltip';
import { Mask } from './components/mask/mask';
import { Dealers } from './tables/dealers';
import { ExtFeatures } from './pages/extFeatures/extFeatures';
import { IOT } from './tables/IOT';
//import { IOTDetail } from './pages/IOT2/IODetail';
import { IOTGraph } from './pages/IOT2/IOTGraph';

import { Documentation } from './pages/documentation/documentation';
import { MessagesCenter } from './pages/messages/messagesCenter';
import { DelivAdr } from './pages/demand/delivAdr';
import { Alerts } from './pages/alerts/alerts';
import { LoginPage } from './pages/loginPage/loginPage';
import { DStats } from './pages/dstats/dstats';
import { SysInfo } from './pages/sysInfo/sysInfo';
import { Home } from './pages/home/home';
//import { BranchesTab } from './tables/branchesTab';
import { Branch } from './pages/branches/branches';
import Dashboard from './pages/dash';
import { ImpOrd } from './pages/impOrd/impOrd';
import { Test } from './pages/test';
import { BranchesTab } from './tables/branchesTab';


function relPage() {
  // console.log("relPage");
  var url = window.location.pathname;
  route("/");
  setTimeout(() => route(url), 10);
}

//registrace obnoveni komponent, protoze nektery komponenty Vite neobnovi automaticky
window.viteUp = (loggedPath, path, timestamp, explicitImportRequired, mod, qualifiedCallbacks) => {
	console.log("viteUp", loggedPath, path, timestamp, explicitImportRequired, mod, qualifiedCallbacks);

  //tyhle stranky se budou prekreslovat po ulozeni
  var pages = [
    "/src/pages/IOT2/IOTGraph.jsx",
    "/src/components/custOffer/custOffer.jsx"
  ]

  if(pages.includes(loggedPath) || loggedPath.includes("/src/tables/")) {
    relPage();
  }
}

export function App() {
  window.Rq = Rq;
  window._ = _;
  window.route = route;
  window.Ref = Ref;

  window.SDate = SDate;

  if(!window._hcomponent) {
    window._hcomponent = true;

    document.onclick =e=> {
      //pokud kliknu na jiny select nebo na date input 
      //nebo jinde na strance, nezavre automaticky
      var hSelect = e.target.closest("h-select");
      var dInput = e.target.closest(".dInput");
      
      document.querySelectorAll("h-select.open").forEach(el => {
        //console.log("kl", el);
        if(el != hSelect) el.open = false;
      });

      document.querySelectorAll(".dInput").forEach(el => {
          if(el != dInput) el.open = false;
      });
    }
  }
  
  //meni horni listu a titulek stranky, registruje nazvy tabulek
  const onRoute =e=> {
    const prop = e.current?.props;

    Ref.modal?.close();

    Ref.Header.tagLinks = [];

    if(typeof prop?.name === "string") {
      Ref.Header.updateTags([prop.name, "", ""]);
      document.title = prop.name;
    }
    else if(Array.isArray(prop?.name)) {
      setTimeout(() => {
        const comp = e.current.__c;
        const namesOrig = [...prop.name];
        const names = [...prop.name];
        document.title = names[0];
        Ref.Header.updateTags(names[0]);

        comp.onLoad = (...data) => {
          if(data.length == 1) {
            names[1] = namesOrig[1].replace("%1", data[0]);
          }
          else if(data.length > 1) {
            const min = Math.min(data.length, names.length);

            for(let i=0;i<min;i++) {
              names[i] = namesOrig[i].replace("%1", data[i]); 
            }
          }
          document.title = names[1];
          Ref.Header.updateTags(names);
        }
      }, 1);
    }
  }

  return (
    <>
      <Header />
      <Notify />
      <Modal ref={it => Ref.modal = it} />
      <Modal ref={it => Ref.modal2 = it} />
      <Modal ref={it => Ref.modal3 = it} />
      <Tooltip ref={it => Ref.tooltip = it} />
      <Confirm ref={it => Ref.confirm = it} />
      
      <div id="main">
        <Menu />
        <Router onChange={onRoute}>
          <Dashboard path="/" />
          <Deals path="/deals" name={_.orders} />
          <Deal path="/deals/:ID" name={[_.orders, _.deal + " %1"]} />

          <Customers path="/customers" name={_.customers} />
          <Customer path="/customer/:ID" name={[_.customers, _.customer + " %1"]} />
          <Customer path="/customer/:ID/:dealID" name={[_.customers, _.customer + " %1"]} />
          <ComplaintsIssued path="cm-issued" name={_.complaintsIssued} />
          <ComplaintIssued path="cm-issued/:ID" name={[_.complaintsIssued, _.reclamation + " %1"]} />

          <Popob path="/orders" name={_.requisitions} />
          <Demandord path="/orders/:ID" name={[_.requisitions, _.requisition + " %1"]} />
          <Demandord path="/orders/:ID/impor/:guid" name={[_.requisitions, _.requisition + " %1"]} />

          <Popob path="/demands" name={_.inquiries} />   
          <Demandord path="/demands/:ID" name={[_.inquiries, _.inquirie + " %1"]} />
          <Demandord path="/demands/:ID/impor/:guid" name={[_.inquiries, _.inquirie + " %1"]} />

          <CalendarEvents path="/calendar" name={_.calendar} />
          <CalendarEvents path="/calendar/:dealID" name={_.calendar} />

          <Users path="/users" name={_.users} />
          <User path="/users/:ID" name={[_.users, "%1"]} />

          <Settings path="/settings" name={_.settings} />

          <InvoicesSup path="/supplies" name={_.supplies} />
          <InvoicesSup path="/invoices" name={_.invoicesIssued} />

          <CustomerOffer path="/custOffer/:recordset/:ID" name={[_.orders, _.deal + " %1", "%1"]} />
          <BranchesTab path="/branches" name={_.dlrBranches} />
          <Branch path="/branches/:ID" name={[_.dlrBranches, _.branch + " %1"]} />

          <Mask path="/mask" />
          <Mask path="/mask/:ID" />
          <Montages path="/montages" name={_.montages} type="0" />
          <Montages path="/meetings" name={_.contactMeetings} type="1" />
          <Montages path="/service"  name={_.service} type="2" />
          <Montages path="/cm-received"  name={_.complaintsReceived} type="3" />

          <DStats path="/dstats" name={_.manfStats} />
          <Dealers path="/dealers" name={_.dealers} />
          <ExtFeatures path="/extFeatures" /> 
          <IOT path="/iot" name={_.iotModules} />

          <Alerts path="/iot/alerts" name={"Výstrahy"} />
          
          {/*<IOTDetail path="/iotDetail/:ID" />*/}
          <IOTGraph path="/iotGraph/:ID" name={[_.iotModules, _.iotModOverView + " %1"]} />
          <SysInfo path="/sysInfo" />
        
          <Documentation path="/documentation" name={_.documentation}  />
          <MessagesCenter path="messages" name={_.messages} />

          <DelivAdr path="/adresy/:ID" />
          <ImpOrd path="/impOrd/:guid" />
          <LoginPage path="/login" />
          <Home path="/home" name={_.dashboard} />
          <Test path="/test" />
        </Router>
      </div>
      
    </>
  )
}