import { SComponent, _, Table, route, createRef, Ref, RInput, Comp } from '../dump'
import { EventDetail } from '../components/eventDetail/eventDetail';

export class Montages extends SComponent {
    async cmount() {
        this.table = createRef();
        this.deals = [];
        this.eventDetail = createRef();
        this.deals = (await Rq.sd("zakazka", 1)).rows;
    }

    formatDeal = row => {
        const deal = this.deals.find((f => f.ID == row.iZakID)) || {};
        row.deal = deal;
        return deal.nsZakCislo || "-";
    }

    formatCustomer = row => {
        const deal = this.deals.find((f => f.ID == row.iZakID)) || {};
        row.deal = deal;
        return deal.nsOrgNazev || "-";
    }

    openDetail =async row=> {
        var res = await this.eventDetail.current.openDetail(row);

        if(res) {
            this.table.current.reload();
        }

        console.log("r", res);
    }

    rnd =s=> {
        return (
            <Comp className="montages">
                <EventDetail ref={this.eventDetail} />
                <Table 
                    recordset="kalendar_m" 
                    sw_iDruhZaznamu={this.props.type || 0} 
                    cols="1" 
                    customCols={true} 
                    detail={this.openDetail}
                    changeCols={false}
                    ref={this.table}
                >
                    <bar-button
                        type="add"
                        icon="Plus"
                        text=""
                        click={rec => {
                            this.openDetail({iKalDruhZaznamu: this.props.type || 0})
                        }}
                    />
                    <tab-button
                        type="warning"
                        icon="Edit"
                        disabled={() => !vrJoin}
                        link={rec => "r/deal/" + rec.ID}
                    />
                    <tab-button
                        type="primary"
                        icon="Cog"
                        click={rec => rec.reload()}
                    >
                        <b-item icon="Trash" text={_.delete} click={rec => rec.del()} />
                    </tab-button>
                    <tab-col name="nsKalNazevJednani" />
                    <tab-col name="deal" type="format" format={this.formatDeal} caption={_.deal} />
                    <tab-col name="nsKalMistoRealizace" />
                    <tab-col name="customer" type="format" format={this.formatCustomer} caption={_.customer} />
                    <tab-col name="nsKalDatumOd" type="date" />
                    <tab-col name="nsKalDatumDo" type="date" />
                </Table>
            </Comp>
        )
    }
}