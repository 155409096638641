
import './styl.scss';
import { SComponent, h, _, Btn } from "../../dump";

export class Tab extends SComponent {
  cmount() {
    this.activeTab = 1;//this.props.tab || 1;
  }

  tabChange = idx => {
    this.activeTab = idx+1;
    this.props.onChange && this.props.onChange(this.activeTab, this);
    this.refresh();
  }

  componentDidUpdate(np) {
    //this.props = np;
    //console.log("du", np);
  }

  componentWillReceiveProps(np) {
    this.props = np;
    console.log("rp", np);
    this.refresh();
  }

  rnd(s,p) {
    this.i++;
    
    return (
      <div className="tab" r={this.i}>
        <div className="tab-buttons">
          {this.getChildrens(this.props.children).filter(f => f.props?.visible !== false).map((child, index) => (
            <Btn
              tab
              key={index}
              onClick={() => this.tabChange(index)}
              className={this.cln({active: index === (this.activeTab - 1)})}
            >
              {child.props.title}
            </Btn>
          ))}
        </div>
        <div className="tab-content">
          {this.props.renderType == 1 ? 
            this.props.children.map((m,i) => <div className={this.cln({["tabCont"+i]: 1, dnone: this.activeTab != i+1})}>{m.props.children}</div>) :
            this.props.children[this.activeTab-1].props.children
          }
        </div>
      </div>
    );
  }
}