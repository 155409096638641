import { Panel } from '../../components/panel/panel';
import { SComponent, RInput, _, Rq, Ariscat, Checkbox, Repo, If, Comp } from '../../dump';

export class Functions extends SComponent {
    async cmount() {
        this.data = [];
        this.setRO = Repo.stg?.bAllowCascSetReadOnly && ((Repo.stg?.bIsSubdealer === false && Repo.stg.cs__subDealersEnabled) || Repo.stg?.bIsProducEmp);
        this.domainID = Repo.stg?.bIsProducEmp ? Repo.company : Repo.dealer.Vyrobce.nsVrbcDomainID;
        const {rows} = await Rq.sd("casc_setting", 1, 0, { nsDomainID: this.domainID }).then(console.log(this.rows));
        this.data = rows;
        this.timer = 0;
        this.refresh();
    }

    saveFunctionsInput = async row => {
        const key = row.nsStTyp + "StHodnota";
        const data = { ID: row.ID, nsStName: row.nsStName, [key]: row[key] };
        const { ferror } = await Rq.up("casc_setting", 1, data, { nsDomainID: this.domainID });
    }

    saveFunctions = async (row, manuf = false) => {
        this.set.saved = "saving";
        const data = { ID: row.ID, nsStName: row.nsStName, iStHodnota: row.iStHodnota ? 1 : 0 };

        if(manuf && this.setRO) {
            data.bStMakeReadOnly = row.bStMakeReadOnly;
        }

        const res = await Rq.up("casc_setting", 1, data, { nsDomainID: this.domainID });
    
        //aktualizace ID z backendu pokud se zmeni po zmene stavu
        if(res.rows.length) row.ID = res.rows[0].ID;
    }

    showFunctionSettings = (filter, txt) => (
        <Panel text={txt || filter}>
            {this.data.filter(f => f.nsStGroupID == filter && f.nsStTyp == "b" && f.bStEditable).map((row,i) =>
                <div className={'fn fnCheckBox ' + row.nsStName}>
                    <If is={this.setRO}>
                        <Checkbox 
                            className="fn fnCheckBox" 
                            timer={this.timer} 
                            onCheck={() => this.saveFunctions(row, true)} 
                            state={row} 
                            item="bStMakeReadOnly" 
                            not={true}
                        />
                    </If>
                    <Checkbox className="fn fnCheckBox" timer={this.timer} onCheck={() => this.saveFunctions(row)} state={row} item="iStHodnota" />
                    <span className="riLabel">{row.nsStPopis}</span>
                </div>
            )}

            {this.data.filter(f => f.nsStGroupID == filter && (f.nsStTyp == "i" || f.nsStTyp == "ns") && f.bStEditable).map((row,i) =>
                <div className={'fn fnInput ' + row.nsStName}>
                    <If is={this.setRO}>
                        <Checkbox 
                            className="fn fnCheckBox" 
                            timer={this.timer} 
                            onCheck={() => this.saveFunctions(row, true)} 
                            state={row} 
                            item="bStMakeReadOnly" 
                            not={true}
                        />
                        <div></div>
                    </If>
                    <If is={!this.setRO}><div></div></If>
                    <RInput className="fn" timer="800" name={row.nsStPopis} onChange={() => this.saveFunctionsInput(row)} state={row} item={row.nsStTyp + "StHodnota"} />
                </div>
            )}
        </Panel>
    )

    rnd = (s,p) => (
        <Comp className="functions">
            <If is={this.setRO}><div className="dlr">{_.enbDlr} / {_.messageState}</div></If>
            <div id="functions" className={this.cln({panels:1, manf: this.setRO, dealer: !this.setRO})}>
                <div>
                    {this.showFunctionSettings("modul", _.modules)}
                    {this.showFunctionSettings("reksrv", _.reclamation)}
                    {this.showFunctionSettings("ostatni", _.other)}
                </div>
                <div>
                    {this.showFunctionSettings("popobjGen", `${_.inquiries} / ${_.requisitions}`)}
                </div>
                <div>
                    {this.showFunctionSettings("produkt", `${_.inquiries} / ${_.requisitions} - ${_.products}`)}
                    {this.showFunctionSettings("popobjCenNab", `${_.inquiries} / ${_.requisitions} - ${_.priceOffer}`)}
                </div>
            </div>
        </Comp>
    )
}