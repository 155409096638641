import { RInput, SComponent, HSelect, DInput, _, SDate, Rq, Table, createRef, route } from "../../dump";

export class ImpOrd extends SComponent {  
    async cmount() {
		this.ID = this.props.guid;
		this.getData();
    }

	badGUID() {
		setTimeout(() => route("/demands"), 5000);
		return Ref.notify(`${_.error}: ${_.badGuid} ${this.ID}! ${_.redirDmndOverv}`, 8, 'error');
	}

	async getData() {
		Ref.notify(_.recDownInf, 2, 'info');
		const res = await Rq.sd("imp_order", 1, {}, {nsImpGuid: this.ID});
		
		if(res.ferror) return this.badGUID();
		
		this.popob = res.rows[0];

		if(!this.popob) return this.badGUID();

		this.jsonStr = this.popob.nsImpDataJSBT.replaceAll('`', '"').replaceAll('__backtick__', '`');
		this.json = JSON.parse(this.jsonStr);

		if(this.json.header.isOrder) {
            this.recordset = "objednavka";
			this.txtName = _.requisition;
            this.do = "order";
            this.pobUrl = "/orders/";
            this.pf = "Obj";
        }
        else {
            this.recordset = "poptavka";
            this.do = "demand";
			this.txtName = _.inquirie;
            this.pobUrl = "/demands/";   
			this.pf = "Pop";
        }

		var usedLst = (this.popob.nsImpUsedObjIDLst || "").split(",").filter(f => f);

		if(usedLst.length) {
			let res = await Ref.confirm.open(_.info, _.guidAlrExist.replace("%1", this.txtName), _.goExisting, _.startNewOne);

			if(res) {
				return route(this.pobUrl + usedLst[0]);
			}
		}

		this.create();
	}

    async create() {
		Ref.notify(_.itCreating + ` ${this.txtName}...`, 2, 'info');

        this.newID = await Rq.newRecord(this.recordset, {[`b${this.pf}Aktivni`]: true}, {nsImpGuid: this.ID});
		var hd = this.json.header || {};
		this.imorProds = Object.keys(this.json.prods || {});
		
		var res = await Rq.up(this.recordset, 1, {
			ID: this.newID,
			[`d${this.pf}TerminPozad`]: hd.reqDate,
			[`ns${this.pf}Oznaceni`]: hd.designation1,
			[`ns${this.pf}DalsiOznaceni`]: hd.designation2,
			[`ns${this.pf}KomentProdej`]: hd.comment,
		});

		this.prejdi();
    }

	//testovaci funkce na import
	async test() {
		var res = await fetch("https://localhost/api/uploadJSOrder", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'X-Token': 'VjBkYWVsUXlOWGxrYm1SVFVraGFkRmRYVmxKTk1IQnFaREJXVEZWcVFUaz0='
			},
			body: JSON.stringify({
				"header": {
					"dealer": {
						"guid": "B8DB7458-A114-42A3-9750-68048B5EABFD",
						"name": "cathedral"
					},
					"isOrder": true,
					"designation1": "Nějaká značka zakázky",
					"designation2": "Druhé označení",
					"reqDate": "2024-01-25",
					"comment": "Nějaká zakázka",
					"callerPairingId": "Párovací znak první objednávky"
				},
				"prods": {
					"PSD": [{
						"__row": 1,
						"Dvojkridlove": 0,
						"Farba": "",
						"Madlo_a_zamok": "",
						"Magnet": 1,
						"Pocet": "1",
						"Pozicia": "0",
						"Poznamka": "0",
						"Ramik": "",
						"Sirka": "1500",
						"Sklo": "",
						"Spodne_vedenie": 0,
						"Typ": "",
						"Vyska": "2000"
					}]
				}
			}),
		});
	
		return await res.json();
	}

	async prejdi() {
		console.log("prejit", this.pobUrl + this.newID + "/impor/" + this.ID);
		route(this.pobUrl + this.newID + "/impor/" + this.ID);
	}

    rnd(s) {
      return (
        <div></div>
      )
    }
}