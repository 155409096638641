import './styl.scss';
import { SComponent, h, _, Ref } from "../../dump";

export class Notify extends SComponent {
    timeout = null;

    state = {
        open: false
    }

    cmount() {
        Ref.notify = this.open.bind(this);
    }

    close = state => {
        this.set.open = false;
        this.dialogRet && this.dialogRet(state);
        return state;
    }

    open(msg, wait = 4, type = "ok") {
        clearTimeout(this.timeout);
        this.setState({msg, type, open: true});
        Ref.Notification.addMessage(msg, type);

        this.timeout = setTimeout(() => {
            this.set.open = false;
            console.error("zavrit", wait);
        }, wait*1000);

        if(type == "error") {
            Ref?.Loading?.error();
        }
    }

    rnd =(s,p)=> {
        return (
            <div id="notify" className={this.cln({open: s.open})} onClick={e=> this.close()}>{s.msg}</div>
        )
    }
}