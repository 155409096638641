import { SComponent, _, Table, route, Checkbox, RInput, RIcons, SDate, createRef, Repo, Comp } from '../../dump'

export class Products extends SComponent {
    prodTable = createRef();

    async cmount() {
        this.dvGroups = (await Rq.sd("dvgroupLinks", 1)).rows;
    }

    favCheck = async (checked,row, data) => {
        console.log("fav", data);
        var group = this.dvGroups.find(gr => row.ID == gr.iGrlVyrobekID) || {};
        group.iGrlVyrobekID = row.ID;

        if(!checked) {
            group.iGrlPoradi = -1;
            this.prodTable.current.renderTable();
            await Rq.up("dvgroupLinks", 1, group);
        }
        else {
            group.iGrlPoradi = 1;
            group.iGrlSkupinaID = 0;
            this.dvGroups.push(group);
            this.prodTable.current.renderTable();
        }

        await Rq.up("dvgroupLinks", 1, group);


        var i = 0;
        
        var dv = this.dvGroups.sort((a,b) => a.iGrlPoradi - b.iGrlPoradi).map((m) => {
            var poradi = m.iGrlPoradi;

            if(poradi > 0) {
                i++;
                poradi = i;
            }

            return {
                ID: m.ID,
                iGrlVyrobekID: m.iGrlVyrobekID,
                iGrlPoradi: i,
                iGrlSkupinaID: m.iGrlSkupinaID
            }
        })
        
        await Rq.up("dvgroupLinks", 1, dv);

        this.dvGroups = (await Rq.sd("dvgroupLinks", 1)).rows;

        //this.prodTable.current.rows = [];
        //this.prodTable.current.renderTable();
        this.prodTable.current.reload();
    }

    moveRow(row, tab, nextIdx) {
        //console.log(i,row,tab);
        const nextRow = tab.rows[nextIdx];
        const num = row.iPrdMaskNo;
        row.iPrdMaskNo = nextIdx.iPrdMaskNo;
        nextRow.iPrdMaskNo = num;

        //tab.rows.sort((b,a) => a.iPrdMaskNo - b.iPrdMaskNo);
        tab.renderTable();
    }

    print = async () => {
        const {rows} = this.prodTable.current;
        rows.sort((a, b) => a.nsPrdNazev.localeCompare(b.nsPrdNazev));

        const groupedRows = rows.reduce((acc, row) => {
            const key = row.nsPrdSkupVyr;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(row);
            return acc;
        }, {});

        const heading = `<tr id="heading">
            <td>${_.product}</td>
            <td>${_.product_shortcut}</td>
            <td>${_.dealer_discount} %</td>
        </tr>`

        let tab = '';
        for (const category in groupedRows) {
            tab += `<tr><td colspan="3" style="text-align: start;"><b>${category}</b></td></tr>`;
            tab += groupedRows[category].map(m => `<tr>
                <td>${m.nsPrdNazev}</td>
                <td>${m.nsPrdZkratka}</td>
                <td>${+m.fPrdMarzePrc}</td>
            </tr>`).join("");
        }

        var pw = window.open('', 'PRINT', 'height=800,width=1000');
        pw.document.write(`<!DOCTYPE html><html moznomarginboxes mozdisallowselectionprint><body>
            <h2>${_.listDealerDiscounts} ${rows[0].nsPrdVrbcNazev || "?"}</h2>
            ${_.printedDay} ${SDate.dmy}
            <br><br>
            <table id="tab">${heading}${tab}</table>
        </body></html>`);
        var style = document.createElement('style');
        style.media = "print";
    
        style.innerHTML = `
            td { border: 1.5px solid black; text-align: center; padding: 2px 10px; }
            table { border-collapse: collapse; table-layout: fixed; }
            #heading { font-weight: bold; }
            body { font-family: Calibri, sans-serif, Arial; }
    
            @media print {
                @page { margin-top: 3.2cm; margin-left: 0; margin-right: 0; }
                @page :first { margin: 0; }
                body { margin: 1.6cm; }
                @page :header { display: none; }
                @page :footer { display: none; }
            }
        `;
    
        pw.document.body.appendChild(style);
        pw.document.close();
        pw.focus();
        setTimeout(() => {
            pw.print();
            pw.close();
        }, 10);
    }

    changeRow = (val,key,row) => {
        Rq.up("produkt", 2, {ID: row.ID, [key]: val});
    }

    rnd = (s, p) => {
        return (
            <Comp>
                <Table recordset="produkt" cols="0" customCols={true} showFilters={false} ref={this.prodTable} panel={_.products}>
                    <bar-button
                        type="add"
                        icon="Sort"
                        text={_.editRank}
                        click={() => this.props.tab.current.tabChange(2)}
                    />
                    <menu-button
                        type="add"
                        icon="Print"
                        text={_.print}
                        click={this.print}
                    />
                    <tab-col name="nsPrdVrbcNazev" />
                    <tab-col name="nsPrdNazev" />
                    <tab-col name="nsPrdZkratka" />
                    <tab-col 
                        name="oblibene" 
                        caption={_.favourite} 
                        fieldtype="string.uni" 
                        type="format" 
                        format={(row, col, i) => <Checkbox timer="500" checked={this.dvGroups.find(gr => row.ID == gr.iGrlVyrobekID)?.iGrlPoradi > 0} onCheck={(val,item,data) => this.favCheck(val,row,data)} />} 
                    />
                    <tab-col 
                        name="rank" 
                        caption={_.rank} 
                        fieldtype="i4" 
                        type="format" 
                        /*
                        format={(row, col, i, tab) => <>
                            <Btn onClick={e=> this.moveRow(row, tab, i-1)} className="action moveBtnUp" title={_.moveUp}><RIcons.Fa.FaCaretUp /></Btn>
                            <Btn onClick={e=> this.moveRow(row, tab, i+1)} className="action moveBtnDown" title={_.moveDown}><RIcons.Fa.FaCaretDown /></Btn>
                        </> }
                        */
                        format={(row, col, i, tab) => (this.dvGroups.find(gr => row.ID == gr.iGrlVyrobekID)?.iGrlPoradi + 1) || ""}

                    />
                    <tab-col name="iPrdZarukaM" />
                    <tab-col name="fPrdPrcKeKoncCeniku_Prd"   type="format" format={row => 
                        <div className="procInput"><RInput type="number" state={row} item="fPrdPrcKeKoncCeniku_Prd" timer="500" onChange={this.changeRow} /><span className="proc">%</span></div>
                    }/>
                    
                    <tab-col name="fPrdPrcKeKoncCeniku_Prisl" type="format" format={row => 
                        <div className="procInput"><RInput type="number" state={row} item="fPrdPrcKeKoncCeniku_Prisl" timer="500" onChange={this.changeRow} /><span className="proc">%</span></div>
                    }/>
                    
                    <tab-col name="fPrdPrcKeKoncCeniku_Pripl" type="format" format={row => 
                        <div className="procInput"><RInput type="number"  state={row} item="fPrdPrcKeKoncCeniku_Pripl" timer="500" onChange={this.changeRow} /><span className="proc">%</span></div>
                    }/>
                </Table>
            </Comp>
        )
    }
}