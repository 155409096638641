/* eslint-disable no-unused-expressions */
import { XMLParser } from "fast-xml-parser";
import { Ariscat, Ref, Repo, SDate } from "../dump";

let xmlParser = new XMLParser({
  ignoreAttributes : false,
  attributeNamePrefix : ""
});

export class Datapacket {
    constructor(fields, commonProp, recordset = "", switches) {
        //registerClass(this);
        this.xml = [];
        this.fields = [];
        this.rows = [];
        this.recordset = recordset;
        this.switches = this.createSwitches(switches);

        if(fields) this.addFields(fields, commonProp);
    }

    //zkratky pro datove typy
    static get fi4()  { return { fieldtype: "i4"} }
    static get fdt()  { return { fieldtype: "dateTime"} }
    static get fstr() { return { fieldtype: "string.uni"}}
    static get fbo()  { return { fieldtype: "boolean"}}
    static get fbl()  { return { fieldtype: "bin.hex"}}

    addField(attrname, visible, orderby, other = {}) {
        let field = { type: "FIELD", attrname, visible, orderby };
        this.fields.push(Object.assign(field, other));
    }

    addFields(fields, commonProp) {
        for(var field of fields) {  

            if(typeof field === "string")
                var attrname = field;
            else 
                var [attrname, setting, orderby, other] = field;

            var f = {type: "FIELD"};
            f = Object.assign(f, { visible: true });
            if(commonProp) f = Object.assign(f, commonProp || {});

            (attrname instanceof Object) ? f = Object.assign(f, attrname) : f.attrname = attrname;

            if(setting instanceof Object)
                f = Object.assign(f, setting)
            else if(typeof setting === "number")
                f.width = setting;
            else if(typeof setting === "boolean")
                f.visible = setting;
            else if(typeof setting === "string")
                f.ftype = setting;

            (orderby  instanceof Object) ? f = Object.assign(f, orderby)  : orderby !== undefined && (f.orderby = orderby);
            (other    instanceof Object) ? f = Object.assign(f, other)    : false;

            if(f.fieldtype === undefined) {
                let type = "string.uni";
                if(attrname == "ID") type = "i4";
                else if(attrname.startsWith("ns")) type = "string.uni";
                else if(attrname.startsWith("d"))  type = "dateTime";
                else if(attrname.startsWith("i"))  type = "i4";
                else if(attrname.startsWith("bl")) type = "bin.hex";
                else if(attrname.startsWith("b"))  type = "boolean";

                else if(attrname.startsWith("f"))  {
                    type = "fixed";
                    f.DECIMALS = 4;
                    f.WIDTH = 32;
                }

                f.fieldtype = type;
            }

            //atribut SUBTYPE musi byt velkym pismem!
            if(f.ftype == "wt" || f.ftype == "WideText") {
                f.SUBTYPE = "WideText";
                f.fieldtype = "bin.hex";
                delete f.ftype;
            }
            else if(f.ftype == "guid" || f.ftype == "Guid") {
                f.SUBTYPE = "Guid";
                f.fieldtype = "string";
                f.width = 38;
                delete f.ftype;
            }
            else if(f.type == "bin" || f.ftype == "Binary") {
                f.SUBTYPE = "Binary";
                f.fieldtype = "bin.hex";
                delete f.ftype;
            }

            this.fields.push(f);
        }
    }

    addRow(row) {
        row.type = "ROW";
        this.rows.push(row);
    }

    addRows(rows) {
        if(!rows) return;

        for(let row of rows) {
            row.type = "ROW";
            this.rows.push(row);
        }
    }

    findField(attrname) {
        return this.fields.find(f => f.attrname == attrname) || {};
    }

    async sendFields(recordset, fields) {
        this.recordset = recordset;
        this.fields = fields;
        await this.send();
    }

    static parseXML(xml) {
        return xmlParser.parse(xml);
    }

    static fromXML(xml) {
        const json = xmlParser.parse(xml);
        const fields = json.METADATA.FIELDS.FIELD;
        const fOther = [];
        const fString = [];

        fields.forEach(m => {
            if(m.SUBTYPE) {
                const item = `['${m.attrname}', '${m.SUBTYPE}'],`;
                (m.fieldtype == "string" || m.fieldtype == "string.uni") ?
                    fString.push(item) : 
                    fOther.push(item);
            }
            else if(m.fieldtype == "string.uni")
                fString.push(`['${m.attrname}', ${m.WIDTH}],`);
            else
                fOther.push(`'${m.attrname}',`); 
        })

        fOther.sort();
        fString.sort();

        return "[\n" + [...fOther, ...fString].join("\n") + "\n]";
    }

    
    static createSwitches(switches = {}) {
        return Object.entries(switches).map(([key, val]) => `/${key}:"${val}"`).join(" ");
    }

    createSwitches(switches = {}) {
        this.switchesRaw = switches;
        const sw =  Object.entries(switches)
            .filter(([k,v]) => v !== undefined && v !== null && v !== NaN)
            .map(([key, val]) => `/${key}:"${val}"`).join(" ");

        this.switches = sw;
        return sw;
    }

    get clear() {
        this.fields = [];
        this.rows = [];
        return true;
    }

    get result() {
        let xml = "";
        let switches = this.switchesRaw || {};

        if(this.fields.length == 0) return "";

        function createXML(array, name) {
            xml += "<" + name + ">"

            for(let ar of array) {
                xml += `<${ar.type} `;
                
                for (let [key, value] of Object.entries(ar)) {
                    //console.log("kv", key, value);
                    
                    if(key != "type" && value !== undefined) {
                        //prevede HTML znacky (vyjimka blob) a typ date -> string
                        if(typeof value === "string" && !key.startsWith("bl")) {
                            value = value.replaceAll("\n", "_._n_._");
                            value = Ariscat.htmlEntities(value);
                            value = encodeURIComponent(value)
                                .replaceAll("_._n_._", "&#x0D;&#x0A;")
                                .replaceAll("%3D", "=");
                        }
                        else if(typeof value === "boolean") {
                            value = value ? "TRUE" : "FALSE";
                        }
                        else if(value instanceof Date) {
                            value = SDate(value).format("YYYY-MM-DDTHH:mm:ss")
                        }
                        xml += `${key}="${value}" `;
                    }
                }
    
                xml += "/>";
            }

            xml += "</" + name + ">"
        }

        createXML(this.fields, "FIELDS");
        xml += "</METADATA>";

        if(this.rows.length) {
            createXML(this.rows, "ROWDATA");
        }

        xml = xml.replace(/ \/\>/g,"/>");

        return `<DATAPACKET Version="2.0"><METADATA>${xml}</DATAPACKET>`;
    }

    convertRows(row) {
        if(!row) return [];

        const rowArray = Object.values(row);

        rowArray.map(m => {
            for(let key of Object.keys(m)) {
                if(key.startsWith("b") && !key.startsWith("blb")) {
                    m[key] = (m[key] == "TRUE");
                }
                else if(key.startsWith("i") || key == "ID") {
                    m[key] = +m[key];
                }
                else if(key.startsWith("f")) {
                    m[key] = +m[key];
                }
                else if(key.startsWith("d")) {
                    m[key] = new SDate(m[key])
                }
                else if(key.startsWith("ns")) {
                    m[key] = m[key].replaceAll("&#x0D;", "").replaceAll("&#x0A;", "\n");
                }

            }
        
            return m
        });

        return rowArray;
    }

    async send(type = "g", xml = this.result, switches = this.switches) {
        let cols = [];
        let rows = [];
        let error = [];
        let ferror = 0;

        let req = [this.recordset, xml, switches];
        
        try {
            let res = await this.postFetch(type + "rl", req);

            if(!res.result) {
                error = "No result!" + res;
                ferror++;
                console.log(error);
            }

            if(res.result[1] === false) {
                ferror++;
                error.push(res.result[2]);
            }

            const xml = res.result[2];
            const data = xmlParser.parse(xml);
    
            if(!data) {
                error.push("noData");
            }

            if(data.DATAPACKET && data.DATAPACKET.ROWDATA != '') {	
                rows = (Array.isArray(data.DATAPACKET.ROWDATA.ROW) ?
                    data.DATAPACKET.ROWDATA.ROW : data.DATAPACKET.ROWDATA) || [];
            }
            else {
                error.push("noRowdata");
            }

            if(data.DATAPACKET && data.DATAPACKET.METADATA)
                cols = (data.DATAPACKET.METADATA.FIELDS || {}).FIELD || [];
            else 
                error.push("noMetadata");

            if(cols.length == 0) error.push("emptyCols");
            if(rows.length == 0) error.push("emptyRows");

            return { 
                error, cols, ferror,
                get res() { return res }, 
                get req() { return req }, 
                get columns() { return Datapacket.fromXML(res.data) },
                reqRows: this.rows,
                reqFields: this.fields,
                rows: this.convertRows(rows), 
                erc: error.length
            }
        }
        catch(e) {
            ferror++;
            console.error(type + " RL!!!: ", e, {info: this});
            error.push(e.message.replace("Invalid XML:", "chyba:"));
            return { cols, rows: this.convertRows(rows), error, erc: error.length, req, ferror }
        }
    }

	static async deleteRecord(recordset, recID) {
		try {
			let json = await this.prototype.postFetch("deleteRecord", [recordset, recID]);

			if(json.result && json.result[1])
				return json.result[1];
            else
                return { error: true, result: json.result[2] }
		}
		catch(e) {
			console.error("deleteRecord!!!: ", e);
            return null;
			//this.parseRequest(request, status, error)
		}
    }

    async duplicateRecord(ID) {
        var nsswitches = this.switches;
        var recordset = this.recordset;
        var data = this.result;

		try {
			let json = await this.postFetch("duplicateRecordByID", [recordset, ID, data, nsswitches ]);

			if(json.result && json.result[1])
				return json.result[1];
            else if (json.result && !json.result[1])
                return { error: json.result[0] }
            else {
                return {error: "Záznam se nepodařilo duplikovat!", result: json }
            }
		}
		catch(e) {
			console.error("duplicateRecord!!!: ", e);
            return { error: e.message }
			//this.parseRequest(request, status, error)
		}
    }

    async postFetch(fnName, values) {
        //console.log("fetch 2", fnName, Repo);
        var r = await fetch(Repo.serverURL + "/api/" + fnName + "/", {
            method: "POST",
            credentials: "same-origin",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
    
        return await r.json();
    }

    static async getRecInfo(recordset, recordid, tabulky = 0, data = "", nsswitches = {}) {
        if(tabulky == 1) nsswitches.bJoinLstWantEmpty = 1;
        const stc = this.prototype;
        const switches = stc.createSwitches(nsswitches);

        try {
            let json = await stc.postFetch("gri", [ recordset, recordid, /*data, switches*/ ]);

            if(json.result && json.result[1]) {
                const res = xmlParser.parse(json.result[2]) || {};
                const row = (res.DATAPACKET.ROWDATA || {});
                let ret = stc.convertRows(row)[0];
                ret ? (ret._error = false) : (ret = { _error: true })
                return ret;
            }
            else if (json.result){
                return { error: json.result[2]}
            }
            else {
                return { error: "Response Error!"} ;
            }
        }
        catch(e) {
            console.error("GetRecordInfo!!!: ", e.message);
            return { error: "Server error!"}
        }	
    }

}