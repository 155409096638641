import './styl.scss';
import { SComponent, h } from "../../dump";

export class Checkbox extends SComponent {
    componentDidMount() {
        this.update();
    }

    /*
    componentDidUpdate(np) {
        this.props = np;
        this.update();
    }
    */

    componentWillReceiveProps(np) {

        this.props = np;
        this.update();
    }

    componentDidUpdate(np) {
        const p = this.props;
        let old = this.state.checked || false;

        if(p.item && np.state) {
            let nw = np.state[p.item] || false;

            if(old !== nw) this.update();
        }
    }

    update() {
        const p = this.props;
        
        let checked = (p.state && p.item) ?
        p.state[p.item] || false :
        p.checked || false;  

        if(checked == "0") checked = false;
        else if(checked == "1") checked = true;

        if(p.state && p.item) {
            p.state[p.item] = checked;
        }
        
        this.set.checked = checked;
    }

    change =e=> {
        //zmeny jeste nejsou ulozeny
        if(document.body.className.includes("changed")) {
            e.preventDefault();
            return;
        }

        //console.log("klik", this.props.timer);
        if (this.timerHandle) clearTimeout(this.timerHandle);

        window.changedCount = window.changedCount || 0;
        window.changedCount++;
        var inpChangedCount = window.changedCount;

        const p = this.props;
        const checked = !this.state.checked;
        //console.log("checked", checked);
        const data = { e, state: p.state, it: this }

        if (p.state && p.item) {
            p.state[p.item] = checked;
        }
        
        if (p.timer > 0) {
            //zabrani editaci dalsich poli, pokud jeste neni ulozena stranka
            document.body.classList.add("changed");

            //nastaveni prodlevy pro onCheck (spusti se az po zadanem case)

            this.set.changed = true;

            this.timerHandle = setTimeout(() => {
                if (inpChangedCount == window.changedCount) {
                    if(p.onCheck) {
                        p.onCheck(checked, p.item, data);
                    }
                }
            }, p.timer);
        }
        else if(p.onCheck) {
            var ret = p.onCheck(checked, p.item, data, this);
        }

        this.set.checked = checked;

        if(p.state && p.item) {
            p.state[p.item] = checked;
        }
    }

    rnd =(s,p)=> {
        const pCopy = {...p};
        delete pCopy.state;

        let name = [];
        if(p.name) name = <label className="riLabel" style={p.style}>{p.name}</label>
        else if(p.tname) name = <label className="riLabel" style={p.style}>{_[p.tname]}</label>

        delete pCopy.name;
        delete pCopy.className;

        return (
            <div className={"checkboxWrapper " + p.item} {...pCopy}>{
                h('input', { 
                    className: this.cln({
                        checkbox: 1, 
                        checked: s.checked, 
                        disabled: p.disabled, 
                        changed: s.changed || false,
                        [p.item]: 1, 
                        [p.className]: 1
                    }),
                    type: "checkbox",
                    tabIndex: "0", 
                    //onKeyPress: this.change,
                    onClick: this.change,
                    checked: p.not ? !s.checked : s.checked,
                    ...pCopy
                })}
                <span> {name}</span>
            </div>
        )
    }
}