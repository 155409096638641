import './tab.scss'
import { SComponent, _, Datapacket, Rq, RIcons, RInput, Repo, Ref, SDate, createRef, Ariscat, If, Btn, ColSettings } from "../../dump";
import { createElement } from "preact";
import { ButtonMenu } from "../buttonMenu/buttonMenu";

/*
props:
prepsat nastaveni sloupcu - tabulka nebude nacitat data z backendu
customCols

vychozi zobrazeni poli s hledanim
showFilters

TLACITKO NA NASTAVENI SLOUPCU
changeCols

recordset
detail

TABULKA SE PREKRESLI PRI KAZDE ZMENE
needUpdate

expand - roztahne tabulku na sirku

showAll - zobrazi vsechny radky
*/

export class Table extends SComponent {
    async cmount(s, p) {
        // console.log("tab", this);
        this.cols = [];
        this.colsVis = [];
        this.rows = [];
        this.rowsSearch = [];
        this.needHide = true;
        this.filters = {};
        this.colStgRef = createRef();
        this.hintRef = createRef();
        this.tabRef = createRef();
        this.showFilters = this.props.showFilters !== false;
        this.customCols = this.props.customCols !== false;
        this.changeCols = this.props.changeCols !== false;

        this.lastPageIdx = -1

        this.rec = {
            max: p.showAll === true ? 1000000 : 20,//4000000000,
            start: 0,
            page: 1
        }

        this.recordsFrom = 0;
        this.recordsTo = 0;
        this.hiddenColumns = []
        this.hiddenColumnsCount = 0;
        this.childrens = (Array.isArray(this.props.children) ? this.props.children : [this.props.children || []])
        this.buttons = this.childrens.filter(f => f.type == "tab-button");
        this.barButtons = this.childrens.filter(f => f.type == "bar-button");
        this.menuButtons = this.childrens.filter(f => f.type == "menu-button");
        this.tabBar = this.childrens.filter(f => f.type == "tab-bar");
        this.customCols = {};
        this.customColsAr = this.childrens.filter(f => f.type == "tab-col").map(m => (this.customCols[m.props.name] = m.props, m.props));

        this.hint = {
            x: 0,
            y: 0,
            text: "",
            visible: true
        }

        this.loc = window.location.pathname.split("/");
        this.loaded = false;

        if (!this.loaded) {
            if (!Repo.tabList) Repo.tabList = [];

            Repo.tabList.push(this);
        }

        //if(this.props.customCols === false) this.customCols = false;

        document.onresize = this.renderTable.bind(this);

        await this.getData(p, true);
    }

    componentWillReceiveProps(np) {
        let needUpdate = false;
        console.log("UP", this);

        if (this.props.needUpdate) {
            this.props = np;
            this.reload();
        }
        else {
            Object.keys(this.props).forEach(p => {
                if (p !== "children" && typeof this.props[p] !== "function" && this.props[p] !== np[p]) {
                    needUpdate = true;
                    return;
                }
            })

            this.props = np;
            needUpdate && this.reload();
        }
    }

    componentWillUnmount() {
        Repo.tabList = Repo.tabList?.filter(f => f !== this) || [];
    }

    getSwitches(p = this.props, reload = false) {
        const switches = {};

        if (reload) {
            switches.frReload = true;
        }
        //  else{
        //     switches.frReload = false
        // }

        for (let [key, val] of Object.entries(p)) {
            if (key.startsWith("sw_")) {
                switches[key.substr(3)] = val;
            }
        }

        return switches;
    }

    async getData(p = this.props, reload = false) {
        const switches = {};

        if (reload) {
            switches.frReload = true;
        }

        for (let [key, val] of Object.entries(p)) {
            if (key.startsWith("sw_")) {
                switches[key.substr(3)] = val;
            }
        }

        if (p.recordset) {
            var stg = Repo.tables[p.recordset];

            if (stg && stg.rows) {
                this.data = {
                    rows: stg.rows,
                    cols: stg.cols
                }

                this.loaded = false;
            }

            let cols;

            if (p.cols == 0 || !p.cols) cols = 0;
            else if (p.cols == 1) cols = 1;

            this.data = await Rq.sd(p.recordset, cols, 0, switches);
        }
        else if (p.rows) {
            this.data = { rows: p.rows, cols: [] };
        }
        if (Array.isArray(p.cols)) {
            this.data.cols = p.cols;
        }
        if (this.props.customCols) {
            Object.keys(this.customCols).forEach(key => {
                const fnd = this.data.cols.find(f => f.attrname == key);
                if (fnd) {
                    this.customCols[key].caption = fnd.caption;
                    this.customCols[key].attrname = fnd.attrname;
                    this.customCols[key].fieldtype = fnd.fieldtype;
                }
            });
        }

        await this.showTable();
        p.onLoad && p.onLoad(this);
        this.saveStg();
        window.onresize = () => this.renderTable();
        // Zamena '?' za prazdny string u vyrobniho statusu
        this.data.rows.map(e => {
            if (e.nsObjPrdStatusTrans === '?') e.nsObjPrdStatusTrans = ''
        })
    }

    async showTable() {
        this.cols = this.data.cols;
        this.colsVis = this.cols.filter(f => f.visible == true || f.visible == "TRUE");
        this.sortCol = this.cols.find(f => Math.abs(f.orderby) == 1) || {};
        this.rows = this.data.rows;
        this.rowsSearch = this.data.rows;

        if (this.loaded) {//resetuje se, az po 1. nacteni
            this.resetFilters();
            this.resetPages();
        }
        else {
            this.loadStg();
        }

        await this.renderTable();
        this.loaded = true;
    }

    componentDidMount() {
        this.update();
    }

    loadStg() {
        var recordset = this.props.recordset;

        //hledani se prepise hledanim v url
        if (this.loc[this.loc.length - 2] == "search") {
            this.state.searchText = this.loc[this.loc.length - 1];
        }
        else if (this.loc[this.loc.length - 2] == "id") {
            this.state.searchID = +this.loc[this.loc.length - 1];
        }

        //promaze ostatni tabulky
        /*
        Object.keys(Repo.tables)
            .filter(key => key.startsWith("tab_") && key != "tab_" + rc)
            .forEach(key => localStorage[key] = "");
        */

        var stg = Repo.tables[recordset];

        if (stg) {
            this.filters = stg.filters;
            this.rec = stg.pages;
        }

        var stg = Repo.tables[recordset];

        //prednost ma hledani v url
        this.state.searchText = this.state.searchText || "";/* || stg.search;*/
    }

    componentDidUpdate(np) {
        const p = this.props;

        if (p.state && p.item) {
            let old = p.state[p.item];
            let nw = np.state[p.item];
            if (old !== nw) this.update();
        }
    }

    update() {
        const p = this.props;

        this.set.value = (p.state && p.item) ?
            p.state[p.item] || "" :
            p.value || "";
    }

    sort = col => {
        this.sortCol = col;
        const orderby = col.orderby;
        this.cols.forEach(f => f.orderby = 0);
        col.orderby = orderby != 0 ? -orderby : 1;
        this.refresh();
        this.resetPages();

        const cols = this.cols.map(col => {
            const ccol = { ...col };
            ccol.type = "FIELD";
            delete ccol.table;
            delete ccol.caption;
            delete ccol.checked;
            return ccol;
        });

        const DP = new Datapacket();
        DP.sendFields(this.props.recordset, cols);
    }

    change = e => {
        const p = this.props;
        const val = e.target.value;
        this.set.value = val

        if (p.state && p.item) {
            p.state[p.item] = val;
        }

        p.onChange && p.onChange(val, p.item, e);
    }

    getPages = () => {
        let r = [];
        let page = this.rec.page;

        let lastPage = Math.ceil(this.rowsSearch.length / this.rec.max);
        // let pageMin = page - 1 > 0 ? page - 1 : 1;
        // let pageMax = page + 1 < lastPage ? page + 1 : lastPage;
        let lastID = 0;

        const li = (i, active, disabled) => {
            let cls = ["paginate_button"];
            active && cls.push("active");
            disabled && cls.push("disabled");
            lastID++;

            return <li key={lastID} className={cls.join(" ")} onClick={() => this.setPage(i)}><a>{i}</a></li>
        }

        // if(page > 2 && lastPage > 2){
        //     r.push(li(1))
        //     r.push(li(2))
        //     r.push(li("...", 0 ,1))
        // }

        // if(page < 3){
        //     for (let i = 3 - page; i < page; i++){
        //         r.push(i)    
        //     }
        // }

        // if(page > 3){
        //     r.push(li(1))

        //     for(let i = -2; i <= 2; i++){
        //         r.push(li(page + i, i === 0))
        //     }
        // }
        // else if (page <= 3){
        //     r.push(li(1, page === 1))
        //     r.push(li(2, page === 2))
        //     r.push(li(3, page === 3))
        //     r.push(li(4, page === 4))
        // }


        // if(page + 2 < lastPage){
        //     r.push(li(lastPage))
        // }

        const totalPages = lastPage; 

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                r.push(li(i, i === page));
            }
        } else {
            if (page <= 4) {
                for (let i = 1; i <= 5; i++) {
                    r.push(li(i, i === page));
                }
                r.push(li('...')); 
                r.push(li(totalPages));
            } else if (page >= totalPages - 3) {
                r.push(li(1));
                r.push(li('...')); 
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    r.push(li(i, i === page));
                }
            } else {
                r.push(li(1));
                r.push(li('...')); 
                for (let i = page - 1; i <= page + 1; i++) {
                    r.push(li(i, i === page));
                }
                r.push(li('...'));
                r.push(li(totalPages));
            }
        }



        // if(page + 2 < lastPage){
        //     r.push(li("...", 0 ,1))
        //     r.push(li(lastPage - 1))
        //     r.push(li(lastPage))
        // }

        // if (page > 2 && lastPage > 2) {
        //     r.push(li(1))
        //     r.push(li("...", 0, 1))
        // }

        // for (let i = pageMin; i <= pageMax; i++) {
        //     r.push(li(i, page == i))
        // }

        // if (page + 2 < lastPage) {
        //     r.push(li("...", 0, 1))
        //     r.push(li(lastPage))
        // }

        return r;
    }

    filterChange(col, value) {
        const key = col.attrname || col.name;

        if (this.rec.page > 1) {
            this.lastPageIdx = this.rec.page
        }

        this.filters[key] = value;
        if (value === "") delete this.filters[key];

        /*
        switch(type) {
          case "d": value = value ? SDate(value).dmy : ""; break;
          case "f": value = +value.replace(",", "."); break;
        }
        
        this.filters[field] = value;
        if(value === "")      delete this.filters[field]
        */

        this.resetPages();
        // this.renderTable();
    }

    resetFilters = async () => {
        this.filters = {};
        await this.renderTable();
        // this.resetPages();
        // Object.keys(this.filters).forEach(key => this.filters[key] = "");
        //tento text bude mazat jen tlacitko u horniho filtru
        //this.set.searchText = "";
        // this.lastPageIdx = -1
        this.setPage(this.lastPageIdx)
        this.lastPageIdx = -1
    }

    createFilters() {
        const cols = this.props.customCols ? this.customColsAr : this.colsVis;

        const inputs = cols.map((col, i) => {
            //pokud se sloupec nevejde, nebo je skryty, nezobrazit
            if (this.hiddenColumns[i] || this.customCols[col.attrname || col.name]?.type == "hide") return [];

            let input =
                <RInput
                    onChange={val => this.filterChange(col, val)}
                    title={_.searchTextColChars}
                    //placeholder={_.search}
                    placIcon="Search"
                    value={this.filters[col.attrname] || this.filters[col.name] || ""}
                />

            return (
                <div className="td filter" key={col.attrname || col.name || i}>{input}</div>
            )
        })

        return (
            <div className={this.cln({ tr: 1, filters: 1, hidden: !this.showFilters })}>
                {this.hiddenColumnsCount ? <div className="td"></div> : []}
                {inputs}
                <div className="td actionBtn">
                    <Btn onClick={this.resetFilters}><RIcons.Fa.FaTimes /></Btn>
                </div>
            </div>
        )
    }

    deleteRow = async (row, showDialog) => {
        var res = true;

        if (showDialog) {
            res = await Ref.confirm.open("Info", _.deleteRecordConfirm);
        }

        if (res) {
            const r = await Rq.del(this.props.recordset, row.ID);
            r.error ? Ref.notify(r.result, 5, "error") : this.getData();
        }
    }

    reload = async () => {
        await this.getData(this.props, true);
    }

    dusts = (m, places = 2) => {
        if (m === undefined || m === null) return "-";
        return parseFloat(m).toLocaleString("cs-CZ", { style: "currency", currency: "BBB", minimumFractionDigits: places }).replace("BBB", "").trim()
    }

    getTD(row) {
        row.del = (dialog = true) => this.deleteRow(row, dialog);
        row.reload = () => this.reload();

        const cols = this.props.customCols ? this.customColsAr : this.colsVis;

        return cols.map((col, i) => {
            //pokud se sloupec nevejde, nezobrazit
            if (this.hiddenColumns[i]) return [];

            const key = col.attrname || col.name;
            const pos = col.fieldtype == "i4" ? "text-right" : "";
            const cls = ["td", "field", key, pos, col.fieldtype, this.customCols[key]?.type || ""].join(" ");
            let value = row[key];

            if (col.fieldtype == "dateTime")
                value = value ? value.fDate : "-";
            if (col.fieldtype == "fixed" || col.fieldtype == "fixedFMT")
                value = this.dusts(value);
            else if (col.fieldtype == "boolean")
                value = value ? <RIcons.Fa.FaCheck /> : "";
            else
                value = value || "-";

            if (this.customCols[key]) {
                const val = row[key];

                if (key == "iAttVelikost") {
                    console.error("vvv", row);
                }

                switch (this.customCols[key].type) {
                    case "hide": return [];
                    case "color": value = <div className="col-color" style={{ background: val }}></div>; break;
                    case "img": value = val ? <img src={val} className="img" /> : []; break;
                    case "format": value = this.customCols[key].format(row, col, i, this); break;
                    case "date": value = SDate(val).toString(); break;
                    case "size": value = Ariscat.printSize(val); break;
                    case "money": value = Ariscat.money(val); break;
                    case "boolean": value = value === true ? <RIcons.Fa.FaCheck /> : ""; break;
                    default: value = val;
                }

                //dulezite pro hledani
                if (this.customCols[key].type == "format") {
                    row._formats = row._formats || {};
                    row._formats[key] = value;
                }
            }

            return <div className={cls} key={i}>{value}</div>
        })
    }

    columnSort = (a, b) => {
        const scl = this.sortCol.attrname;
        let x = a;
        let y = b;

        /*
        if(this.sortCol.orderby == -1) {
          x = b;
          y = a;
        }
        */

        const sortColCust = this.customColsAr?.find(f => f.sorting !== undefined);

        if (this.sortCol.fieldtype == "string.uni") {
            return String(x[scl]).localeCompare(y[scl], navigator.language, { sensitivity: 'variant', caseFirst: 'upper' });
        }
        else if (sortColCust) {
            const key = sortColCust.attrname || sortColCust.name;
            console.log("cst", key, x, y);
            return (x[key] || 0) - (y[key] || 0);
        }
        else {
            //console.log("col sort", scl, x[scl] - y[scl], x, y);
            return (x[scl] || 0) - (y[scl] || 0);
        }
    }

    createActions(row) {
        var button = this.buttons.find(f => f.props?.children) || [];
        this.actionsNum = 0;

        this.actions = this.getChildrens(button.props?.children || []).map((ac, i) => {
            let txt = ac.props.text;
            const visible = ac.props.visible ? ac.props.visible(row, i) : true;

            if (ac.props.textFN) txt = ac.props.textFN(row);

            if (visible) this.actionsNum++;

            return (
                <me-nu key={i}
                    className={this.cln({ dnone: !visible, [ac.props.className || ""]: 1 })}
                    onClick={e => ac.props.click(row, e, i)}
                >
                    {typeof ac.props.icon === "string" ? createElement(RIcons.Fa["Fa" + ac.props.icon]) : ac.props.icon} {txt}
                </me-nu>
            )
        })
    }

    //zformatuje radek na pole textovych hodnot pro vyhledavani
    getRowValues(row) {
        var rowValues = [];

        for (let [key, val] of Object.entries(row || {})) {
            const type = typeof val;

            if (val instanceof Date) {
                rowValues.push(val.toString());
            }
            else if (type == "number" || type == "string") {
                rowValues.push(String(val));
            }
            else if (type == "boolean") {
                rowValues.push(val ? _.yes : _.no);
            }
            //hledani i v naformatovanych sloupcich
            else if (type == "object" && key == "_formats") {
                rowValues.push(...Object.values(val))
            }
            else {
                //console.log("KV", key, val)
            }

            //console.log(type);
        }

        return rowValues;
    }

    getSearchRows() {
        this.rowsSearch = this.rows.filter((row, i) => {
            let hidden = true;

            for (let [key, val] of Object.entries(this.filters)) {
                //console.log("CT", key);
                let rk = row._formats?.[key] || row[key];
                rk = rk instanceof Date ? rk.dmy : String(rk);
                hidden = hidden && row[key] && Ariscat.compareText(val, String(rk));
            }

            if (this.state.searchText) {
                hidden = hidden && Ariscat.compareText(this.state.searchText, this.getRowValues(row));
            }

            return hidden;
        })

        if (this.props.filter) {
            this.rowsSearch = this.rowsSearch.filter(this.props.filter);
        }

        return this.rowsSearch;
    }

    getRows() {
        const r = this.rec;

        this.rowsSearch = this.getSearchRows();

        const sorted = this.rowsSearch.sort(this.columnSort);

        if (this.sortCol && this.sortCol.orderby == -1) {
            sorted.reverse();
        }

        //console.log("sorted", sorted, this.rows);

        if (sorted.length && this.state.searchID && !this.state.wasPageSet) {
            var fnd = sorted.find(f => f.ID == this.state.searchID);
            var idx = sorted.indexOf(fnd);
            var page = Math.floor(idx / r.max) + 1;
            //console.log("idx", idx, r.max, page);
            this.state.wasPageSet = true;
            this.setPage(page, 0);
        }

        return sorted.filter((row, i) => (i >= r.start) && (i < (r.start + r.max)))
            .map((row, i) => {
                let cn = this.props.classTR ? this.props.classTR(row, i, this) : "";
                if (this.props.detail) cn += " detail";

                return (
                    <div key={i} className={"tr " + cn} onClick={e => {
                        e.stopPropagation();

                        //pokud je oznacen text, neklikat
                        if (window.getSelection().toString().length > 0) {
                            return;
                        }

                        if (this.props.detail && e.target?.className.includes("td")) {
                            this.props.detail(row, i, this.rows);
                        }
                    }}>
                        {this.hiddenColumnsCount ?
                            <div className="td tableTooltip">
                                <Btn info
                                    onMouseOver={e => Ref.tooltip.open(this.createTooltip(row), e)}
                                    onMouseLeave={e => (e.stopPropagation(), Ref.tooltip.close())}
                                >
                                    <RIcons.Fa.FaInfoCircle />
                                </Btn>
                            </div>
                            : []}
                        {this.getTD(row)}
                        <div className="td actions">
                            {this.createActions(row)}
                            <If is={this.actionsNum}><ButtonMenu name="" className="right"
                                icon={<>
                                    <div style={{ minWidth: "1.5rem" }}>
                                        <RIcons.Fa.FaCog />
                                        <RIcons.Fa.FaCaretDown />
                                    </div>
                                </>}
                            >{this.actions}</ButtonMenu></If>
                        </div>
                    </div>)
            })
    }

    createTooltip(row) {
        return this.colsVis.filter((f, i) => this.hiddenColumns[i]).map(col => {
            var key = col.attrname;
            var val = String(row[key] || "-");

            //312210834
            if (typeof row[key] === "boolean") {
                val = row[key] ? _.yes : _.no;
            }
            else if (this.customCols[key]) {
                switch (this.customCols[key].type) {
                    case "color": val = <div className="col-color" style={{ background: val }}></div>; break;
                    case "img": val = val ? <img src={val} className="img" /> : []; break;
                    case "format": val = this.customCols[key].format(row, col);
                }
            }

            return (
                <div className={"tooltipRow " + col.attrname}>
                    <span className="col1">{col.caption + ": "}</span>
                    <span className="col2">{val}</span>
                </div>
            )
        })

        /*
        const pageHg = document.documentElement.scrollHeight;

        this.hint = {
            x: e.pageX + 10,
            y: e.pageY + 10,
            visible: true,
            text: <table><tbody>{table}</tbody></table>
        }

        await this.refresh();

        const hintHg = 200;//DODELAT; this.hintRef.current.scrollHeight;
        const hintPagePos = pageHg - (e.pageY + 10 + hintHg);

        if (hintPagePos < 0) {
            this.hint.y += hintPagePos - 10;

            this.refresh();
        }
        */
    }

    hideHint = () => {
        this.hint.visible = false;
        this.refresh();
    }

    setPage(page, dif = 0) {
        // console.log('Set page, page: ', page, this.rowsSearch.length, this.rec.max);
        if (page == 0) page = this.rec.page + dif;

        if (page < 1 || page > Math.ceil(this.rowsSearch.length / this.rec.max) || page == "...") return;

        const r = this.rec;
        r.page = page;
        r.start = page * r.max - r.max;

        this.set.searchID = "";

        this.renderTable();
        this.saveStg();
    }

    saveStg = async () => {
        //pockat na prekresleni
        await Ariscat.wait(50);

        var stg = {
            recordset: this.props.recordset,
            pages: this.rec,
            filters: this.filters,
            search: this.state.searchText,
            cols: this.cols,
            rows: this.rows
        }

        Repo.tables[this.props.recordset] = stg;

        window.stg = stg;
    }

    setFilter = max => {
        this.resetPages();
        this.set.searchID = "";
        this.rec.max = +max;
        //this.setState({ tabLoading: true});
        this.renderTable();
        this.saveStg();
    }

    resetPages() {
        this.set.searchID = "";
        this.rec.min = 0;
        this.rec.page = 1;
        this.rec.start = 0;
        this.saveStg();
    }

    search = async () => {
        this.resetPages();
        this.saveStg();
        this.renderTable();
    }

    searchClear = async e => {
        this.resetPages();
        this.set.searchText = "";
        this.saveStg();
        this.renderTable();
    }

    createLabels = () => {
        const cols = this.props.customCols ? this.customColsAr : this.colsVis;

        return cols.map((col, i) => {
            let sort = "";
            let sortIcon = null;

            //pokud se sloupec nevejde, nebo je skryty, nezobrazit
            if (this.hiddenColumns[i] || this.customCols[col.attrname || col.name]?.type == "hide") return [];


            if (col.orderby == -1) {
                sort = "sorting down";
                sortIcon = RIcons.Fa.FaSortDown;
            }
            else if (col.orderby == 1) {
                sort = "sorting up";
                sortIcon = RIcons.Fa.FaSortUp
            }

            const key = col.attrname || col.name;
            const pos = col.fieldtype == "i4" ? "text-right" : "";
            const ftype = (col.fieldtype || "").replaceAll(".", "");
            const cls = [sort, col.attrname, pos, ftype, this.customCols[key]?.type || ""].join(" ");

            return (
                <div
                    className={"th tableHeader " + cls}
                    key={col.attrname}
                    onClick={e => this.sort(col)}
                >
                    {col.caption}{sortIcon && createElement(sortIcon)}
                </div>
            )
        })
    }

    async renderTable() {
        this.hiddenColumns = [];
        this.hiddenCols = false;
        await this.refresh();

        if (this.props.expand !== true) {
            this.hideCols();
            await this.refresh();
        }
    }

    hideCols() {
        const tab = this.tabRef.current;
        //const maxSpace = window.innerWidth - tab?.offsetLeft - 40;
        let maxSpace = tab?.closest(".tableWrapper")?.getBoundingClientRect()?.width - 220;
        //console.log("mx", maxSpace);

        let sum = 0;
        this.hiddenColumnsCount = 0;

        if (!tab) {
            return;
        }

        //projde vsechny sloupce
        for (let s of tab?.querySelectorAll(".tabCols .th")) {
            sum += s.clientWidth;
            //console.log("s", s);

            if (s.className.includes("tableHeader")) {
                const hide = sum >= maxSpace;
                this.hiddenColumns.push(hide);

                if (hide) this.hiddenColumnsCount++;
            }
        }

        if (this.hiddenColumnsCount) {
            this.hiddenCols = true;
        }
    }

    // if (this.state.tabLoading) { // udelat loading tabulky }

    get colsCount() {
        const cols = this.props.customCols ? this.customColsAr : this.colsVis;
        const plus = this.hiddenColumnsCount ? 2 : 1;
        return cols.filter((col, i) => !(this.hiddenColumns[i] || this.customCols[col.attrname || col.name]?.type == "hide")).length + plus;
    }

    get tabInfo() {
        const start = this.rowsSearch.length ? (this.rec.start + 1) : "0";
        const max = (+this.rec.start + (+this.rec.max)) < this.rowsSearch.length ?
            (+this.rec.start + (+this.rec.max)) :
            this.rowsSearch.length;

        const length = this.rowsSearch.length || "0";

        return `${_.viewed} ${start} ${"-"} ${max} ${_.of} ${length} ${_.records}`;
    }

    rnd = (s, p) => {
        const rowsCount = this.getSearchRows().length;

        const tab = <div className={this.cln({ tableWrapper: 1, hiddenCols: this.hiddenCols, expand: this.props.expand })}>
            <If is={this.data}>
                <ColSettings
                    ref={this.colStgRef}
                    save={() => this.getData(this.props, true)}
                    recordset={p.recordset}
                    data={this.data}
                    switches={this.getSwitches(this.props, false)}
                />
            </If>
            <div className="tableDataWrapper">
                <If is={!this.props.panel}>
                    <div className="tabButtons">
                        {this.barButtons.map(m => {
                            const vis = m.props.visible ? m.props.visible(m) : true;
                            const icon = typeof m.props.icon === "string" ? createElement(RIcons.Fa["Fa" + m.props.icon]) : m.props.icon;

                            return <Btn type={m.props.type || "add"}
                                key={"bb" + m.props.text}
                                onClick={e => m.props.click(e, this, m)}
                                disabled={m.props.disabled === true}
                                className={this.cln({ dnone: !vis })}>{icon} {m.props.text}
                            </Btn>
                        })}
                        <If is={!this.props.panel && this.props.showMenu !== false}>
                            <ButtonMenu className="customCols" name={_.menu}>
                                <me-nu
                                    className={this.cln({ dnone: this.props.customCols === false || Repo.stg.cs__hideTableFilters })}
                                    onClick={e => this.colStgRef.current.open()}>
                                    <RIcons.Hi.HiOutlineCog /> {_.colStg}
                                </me-nu>
                                <me-nu onClick={e => ((this.showFilters = !this.showFilters), this.refresh())}><RIcons.Fa.FaSearch /> {_.detSearch}</me-nu>
                            </ButtonMenu>
                        </If>

                        {/*<If is={this.props.showSearch !== false}>
                            <RInput placeholder={_.search} state={s} item="searchText" onChange={this.search} />
                            <Btn clean className="searchClear" onClick={this.searchClear}><RIcons.Fa.FaTimes /></Btn>
                        </If>*/}
                        {this.tabBar}
                    </div>
                </If>
                <If is={rowsCount || Object.keys(this.filters).length}>
                    <div
                        className={this.cln({ table: 1, tableLoading: s.tabLoading, [this.props.className]: this.props.className })}
                        ref={this.tabRef}
                        style={{ gridTemplateColumns: `repeat(${this.colsCount}, auto` }}
                    >
                        {/*<div className='thead'>*/}
                        <div className={this.cln({ tr: 1, tabCols: 1, filtersEnabled: this.showFilters })}>
                            <If is={this.hiddenColumnsCount}><th className=""></th></If>
                            {this.createLabels()}
                            <div className="th empty tableHeader"></div>
                        </div>
                        {this.createFilters()}
                        {/*</div>*/}
                        {/*<div className="tbody">*/}
                        {this.getRows()}
                        {/*</div>*/}
                    </div>

                    <div className="tableFooter">
                        <If is={p.showAll !== true && p.showPages !== false}>
                            <ButtonMenu className="tabInfo right" name={this.tabInfo} icon="">
                                <me-nu onClick={e => this.setFilter(10)}><RIcons.Fa.FaList /> 10 {_.records}</me-nu>
                                <me-nu onClick={e => this.setFilter(20)}><RIcons.Fa.FaList /> 20 {_.records}</me-nu>
                                <me-nu onClick={e => this.setFilter(100)}><RIcons.Fa.FaList /> 100 {_.records}</me-nu>
                                <me-nu onClick={e => this.setFilter(1000)}><RIcons.Fa.FaList /> 1000 {_.records}</me-nu>
                                <me-nu onClick={e => this.setFilter(1000000)}><RIcons.Fa.FaList /> {_.All}</me-nu>
                            </ButtonMenu>

                            <div className="tabPages">
                                <ul className="pagination">
                                    <li className="paginate_button prev" onClick={() => this.setPage(0, -1)}><a><RIcons.Io.IoIosArrowBack /></a></li>
                                    {this.getPages()}
                                    <li className="paginate_button next" onClick={() => this.setPage(0, 1)}><a><RIcons.Io.IoIosArrowForward /></a></li>
                                </ul>
                            </div>
                        </If>
                    </div>
                </If>

                <If is={!rowsCount && !Object.keys(this.filters).length}>
                    <div className="noRows">{_.noRecords}</div>
                </If>

            </div>
        </div>

        if (this.props.panel) {
            let menu;

            if (this.props.showMenu !== false) {
                let menuItems = [
                    <me-nu onClick={e => this.colStgRef.current.open()} className={this.changeCols ? "" : "dnone"}><RIcons.Hi.HiOutlineCog /> {_.colStg}</me-nu>,
                    <me-nu onClick={e => ((this.customCols = !this.customCols), this.refresh())}><RIcons.Fa.FaSearch /> {_.detSearch}</me-nu>,
                    ...this.menuButtons.map(m => {
                        const vis = m.props.visible ? m.props.visible(m) : true;
                        const icon = typeof m.props.icon === "string" ? createElement(RIcons.Fa["Fa" + m.props.icon]) : m.props.icon;

                        return <me-nu
                            type={m.props.type || "add"}
                            key={"bb" + m.props.text}
                            onClick={e => m.props.click(e, this, m)}
                            disabled={m.props.disabled === true}
                            className={this.cln({ dnone: !vis })}
                        >
                            {icon} {m.props.text}
                        </me-nu>
                    })
                ]

                menu = <ButtonMenu className="customCols" name={_.menu}>{menuItems}</ButtonMenu>
            }

            return (
                <div className="panel">
                    <header className="flex">
                        {this.props.panel}

                        <div className="tabButtons">
                            {this.barButtons.map(m => {
                                const vis = m.props.visible ? m.props.visible(m) : true;
                                const icon = typeof m.props.icon === "string" ? createElement(RIcons.Fa["Fa" + m.props.icon]) : m.props.icon;

                                return <Btn type={m.props.type || "add"}
                                    key={"bb" + m.props.text}
                                    onClick={e => m.props.click(e, this, m)}
                                    disabled={m.props.disabled === true}
                                    className={this.cln({ dnone: !vis })}>{icon} {m.props.text}
                                </Btn>
                            })}

                            {menu}
                        </div>

                    </header>
                    {tab}
                </div>
            )
        }

        return tab;
    }
}
