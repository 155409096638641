import { SComponent, _, Table, Http, Ref, Rq, route, Comp } from '../dump'
import { loadAriscat } from '../main';

export class Dealers extends SComponent {
    async activate(rec, activate) {
        var [match, code] = rec?.nsDlistSchvalURL?.match(/req=(.*)/);
        var res = await Http.dealerAprRej(code, activate);

        console.log("r", res);

        if(res.error) {
            Ref.notify(res.msg.error, 4, "error");
        }
        else {
            rec.reload();
        }
    }

    login = async rec => {
        if(!rec.nsDlistGuid) {
            rec = await Rq.info("strv_dealer_list", rec.ID);
        }

        var res = await Http.impersonate(rec.nsDlistGuid);

        console.log(res);
        
        if(res && res.length == 3 && res[1] === false) {
            return Ref.notify(_.failSignAsDlr + " " + res[2], 20, 'danger');
        }
        else if(res && res.length != 3) {
            return Ref.notify(failSignAsDlr + " " + _.unknEr, 20, 'error');
        }

        console.log("imp", res);


        Ref.notify(_.dlrLogProg, 2, 'info');
        
        await Http.logout();

        var res = await Http.login({
            user: rec.nsDlistLogin,
            psw: "impersonate:" + res[2],
            port: Repo.port, 
            company: Repo.company, 
            lang: Repo.langNum
        });

        await loadAriscat();

        Ref.notify(_.urLogAsDlr + " " + rec.nsDlistGuid, 8, 'info');
        //route("/deals");
        //await Ariscat.app.loginUser(rec.nsDlistLogin, "impersonate:" + res[2]);
        //location.href = "/";
    }

    rnd = (s, p) => {
        return (
            <Comp className="dealers">
                <Table recordset="strv_dealer_list" cols="1" customCols={false}>
                    <tab-button
                        type="primary"
                        icon="Cog"
                    >
                        <b-item 
                            icon="TimesCircle"
                            text={_.deactivate}
                            visible={r => r.iDlistStav == 2}
                            click={rec => this.activate(rec, false)}
                        />
                        <b-item 
                            icon="CheckCircle"
                            text={_.activate}
                            visible={r => r.iDlistStav == 1 || r.iDlistStav == 3}
                            click={rec => this.activate(rec, true)}
                        />
                        <b-item 
                            icon="Ban"
                            text="Zamítnout"
                            visible={r => r.iDlistStav == 1}
                            click={rec => this.activate(rec, false)}
                        />
                        <b-item 
                            icon="User"
                            text="Přihlásit se jako dealer"
                            visible={r => true}
                            click={this.login}
                        />
                    </tab-button>
                    <tab-col name="ID" type="hide" />
                    <tab-col name="nsDlistDic" type="hide" />
                    <tab-col name="bDlistDph" type="hide" />
                    <tab-col name="bDlistAktivni" type="hide" />
                    <tab-col name="nsDlistSchvalURL" type="hide" />
                    <tab-col name="bDlistPlatny" type="hide" />
                    <tab-col name="iDlistJazyk" type="hide" />
                    <tab-col name="iDlistLicLevel" type="hide" />
                    <tab-col name="iDlistStav" type="hide" />
                    <tab-col name="iDlistZamID" type="hide" />
                    <tab-col name="nsDlistIco" type="hide" />
                    <tab-col name="nsDlistLogin" type="hide" />
                    <tab-col name="nsDlistMena" type="hide" />
                    <tab-col name="nsDlistMesto" type="hide" />
                    <tab-col name="nsDlistPsc" type="hide" />
                    <tab-col name="nsDlistTelefon" type="hide" />
                    <tab-col name="nsDlistUlice" type="hide" />
                    <tab-col name="nsDlistZamitURL" type="hide" />
                    <tab-col name="nsDlistZeme" type="hide" />
                </Table>
            </Comp>
        )
    }
}