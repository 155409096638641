import {
	Http, SComponent, HSelect, RInput, Checkbox, _,
	route, RIcons, Ref, Table, Ariscat, Repo, Btn
} from "../../dump";

import "./styl.scss";

export class Mask extends SComponent {
	async cmount(s, p) {
		this.podskp = [];
		this.masks = {};
		this.grid = {};
		this.layout = {};
		this.html = "";
		this.tree = {};
		this.langID = 1;
		this.readOnly = false;
		this.isPM = false;
		this.badFil = {};
		this.saved = false;
		this.producer = this.props.producer;// || "4C3F9E7D-E734-4492-B15D-3681DB8BB130";
		this.ware = this.props.ID || this.props.ware || "BA02DBA5-BBF6-44D6-B756-78E1DC4CE13E";
		this.isPM = this.props.pm || false;
		this.type = this.props.type || "MASTER";
		this.filled = {};
		this.mobj = {};
		//this.vrbc = "C6A8F803-BEDA-47A6-B0FE-9BEFE5FDD420"
		//this.produkt = "680B22F3-1A10-40E2-B4CF-C6D8C798696A";
		//this.produkt = "87265D63-408C-46CC-B823-E14F3B56BDA2";

		if (this.isPM) {
			/*
			var maskPM = file_get_contents(realpath(dirname(__FILE__)+'/')+ '/MaskPM.msk');
			var xml = str_replace("\n","_tran_", maskPM); 
			this.xml = json_decode(json_encode(simplexml_load_string(xml)), true);	     
			*/
		}
		else if (this.props.mask) {
			this.mask = this.props.mask[0] || {};
		}
		else {
			var mask = await Http.getMask(this.producer, this.ware);
			this.mask = mask[0] || {};
		}

		const arisVals = this.props.data?.nsPolArisValues || this.props.data?.nsPozArisValues;

		//nacte vyplnene hodnoty
		if (arisVals) {
			for (let val of arisVals.split("\n")) {
				let [k, v] = val.split("=");
				this.filled[k] = v;
			}
		}
		/*
		else {
			var data = await Rq.info("polozka", 690927, {iZakID:"171421", iPoziceID:"169333"})

			for(let val of arisVals.split("\n")) {
				let [k,v] = val.split("=");
				this.filled[k] = v;
			}
		}
		*/

		this.readOnly = (this.props.readOnly === true);

		//vytvori objekty
		for (var msk of this.mask.Object || []) {
			const mType = msk.MainParent;

			if (!this.masks[mType]) {
				this.masks[mType] = {};
			}

			this.mobj[msk.ID] = msk;
			this.masks[mType][msk.ID] = msk;

			msk.sDefValue = this.filled[msk.ColName] || msk.sDefValue;

			//VYBER ZE SKLADU
			if (msk.TypLECG == "S") {
				//console.log("SKLAD", msk);
				msk.sNameValue = (await Http.getMaskSKDescr({ stockNo: msk.sDefValue, prodCode: this.ware, vrbcCode: this.producer }))?.cardDescr || msk.sDefValue;
			}
		}

		/*this.getPodskp();*/

		//vytvori objektovy strom
		for (var [mType, mtMasks] of Object.entries(this.masks)) {
			this.tree[mType] = this.generateTree(Object.values(mtMasks), undefined);
			this.grid[mType] = this.createLayout(this.tree[mType], mType);
		}

		//zvyrazni spate vyplneny hodnoty
		if (this.props.selectElement) {
			this.saved = true;//true;
		}

		this.refresh();
	}

	componentDidMount() {
		setTimeout(() => {
			this.onLoad && this.onLoad(this);
		}, 100);
	}

	rnd(s, p) {
		//console.log("render", this.props.type, this.masks.MASTER);

		if (this.props.type == "MASTER" && !this.masks.MASTER) {
			return <div className="msk NO-MASTER dnone"></div>
		}
		else if (this.props.type == "DETAIL" && !this.masks.DETAIL) {
			return <div className="msk NO-DETAIL dnone"></div>
		}

		return (
			<div className={"msk " + this.props.type}>
				<div className="dnone">
					<Btn onClick={() => route("/mask/51066A33-2699-41A2-B550-91E89391D786")}>Roleta</Btn>
					<Btn onClick={() => route("/mask/6AA9B5BA-B021-4555-BC26-DCA818C2ECA8")}>Plošina CPM 300-2</Btn>
					<Btn onClick={() => route("/mask/D5472E37-E273-45F0-87A0-9BB77AF652B8")}>Vinyl</Btn>
					<Btn onClick={() => route("/mask/54F104F6-59F1-49F8-BADF-871A0A6E41A7")}>Dveře</Btn>
					<Btn onClick={() => route("/mask/BA02DBA5-BBF6-44D6-B756-78E1DC4CE13E")}>Roleta</Btn>
					<Btn onClick={() => route("/mask/4B57AAD4-8514-445D-ADDE-6512AD57F186")}>Colognia</Btn>
					<Btn onClick={() => route("/mask/8A8D1201-6796-4F57-836E-9B030EDE20D5")}>Sítě</Btn>
				</div>

				<div className="mask">{this.generateMsk()}</div>
			</div>
		)
	}

	generateMsk() {
		//console.log("gr", this.grid, this.type, this.grid[this.type]);
		return (<div className={this.cln({ validate: 1, saved: this.saved })}>
			{this.podskp.map(ps => <div className="podSkp" skp={ps.skp} var={ps.var} val={ps.val} style={{ display: "none" }}></div>)}
			<div className={"mask-layout " + this.props.type}>{this.drawLayout(this.grid[this.type] || {}, this.type, 12)}</div>
			<hr />
			{this.createButtons(this.type)}
		</div>)
	}

	drawLayout(masx) {
		var amasx = Object.values(masx.Objects || masx);

		return amasx.map(val => {
			//row nebo col
			if (typeof val === "string") {
				return [];
			}
			else if (val.type == "row") {
				return (<div className="row">{this.drawLayout(val)}</div>)
			}
			else if (val.type == "col") {
				return <div className={"col col-" + Math.floor(12 / (amasx.length - 1))}>{this.drawLayout(val)}</div>
			}
			else if (val.TypLECG == 'P') {
				return <div className="mask-panel">{this.drawLayout(val)}</div>
			}
			else if (val.TypLECG == 'G') {
				return (
					<fieldset data-id={val.ID} className={"element" + (val.Visible == 0 ? ' hidden' : '')}>
						<legend><span>{this.getTranslation(val.Caption, val.CaptionTranslations)}</span></legend>
						{this.drawLayout(val, "G")}
					</fieldset>
				)
			}
			else if (val.ID && !val?.Object?.length) {
				return <div className={this.cln({ element: 1, hidden: val.Visible == 0 })} data-id={val.ID}>{this.generateType(val)}</div>
			}
			else { // to by se mohlo stat jen pokud by maska obsahovala novou komponentu
				console.error("neznama komponenta v masce!", masx);
				return "?";
			}
		});
	}

	changeItem = (id, key, item) => {
		//pokud je jen 1 akce, tak je jako objekt!
		var actions = Array.isArray(item.Action || []) ? (item.Action || []) : [item.Action];

		//console.log("CH", item);

		for (let ac of actions) {
			var changob = this.mobj[ac.ID_OBJECT];

			//pokud se nenajde ID objektu, to znamena ze v masce tento objekt neni
			if (changob === undefined) continue;

			var refreshItem = false;

			//console.log("chg",ac.ID_OBJECT, actions);

			//pokud byl pred tim prvek {(zakazan & skryt) & (ma se obnovit) & (ma prazdnou hodnotu)}, doplni se vychozi
			if (changob.Enabled == 0 && changob.Visible == 0 && changob.sDefValue == "" && ac.HidSh == 2 && ac.DisEn == 2) {
				changob.sDefValue = changob.origValue;
				refreshItem = true;
			}

			//nastavit min a max hodnotu
			if (ac.bSetMinValue != 0) changob.fMinValue = ac.fMinValue;
			if (ac.bSetMaxValue != 0) changob.fMaxValue = ac.fMaxValue;

			//zakazat nebo povolit prvek
			if (ac.DisEn == 1) changob.Enabled = 0;
			else if (ac.DisEn == 2) changob.Enabled = 1;

			//schovat nebo zobrazit prvek
			if (ac.HidSh == 1) changob.Visible = 0;
			else if (ac.HidSh == 2) changob.Visible = 1;

			//pokud byl prvek obnoven, nema smysl nulovat hodnotu
			if (ac.SetIt != 0 && ac.NullIt == 1 && !refreshItem) {
				changob.sDefValue = ac.NullIt == 1 ? null : ac.NewValue;
			}
		}


		//pokud select meni obrazek, je potreba hodnotu ziskat z "item" a najit obrazek podle data-boundcmb
		if (this.mobj[item?.ID_OBJECT]?.driveImage) {
			for (var img of Object.values(RI.Mask.mobj).filter(f => f.BoundCmb == item.ID_OBJECT)) {
				img.PicturePath = item.Value + ".jpg";
			}
		}

		this.checkFil(Object.values(this.masks[this.type]), this.type);
		this.refresh();
	}

	formatImageItem = item => {
		return (<div className="imageItem"><img src={`${Repo.serverURL}/api/maskImg/VRBC/${this.producer}/MSK_JPG/${item.nsImgFName}`} />{item.Value}</div>)
	}

	miniMax(item) {
		return [
			(item.fMinValue ? "Min: " + +item.fMinValue : null),
			(item.fMaxValue ? "Max: " + +item.fMaxValue : null)
		].filter(f => f != null).join(", ") || "";
	}

	generateType(item) {
		item.origValue = item.origValue || item.sDefValue;
		var caption = this.createCaption(item);

		switch (item.TypLECG) {
			case "C":
				item.driveImage = (item.PicturePath || "").includes(`/bDrivesPic:"1"`);

				//item.bZobrExtVyber
				//<h-op format={r => r.nsZakCislo + (r.nsZakOznaceni ? " - " + r.nsZakOznaceni : "")} />

				let items = Ariscat.arrayObj(item.Item);
				const emptyVal = "----"

				const emptyItemIndex = items.findIndex(f => f.Value == emptyVal);

				if (emptyItemIndex === -1) {
					const emptyItem = {
						_html: emptyVal,
						Value: emptyVal,
						ActionsDefined: "0",
						ID: 0
					};
					items.unshift(emptyItem); 
				} else if (emptyItemIndex !== 0) {
					const emptyItem = items.splice(emptyItemIndex, 1)[0];
					items.unshift(emptyItem);
				}

				return (<HSelect
					name={caption}
					state={item}
					item="sDefValue"
					values={items.sort((a,b) => {
						if(b.Value == emptyVal) {
							b._empty = true;
							return 1;
						}

						return a.ActionsDefined - b.ActionsDefined;
					})}
					label="Value"
					uid="Value"
					className={this.cln({ [item.Name]: 1, ["sel" + item.ID]: 1, required: item.MustBeFld == 1, invalid: this.badFil[item.ID] })}
					disabled={item.Enabled != "1" || this.readOnly}
					onChange={this.changeItem}
					onLoad={this.changeItem} //akce se musi zpracovat uz pri nacteni masky!
					style={{
						minWidth: item.Width + "px",
						maxWidth: item.Width == "100%" ? null : item.Width * 1.5 + "px"
					}}
					empty={emptyVal}
				>
					<h-op format={item.bZobrExtVyber == "1" ? this.formatImageItem : null} />
				</HSelect>
				)
			case "E": //input
			case "S": //sklad
			case "M": //textarea
				return (<RInput
					name={caption}
					state={item}
					type={item.TypLECG == "M" ? "tarea" : (item.EditDataType == 'S' || item.EditDataType == "" ? "text" : "number")}
					step={item.EditDataType == 'F' ? "any" : ""}
					min={item.fMinValue}
					max={item.fMaxValue}
					item={item.TypLECG == "S" ? "sNameValue" : "sDefValue"}
					disabled={item.Enabled != "1" || this.readOnly || item.TypLECG == "S"}
					className={this.cln({ [item.Name]: 1, required: item.MustBeFld == 1, invalid: this.badFil[item.ID] })}
					onChange={this.changeItem}
					style={{ minWidth: item.Width + "px", maxWidth: item.Width == "100%" ? null : item.Width * 1.5 + "px" }}
					button={item.TypLECG == "S" ? "..." : ""}
					buttonClick={e => !this.readOnly && this.loadStock(item)}
					title={this.miniMax(item)}
				/>)
			case "K":
				return (<Checkbox
					name={caption}
					state={item}
					item="sDefValue"
					disabled={item.Enabled != "1"}
					className={this.cln({ [item.Name]: 1, required: item.MustBeFld == 1, invalid: this.badFil[item.ID] })}
					onCheck={(ch, key, data) => this.changeItem(ch, key, item)}
				//style={{width: item.Width + "px"}}
				/>)
			case 'I':
				return <img
					src={`/api/maskImg/VRBC/${this.producer}/MSK_JPG/${item.PicturePath}`}
					data-boundcmb={item.BoundCmb}
					data-id={item.ID}
				/>
			case 'L':
				return <div className="row captionText">{caption}</div>
			default:
				//console.log("def", mask.TypLECG, mask)
				return "?typ: " + item.TypLECG;
		}
	}

	async openStock(item) {
		const res = await Ref.modal2.open(item.Caption,
			<Table
				rows={item.Item.filter(f => !f._empty)}
				customCols={true}
				// customCols={false}
				detail={row => Ref.modal2.close(row)}
			>
				<tab-col name="Value" caption={_.type} />
				<tab-col
					name="nsImgFName"
					caption={_.preview}
					type="format"
					format={f => <img alt="" src={`/api/maskImg/VRBC/${this.producer}/MSK_JPG/${f.nsImgFName}`} />}
				/>
			</Table>
		);

		if (res?.Value) {
			item.sDefValue = res.Value;
			this.refresh();
		}
	}

	async loadStock(item) {
		const res = await Ref.modal2.open(item.Caption,
			<Table
				recordset="skscenou"
				sw_nsSkladSkupKod={item.guidSkladSkup}
				sw_nsVrbcKod={this.producer}
				sw_nsCilMenaKod="EUR"
				sw_iPoziceID="null"
				sw_nsPrdKod={this.ware}
				customCols={false}
				detail={row => Ref.modal2.close(row)}
			>
				<tab-col name="nsSkPicSHA256_RURL" type="format" format={f => {
					const url = `/api/maskImg${f.nsSkPicSHA256_RURL?.replace("<nsSkVrbcKod>", this.producer)

						}`;

					return (f.nsSkPicSHA256_RURL ? <img
						onMouseOver={e => Ref.tooltip.open(<img src={url} />, e)}
						onMouseLeave={e => (e.stopPropagation(), Ref.tooltip.close())}
						className="tabStockImg"
						src={url}
					/> : [])
				}
				} />
			</Table>
		);

		//console.log("res", res);

		if (res?.nsSkCislo) {
			//console.log("cislo", res.nsSkCislo, item);
			item.sDefValue = res.nsSkCislo;
			item.sNameValue = res.nsSkMaskSKDescr || res.nsSkCislo;
			this.refresh();
		}
	}

	createCaption(item) {
		var caption = item.Caption == "undefined" ? "" : item.Caption;

		//console.log("cap", caption, item);
		var showHint = item.ShowHint == 1 && item.Hint != "";
		var hint = (item.Hint || "");//.replaceAll("\n", "<br>");
		var hintText = this.getTranslation(hint, item.HintTranslations).replaceAll("&#x09;", "").split("\n").map(m => <div className="hintxt">{m || " "}</div>)
		var link = item.nsHelpURL || "";
		var linkHtml = [];

		if (link) {
			const codes = ["CZ", "EN", "DE", "SK", "FR"];
			link = link.replaceAll("<LNG_CODE>", codes[this.langID]);
		}

		if (item.TypLECG == 'C' && item.bZobrExtVyber == 1 && item.nsHelpURL) {
			linkHtml = <a className="combo-link" tabindex="-1" select-id={item.ID} href={link}><RIcons.Fa.FaFolderOpen /></a>
		}
		else if (item.nsHelpURL) {
			linkHtml = <a className="link windowLink fas fa-external-link-alt noSave" tabindex="-1" wWidth="1000" wHeight="800" href={link}></a>
		}
		else if (item.TypLECG == 'C' && item.bZobrExtVyber == 1) {
			linkHtml = <Btn open className="combo-link" tabindex="-1" select-id={item.ID} onClick={e => this.openStock(item)}><RIcons.Fa.FaFolderOpen /></Btn>
		}

		return (
			<div className={this.cln({ caption: 1, [`text${item.AutoLabAlign || "Left"}`]: 1, "has-hint": showHint })}>
				{showHint &&
					<span onMouseOver={e => Ref.tooltip.open(hintText, e)} onMouseLeave={e => (e.stopPropagation(), Ref.tooltip.close())}>
						<span className="mask-hint"><RIcons.Fa.FaInfoCircle /></span>{caption}
					</span>}
				{!showHint && caption}
				{linkHtml}
			</div>
		)
	}



	checkFil(vals, type = "MASTER") {
		vals = vals || Object.values(this.masks[type])
		this.badFil = {};

		const badFil = vals.filter(f =>
			(f.MustBeFld == "1" && f.Visible == "1" && f.Enabled == "1") && (
				(!f.sDefValue || f.sDefValue == "----") ||
				(f.fMinValue && (+f.sDefValue < +f.fMinValue || !Ariscat.isNumber(f.sDefValue))) ||
				(f.fMaxValue && (+f.sDefValue > +f.fMaxValue || !Ariscat.isNumber(f.sDefValue)))
			));

		badFil.forEach(f => this.badFil[f.ID] = f);

		return badFil;
	}

	save = (type = "MASTER", duplicate = false) => {
		this.saved = true;
		var vals = Object.values(this.masks[type]);
		var badFil = this.checkFil(vals, type);

		//console.log("badf", badFil);

		var saved = vals.filter(f =>
			f.Visible == "1" &&
			f.Enabled == "1" &&
			f.TypLECG != "P" &&
			f.TypLECG != "G" &&
			f.TypLECG != "I" &&
			f.TypLECG != "L"
		).map(m => {
			const val = m.sDefValue === true ? "1" :
				m.sDefValue === false ? "0" :
					m.sDefValue;

			return (m.ColName + "=" + val + "\n");
		}).join("");

		var savObj = {
			duplicate,
			vals,
			text: saved,
			badFil,
			ok: !badFil.length,
			record: this.props.data
		}

		this.props.onSave && this.props.onSave(savObj);

		this.refresh();

		return savObj;
	}

	createButtons(mType) {
		const badFilNum = Object.keys(this.badFil).length;

		if (mType == "MASTER" && !this.readOnly)
			return <div className="modal-buttons"><Btn className="btn saveMaster" onClick={e => this.save("MASTER")} disabled={badFilNum}>{_.save}</Btn></div>
		else if (mType == 'DETAIL' && !this.readOnly) {
			return (
				<div className="modal-buttons">
					<Btn edit className="btn saveDuplicate" onClick={e => this.save("DETAIL", true)} /*disabled={badFilNum}*/>{_.save_and_dupl}</Btn>
					<Btn save className="btn saveDetail" onClick={e => this.save("DETAIL")} /*disabled={badFilNum}*/>{_.save}</Btn>
					<Btn cancel className="btn cancelMask" onClick={Ref.modal.close}>{_.cancel}</Btn>
				</div>
			)
		}
		else if (mType == 'DETAIL' && this.readOnly) {
			return (
				<div className="">
					<Btn close className="cancelMask">{_.close}</Btn>
				</div>
			)
		}
	}

	/* TODO: Podskupiny */
	getPodskp() { //Získá z masky podskupiny
		var podskpArray = [];

		for (var val of this.mask['podskp']) {
			var objskp = {};
			objskp.skp = val['skp'];
			objskp.var = val['var'];
			objskp.val = val['val'];
			podskpArray = [objskp];
		}

		this.podskp = podskpArray;
	}

	generateTree(arr, parentID) {
		return arr.filter(f => f.Parent === parentID).map(mask => {
			mask.Object = this.generateTree(arr, mask.ID);
			return mask
		})
	}

	createLayout(masx, mask) {
		masx.sort((a, b) => {
			var rdiff = +(a.Y) - +(b.Y);

			if (rdiff) return rdiff;

			return +(a.X) - +(b.X)
		});

		var rowsCols = {};
		var i = 0;

		for (var mask of masx) {
			if (mask) {
				//Rozložení na řádky								
				var y = mask.Y;

				if (!rowsCols[y]) rowsCols[y] = {};

				rowsCols[y].type = 'row';

				//Rozložení na sloupce
				var x = mask.X;

				if (!rowsCols[y][x]) rowsCols[y][x] = {};

				rowsCols[y][x].type = 'col';

				if (mask['Col-md']) {
					rowsCols[y][x]['col-md'] = mask['Col-md'];
				}

				rowsCols[y][x][i] = mask;

				if (mask.Item) {
					rowsCols[y][x][i].Item = mask.Item;
				}

				if (mask.Object) {
					rowsCols[y][x][i].Objects = this.createLayout(mask.Object, mask);
				}
			}

			i++;
		}

		return rowsCols;
	}

	isObj(obj) {
		return (
			typeof obj === 'object' &&
			!Array.isArray(obj) &&
			obj !== null
		)
	}

	getTranslation(defaultParam, tranParams) {
		if (!tranParams) return defaultParam;

		return tranParams.split("\n")?.find(f => f.startsWith(this.langID + "="))?.substr(2) || defaultParam;
	}
}

async function mas() {
	var dMask = new DynamicMask();
	dMask.langID = 2;//this.lang;
	//maska.translate = this.translations;
	dMask.isPM = false;
	dMask.readOnly = false;

	//venkovni zaluzie
	//await dMask.dynamicMaskInit("4C3F9E7D-E734-4492-B15D-3681DB8BB130", "51066A33-2699-41A2-B550-91E89391D786");

	//roleta
	await dMask.dynamicMaskInit("4C3F9E7D-E734-4492-B15D-3681DB8BB130", "BA02DBA5-BBF6-44D6-B756-78E1DC4CE13E");

	//plastove dvere
	//await dMask.dynamicMaskInit("4C3F9E7D-E734-4492-B15D-3681DB8BB130", "54F104F6-59F1-49F8-BADF-871A0A6E41A7");



	return dMask;
}

window.mk = mas;